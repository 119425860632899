/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoModelsAppInfo,
    MelcoModelsAppInfoFromJSON,
    MelcoModelsAppInfoFromJSONTyped,
    MelcoModelsAppInfoToJSON,
    MelcoModelsDevice,
    MelcoModelsDeviceFromJSON,
    MelcoModelsDeviceFromJSONTyped,
    MelcoModelsDeviceToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoModelsVirtualAuth
 */
export interface MelcoModelsVirtualAuth {
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsVirtualAuth
     */
    user_name: string;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsVirtualAuth
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsVirtualAuth
     */
    token?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsVirtualAuth
     */
    product_code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsVirtualAuth
     */
    version_number?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsVirtualAuth
     */
    validation_string?: string | null;
    /**
     * 
     * @type {MelcoModelsDevice}
     * @memberof MelcoModelsVirtualAuth
     */
    device_info: MelcoModelsDevice;
    /**
     * 
     * @type {MelcoModelsAppInfo}
     * @memberof MelcoModelsVirtualAuth
     */
    app_info?: MelcoModelsAppInfo;
}

export function MelcoModelsVirtualAuthFromJSON(json: any): MelcoModelsVirtualAuth {
    return MelcoModelsVirtualAuthFromJSONTyped(json, false);
}

export function MelcoModelsVirtualAuthFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsVirtualAuth {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user_name': json['user_name'],
        'password': json['password'],
        'token': !exists(json, 'token') ? undefined : json['token'],
        'product_code': !exists(json, 'product_code') ? undefined : json['product_code'],
        'version_number': !exists(json, 'version_number') ? undefined : json['version_number'],
        'validation_string': !exists(json, 'validation_string') ? undefined : json['validation_string'],
        'device_info': MelcoModelsDeviceFromJSON(json['device_info']),
        'app_info': !exists(json, 'app_info') ? undefined : MelcoModelsAppInfoFromJSON(json['app_info']),
    };
}

export function MelcoModelsVirtualAuthToJSON(value?: MelcoModelsVirtualAuth | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_name': value.user_name,
        'password': value.password,
        'token': value.token,
        'product_code': value.product_code,
        'version_number': value.version_number,
        'validation_string': value.validation_string,
        'device_info': MelcoModelsDeviceToJSON(value.device_info),
        'app_info': MelcoModelsAppInfoToJSON(value.app_info),
    };
}


