/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoModelsUserImage,
    MelcoModelsUserImageFromJSON,
    MelcoModelsUserImageFromJSONTyped,
    MelcoModelsUserImageToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoFusionModelsUpdateBlank
 */
export interface MelcoFusionModelsUpdateBlank {
    /**
     * 
     * @type {string}
     * @memberof MelcoFusionModelsUpdateBlank
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoFusionModelsUpdateBlank
     */
    canvas_width?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoFusionModelsUpdateBlank
     */
    canvas_height?: number;
    /**
     * 
     * @type {Array<MelcoModelsUserImage>}
     * @memberof MelcoFusionModelsUpdateBlank
     */
    images?: Array<MelcoModelsUserImage> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MelcoFusionModelsUpdateBlank
     */
    colors?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MelcoFusionModelsUpdateBlank
     */
    views?: Array<string> | null;
}

export function MelcoFusionModelsUpdateBlankFromJSON(json: any): MelcoFusionModelsUpdateBlank {
    return MelcoFusionModelsUpdateBlankFromJSONTyped(json, false);
}

export function MelcoFusionModelsUpdateBlankFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoFusionModelsUpdateBlank {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'canvas_width': !exists(json, 'canvas_width') ? undefined : json['canvas_width'],
        'canvas_height': !exists(json, 'canvas_height') ? undefined : json['canvas_height'],
        'images': !exists(json, 'images') ? undefined : (json['images'] === null ? null : (json['images'] as Array<any>).map(MelcoModelsUserImageFromJSON)),
        'colors': !exists(json, 'colors') ? undefined : json['colors'],
        'views': !exists(json, 'views') ? undefined : json['views'],
    };
}

export function MelcoFusionModelsUpdateBlankToJSON(value?: MelcoFusionModelsUpdateBlank | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'canvas_width': value.canvas_width,
        'canvas_height': value.canvas_height,
        'images': value.images === undefined ? undefined : (value.images === null ? null : (value.images as Array<any>).map(MelcoModelsUserImageToJSON)),
        'colors': value.colors,
        'views': value.views,
    };
}


