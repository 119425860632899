/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoModelsCanvasPreview
 */
export interface MelcoModelsCanvasPreview {
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsCanvasPreview
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsCanvasPreview
     */
    url?: string | null;
}

export function MelcoModelsCanvasPreviewFromJSON(json: any): MelcoModelsCanvasPreview {
    return MelcoModelsCanvasPreviewFromJSONTyped(json, false);
}

export function MelcoModelsCanvasPreviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsCanvasPreview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'url': !exists(json, 'url') ? undefined : json['url'],
    };
}

export function MelcoModelsCanvasPreviewToJSON(value?: MelcoModelsCanvasPreview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'url': value.url,
    };
}


