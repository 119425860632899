import { FusionMelcoModelsDesign, convertUnit } from "melco-shared-logic";
import { useBasicSettings } from "../../settings/useBasicSettings";

export const useDesignSize = (design: FusionMelcoModelsDesign) => {
  const { settings, isLoading } = useBasicSettings();

  const { width, height, unit } = design;

  if (!width || !height || !unit || unit.toLowerCase() !== "points") {
    return [isLoading, ""] as const;
  }

  const targetUnit = settings?.measurement_unit === "english" ? "inch" : "mm";

  return [
    isLoading,
    `${convertUnit({
      value: width,
      from: "points",
      to: targetUnit,
    }).toFixed(2)} x ${convertUnit({
      value: height,
      from: "points",
      to: targetUnit,
    }).toFixed(2)} ${targetUnit}`,
  ] as const;
};
