/* tslint:disable */
/* eslint-disable */
/**
 * Melco Efs APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EfsMelcoEfsModelsMappingListItem
 */
export interface EfsMelcoEfsModelsMappingListItem {
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoEfsModelsMappingListItem
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoEfsModelsMappingListItem
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EfsMelcoEfsModelsMappingListItem
     */
    global?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EfsMelcoEfsModelsMappingListItem
     */
    configured?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoEfsModelsMappingListItem
     */
    format_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoEfsModelsMappingListItem
     */
    format_key?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoEfsModelsMappingListItem
     */
    config_type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoEfsModelsMappingListItem
     */
    service_type?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof EfsMelcoEfsModelsMappingListItem
     */
    created_date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EfsMelcoEfsModelsMappingListItem
     */
    updated_date?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof EfsMelcoEfsModelsMappingListItem
     */
    mapping_condition_count?: number;
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoEfsModelsMappingListItem
     */
    design_name?: string | null;
}

export function EfsMelcoEfsModelsMappingListItemFromJSON(json: any): EfsMelcoEfsModelsMappingListItem {
    return EfsMelcoEfsModelsMappingListItemFromJSONTyped(json, false);
}

export function EfsMelcoEfsModelsMappingListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): EfsMelcoEfsModelsMappingListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'global': !exists(json, 'global') ? undefined : json['global'],
        'configured': !exists(json, 'configured') ? undefined : json['configured'],
        'format_name': !exists(json, 'format_name') ? undefined : json['format_name'],
        'format_key': !exists(json, 'format_key') ? undefined : json['format_key'],
        'config_type': !exists(json, 'config_type') ? undefined : json['config_type'],
        'service_type': !exists(json, 'service_type') ? undefined : json['service_type'],
        'created_date': !exists(json, 'created_date') ? undefined : (new Date(json['created_date'])),
        'updated_date': !exists(json, 'updated_date') ? undefined : (json['updated_date'] === null ? null : new Date(json['updated_date'])),
        'mapping_condition_count': !exists(json, 'mapping_condition_count') ? undefined : json['mapping_condition_count'],
        'design_name': !exists(json, 'design_name') ? undefined : json['design_name'],
    };
}

export function EfsMelcoEfsModelsMappingListItemToJSON(value?: EfsMelcoEfsModelsMappingListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'global': value.global,
        'configured': value.configured,
        'format_name': value.format_name,
        'format_key': value.format_key,
        'config_type': value.config_type,
        'service_type': value.service_type,
        'created_date': value.created_date === undefined ? undefined : (value.created_date.toISOString()),
        'updated_date': value.updated_date === undefined ? undefined : (value.updated_date === null ? null : value.updated_date.toISOString()),
        'mapping_condition_count': value.mapping_condition_count,
        'design_name': value.design_name,
    };
}


