/* tslint:disable */
/* eslint-disable */
/**
 * Melco Reporting APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportingMelcoMachineStatisticsModelsStatisticListItem
 */
export interface ReportingMelcoMachineStatisticsModelsStatisticListItem {
    /**
     * 
     * @type {string}
     * @memberof ReportingMelcoMachineStatisticsModelsStatisticListItem
     */
    group_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportingMelcoMachineStatisticsModelsStatisticListItem
     */
    group_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportingMelcoMachineStatisticsModelsStatisticListItem
     */
    machine_serial_number?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportingMelcoMachineStatisticsModelsStatisticListItem
     */
    machine_name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReportingMelcoMachineStatisticsModelsStatisticListItem
     */
    stitches?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportingMelcoMachineStatisticsModelsStatisticListItem
     */
    runs?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportingMelcoMachineStatisticsModelsStatisticListItem
     */
    thread_breaks?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportingMelcoMachineStatisticsModelsStatisticListItem
     */
    total_running_time?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportingMelcoMachineStatisticsModelsStatisticListItem
     */
    total_idle_time?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportingMelcoMachineStatisticsModelsStatisticListItem
     */
    total_error_time?: number;
}

export function ReportingMelcoMachineStatisticsModelsStatisticListItemFromJSON(json: any): ReportingMelcoMachineStatisticsModelsStatisticListItem {
    return ReportingMelcoMachineStatisticsModelsStatisticListItemFromJSONTyped(json, false);
}

export function ReportingMelcoMachineStatisticsModelsStatisticListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportingMelcoMachineStatisticsModelsStatisticListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'group_id': !exists(json, 'group_id') ? undefined : json['group_id'],
        'group_name': !exists(json, 'group_name') ? undefined : json['group_name'],
        'machine_serial_number': !exists(json, 'machine_serial_number') ? undefined : json['machine_serial_number'],
        'machine_name': !exists(json, 'machine_name') ? undefined : json['machine_name'],
        'stitches': !exists(json, 'stitches') ? undefined : json['stitches'],
        'runs': !exists(json, 'runs') ? undefined : json['runs'],
        'thread_breaks': !exists(json, 'thread_breaks') ? undefined : json['thread_breaks'],
        'total_running_time': !exists(json, 'total_running_time') ? undefined : json['total_running_time'],
        'total_idle_time': !exists(json, 'total_idle_time') ? undefined : json['total_idle_time'],
        'total_error_time': !exists(json, 'total_error_time') ? undefined : json['total_error_time'],
    };
}

export function ReportingMelcoMachineStatisticsModelsStatisticListItemToJSON(value?: ReportingMelcoMachineStatisticsModelsStatisticListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'group_id': value.group_id,
        'group_name': value.group_name,
        'machine_serial_number': value.machine_serial_number,
        'machine_name': value.machine_name,
        'stitches': value.stitches,
        'runs': value.runs,
        'thread_breaks': value.thread_breaks,
        'total_running_time': value.total_running_time,
        'total_idle_time': value.total_idle_time,
        'total_error_time': value.total_error_time,
    };
}


