/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoModelsLocaleCultureString,
    MelcoModelsLocaleCultureStringFromJSON,
    MelcoModelsLocaleCultureStringFromJSONTyped,
    MelcoModelsLocaleCultureStringToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoModelsLocaleString
 */
export interface MelcoModelsLocaleString {
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsLocaleString
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsLocaleString
     */
    key?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsLocaleString
     */
    source?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsLocaleString
     */
    module?: string | null;
    /**
     * 
     * @type {Array<MelcoModelsLocaleCultureString>}
     * @memberof MelcoModelsLocaleString
     */
    strings?: Array<MelcoModelsLocaleCultureString> | null;
}

export function MelcoModelsLocaleStringFromJSON(json: any): MelcoModelsLocaleString {
    return MelcoModelsLocaleStringFromJSONTyped(json, false);
}

export function MelcoModelsLocaleStringFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsLocaleString {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'key': !exists(json, 'key') ? undefined : json['key'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'module': !exists(json, 'module') ? undefined : json['module'],
        'strings': !exists(json, 'strings') ? undefined : (json['strings'] === null ? null : (json['strings'] as Array<any>).map(MelcoModelsLocaleCultureStringFromJSON)),
    };
}

export function MelcoModelsLocaleStringToJSON(value?: MelcoModelsLocaleString | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'key': value.key,
        'source': value.source,
        'module': value.module,
        'strings': value.strings === undefined ? undefined : (value.strings === null ? null : (value.strings as Array<any>).map(MelcoModelsLocaleCultureStringToJSON)),
    };
}


