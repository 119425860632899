/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoModelsSubscriptionListItem
 */
export interface MelcoModelsSubscriptionListItem {
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsSubscriptionListItem
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsSubscriptionListItem
     */
    serial_number?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsSubscriptionListItem
     */
    display_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsSubscriptionListItem
     */
    product_code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsSubscriptionListItem
     */
    product_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsSubscriptionListItem
     */
    batch?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsSubscriptionListItem
     */
    referral?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsSubscriptionListItem
     */
    product_level_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsSubscriptionListItem
     */
    product_version_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsSubscriptionListItem
     */
    product_version_number?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsSubscriptionListItem
     */
    product_level_code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsSubscriptionListItem
     */
    product_level_short_code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsSubscriptionListItem
     */
    product_level_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsSubscriptionListItem
     */
    product_level_description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoModelsSubscriptionListItem
     */
    is_trial_available?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MelcoModelsSubscriptionListItem
     */
    number_trial_days?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoModelsSubscriptionListItem
     */
    saleable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsSubscriptionListItem
     */
    buy_url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsSubscriptionListItem
     */
    details_url?: string | null;
}

export function MelcoModelsSubscriptionListItemFromJSON(json: any): MelcoModelsSubscriptionListItem {
    return MelcoModelsSubscriptionListItemFromJSONTyped(json, false);
}

export function MelcoModelsSubscriptionListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsSubscriptionListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'serial_number': !exists(json, 'serial_number') ? undefined : json['serial_number'],
        'display_name': !exists(json, 'display_name') ? undefined : json['display_name'],
        'product_code': !exists(json, 'product_code') ? undefined : json['product_code'],
        'product_name': !exists(json, 'product_name') ? undefined : json['product_name'],
        'batch': !exists(json, 'batch') ? undefined : json['batch'],
        'referral': !exists(json, 'referral') ? undefined : json['referral'],
        'product_level_id': !exists(json, 'product_level_id') ? undefined : json['product_level_id'],
        'product_version_id': !exists(json, 'product_version_id') ? undefined : json['product_version_id'],
        'product_version_number': !exists(json, 'product_version_number') ? undefined : json['product_version_number'],
        'product_level_code': !exists(json, 'product_level_code') ? undefined : json['product_level_code'],
        'product_level_short_code': !exists(json, 'product_level_short_code') ? undefined : json['product_level_short_code'],
        'product_level_name': !exists(json, 'product_level_name') ? undefined : json['product_level_name'],
        'product_level_description': !exists(json, 'product_level_description') ? undefined : json['product_level_description'],
        'is_trial_available': !exists(json, 'is_trial_available') ? undefined : json['is_trial_available'],
        'number_trial_days': !exists(json, 'number_trial_days') ? undefined : json['number_trial_days'],
        'saleable': !exists(json, 'saleable') ? undefined : json['saleable'],
        'buy_url': !exists(json, 'buy_url') ? undefined : json['buy_url'],
        'details_url': !exists(json, 'details_url') ? undefined : json['details_url'],
    };
}

export function MelcoModelsSubscriptionListItemToJSON(value?: MelcoModelsSubscriptionListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'serial_number': value.serial_number,
        'display_name': value.display_name,
        'product_code': value.product_code,
        'product_name': value.product_name,
        'batch': value.batch,
        'referral': value.referral,
        'product_level_id': value.product_level_id,
        'product_version_id': value.product_version_id,
        'product_version_number': value.product_version_number,
        'product_level_code': value.product_level_code,
        'product_level_short_code': value.product_level_short_code,
        'product_level_name': value.product_level_name,
        'product_level_description': value.product_level_description,
        'is_trial_available': value.is_trial_available,
        'number_trial_days': value.number_trial_days,
        'saleable': value.saleable,
        'buy_url': value.buy_url,
        'details_url': value.details_url,
    };
}


