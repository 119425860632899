/* tslint:disable */
/* eslint-disable */
/**
 * Melco Efs APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EfsMelcoEfsModelsMappingOutputAttribute,
    EfsMelcoEfsModelsMappingOutputAttributeFromJSON,
    EfsMelcoEfsModelsMappingOutputAttributeFromJSONTyped,
    EfsMelcoEfsModelsMappingOutputAttributeToJSON,
} from './';

/**
 * 
 * @export
 * @interface EfsMelcoEfsModelsMappingOutputElement
 */
export interface EfsMelcoEfsModelsMappingOutputElement {
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoEfsModelsMappingOutputElement
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoEfsModelsMappingOutputElement
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoEfsModelsMappingOutputElement
     */
    lettering_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoEfsModelsMappingOutputElement
     */
    mapping_id?: string | null;
    /**
     * 
     * @type {Array<EfsMelcoEfsModelsMappingOutputAttribute>}
     * @memberof EfsMelcoEfsModelsMappingOutputElement
     */
    attributes?: Array<EfsMelcoEfsModelsMappingOutputAttribute> | null;
    /**
     * 
     * @type {boolean}
     * @memberof EfsMelcoEfsModelsMappingOutputElement
     */
    is_delete_empty?: boolean;
}

export function EfsMelcoEfsModelsMappingOutputElementFromJSON(json: any): EfsMelcoEfsModelsMappingOutputElement {
    return EfsMelcoEfsModelsMappingOutputElementFromJSONTyped(json, false);
}

export function EfsMelcoEfsModelsMappingOutputElementFromJSONTyped(json: any, ignoreDiscriminator: boolean): EfsMelcoEfsModelsMappingOutputElement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'lettering_id': !exists(json, 'lettering_id') ? undefined : json['lettering_id'],
        'mapping_id': !exists(json, 'mapping_id') ? undefined : json['mapping_id'],
        'attributes': !exists(json, 'attributes') ? undefined : (json['attributes'] === null ? null : (json['attributes'] as Array<any>).map(EfsMelcoEfsModelsMappingOutputAttributeFromJSON)),
        'is_delete_empty': !exists(json, 'is_delete_empty') ? undefined : json['is_delete_empty'],
    };
}

export function EfsMelcoEfsModelsMappingOutputElementToJSON(value?: EfsMelcoEfsModelsMappingOutputElement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'type': value.type,
        'lettering_id': value.lettering_id,
        'mapping_id': value.mapping_id,
        'attributes': value.attributes === undefined ? undefined : (value.attributes === null ? null : (value.attributes as Array<any>).map(EfsMelcoEfsModelsMappingOutputAttributeToJSON)),
        'is_delete_empty': value.is_delete_empty,
    };
}


