/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoModelsAlphabetListItem,
    MelcoModelsAlphabetListItemFromJSON,
    MelcoModelsAlphabetListItemFromJSONTyped,
    MelcoModelsAlphabetListItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoModelsUpdateAlphabetCollection
 */
export interface MelcoModelsUpdateAlphabetCollection {
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUpdateAlphabetCollection
     */
    name?: string | null;
    /**
     * 
     * @type {Array<MelcoModelsAlphabetListItem>}
     * @memberof MelcoModelsUpdateAlphabetCollection
     */
    alphabet_list?: Array<MelcoModelsAlphabetListItem> | null;
}

export function MelcoModelsUpdateAlphabetCollectionFromJSON(json: any): MelcoModelsUpdateAlphabetCollection {
    return MelcoModelsUpdateAlphabetCollectionFromJSONTyped(json, false);
}

export function MelcoModelsUpdateAlphabetCollectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsUpdateAlphabetCollection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'alphabet_list': !exists(json, 'alphabet_list') ? undefined : (json['alphabet_list'] === null ? null : (json['alphabet_list'] as Array<any>).map(MelcoModelsAlphabetListItemFromJSON)),
    };
}

export function MelcoModelsUpdateAlphabetCollectionToJSON(value?: MelcoModelsUpdateAlphabetCollection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'alphabet_list': value.alphabet_list === undefined ? undefined : (value.alphabet_list === null ? null : (value.alphabet_list as Array<any>).map(MelcoModelsAlphabetListItemToJSON)),
    };
}


