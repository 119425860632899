import {
  FusionMelcoFusionModelsCustomProductDesignCollection,
  useAPIAction,
  UserContentsDraftPublishApi,
} from "melco-shared-logic";
import { useFormikContext } from "formik";
import { QueryObserverResult } from "react-query";
import { designCollectionToInitialValues } from "../../../components/libraries/designCollections/DesignCollectionDetailForm";

export const useRevertDesignCollection = (
  designCollectionId: string,
  refetch: () => Promise<
    QueryObserverResult<
      FusionMelcoFusionModelsCustomProductDesignCollection,
      unknown
    >
  >
) => {
  const { resetForm } = useFormikContext();

  return useAPIAction(
    UserContentsDraftPublishApi,
    async (api) => {
      const data = await api.userDesignCollectionsRevert({
        id: designCollectionId,
      });

      return {
        successMessages: [
          {
            message: "default",
          },
        ],
        data,
      };
    },
    {
      translationPrefix: "libraries.design_collections.detail.revert",
      onSuccess: async () => {
        const refetchResult = await refetch();

        resetForm({
          values: refetchResult.data
            ? designCollectionToInitialValues(refetchResult.data)
            : {},
        });
      },
    }
  );
};
