/* tslint:disable */
/* eslint-disable */
/**
 * Melco Efs APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EfsMelcoModelsProductUserListItem
 */
export interface EfsMelcoModelsProductUserListItem {
    /**
     * 
     * @type {number}
     * @memberof EfsMelcoModelsProductUserListItem
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoModelsProductUserListItem
     */
    user_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoModelsProductUserListItem
     */
    first_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoModelsProductUserListItem
     */
    last_name?: string | null;
}

export function EfsMelcoModelsProductUserListItemFromJSON(json: any): EfsMelcoModelsProductUserListItem {
    return EfsMelcoModelsProductUserListItemFromJSONTyped(json, false);
}

export function EfsMelcoModelsProductUserListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): EfsMelcoModelsProductUserListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'user_name': !exists(json, 'user_name') ? undefined : json['user_name'],
        'first_name': !exists(json, 'first_name') ? undefined : json['first_name'],
        'last_name': !exists(json, 'last_name') ? undefined : json['last_name'],
    };
}

export function EfsMelcoModelsProductUserListItemToJSON(value?: EfsMelcoModelsProductUserListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'user_name': value.user_name,
        'first_name': value.first_name,
        'last_name': value.last_name,
    };
}


