/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoModelsPermissionsSubListItem
 */
export interface MelcoModelsPermissionsSubListItem {
    /**
     * 
     * @type {boolean}
     * @memberof MelcoModelsPermissionsSubListItem
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsPermissionsSubListItem
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsPermissionsSubListItem
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsPermissionsSubListItem
     */
    parent_code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsPermissionsSubListItem
     */
    filter_type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsPermissionsSubListItem
     */
    filter_list?: string | null;
}

export function MelcoModelsPermissionsSubListItemFromJSON(json: any): MelcoModelsPermissionsSubListItem {
    return MelcoModelsPermissionsSubListItemFromJSONTyped(json, false);
}

export function MelcoModelsPermissionsSubListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsPermissionsSubListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active': !exists(json, 'active') ? undefined : json['active'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'parent_code': !exists(json, 'parent_code') ? undefined : json['parent_code'],
        'filter_type': !exists(json, 'filter_type') ? undefined : json['filter_type'],
        'filter_list': !exists(json, 'filter_list') ? undefined : json['filter_list'],
    };
}

export function MelcoModelsPermissionsSubListItemToJSON(value?: MelcoModelsPermissionsSubListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active': value.active,
        'code': value.code,
        'name': value.name,
        'parent_code': value.parent_code,
        'filter_type': value.filter_type,
        'filter_list': value.filter_list,
    };
}


