import { isEmpty } from "lodash-es";
import { Button, RemoveIcon } from "melco-ui";
import { useTranslation } from "react-i18next";
import { Key } from "antd/lib/table/interface";
import { FusionMelcoModelsColorListItem } from "melco-shared-logic";
import { useAddColors } from "../../../hooks/libraries/colorCollections/useAddColors";

type BulkActionRemoveColorsProps = {
  allColors: FusionMelcoModelsColorListItem[];
  selectedRowKeys: Key[];
  setSelectedRowKeys: (keys: Key[]) => void;
};

export const BulkActionRemoveColors: React.FC<BulkActionRemoveColorsProps> = ({
  allColors,
  selectedRowKeys,
  setSelectedRowKeys,
}) => {
  const { t } = useTranslation();

  const { removeColors } = useAddColors();

  if (isEmpty(selectedRowKeys)) {
    return null;
  }

  return (
    <Button
      key="bulk-remove"
      icon={<RemoveIcon />}
      onClick={() => {
        const toRemove = allColors.filter(
          (c) => c.id && selectedRowKeys.includes(c.id)
        );

        removeColors(toRemove as { id: string }[]);

        setSelectedRowKeys([]);
      }}
    >
      {t("color_collections.actions.add_colors.actions.remove")}
    </Button>
  );
};
