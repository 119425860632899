import {
  FusionMelcoFusionModelsCustomProductListItem,
  selectThumbnailForSize,
} from "melco-shared-logic";

export const useProductThumbnailURL = (
  product: FusionMelcoFusionModelsCustomProductListItem,
  width: number,
  height: number
) => {
  const thumbnail = selectThumbnailForSize(
    product.default_previews ?? [],
    Math.max(width, height)
  );

  return thumbnail?.url;
};
