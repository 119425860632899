import { useCallback, useState } from "react";
import { Button, CheckIcon, Col, Table, TableActionBarRow } from "melco-ui";
import { useTranslation } from "react-i18next";
import {
  DrawerType,
  MelcoFusionModelsUserBlankListItem,
  Search,
  ViewContext,
} from "melco-shared-logic";
import { useBlanks } from "../../../hooks/libraries/blanks/useBlanks";
import { blanksListColumns } from "../../libraries/blanks/BlanksList";
import { useUpdateViewListWhenBlankChanges } from "../../../hooks/customization/products/useUpdateViewListWhenBlankChanges";

type ChooseBlankListProps = {
  drawer: DrawerType;
};

export const ChooseBlankList: React.FC<ChooseBlankListProps> = ({ drawer }) => {
  const {
    isLoading,
    isFetching,
    data,
    isPreviousData,
    errorComponent,
    onTableConfigChange,
    pagination,
    searchQueryChange,
  } = useBlanks("published");

  const { t } = useTranslation();
  const { closeDrawer, drawerField } = drawer;
  const { isSelected, select } = drawerField;
  const [updateViewList, isUpdatingViewList] =
    useUpdateViewListWhenBlankChanges();
  const [selectedBlank, setSelectedBlank] = useState<
    MelcoFusionModelsUserBlankListItem | undefined
  >();

  const onSelect = useCallback(
    async (blank: MelcoFusionModelsUserBlankListItem) => {
      setSelectedBlank(blank);

      const result = await updateViewList(blank);

      if (result?.blank) {
        select([result?.blank]);
      }

      closeDrawer();
    },
    [select, updateViewList, closeDrawer]
  );

  const columns = [
    ...blanksListColumns(t, ViewContext.Drawer),
    {
      title: "",
      key: "action",
      dataIndex: "action",
      align: "right" as const,
      render: (_: string, blank: MelcoFusionModelsUserBlankListItem) => {
        return isSelected(blank) ? (
          <Button type="primary" icon={<CheckIcon />} size="small" disabled>
            {t("products.actions.choose_blank.actions.selected")}
          </Button>
        ) : (
          <Button
            type="primary"
            icon={<CheckIcon />}
            size="small"
            onClick={() => onSelect(blank)}
            loading={isUpdatingViewList && selectedBlank?.id === blank.id}
          >
            {t("products.actions.choose_blank.actions.select")}
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <TableActionBarRow>
        <Col>
          <Search
            onSearchInputChange={(query: string) => searchQueryChange(query)}
          />
        </Col>
      </TableActionBarRow>
      <Table
        showSorterTooltip={false}
        sticky
        loading={isPreviousData ? isFetching : isLoading}
        dataSource={data}
        columns={columns}
        onChange={onTableConfigChange}
        pagination={pagination}
        rowKey="id"
        noDataMessage={t("blanks_list.empty")}
        errorComponent={errorComponent}
      />
    </>
  );
};
