/* tslint:disable */
/* eslint-disable */
/**
 * Melco Fusion APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FusionMelcoFusionModelsCustomProductView,
    FusionMelcoFusionModelsCustomProductViewFromJSON,
    FusionMelcoFusionModelsCustomProductViewFromJSONTyped,
    FusionMelcoFusionModelsCustomProductViewToJSON,
    FusionMelcoFusionModelsUserBlank,
    FusionMelcoFusionModelsUserBlankFromJSON,
    FusionMelcoFusionModelsUserBlankFromJSONTyped,
    FusionMelcoFusionModelsUserBlankToJSON,
} from './';

/**
 * 
 * @export
 * @interface FusionMelcoFusionModelsCreateCustomProduct
 */
export interface FusionMelcoFusionModelsCreateCustomProduct {
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoFusionModelsCreateCustomProduct
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoFusionModelsCreateCustomProduct
     */
    token?: string | null;
    /**
     * 
     * @type {FusionMelcoFusionModelsUserBlank}
     * @memberof FusionMelcoFusionModelsCreateCustomProduct
     */
    blank?: FusionMelcoFusionModelsUserBlank;
    /**
     * 
     * @type {Array<FusionMelcoFusionModelsCustomProductView>}
     * @memberof FusionMelcoFusionModelsCreateCustomProduct
     */
    view_list?: Array<FusionMelcoFusionModelsCustomProductView> | null;
    /**
     * 
     * @type {number}
     * @memberof FusionMelcoFusionModelsCreateCustomProduct
     */
    configurator_version?: number | null;
}

export function FusionMelcoFusionModelsCreateCustomProductFromJSON(json: any): FusionMelcoFusionModelsCreateCustomProduct {
    return FusionMelcoFusionModelsCreateCustomProductFromJSONTyped(json, false);
}

export function FusionMelcoFusionModelsCreateCustomProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): FusionMelcoFusionModelsCreateCustomProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'token': !exists(json, 'token') ? undefined : json['token'],
        'blank': !exists(json, 'blank') ? undefined : FusionMelcoFusionModelsUserBlankFromJSON(json['blank']),
        'view_list': !exists(json, 'view_list') ? undefined : (json['view_list'] === null ? null : (json['view_list'] as Array<any>).map(FusionMelcoFusionModelsCustomProductViewFromJSON)),
        'configurator_version': !exists(json, 'configurator_version') ? undefined : json['configurator_version'],
    };
}

export function FusionMelcoFusionModelsCreateCustomProductToJSON(value?: FusionMelcoFusionModelsCreateCustomProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'token': value.token,
        'blank': FusionMelcoFusionModelsUserBlankToJSON(value.blank),
        'view_list': value.view_list === undefined ? undefined : (value.view_list === null ? null : (value.view_list as Array<any>).map(FusionMelcoFusionModelsCustomProductViewToJSON)),
        'configurator_version': value.configurator_version,
    };
}


