/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoModelsMachineCloudLogin
 */
export interface MelcoModelsMachineCloudLogin {
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsMachineCloudLogin
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsMachineCloudLogin
     */
    token?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsMachineCloudLogin
     */
    account_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsMachineCloudLogin
     */
    user_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsMachineCloudLogin
     */
    first_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsMachineCloudLogin
     */
    last_name?: string | null;
}

export function MelcoModelsMachineCloudLoginFromJSON(json: any): MelcoModelsMachineCloudLogin {
    return MelcoModelsMachineCloudLoginFromJSONTyped(json, false);
}

export function MelcoModelsMachineCloudLoginFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsMachineCloudLogin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'token': !exists(json, 'token') ? undefined : json['token'],
        'account_name': !exists(json, 'account_name') ? undefined : json['account_name'],
        'user_name': !exists(json, 'user_name') ? undefined : json['user_name'],
        'first_name': !exists(json, 'first_name') ? undefined : json['first_name'],
        'last_name': !exists(json, 'last_name') ? undefined : json['last_name'],
    };
}

export function MelcoModelsMachineCloudLoginToJSON(value?: MelcoModelsMachineCloudLogin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'token': value.token,
        'account_name': value.account_name,
        'user_name': value.user_name,
        'first_name': value.first_name,
        'last_name': value.last_name,
    };
}


