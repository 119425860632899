/* tslint:disable */
/* eslint-disable */
/**
 * Melco Efs APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EfsMelcoModelsThreadColor
 */
export interface EfsMelcoModelsThreadColor {
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoModelsThreadColor
     */
    argb?: string | null;
}

export function EfsMelcoModelsThreadColorFromJSON(json: any): EfsMelcoModelsThreadColor {
    return EfsMelcoModelsThreadColorFromJSONTyped(json, false);
}

export function EfsMelcoModelsThreadColorFromJSONTyped(json: any, ignoreDiscriminator: boolean): EfsMelcoModelsThreadColor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'argb': !exists(json, 'argb') ? undefined : json['argb'],
    };
}

export function EfsMelcoModelsThreadColorToJSON(value?: EfsMelcoModelsThreadColor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'argb': value.argb,
    };
}


