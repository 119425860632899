import { CustomizableIcon, CustomizableNotIcon, DesignTokens } from "melco-ui";
import styled from "styled-components";
import { FusionMelcoModelsDesignElement } from "melco-shared-logic";

const { colors } = DesignTokens;

const ElementListItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EditableIcon = styled(CustomizableIcon)`
  color: ${colors.main.disabled};
`;

const NotEditableIcon = styled(CustomizableNotIcon)`
  color: ${colors.main.disabled};
`;

type ElementListItemProps = {
  element: FusionMelcoModelsDesignElement;
  children?: React.ReactNode;
};

export const ElementListItem: React.FC<ElementListItemProps> = ({
  element,
  children,
}) => {
  const { editable } = element;

  return (
    <ElementListItemWrapper>
      {children}

      {editable ? <EditableIcon /> : <NotEditableIcon />}
    </ElementListItemWrapper>
  );
};
