/* tslint:disable */
/* eslint-disable */
/**
 * Melco Efs APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EfsMelcoEfsModelsMappingCondition,
    EfsMelcoEfsModelsMappingConditionFromJSON,
    EfsMelcoEfsModelsMappingConditionFromJSONTyped,
    EfsMelcoEfsModelsMappingConditionToJSON,
    EfsMelcoEfsModelsMappingFileAttribute,
    EfsMelcoEfsModelsMappingFileAttributeFromJSON,
    EfsMelcoEfsModelsMappingFileAttributeFromJSONTyped,
    EfsMelcoEfsModelsMappingFileAttributeToJSON,
    EfsMelcoEfsModelsMappingGroup,
    EfsMelcoEfsModelsMappingGroupFromJSON,
    EfsMelcoEfsModelsMappingGroupFromJSONTyped,
    EfsMelcoEfsModelsMappingGroupToJSON,
    EfsMelcoEfsModelsMappingOutput,
    EfsMelcoEfsModelsMappingOutputFromJSON,
    EfsMelcoEfsModelsMappingOutputFromJSONTyped,
    EfsMelcoEfsModelsMappingOutputToJSON,
} from './';

/**
 * 
 * @export
 * @interface EfsMelcoEfsModelsCreateMapping
 */
export interface EfsMelcoEfsModelsCreateMapping {
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoEfsModelsCreateMapping
     */
    format_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoEfsModelsCreateMapping
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EfsMelcoEfsModelsCreateMapping
     */
    global?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoEfsModelsCreateMapping
     */
    config_type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoEfsModelsCreateMapping
     */
    service_type?: string | null;
    /**
     * 
     * @type {Array<EfsMelcoEfsModelsMappingCondition>}
     * @memberof EfsMelcoEfsModelsCreateMapping
     */
    conditions?: Array<EfsMelcoEfsModelsMappingCondition> | null;
    /**
     * 
     * @type {Array<EfsMelcoEfsModelsMappingOutput>}
     * @memberof EfsMelcoEfsModelsCreateMapping
     */
    outputs?: Array<EfsMelcoEfsModelsMappingOutput> | null;
    /**
     * 
     * @type {Array<EfsMelcoEfsModelsMappingFileAttribute>}
     * @memberof EfsMelcoEfsModelsCreateMapping
     */
    file_name_configuration?: Array<EfsMelcoEfsModelsMappingFileAttribute> | null;
    /**
     * 
     * @type {Array<EfsMelcoEfsModelsMappingGroup>}
     * @memberof EfsMelcoEfsModelsCreateMapping
     */
    folder_groups?: Array<EfsMelcoEfsModelsMappingGroup> | null;
}

export function EfsMelcoEfsModelsCreateMappingFromJSON(json: any): EfsMelcoEfsModelsCreateMapping {
    return EfsMelcoEfsModelsCreateMappingFromJSONTyped(json, false);
}

export function EfsMelcoEfsModelsCreateMappingFromJSONTyped(json: any, ignoreDiscriminator: boolean): EfsMelcoEfsModelsCreateMapping {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'format_id': !exists(json, 'format_id') ? undefined : json['format_id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'global': !exists(json, 'global') ? undefined : json['global'],
        'config_type': !exists(json, 'config_type') ? undefined : json['config_type'],
        'service_type': !exists(json, 'service_type') ? undefined : json['service_type'],
        'conditions': !exists(json, 'conditions') ? undefined : (json['conditions'] === null ? null : (json['conditions'] as Array<any>).map(EfsMelcoEfsModelsMappingConditionFromJSON)),
        'outputs': !exists(json, 'outputs') ? undefined : (json['outputs'] === null ? null : (json['outputs'] as Array<any>).map(EfsMelcoEfsModelsMappingOutputFromJSON)),
        'file_name_configuration': !exists(json, 'file_name_configuration') ? undefined : (json['file_name_configuration'] === null ? null : (json['file_name_configuration'] as Array<any>).map(EfsMelcoEfsModelsMappingFileAttributeFromJSON)),
        'folder_groups': !exists(json, 'folder_groups') ? undefined : (json['folder_groups'] === null ? null : (json['folder_groups'] as Array<any>).map(EfsMelcoEfsModelsMappingGroupFromJSON)),
    };
}

export function EfsMelcoEfsModelsCreateMappingToJSON(value?: EfsMelcoEfsModelsCreateMapping | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'format_id': value.format_id,
        'name': value.name,
        'global': value.global,
        'config_type': value.config_type,
        'service_type': value.service_type,
        'conditions': value.conditions === undefined ? undefined : (value.conditions === null ? null : (value.conditions as Array<any>).map(EfsMelcoEfsModelsMappingConditionToJSON)),
        'outputs': value.outputs === undefined ? undefined : (value.outputs === null ? null : (value.outputs as Array<any>).map(EfsMelcoEfsModelsMappingOutputToJSON)),
        'file_name_configuration': value.file_name_configuration === undefined ? undefined : (value.file_name_configuration === null ? null : (value.file_name_configuration as Array<any>).map(EfsMelcoEfsModelsMappingFileAttributeToJSON)),
        'folder_groups': value.folder_groups === undefined ? undefined : (value.folder_groups === null ? null : (value.folder_groups as Array<any>).map(EfsMelcoEfsModelsMappingGroupToJSON)),
    };
}


