import { isEmpty } from "lodash-es";
import { useToggle } from "react-use";
import { useTranslationWithFallback } from "../translation/useTranslationWithFallback";
import { MelcoModelsCustomWarning } from "../../api";

type UseWarningOptions = {
  translationPrefix: string;
};

export const useWarnings = (
  warningsFromAPI: MelcoModelsCustomWarning[] | undefined,
  options: UseWarningOptions
) => {
  const { translationPrefix } = options;
  const translateWithFallback = useTranslationWithFallback(
    [translationPrefix, "warning"].join(".")
  );

  const [areWarningDetailsShown, toggleWarnings] = useToggle(false);

  if (!warningsFromAPI || isEmpty(warningsFromAPI)) {
    return [undefined, false, undefined] as const;
  }

  const warningMessages: string[] = warningsFromAPI.map((warning) => {
    const source = (warning.source ?? "").toLowerCase();
    const key = (warning.key ?? "").toLowerCase();

    return translateWithFallback(
      [[source, key].join("."), [source, "default"].join("."), key],
      warning.message ?? ""
    );
  });

  return [warningMessages, areWarningDetailsShown, toggleWarnings] as const;
};
