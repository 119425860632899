import { useState } from "react";
import { Breadcrumb } from "antd";
import { useTranslation } from "react-i18next";
import { DrawerWithHeader } from "melco-ui";
import { titleForThreadSupplierProduct } from "../../../screens/libraries/colors/Threads";
import { MelcoModelsSupplierProduct } from "melco-shared-logic";
import { ThreadSupplierProductsList } from "../colors/ThreadSupplierProductsList";
import { AddColorsList } from "./AddColorsList";

type AddColorsDrawerProps = {
  isDrawerOpen: boolean;
  setDrawerOpen: (isDrawerOpen: boolean) => void;
};

export const AddColorsDrawer: React.FC<AddColorsDrawerProps> = ({
  isDrawerOpen,
  setDrawerOpen,
}) => {
  const { t } = useTranslation();

  const [threadSupplierProduct, setThreadSupplierProduct] = useState<
    MelcoModelsSupplierProduct | undefined
  >(undefined);

  const title = threadSupplierProduct
    ? titleForThreadSupplierProduct(threadSupplierProduct)
    : t("libraries.colors.embroidery.title");

  const breadcrumb = threadSupplierProduct ? (
    <Breadcrumb>
      <a href="#" onClick={() => setThreadSupplierProduct(undefined)}>
        {t("libraries.colors.embroidery.title")}
      </a>
    </Breadcrumb>
  ) : undefined;

  return (
    <DrawerWithHeader
      title={title}
      breadcrumb={breadcrumb}
      placement="right"
      width={1000}
      closable={true}
      onClose={() => setDrawerOpen(false)}
      open={isDrawerOpen}
      bodyStyle={{ padding: 0 }}
    >
      {threadSupplierProduct ? (
        <AddColorsList threadSupplierProduct={threadSupplierProduct} />
      ) : (
        <ThreadSupplierProductsList
          onRowClick={(tsp) => setThreadSupplierProduct(tsp)}
        />
      )}
    </DrawerWithHeader>
  );
};
