import { useCallback, useEffect } from "react";
import { useField } from "formik";
import {
  compact,
  find,
  isEmpty,
  isEqual,
  reject,
  uniqBy,
  differenceBy,
} from "lodash";
import { FusionMelcoModelsUserImage } from "melco-shared-logic";
import { moveArray } from "../../../helper/moveArray";

export const deriveViewsFromBlankPictures = (
  blankPictures: FusionMelcoModelsUserImage[],
  previousViewIds: string[]
) => {
  const allViews = blankPictures.map((image) => ({
    id: image.id!,
    view_name: image.view_name,
  }));

  const previouslySortedViews = compact(
    previousViewIds.map((id) => find(blankPictures, (bp) => bp.id === id))
  );

  const newlyAddedViews = differenceBy(allViews, previouslySortedViews, "id");

  const views = [...previouslySortedViews, ...newlyAddedViews];

  const uniqueViews = reject(uniqBy(views, "view_name"), (c) =>
    isEmpty(c.view_name)
  );

  return uniqueViews.map((c) => c.id) as string[];
};

export type ViewNameListItem = {
  key: string;
  viewName: string;
  blankPicture: FusionMelcoModelsUserImage;
};

export const idsToViewNames = (
  blankPictureIds: string[],
  blankPictures: FusionMelcoModelsUserImage[]
) =>
  uniqBy(
    blankPictureIds.flatMap((bpId) => {
      const blankPicture = find(blankPictures, (bp) => bp.id === bpId);
      if (!blankPicture) {
        return [];
      }

      const { view_name } = blankPicture;

      return [
        {
          // we always need to pass a unique string to be used as a draggable id
          key: isEmpty(view_name) ? "EMPTY-STRING" : view_name!,
          viewName: view_name!,
          blankPicture: blankPicture,
        } as ViewNameListItem,
      ];
    }),
    "viewName"
  );

export const useBlankViews = () => {
  const [{ value: blankPictures }] =
    useField<FusionMelcoModelsUserImage[]>("images");

  const [
    { value: orderedBlankPictureIds },
    ,
    { setValue: setOrderedBlankPictureIds },
  ] = useField<string[]>("views");

  useEffect(() => {
    const newViews = deriveViewsFromBlankPictures(
      blankPictures,
      orderedBlankPictureIds
    );

    if (!isEqual(newViews, orderedBlankPictureIds)) {
      setOrderedBlankPictureIds(newViews);
    }

    // ignoring changes to views, as we only perform a one way sync from blank pictures to views
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blankPictures, setOrderedBlankPictureIds]);

  const reorderViews = useCallback(
    (fromIndex: number, toIndex: number) => {
      setOrderedBlankPictureIds(
        moveArray(orderedBlankPictureIds, fromIndex, toIndex)
      );
    },
    [orderedBlankPictureIds, setOrderedBlankPictureIds]
  );

  const views = idsToViewNames(orderedBlankPictureIds, blankPictures);

  return [views, reorderViews] as const;
};
