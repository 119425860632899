import {
  ConfiguratorApi,
  FusionMelcoFusionModelsSessionPersonalizationListItem,
  useAPIQuery,
  useTable,
} from "melco-shared-logic";
import isEmpty from "lodash-es/isEmpty";
import { useTableErrorComponent } from "../table/useTableErrorComponent";
import {
  ALL_ORDER_SESSION_STATUS,
  OrderProductionStatus,
  OrderSessionStatus,
} from "../../helper/ordersHelper";

export type OrdersListFiltersType = {
  searchText?: string;
  sessionStatus?: OrderSessionStatus[];
  productionStatus?: OrderProductionStatus[];
  startTimeStamp?: Date;
  endTimeStamp?: Date;
};

const DEFAULT_SESSION_STATUS_FILTER = ALL_ORDER_SESSION_STATUS;

export const useOrders = () => {
  const {
    onTableConfigChange,
    pagination,
    setTotal,
    filters,
    filtersChange,
    sorter,
    currentPage,
    pageSize,
    sortBy,
  } = useTable<
    FusionMelcoFusionModelsSessionPersonalizationListItem,
    OrdersListFiltersType
  >({
    pageSizeOptions: [10, 20],
    pageSize: 20,
  });

  const query = useAPIQuery(
    ConfiguratorApi,
    ["orders", currentPage, pageSize, filters, sortBy()],
    async (api) => {
      const response = await api.configuratorSessionsSearchPersonalizations({
        offset: (currentPage - 1) * pageSize,
        limit: pageSize,
        sortBy: sortBy(),
        searchText:
          filters?.searchText && !isEmpty(filters.searchText)
            ? filters.searchText
            : undefined,
        sessionStatus:
          filters?.sessionStatus && !isEmpty(filters.sessionStatus)
            ? filters.sessionStatus.join(",")
            : DEFAULT_SESSION_STATUS_FILTER,
        productionStatus:
          filters?.productionStatus && !isEmpty(filters.productionStatus)
            ? filters.productionStatus.join(",")
            : undefined,
        startTimestamp: filters?.startTimeStamp
          ? filters.startTimeStamp
          : undefined,
        endTimestamp: filters?.endTimeStamp ? filters.endTimeStamp : undefined,
      });

      setTotal(response.total);

      return response.list;
    },
    {
      queryOptions: {
        keepPreviousData: true,
      },
    }
  );

  const errorComponent = useTableErrorComponent(query.error, query.refetch);

  return {
    onTableConfigChange,
    pagination,
    setTotal,
    filters,
    filtersChange,
    sorter,
    errorComponent,
    ...query,
  };
};
