import {
  ElementListHelper,
  FusionMelcoModelsDesignElement,
} from "melco-shared-logic";
import { useTranslation } from "react-i18next";
import { useElementList } from "./useElementList";

const { isLettering } = ElementListHelper;

export const useElementTitle = (
  element: FusionMelcoModelsDesignElement | undefined
) => {
  const { t } = useTranslation();

  const { groupIndex } = useElementList();

  if (!element) {
    // useElementTitle called without an element can happen if the design is replaced and the element that has been selected before does not exist anymore
    return { title: "", subtitle: "" };
  }

  const title = isLettering(element)
    ? t("libraries.designs.detail.form.element_list.lettering_title", {
        index: groupIndex(element),
      })
    : element.color?.name ?? "";

  const subtitle = "Embroidery";

  return { title, subtitle };
};
