/* tslint:disable */
/* eslint-disable */
/**
 * Melco Fusion APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FusionMelcoModelsPreview,
    FusionMelcoModelsPreviewFromJSON,
    FusionMelcoModelsPreviewFromJSONTyped,
    FusionMelcoModelsPreviewToJSON,
} from './';

/**
 * 
 * @export
 * @interface FusionMelcoFusionModelsCustomProductListItem
 */
export interface FusionMelcoFusionModelsCustomProductListItem {
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoFusionModelsCustomProductListItem
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoFusionModelsCustomProductListItem
     */
    token?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoFusionModelsCustomProductListItem
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoFusionModelsCustomProductListItem
     */
    blank_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FusionMelcoFusionModelsCustomProductListItem
     */
    blank_name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FusionMelcoFusionModelsCustomProductListItem
     */
    publish_state?: number;
    /**
     * 
     * @type {Date}
     * @memberof FusionMelcoFusionModelsCustomProductListItem
     */
    created_date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof FusionMelcoFusionModelsCustomProductListItem
     */
    publish_date?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FusionMelcoFusionModelsCustomProductListItem
     */
    updated_date?: Date | null;
    /**
     * 
     * @type {Array<FusionMelcoModelsPreview>}
     * @memberof FusionMelcoFusionModelsCustomProductListItem
     */
    default_previews?: Array<FusionMelcoModelsPreview> | null;
}

export function FusionMelcoFusionModelsCustomProductListItemFromJSON(json: any): FusionMelcoFusionModelsCustomProductListItem {
    return FusionMelcoFusionModelsCustomProductListItemFromJSONTyped(json, false);
}

export function FusionMelcoFusionModelsCustomProductListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): FusionMelcoFusionModelsCustomProductListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'token': !exists(json, 'token') ? undefined : json['token'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'blank_id': !exists(json, 'blank_id') ? undefined : json['blank_id'],
        'blank_name': !exists(json, 'blank_name') ? undefined : json['blank_name'],
        'publish_state': !exists(json, 'publish_state') ? undefined : json['publish_state'],
        'created_date': !exists(json, 'created_date') ? undefined : (new Date(json['created_date'])),
        'publish_date': !exists(json, 'publish_date') ? undefined : (json['publish_date'] === null ? null : new Date(json['publish_date'])),
        'updated_date': !exists(json, 'updated_date') ? undefined : (json['updated_date'] === null ? null : new Date(json['updated_date'])),
        'default_previews': !exists(json, 'default_previews') ? undefined : (json['default_previews'] === null ? null : (json['default_previews'] as Array<any>).map(FusionMelcoModelsPreviewFromJSON)),
    };
}

export function FusionMelcoFusionModelsCustomProductListItemToJSON(value?: FusionMelcoFusionModelsCustomProductListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'token': value.token,
        'name': value.name,
        'blank_id': value.blank_id,
        'blank_name': value.blank_name,
        'publish_state': value.publish_state,
        'created_date': value.created_date === undefined ? undefined : (value.created_date.toISOString()),
        'publish_date': value.publish_date === undefined ? undefined : (value.publish_date === null ? null : value.publish_date.toISOString()),
        'updated_date': value.updated_date === undefined ? undefined : (value.updated_date === null ? null : value.updated_date.toISOString()),
        'default_previews': value.default_previews === undefined ? undefined : (value.default_previews === null ? null : (value.default_previews as Array<any>).map(FusionMelcoModelsPreviewToJSON)),
    };
}


