/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoInternalModelsControlCenterAppSetting
 */
export interface MelcoInternalModelsControlCenterAppSetting {
    /**
     * 
     * @type {string}
     * @memberof MelcoInternalModelsControlCenterAppSetting
     */
    download_storage_uri?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoInternalModelsControlCenterAppSetting
     */
    download_storage_sas?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoInternalModelsControlCenterAppSetting
     */
    download_storage_container?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoInternalModelsControlCenterAppSetting
     */
    download_host?: string | null;
}

export function MelcoInternalModelsControlCenterAppSettingFromJSON(json: any): MelcoInternalModelsControlCenterAppSetting {
    return MelcoInternalModelsControlCenterAppSettingFromJSONTyped(json, false);
}

export function MelcoInternalModelsControlCenterAppSettingFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoInternalModelsControlCenterAppSetting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'download_storage_uri': !exists(json, 'download_storage_uri') ? undefined : json['download_storage_uri'],
        'download_storage_sas': !exists(json, 'download_storage_sas') ? undefined : json['download_storage_sas'],
        'download_storage_container': !exists(json, 'download_storage_container') ? undefined : json['download_storage_container'],
        'download_host': !exists(json, 'download_host') ? undefined : json['download_host'],
    };
}

export function MelcoInternalModelsControlCenterAppSettingToJSON(value?: MelcoInternalModelsControlCenterAppSetting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'download_storage_uri': value.download_storage_uri,
        'download_storage_sas': value.download_storage_sas,
        'download_storage_container': value.download_storage_container,
        'download_host': value.download_host,
    };
}


