/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoModelsSubUserDetail
 */
export interface MelcoModelsSubUserDetail {
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsSubUserDetail
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsSubUserDetail
     */
    user_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsSubUserDetail
     */
    first_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsSubUserDetail
     */
    last_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsSubUserDetail
     */
    password?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoModelsSubUserDetail
     */
    is_email?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsSubUserDetail
     */
    parent_user_id?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MelcoModelsSubUserDetail
     */
    created_date?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof MelcoModelsSubUserDetail
     */
    updated_date?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof MelcoModelsSubUserDetail
     */
    last_login_date?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof MelcoModelsSubUserDetail
     */
    verified_on?: Date | null;
}

export function MelcoModelsSubUserDetailFromJSON(json: any): MelcoModelsSubUserDetail {
    return MelcoModelsSubUserDetailFromJSONTyped(json, false);
}

export function MelcoModelsSubUserDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsSubUserDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'user_name': !exists(json, 'user_name') ? undefined : json['user_name'],
        'first_name': !exists(json, 'first_name') ? undefined : json['first_name'],
        'last_name': !exists(json, 'last_name') ? undefined : json['last_name'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'is_email': !exists(json, 'is_email') ? undefined : json['is_email'],
        'parent_user_id': !exists(json, 'parent_user_id') ? undefined : json['parent_user_id'],
        'created_date': !exists(json, 'created_date') ? undefined : (json['created_date'] === null ? null : new Date(json['created_date'])),
        'updated_date': !exists(json, 'updated_date') ? undefined : (json['updated_date'] === null ? null : new Date(json['updated_date'])),
        'last_login_date': !exists(json, 'last_login_date') ? undefined : (json['last_login_date'] === null ? null : new Date(json['last_login_date'])),
        'verified_on': !exists(json, 'verified_on') ? undefined : (json['verified_on'] === null ? null : new Date(json['verified_on'])),
    };
}

export function MelcoModelsSubUserDetailToJSON(value?: MelcoModelsSubUserDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'user_name': value.user_name,
        'first_name': value.first_name,
        'last_name': value.last_name,
        'password': value.password,
        'is_email': value.is_email,
        'parent_user_id': value.parent_user_id,
        'created_date': value.created_date === undefined ? undefined : (value.created_date === null ? null : value.created_date.toISOString()),
        'updated_date': value.updated_date === undefined ? undefined : (value.updated_date === null ? null : value.updated_date.toISOString()),
        'last_login_date': value.last_login_date === undefined ? undefined : (value.last_login_date === null ? null : value.last_login_date.toISOString()),
        'verified_on': value.verified_on === undefined ? undefined : (value.verified_on === null ? null : value.verified_on.toISOString()),
    };
}


