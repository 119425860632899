/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoModelsProductTrial,
    MelcoModelsProductTrialFromJSON,
    MelcoModelsProductTrialFromJSONTyped,
    MelcoModelsProductTrialToJSON,
    MelcoModelsUserProductLevel,
    MelcoModelsUserProductLevelFromJSON,
    MelcoModelsUserProductLevelFromJSONTyped,
    MelcoModelsUserProductLevelToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoModelsVirtualLogin
 */
export interface MelcoModelsVirtualLogin {
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsVirtualLogin
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsVirtualLogin
     */
    token?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsVirtualLogin
     */
    user_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsVirtualLogin
     */
    first_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsVirtualLogin
     */
    last_name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoModelsVirtualLogin
     */
    seconds_to_expire_utc?: number;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsVirtualLogin
     */
    warning?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsVirtualLogin
     */
    single_use_code?: string | null;
    /**
     * 
     * @type {Array<MelcoModelsUserProductLevel>}
     * @memberof MelcoModelsVirtualLogin
     */
    product_level_list?: Array<MelcoModelsUserProductLevel> | null;
    /**
     * 
     * @type {MelcoModelsProductTrial}
     * @memberof MelcoModelsVirtualLogin
     */
    trial?: MelcoModelsProductTrial;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsVirtualLogin
     */
    signature?: string | null;
}

export function MelcoModelsVirtualLoginFromJSON(json: any): MelcoModelsVirtualLogin {
    return MelcoModelsVirtualLoginFromJSONTyped(json, false);
}

export function MelcoModelsVirtualLoginFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsVirtualLogin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'token': !exists(json, 'token') ? undefined : json['token'],
        'user_name': !exists(json, 'user_name') ? undefined : json['user_name'],
        'first_name': !exists(json, 'first_name') ? undefined : json['first_name'],
        'last_name': !exists(json, 'last_name') ? undefined : json['last_name'],
        'seconds_to_expire_utc': !exists(json, 'seconds_to_expire_utc') ? undefined : json['seconds_to_expire_utc'],
        'warning': !exists(json, 'warning') ? undefined : json['warning'],
        'single_use_code': !exists(json, 'single_use_code') ? undefined : json['single_use_code'],
        'product_level_list': !exists(json, 'product_level_list') ? undefined : (json['product_level_list'] === null ? null : (json['product_level_list'] as Array<any>).map(MelcoModelsUserProductLevelFromJSON)),
        'trial': !exists(json, 'trial') ? undefined : MelcoModelsProductTrialFromJSON(json['trial']),
        'signature': !exists(json, 'signature') ? undefined : json['signature'],
    };
}

export function MelcoModelsVirtualLoginToJSON(value?: MelcoModelsVirtualLogin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'token': value.token,
        'user_name': value.user_name,
        'first_name': value.first_name,
        'last_name': value.last_name,
        'seconds_to_expire_utc': value.seconds_to_expire_utc,
        'warning': value.warning,
        'single_use_code': value.single_use_code,
        'product_level_list': value.product_level_list === undefined ? undefined : (value.product_level_list === null ? null : (value.product_level_list as Array<any>).map(MelcoModelsUserProductLevelToJSON)),
        'trial': MelcoModelsProductTrialToJSON(value.trial),
        'signature': value.signature,
    };
}


