/* tslint:disable */
/* eslint-disable */
/**
 * Melco Reporting APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ReportingMelcoMachineStatisticsModelsCreateGroup,
    ReportingMelcoMachineStatisticsModelsCreateGroupFromJSON,
    ReportingMelcoMachineStatisticsModelsCreateGroupToJSON,
    ReportingMelcoMachineStatisticsModelsGroup,
    ReportingMelcoMachineStatisticsModelsGroupFromJSON,
    ReportingMelcoMachineStatisticsModelsGroupToJSON,
    ReportingMelcoMachineStatisticsModelsUpdateGroup,
    ReportingMelcoMachineStatisticsModelsUpdateGroupFromJSON,
    ReportingMelcoMachineStatisticsModelsUpdateGroupToJSON,
    ReportingMelcoModelsError,
    ReportingMelcoModelsErrorFromJSON,
    ReportingMelcoModelsErrorToJSON,
    ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsGroupListItem,
    ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsGroupListItemFromJSON,
    ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsGroupListItemToJSON,
} from '../models';

export interface GroupsApiStatisticsGroupsCreateRequest {
    reportingMelcoMachineStatisticsModelsCreateGroup?: ReportingMelcoMachineStatisticsModelsCreateGroup;
}

export interface GroupsApiStatisticsGroupsDeleteRequest {
    id: string;
}

export interface GroupsApiStatisticsGroupsEditRequest {
    id: string;
    reportingMelcoMachineStatisticsModelsUpdateGroup?: ReportingMelcoMachineStatisticsModelsUpdateGroup;
}

export interface GroupsApiStatisticsGroupsGetByIdRequest {
    id: string;
}

export interface GroupsApiStatisticsGroupsSearchRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    searchText?: string;
}

/**
 * 
 */
export class GroupsApi extends runtime.BaseAPI {

    /**
     */
    async statisticsGroupsCreateRaw(requestParameters: GroupsApiStatisticsGroupsCreateRequest): Promise<runtime.ApiResponse<ReportingMelcoMachineStatisticsModelsGroup>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/v1/machines/reporting/groups`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReportingMelcoMachineStatisticsModelsCreateGroupToJSON(requestParameters.reportingMelcoMachineStatisticsModelsCreateGroup),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportingMelcoMachineStatisticsModelsGroupFromJSON(jsonValue));
    }

    /**
     */
    async statisticsGroupsCreate(requestParameters: GroupsApiStatisticsGroupsCreateRequest): Promise<ReportingMelcoMachineStatisticsModelsGroup> {
        const response = await this.statisticsGroupsCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async statisticsGroupsDeleteRaw(requestParameters: GroupsApiStatisticsGroupsDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statisticsGroupsDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/machines/reporting/groups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async statisticsGroupsDelete(requestParameters: GroupsApiStatisticsGroupsDeleteRequest): Promise<void> {
        await this.statisticsGroupsDeleteRaw(requestParameters);
    }

    /**
     */
    async statisticsGroupsEditRaw(requestParameters: GroupsApiStatisticsGroupsEditRequest): Promise<runtime.ApiResponse<ReportingMelcoMachineStatisticsModelsGroup>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statisticsGroupsEdit.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/v1/machines/reporting/groups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ReportingMelcoMachineStatisticsModelsUpdateGroupToJSON(requestParameters.reportingMelcoMachineStatisticsModelsUpdateGroup),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportingMelcoMachineStatisticsModelsGroupFromJSON(jsonValue));
    }

    /**
     */
    async statisticsGroupsEdit(requestParameters: GroupsApiStatisticsGroupsEditRequest): Promise<ReportingMelcoMachineStatisticsModelsGroup> {
        const response = await this.statisticsGroupsEditRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async statisticsGroupsGetByIdRaw(requestParameters: GroupsApiStatisticsGroupsGetByIdRequest): Promise<runtime.ApiResponse<ReportingMelcoMachineStatisticsModelsGroup>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statisticsGroupsGetById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/machines/reporting/groups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportingMelcoMachineStatisticsModelsGroupFromJSON(jsonValue));
    }

    /**
     */
    async statisticsGroupsGetById(requestParameters: GroupsApiStatisticsGroupsGetByIdRequest): Promise<ReportingMelcoMachineStatisticsModelsGroup> {
        const response = await this.statisticsGroupsGetByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async statisticsGroupsSearchRaw(requestParameters: GroupsApiStatisticsGroupsSearchRequest): Promise<runtime.ApiResponse<ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsGroupListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/machines/reporting/groups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsGroupListItemFromJSON(jsonValue));
    }

    /**
     */
    async statisticsGroupsSearch(requestParameters: GroupsApiStatisticsGroupsSearchRequest): Promise<ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsGroupListItem> {
        const response = await this.statisticsGroupsSearchRaw(requestParameters);
        return await response.value();
    }

}
