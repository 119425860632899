/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoModelsDesignElement,
    MelcoModelsDesignElementFromJSON,
    MelcoModelsDesignElementFromJSONTyped,
    MelcoModelsDesignElementToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoModelsDesignMetadata
 */
export interface MelcoModelsDesignMetadata {
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsDesignMetadata
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoModelsDesignMetadata
     */
    stitches?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoModelsDesignMetadata
     */
    colors?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoModelsDesignMetadata
     */
    trims?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoModelsDesignMetadata
     */
    height?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoModelsDesignMetadata
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoModelsDesignMetadata
     */
    color_changes?: number;
    /**
     * 
     * @type {Array<MelcoModelsDesignElement>}
     * @memberof MelcoModelsDesignMetadata
     */
    elements?: Array<MelcoModelsDesignElement> | null;
}

export function MelcoModelsDesignMetadataFromJSON(json: any): MelcoModelsDesignMetadata {
    return MelcoModelsDesignMetadataFromJSONTyped(json, false);
}

export function MelcoModelsDesignMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsDesignMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'stitches': !exists(json, 'stitches') ? undefined : json['stitches'],
        'colors': !exists(json, 'colors') ? undefined : json['colors'],
        'trims': !exists(json, 'trims') ? undefined : json['trims'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'width': !exists(json, 'width') ? undefined : json['width'],
        'color_changes': !exists(json, 'color_changes') ? undefined : json['color_changes'],
        'elements': !exists(json, 'elements') ? undefined : (json['elements'] === null ? null : (json['elements'] as Array<any>).map(MelcoModelsDesignElementFromJSON)),
    };
}

export function MelcoModelsDesignMetadataToJSON(value?: MelcoModelsDesignMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'stitches': value.stitches,
        'colors': value.colors,
        'trims': value.trims,
        'height': value.height,
        'width': value.width,
        'color_changes': value.color_changes,
        'elements': value.elements === undefined ? undefined : (value.elements === null ? null : (value.elements as Array<any>).map(MelcoModelsDesignElementToJSON)),
    };
}


