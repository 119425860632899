/* tslint:disable */
/* eslint-disable */
/**
 * Melco Reporting APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportingMelcoMachineStatisticsModelsAuthViewerLink
 */
export interface ReportingMelcoMachineStatisticsModelsAuthViewerLink {
    /**
     * 
     * @type {string}
     * @memberof ReportingMelcoMachineStatisticsModelsAuthViewerLink
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportingMelcoMachineStatisticsModelsAuthViewerLink
     */
    access_token?: string | null;
}

export function ReportingMelcoMachineStatisticsModelsAuthViewerLinkFromJSON(json: any): ReportingMelcoMachineStatisticsModelsAuthViewerLink {
    return ReportingMelcoMachineStatisticsModelsAuthViewerLinkFromJSONTyped(json, false);
}

export function ReportingMelcoMachineStatisticsModelsAuthViewerLinkFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportingMelcoMachineStatisticsModelsAuthViewerLink {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'password': !exists(json, 'password') ? undefined : json['password'],
        'access_token': !exists(json, 'access_token') ? undefined : json['access_token'],
    };
}

export function ReportingMelcoMachineStatisticsModelsAuthViewerLinkToJSON(value?: ReportingMelcoMachineStatisticsModelsAuthViewerLink | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'password': value.password,
        'access_token': value.access_token,
    };
}


