/* tslint:disable */
/* eslint-disable */
/**
 * Melco Efs APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    EfsMelcoEfsModelsCreateUserSetting,
    EfsMelcoEfsModelsCreateUserSettingFromJSON,
    EfsMelcoEfsModelsCreateUserSettingToJSON,
    EfsMelcoEfsModelsEfsDefaultAppSetting,
    EfsMelcoEfsModelsEfsDefaultAppSettingFromJSON,
    EfsMelcoEfsModelsEfsDefaultAppSettingToJSON,
    EfsMelcoEfsModelsEfsUserResponseSetting,
    EfsMelcoEfsModelsEfsUserResponseSettingFromJSON,
    EfsMelcoEfsModelsEfsUserResponseSettingToJSON,
    EfsMelcoModelsError,
    EfsMelcoModelsErrorFromJSON,
    EfsMelcoModelsErrorToJSON,
} from '../models';

export interface EfsObsoleteApiAccountProductSettingsEditEfsAppSettingRequest {
    efsMelcoEfsModelsCreateUserSetting?: EfsMelcoEfsModelsCreateUserSetting;
}

/**
 * 
 */
export class EfsObsoleteApi extends runtime.BaseAPI {

    /**
     */
    async accountProductSettingsDeleteEfsAppSettingRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/products/efs/settings/app`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async accountProductSettingsDeleteEfsAppSetting(): Promise<void> {
        await this.accountProductSettingsDeleteEfsAppSettingRaw();
    }

    /**
     */
    async accountProductSettingsEditEfsAppSettingRaw(requestParameters: EfsObsoleteApiAccountProductSettingsEditEfsAppSettingRequest): Promise<runtime.ApiResponse<EfsMelcoEfsModelsEfsUserResponseSetting>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/account/products/efs/settings/app`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EfsMelcoEfsModelsCreateUserSettingToJSON(requestParameters.efsMelcoEfsModelsCreateUserSetting),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EfsMelcoEfsModelsEfsUserResponseSettingFromJSON(jsonValue));
    }

    /**
     */
    async accountProductSettingsEditEfsAppSetting(requestParameters: EfsObsoleteApiAccountProductSettingsEditEfsAppSettingRequest): Promise<EfsMelcoEfsModelsEfsUserResponseSetting> {
        const response = await this.accountProductSettingsEditEfsAppSettingRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async accountProductSettingsGetEfsAppSettingRaw(): Promise<runtime.ApiResponse<EfsMelcoEfsModelsEfsUserResponseSetting>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/products/efs/settings/app`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EfsMelcoEfsModelsEfsUserResponseSettingFromJSON(jsonValue));
    }

    /**
     */
    async accountProductSettingsGetEfsAppSetting(): Promise<EfsMelcoEfsModelsEfsUserResponseSetting> {
        const response = await this.accountProductSettingsGetEfsAppSettingRaw();
        return await response.value();
    }

    /**
     */
    async productSettingsGetEfsAppSettingRaw(): Promise<runtime.ApiResponse<EfsMelcoEfsModelsEfsDefaultAppSetting>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/products/efs/settings/app`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EfsMelcoEfsModelsEfsDefaultAppSettingFromJSON(jsonValue));
    }

    /**
     */
    async productSettingsGetEfsAppSetting(): Promise<EfsMelcoEfsModelsEfsDefaultAppSetting> {
        const response = await this.productSettingsGetEfsAppSettingRaw();
        return await response.value();
    }

}
