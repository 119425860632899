/* tslint:disable */
/* eslint-disable */
/**
 * Melco Efs APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EfsMelcoEfsModelsGlobalFormatListItem,
    EfsMelcoEfsModelsGlobalFormatListItemFromJSON,
    EfsMelcoEfsModelsGlobalFormatListItemFromJSONTyped,
    EfsMelcoEfsModelsGlobalFormatListItemToJSON,
    EfsMelcoModelsCustomWarning,
    EfsMelcoModelsCustomWarningFromJSON,
    EfsMelcoModelsCustomWarningFromJSONTyped,
    EfsMelcoModelsCustomWarningToJSON,
} from './';

/**
 * 
 * @export
 * @interface EfsMelcoModelsGenericList1MelcoEfsModelsGlobalFormatListItem
 */
export interface EfsMelcoModelsGenericList1MelcoEfsModelsGlobalFormatListItem {
    /**
     * 
     * @type {Array<EfsMelcoEfsModelsGlobalFormatListItem>}
     * @memberof EfsMelcoModelsGenericList1MelcoEfsModelsGlobalFormatListItem
     */
    list: Array<EfsMelcoEfsModelsGlobalFormatListItem>;
    /**
     * 
     * @type {Array<EfsMelcoModelsCustomWarning>}
     * @memberof EfsMelcoModelsGenericList1MelcoEfsModelsGlobalFormatListItem
     */
    warning_list?: Array<EfsMelcoModelsCustomWarning> | null;
    /**
     * 
     * @type {number}
     * @memberof EfsMelcoModelsGenericList1MelcoEfsModelsGlobalFormatListItem
     */
    total: number;
}

export function EfsMelcoModelsGenericList1MelcoEfsModelsGlobalFormatListItemFromJSON(json: any): EfsMelcoModelsGenericList1MelcoEfsModelsGlobalFormatListItem {
    return EfsMelcoModelsGenericList1MelcoEfsModelsGlobalFormatListItemFromJSONTyped(json, false);
}

export function EfsMelcoModelsGenericList1MelcoEfsModelsGlobalFormatListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): EfsMelcoModelsGenericList1MelcoEfsModelsGlobalFormatListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'list': ((json['list'] as Array<any>).map(EfsMelcoEfsModelsGlobalFormatListItemFromJSON)),
        'warning_list': !exists(json, 'warning_list') ? undefined : (json['warning_list'] === null ? null : (json['warning_list'] as Array<any>).map(EfsMelcoModelsCustomWarningFromJSON)),
        'total': json['total'],
    };
}

export function EfsMelcoModelsGenericList1MelcoEfsModelsGlobalFormatListItemToJSON(value?: EfsMelcoModelsGenericList1MelcoEfsModelsGlobalFormatListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'list': ((value.list as Array<any>).map(EfsMelcoEfsModelsGlobalFormatListItemToJSON)),
        'warning_list': value.warning_list === undefined ? undefined : (value.warning_list === null ? null : (value.warning_list as Array<any>).map(EfsMelcoModelsCustomWarningToJSON)),
        'total': value.total,
    };
}


