/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MelcoFusionModelsFusionAppSetting,
    MelcoFusionModelsFusionAppSettingFromJSON,
    MelcoFusionModelsFusionAppSettingToJSON,
    MelcoFusionModelsFusionConfiguratorSetting,
    MelcoFusionModelsFusionConfiguratorSettingFromJSON,
    MelcoFusionModelsFusionConfiguratorSettingToJSON,
    MelcoModelsApiKey,
    MelcoModelsApiKeyFromJSON,
    MelcoModelsApiKeyToJSON,
    MelcoModelsAppStartUp,
    MelcoModelsAppStartUpFromJSON,
    MelcoModelsAppStartUpToJSON,
    MelcoModelsBoolResponse,
    MelcoModelsBoolResponseFromJSON,
    MelcoModelsBoolResponseToJSON,
    MelcoModelsCreateApiKey,
    MelcoModelsCreateApiKeyFromJSON,
    MelcoModelsCreateApiKeyToJSON,
    MelcoModelsDeviceLogoffRequest,
    MelcoModelsDeviceLogoffRequestFromJSON,
    MelcoModelsDeviceLogoffRequestToJSON,
    MelcoModelsDuplicateUser,
    MelcoModelsDuplicateUserFromJSON,
    MelcoModelsDuplicateUserToJSON,
    MelcoModelsEditSubUser,
    MelcoModelsEditSubUserFromJSON,
    MelcoModelsEditSubUserToJSON,
    MelcoModelsEmailOptInListItem,
    MelcoModelsEmailOptInListItemFromJSON,
    MelcoModelsEmailOptInListItemToJSON,
    MelcoModelsError,
    MelcoModelsErrorFromJSON,
    MelcoModelsErrorToJSON,
    MelcoModelsExtendTrialRequest,
    MelcoModelsExtendTrialRequestFromJSON,
    MelcoModelsExtendTrialRequestToJSON,
    MelcoModelsForcePasswordChange,
    MelcoModelsForcePasswordChangeFromJSON,
    MelcoModelsForcePasswordChangeToJSON,
    MelcoModelsGenericList1MelcoModelsApiKey,
    MelcoModelsGenericList1MelcoModelsApiKeyFromJSON,
    MelcoModelsGenericList1MelcoModelsApiKeyToJSON,
    MelcoModelsGenericList1MelcoModelsEmailOptInListItem,
    MelcoModelsGenericList1MelcoModelsEmailOptInListItemFromJSON,
    MelcoModelsGenericList1MelcoModelsEmailOptInListItemToJSON,
    MelcoModelsGenericList1MelcoModelsNotification,
    MelcoModelsGenericList1MelcoModelsNotificationFromJSON,
    MelcoModelsGenericList1MelcoModelsNotificationToJSON,
    MelcoModelsGenericList1MelcoModelsSerialNumberHistory,
    MelcoModelsGenericList1MelcoModelsSerialNumberHistoryFromJSON,
    MelcoModelsGenericList1MelcoModelsSerialNumberHistoryToJSON,
    MelcoModelsGenericList1MelcoModelsSubUserDetail,
    MelcoModelsGenericList1MelcoModelsSubUserDetailFromJSON,
    MelcoModelsGenericList1MelcoModelsSubUserDetailToJSON,
    MelcoModelsGenericList1MelcoModelsSubscriptionUserList,
    MelcoModelsGenericList1MelcoModelsSubscriptionUserListFromJSON,
    MelcoModelsGenericList1MelcoModelsSubscriptionUserListToJSON,
    MelcoModelsGenericList1MelcoModelsUserActivityListItem,
    MelcoModelsGenericList1MelcoModelsUserActivityListItemFromJSON,
    MelcoModelsGenericList1MelcoModelsUserActivityListItemToJSON,
    MelcoModelsGenericList1MelcoModelsUserDeviceListItem,
    MelcoModelsGenericList1MelcoModelsUserDeviceListItemFromJSON,
    MelcoModelsGenericList1MelcoModelsUserDeviceListItemToJSON,
    MelcoModelsGenericList1MelcoModelsUserProductListItem,
    MelcoModelsGenericList1MelcoModelsUserProductListItemFromJSON,
    MelcoModelsGenericList1MelcoModelsUserProductListItemToJSON,
    MelcoModelsGenericList1MelcoModelsUserSubscriptionListItem,
    MelcoModelsGenericList1MelcoModelsUserSubscriptionListItemFromJSON,
    MelcoModelsGenericList1MelcoModelsUserSubscriptionListItemToJSON,
    MelcoModelsGenericList1MelcoModelsUserTrialListItem,
    MelcoModelsGenericList1MelcoModelsUserTrialListItemFromJSON,
    MelcoModelsGenericList1MelcoModelsUserTrialListItemToJSON,
    MelcoModelsProductDefaults,
    MelcoModelsProductDefaultsFromJSON,
    MelcoModelsProductDefaultsToJSON,
    MelcoModelsProductTrial,
    MelcoModelsProductTrialFromJSON,
    MelcoModelsProductTrialToJSON,
    MelcoModelsReGeneratePassword,
    MelcoModelsReGeneratePasswordFromJSON,
    MelcoModelsReGeneratePasswordToJSON,
    MelcoModelsSecurityToken,
    MelcoModelsSecurityTokenFromJSON,
    MelcoModelsSecurityTokenToJSON,
    MelcoModelsServiceUser,
    MelcoModelsServiceUserFromJSON,
    MelcoModelsServiceUserToJSON,
    MelcoModelsSetPassword,
    MelcoModelsSetPasswordFromJSON,
    MelcoModelsSetPasswordToJSON,
    MelcoModelsSubUser,
    MelcoModelsSubUserFromJSON,
    MelcoModelsSubUserToJSON,
    MelcoModelsSubUserDetail,
    MelcoModelsSubUserDetailFromJSON,
    MelcoModelsSubUserDetailToJSON,
    MelcoModelsUser,
    MelcoModelsUserFromJSON,
    MelcoModelsUserToJSON,
    MelcoModelsUserDefaults,
    MelcoModelsUserDefaultsFromJSON,
    MelcoModelsUserDefaultsToJSON,
    MelcoModelsUserProduct,
    MelcoModelsUserProductFromJSON,
    MelcoModelsUserProductToJSON,
    MelcoModelsUserProductPermissions,
    MelcoModelsUserProductPermissionsFromJSON,
    MelcoModelsUserProductPermissionsToJSON,
    MelcoModelsUserSerialNumber,
    MelcoModelsUserSerialNumberFromJSON,
    MelcoModelsUserSerialNumberToJSON,
    MelcoModelsUserSerialNumberEdit,
    MelcoModelsUserSerialNumberEditFromJSON,
    MelcoModelsUserSerialNumberEditToJSON,
    MelcoModelsUserSerialNumberReminder,
    MelcoModelsUserSerialNumberReminderFromJSON,
    MelcoModelsUserSerialNumberReminderToJSON,
    MelcoModelsUserSubscription,
    MelcoModelsUserSubscriptionFromJSON,
    MelcoModelsUserSubscriptionToJSON,
} from '../models';

export interface UserAccountApiAccountApiKeysCreateRequest {
    melcoModelsCreateApiKey?: MelcoModelsCreateApiKey;
}

export interface UserAccountApiAccountApiKeysDeleteRequest {
    id: string;
}

export interface UserAccountApiAccountGetSubscriptionUserListRequest {
    searchText?: string;
    offset?: number;
    limit?: number;
    sortBy?: string;
    userName?: string;
    includeParent?: boolean;
}

export interface UserAccountApiAccountGetSubscriptionsRequest {
    productCodeList?: string;
    typeList?: string;
    searchText?: string;
    offset?: number;
    limit?: number;
    sortBy?: string;
}

export interface UserAccountApiAccountGetSubscriptionsTrialsRequest {
    productCode: string;
    searchText?: string;
    offset?: number;
    limit?: number;
    sortBy?: string;
}

export interface UserAccountApiAccountProductSettingsEditApiServiceAppSettingRequest {
    melcoFusionModelsFusionAppSetting?: MelcoFusionModelsFusionAppSetting;
}

export interface UserAccountApiAccountProductSettingsEditFusionAppSettingRequest {
    melcoFusionModelsFusionAppSetting?: MelcoFusionModelsFusionAppSetting;
}

export interface UserAccountApiAccountProductSettingsEditFusionConfiguratorSettingRequest {
    melcoFusionModelsFusionConfiguratorSetting?: MelcoFusionModelsFusionConfiguratorSetting;
}

export interface UserAccountApiAccountProductsAllowProductAccessRequest {
    productCode: string;
}

export interface UserAccountApiAccountProductsProductRequest {
    productCode: string;
}

export interface UserAccountApiAccountProductsRemoveProductAccessRequest {
    productCode: string;
}

export interface UserAccountApiAccountProductsRequestDemoRequest {
    productCode: string;
}

export interface UserAccountApiAccountProductsStartUpRequest {
    productCode: string;
}

export interface UserAccountApiAccountServiceTermsAcceptRequest {
    productCode: string;
}

export interface UserAccountApiAccountServiceTermsDeclineRequest {
    productCode: string;
}

export interface UserAccountApiAccountServiceTermsListRequest {
    productCode: string;
}

export interface UserAccountApiAccountServiceTermsNewsletterAcceptRequest {
    productCode: string;
}

export interface UserAccountApiAccountsApiKeysGetListRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    productCode?: string;
    searchText?: string;
}

export interface UserAccountApiDeviceGetDeviceListRequest {
    productCode: string;
}

export interface UserAccountApiDeviceLogoffDeviceRequest {
    productCode: string;
    melcoModelsDeviceLogoffRequest?: MelcoModelsDeviceLogoffRequest;
}

export interface UserAccountApiGetProductTrialRequest {
    productCode: string;
    versionNumber: string;
}

export interface UserAccountApiProductGetActivityListRequest {
    productCode: string;
    offset?: number;
    limit?: number;
}

export interface UserAccountApiProductLicensesGetReminderRequest {
    userSubscriptionId: string;
}

export interface UserAccountApiProductLicensesPutReminderRequest {
    userSubscriptionId: string;
    melcoModelsUserSerialNumberReminder?: MelcoModelsUserSerialNumberReminder;
}

export interface UserAccountApiProductsGetProductDefaultsRequest {
    productCode: string;
}

export interface UserAccountApiSubscriptionActivateRequest {
    serialNumber: string;
}

export interface UserAccountApiSubscriptionAssignRequest {
    userSubscriptionId: string;
    melcoModelsUserSubscription?: MelcoModelsUserSubscription;
}

export interface UserAccountApiSubscriptionEditRequest {
    userSubscriptionId: string;
    melcoModelsUserSerialNumberEdit?: MelcoModelsUserSerialNumberEdit;
}

export interface UserAccountApiSubscriptionExtendTrialRequest {
    productCode: string;
    versionNumber: string;
    melcoModelsExtendTrialRequest?: MelcoModelsExtendTrialRequest;
}

export interface UserAccountApiSubscriptionGetRequest {
    userSubscriptionId: string;
}

export interface UserAccountApiSubscriptionGetHistoryRequest {
    userSubscriptionId: string;
}

export interface UserAccountApiSubscriptionGetTokenByIdRequest {
    tokenId: string;
}

export interface UserAccountApiSubscriptionStartTrialRequest {
    productCode: string;
    versionNumber: string;
}

export interface UserAccountApiUserAccountCreateRequest {
    melcoModelsSubUser?: MelcoModelsSubUser;
}

export interface UserAccountApiUserAccountDeleteRequest {
    id: string;
}

export interface UserAccountApiUserAccountDuplicateRequest {
    melcoModelsDuplicateUser?: MelcoModelsDuplicateUser;
}

export interface UserAccountApiUserAccountEditRequest {
    id: string;
    melcoModelsEditSubUser?: MelcoModelsEditSubUser;
}

export interface UserAccountApiUserAccountEditAllPermissionsRequest {
    userId: string;
    melcoModelsUserProductPermissions?: MelcoModelsUserProductPermissions;
}

export interface UserAccountApiUserAccountForcePasswordChangeRequest {
    melcoModelsForcePasswordChange?: MelcoModelsForcePasswordChange;
}

export interface UserAccountApiUserAccountGetAllPermissionsRequest {
    userId: string;
}

export interface UserAccountApiUserAccountGetByIdRequest {
    id: string;
}

export interface UserAccountApiUserAccountGetListRequest {
    searchText?: string;
    offset?: number;
    limit?: number;
    sortBy?: string;
    userName?: string;
    includeParent?: boolean;
}

export interface UserAccountApiUserAccountGetProductsListRequest {
    id: string;
}

export interface UserAccountApiUserAccountRegeneratePasswordRequest {
    id: string;
}

export interface UserAccountApiUserAccountResendInviteRequest {
    id: string;
}

export interface UserAccountApiUserAccountSetPasswordRequest {
    melcoModelsSetPassword?: MelcoModelsSetPassword;
}

export interface UserAccountApiUserDefaultsUpdateRequest {
    melcoModelsUserDefaults?: MelcoModelsUserDefaults;
}

export interface UserAccountApiUserProductNotificationsDeleteRequest {
    id: string;
    productCode: string;
}

export interface UserAccountApiUserProductNotificationsDeleteByProductRequest {
    productCode: string;
}

export interface UserAccountApiUserProductNotificationsGetListRequest {
    productCode: string;
    userId?: string;
}

/**
 * 
 */
export class UserAccountApi extends runtime.BaseAPI {

    /**
     * Create New Api Key
     */
    async accountApiKeysCreateRaw(requestParameters: UserAccountApiAccountApiKeysCreateRequest): Promise<runtime.ApiResponse<MelcoModelsApiKey>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/account/apikeys`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsCreateApiKeyToJSON(requestParameters.melcoModelsCreateApiKey),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsApiKeyFromJSON(jsonValue));
    }

    /**
     * Create New Api Key
     */
    async accountApiKeysCreate(requestParameters: UserAccountApiAccountApiKeysCreateRequest): Promise<MelcoModelsApiKey> {
        const response = await this.accountApiKeysCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete Api Key
     */
    async accountApiKeysDeleteRaw(requestParameters: UserAccountApiAccountApiKeysDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling accountApiKeysDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/apikeys/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Api Key
     */
    async accountApiKeysDelete(requestParameters: UserAccountApiAccountApiKeysDeleteRequest): Promise<void> {
        await this.accountApiKeysDeleteRaw(requestParameters);
    }

    /**
     * disable user account
     * disable user account
     */
    async accountDisableRaw(): Promise<runtime.ApiResponse<MelcoModelsBoolResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/disable`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     * disable user account
     * disable user account
     */
    async accountDisable(): Promise<MelcoModelsBoolResponse> {
        const response = await this.accountDisableRaw();
        return await response.value();
    }

    /**
     * enable user account
     * enable user account
     */
    async accountEnableRaw(): Promise<runtime.ApiResponse<MelcoModelsBoolResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/enable`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     * enable user account
     * enable user account
     */
    async accountEnable(): Promise<MelcoModelsBoolResponse> {
        const response = await this.accountEnableRaw();
        return await response.value();
    }

    /**
     * get list of users for subscription
     */
    async accountGetSubscriptionUserListRaw(requestParameters: UserAccountApiAccountGetSubscriptionUserListRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsSubscriptionUserList>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.userName !== undefined) {
            queryParameters['user_name'] = requestParameters.userName;
        }

        if (requestParameters.includeParent !== undefined) {
            queryParameters['include_parent'] = requestParameters.includeParent;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/subscriptions/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsSubscriptionUserListFromJSON(jsonValue));
    }

    /**
     * get list of users for subscription
     */
    async accountGetSubscriptionUserList(requestParameters: UserAccountApiAccountGetSubscriptionUserListRequest): Promise<MelcoModelsGenericList1MelcoModelsSubscriptionUserList> {
        const response = await this.accountGetSubscriptionUserListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get User Product Subscriptions
     */
    async accountGetSubscriptionsRaw(requestParameters: UserAccountApiAccountGetSubscriptionsRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsUserSubscriptionListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.productCodeList !== undefined) {
            queryParameters['product_code_list'] = requestParameters.productCodeList;
        }

        if (requestParameters.typeList !== undefined) {
            queryParameters['type_list'] = requestParameters.typeList;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/subscriptions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsUserSubscriptionListItemFromJSON(jsonValue));
    }

    /**
     * Get User Product Subscriptions
     */
    async accountGetSubscriptions(requestParameters: UserAccountApiAccountGetSubscriptionsRequest): Promise<MelcoModelsGenericList1MelcoModelsUserSubscriptionListItem> {
        const response = await this.accountGetSubscriptionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * get trial list
     */
    async accountGetSubscriptionsTrialsRaw(requestParameters: UserAccountApiAccountGetSubscriptionsTrialsRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsUserTrialListItem>> {
        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling accountGetSubscriptionsTrials.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/products/{product_code}/trials`.replace(`{${"product_code"}}`, encodeURIComponent(String(requestParameters.productCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsUserTrialListItemFromJSON(jsonValue));
    }

    /**
     * get trial list
     */
    async accountGetSubscriptionsTrials(requestParameters: UserAccountApiAccountGetSubscriptionsTrialsRequest): Promise<MelcoModelsGenericList1MelcoModelsUserTrialListItem> {
        const response = await this.accountGetSubscriptionsTrialsRaw(requestParameters);
        return await response.value();
    }

    /**
     * delete user api service app settings
     */
    async accountProductSettingsDeleteApiServiceAppSettingRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/products/api_service/settings/app`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete user api service app settings
     */
    async accountProductSettingsDeleteApiServiceAppSetting(): Promise<void> {
        await this.accountProductSettingsDeleteApiServiceAppSettingRaw();
    }

    /**
     * delete user fusion app settings
     */
    async accountProductSettingsDeleteFusionAppSettingRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/products/fusion/settings/app`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete user fusion app settings
     */
    async accountProductSettingsDeleteFusionAppSetting(): Promise<void> {
        await this.accountProductSettingsDeleteFusionAppSettingRaw();
    }

    /**
     * delete fusion configurator setting
     */
    async accountProductSettingsDeleteFusionConfiguratorSettingRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/products/fusion/settings/configurator`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete fusion configurator setting
     */
    async accountProductSettingsDeleteFusionConfiguratorSetting(): Promise<void> {
        await this.accountProductSettingsDeleteFusionConfiguratorSettingRaw();
    }

    /**
     * update api service app settings
     * update api service app settings
     */
    async accountProductSettingsEditApiServiceAppSettingRaw(requestParameters: UserAccountApiAccountProductSettingsEditApiServiceAppSettingRequest): Promise<runtime.ApiResponse<MelcoFusionModelsFusionAppSetting>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/account/products/api_service/settings/app`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoFusionModelsFusionAppSettingToJSON(requestParameters.melcoFusionModelsFusionAppSetting),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoFusionModelsFusionAppSettingFromJSON(jsonValue));
    }

    /**
     * update api service app settings
     * update api service app settings
     */
    async accountProductSettingsEditApiServiceAppSetting(requestParameters: UserAccountApiAccountProductSettingsEditApiServiceAppSettingRequest): Promise<MelcoFusionModelsFusionAppSetting> {
        const response = await this.accountProductSettingsEditApiServiceAppSettingRaw(requestParameters);
        return await response.value();
    }

    /**
     * update fusion app settings
     * update fusion app settings
     */
    async accountProductSettingsEditFusionAppSettingRaw(requestParameters: UserAccountApiAccountProductSettingsEditFusionAppSettingRequest): Promise<runtime.ApiResponse<MelcoFusionModelsFusionAppSetting>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/account/products/fusion/settings/app`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoFusionModelsFusionAppSettingToJSON(requestParameters.melcoFusionModelsFusionAppSetting),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoFusionModelsFusionAppSettingFromJSON(jsonValue));
    }

    /**
     * update fusion app settings
     * update fusion app settings
     */
    async accountProductSettingsEditFusionAppSetting(requestParameters: UserAccountApiAccountProductSettingsEditFusionAppSettingRequest): Promise<MelcoFusionModelsFusionAppSetting> {
        const response = await this.accountProductSettingsEditFusionAppSettingRaw(requestParameters);
        return await response.value();
    }

    /**
     * edit fusion configurator setting
     */
    async accountProductSettingsEditFusionConfiguratorSettingRaw(requestParameters: UserAccountApiAccountProductSettingsEditFusionConfiguratorSettingRequest): Promise<runtime.ApiResponse<MelcoFusionModelsFusionConfiguratorSetting>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/account/products/fusion/settings/configurator`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoFusionModelsFusionConfiguratorSettingToJSON(requestParameters.melcoFusionModelsFusionConfiguratorSetting),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoFusionModelsFusionConfiguratorSettingFromJSON(jsonValue));
    }

    /**
     * edit fusion configurator setting
     */
    async accountProductSettingsEditFusionConfiguratorSetting(requestParameters: UserAccountApiAccountProductSettingsEditFusionConfiguratorSettingRequest): Promise<MelcoFusionModelsFusionConfiguratorSetting> {
        const response = await this.accountProductSettingsEditFusionConfiguratorSettingRaw(requestParameters);
        return await response.value();
    }

    /**
     * get api service app settings
     * get api service app settings
     */
    async accountProductSettingsGetApiServiceAppSettingRaw(): Promise<runtime.ApiResponse<MelcoFusionModelsFusionAppSetting>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/products/api_service/settings/app`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoFusionModelsFusionAppSettingFromJSON(jsonValue));
    }

    /**
     * get api service app settings
     * get api service app settings
     */
    async accountProductSettingsGetApiServiceAppSetting(): Promise<MelcoFusionModelsFusionAppSetting> {
        const response = await this.accountProductSettingsGetApiServiceAppSettingRaw();
        return await response.value();
    }

    /**
     * get fusion app settings
     * get fusion app settings
     */
    async accountProductSettingsGetFusionAppSettingRaw(): Promise<runtime.ApiResponse<MelcoFusionModelsFusionAppSetting>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/products/fusion/settings/app`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoFusionModelsFusionAppSettingFromJSON(jsonValue));
    }

    /**
     * get fusion app settings
     * get fusion app settings
     */
    async accountProductSettingsGetFusionAppSetting(): Promise<MelcoFusionModelsFusionAppSetting> {
        const response = await this.accountProductSettingsGetFusionAppSettingRaw();
        return await response.value();
    }

    /**
     * get fusion configurator setting
     */
    async accountProductSettingsGetFusionConfiguratorSettingRaw(): Promise<runtime.ApiResponse<MelcoFusionModelsFusionConfiguratorSetting>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/products/fusion/settings/configurator`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoFusionModelsFusionConfiguratorSettingFromJSON(jsonValue));
    }

    /**
     * get fusion configurator setting
     */
    async accountProductSettingsGetFusionConfiguratorSetting(): Promise<MelcoFusionModelsFusionConfiguratorSetting> {
        const response = await this.accountProductSettingsGetFusionConfiguratorSettingRaw();
        return await response.value();
    }

    /**
     * allow access to product
     */
    async accountProductsAllowProductAccessRaw(requestParameters: UserAccountApiAccountProductsAllowProductAccessRequest): Promise<runtime.ApiResponse<MelcoModelsBoolResponse>> {
        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling accountProductsAllowProductAccess.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/products/{product_code}`.replace(`{${"product_code"}}`, encodeURIComponent(String(requestParameters.productCode))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     * allow access to product
     */
    async accountProductsAllowProductAccess(requestParameters: UserAccountApiAccountProductsAllowProductAccessRequest): Promise<MelcoModelsBoolResponse> {
        const response = await this.accountProductsAllowProductAccessRaw(requestParameters);
        return await response.value();
    }

    /**
     * get list of user products.
     * get user product list
     */
    async accountProductsListRaw(): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsUserProductListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/products`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsUserProductListItemFromJSON(jsonValue));
    }

    /**
     * get list of user products.
     * get user product list
     */
    async accountProductsList(): Promise<MelcoModelsGenericList1MelcoModelsUserProductListItem> {
        const response = await this.accountProductsListRaw();
        return await response.value();
    }

    /**
     * get list of user products.
     * get user product
     */
    async accountProductsProductRaw(requestParameters: UserAccountApiAccountProductsProductRequest): Promise<runtime.ApiResponse<MelcoModelsUserProduct>> {
        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling accountProductsProduct.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/products/{product_code}`.replace(`{${"product_code"}}`, encodeURIComponent(String(requestParameters.productCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsUserProductFromJSON(jsonValue));
    }

    /**
     * get list of user products.
     * get user product
     */
    async accountProductsProduct(requestParameters: UserAccountApiAccountProductsProductRequest): Promise<MelcoModelsUserProduct> {
        const response = await this.accountProductsProductRaw(requestParameters);
        return await response.value();
    }

    /**
     * delete product access
     */
    async accountProductsRemoveProductAccessRaw(requestParameters: UserAccountApiAccountProductsRemoveProductAccessRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling accountProductsRemoveProductAccess.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/products/{product_code}`.replace(`{${"product_code"}}`, encodeURIComponent(String(requestParameters.productCode))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete product access
     */
    async accountProductsRemoveProductAccess(requestParameters: UserAccountApiAccountProductsRemoveProductAccessRequest): Promise<void> {
        await this.accountProductsRemoveProductAccessRaw(requestParameters);
    }

    /**
     * request product demo
     */
    async accountProductsRequestDemoRaw(requestParameters: UserAccountApiAccountProductsRequestDemoRequest): Promise<runtime.ApiResponse<MelcoModelsBoolResponse>> {
        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling accountProductsRequestDemo.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/products/{product_code}/requestdemo`.replace(`{${"product_code"}}`, encodeURIComponent(String(requestParameters.productCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     * request product demo
     */
    async accountProductsRequestDemo(requestParameters: UserAccountApiAccountProductsRequestDemoRequest): Promise<MelcoModelsBoolResponse> {
        const response = await this.accountProductsRequestDemoRaw(requestParameters);
        return await response.value();
    }

    /**
     * app startup call to validate all the required info/settings
     */
    async accountProductsStartUpRaw(requestParameters: UserAccountApiAccountProductsStartUpRequest): Promise<runtime.ApiResponse<MelcoModelsAppStartUp>> {
        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling accountProductsStartUp.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/products/{product_code}/startup`.replace(`{${"product_code"}}`, encodeURIComponent(String(requestParameters.productCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsAppStartUpFromJSON(jsonValue));
    }

    /**
     * app startup call to validate all the required info/settings
     */
    async accountProductsStartUp(requestParameters: UserAccountApiAccountProductsStartUpRequest): Promise<MelcoModelsAppStartUp> {
        const response = await this.accountProductsStartUpRaw(requestParameters);
        return await response.value();
    }

    /**
     * accept product legal service terms
     */
    async accountServiceTermsAcceptRaw(requestParameters: UserAccountApiAccountServiceTermsAcceptRequest): Promise<runtime.ApiResponse<MelcoModelsEmailOptInListItem>> {
        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling accountServiceTermsAccept.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/products/{product_code}/serviceterms/accept`.replace(`{${"product_code"}}`, encodeURIComponent(String(requestParameters.productCode))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsEmailOptInListItemFromJSON(jsonValue));
    }

    /**
     * accept product legal service terms
     */
    async accountServiceTermsAccept(requestParameters: UserAccountApiAccountServiceTermsAcceptRequest): Promise<MelcoModelsEmailOptInListItem> {
        const response = await this.accountServiceTermsAcceptRaw(requestParameters);
        return await response.value();
    }

    /**
     * decline product legal service terms
     */
    async accountServiceTermsDeclineRaw(requestParameters: UserAccountApiAccountServiceTermsDeclineRequest): Promise<runtime.ApiResponse<MelcoModelsEmailOptInListItem>> {
        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling accountServiceTermsDecline.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/products/{product_code}/serviceterms/decline`.replace(`{${"product_code"}}`, encodeURIComponent(String(requestParameters.productCode))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsEmailOptInListItemFromJSON(jsonValue));
    }

    /**
     * decline product legal service terms
     */
    async accountServiceTermsDecline(requestParameters: UserAccountApiAccountServiceTermsDeclineRequest): Promise<MelcoModelsEmailOptInListItem> {
        const response = await this.accountServiceTermsDeclineRaw(requestParameters);
        return await response.value();
    }

    /**
     * get list of user service terms acceptance history
     */
    async accountServiceTermsListRaw(requestParameters: UserAccountApiAccountServiceTermsListRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsEmailOptInListItem>> {
        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling accountServiceTermsList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/products/{product_code}/serviceterms`.replace(`{${"product_code"}}`, encodeURIComponent(String(requestParameters.productCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsEmailOptInListItemFromJSON(jsonValue));
    }

    /**
     * get list of user service terms acceptance history
     */
    async accountServiceTermsList(requestParameters: UserAccountApiAccountServiceTermsListRequest): Promise<MelcoModelsGenericList1MelcoModelsEmailOptInListItem> {
        const response = await this.accountServiceTermsListRaw(requestParameters);
        return await response.value();
    }

    /**
     * opt in for marketing newsletter
     */
    async accountServiceTermsNewsletterAcceptRaw(requestParameters: UserAccountApiAccountServiceTermsNewsletterAcceptRequest): Promise<runtime.ApiResponse<MelcoModelsEmailOptInListItem>> {
        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling accountServiceTermsNewsletterAccept.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/products/{product_code}/newsletter/accept`.replace(`{${"product_code"}}`, encodeURIComponent(String(requestParameters.productCode))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsEmailOptInListItemFromJSON(jsonValue));
    }

    /**
     * opt in for marketing newsletter
     */
    async accountServiceTermsNewsletterAccept(requestParameters: UserAccountApiAccountServiceTermsNewsletterAcceptRequest): Promise<MelcoModelsEmailOptInListItem> {
        const response = await this.accountServiceTermsNewsletterAcceptRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get ApiKey List
     */
    async accountsApiKeysGetListRaw(requestParameters: UserAccountApiAccountsApiKeysGetListRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsApiKey>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.productCode !== undefined) {
            queryParameters['product_code'] = requestParameters.productCode;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/apikeys`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsApiKeyFromJSON(jsonValue));
    }

    /**
     * Get ApiKey List
     */
    async accountsApiKeysGetList(requestParameters: UserAccountApiAccountsApiKeysGetListRequest): Promise<MelcoModelsGenericList1MelcoModelsApiKey> {
        const response = await this.accountsApiKeysGetListRaw(requestParameters);
        return await response.value();
    }

    /**
     * get list of user devices/tokens.
     * get user device list
     */
    async deviceGetDeviceListRaw(requestParameters: UserAccountApiDeviceGetDeviceListRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsUserDeviceListItem>> {
        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling deviceGetDeviceList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/products/{product_code}/devices`.replace(`{${"product_code"}}`, encodeURIComponent(String(requestParameters.productCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsUserDeviceListItemFromJSON(jsonValue));
    }

    /**
     * get list of user devices/tokens.
     * get user device list
     */
    async deviceGetDeviceList(requestParameters: UserAccountApiDeviceGetDeviceListRequest): Promise<MelcoModelsGenericList1MelcoModelsUserDeviceListItem> {
        const response = await this.deviceGetDeviceListRaw(requestParameters);
        return await response.value();
    }

    /**
     * log off device
     * log off device
     */
    async deviceLogoffDeviceRaw(requestParameters: UserAccountApiDeviceLogoffDeviceRequest): Promise<runtime.ApiResponse<MelcoModelsBoolResponse>> {
        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling deviceLogoffDevice.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/account/products/{product_code}/devices/logoff`.replace(`{${"product_code"}}`, encodeURIComponent(String(requestParameters.productCode))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsDeviceLogoffRequestToJSON(requestParameters.melcoModelsDeviceLogoffRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     * log off device
     * log off device
     */
    async deviceLogoffDevice(requestParameters: UserAccountApiDeviceLogoffDeviceRequest): Promise<MelcoModelsBoolResponse> {
        const response = await this.deviceLogoffDeviceRaw(requestParameters);
        return await response.value();
    }

    /**
     * get user product trial details
     */
    async getProductTrialRaw(requestParameters: UserAccountApiGetProductTrialRequest): Promise<runtime.ApiResponse<MelcoModelsProductTrial>> {
        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling getProductTrial.');
        }

        if (requestParameters.versionNumber === null || requestParameters.versionNumber === undefined) {
            throw new runtime.RequiredError('versionNumber','Required parameter requestParameters.versionNumber was null or undefined when calling getProductTrial.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/products/{product_code}/{version_number}/start_trial`.replace(`{${"product_code"}}`, encodeURIComponent(String(requestParameters.productCode))).replace(`{${"version_number"}}`, encodeURIComponent(String(requestParameters.versionNumber))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsProductTrialFromJSON(jsonValue));
    }

    /**
     * get user product trial details
     */
    async getProductTrial(requestParameters: UserAccountApiGetProductTrialRequest): Promise<MelcoModelsProductTrial> {
        const response = await this.getProductTrialRaw(requestParameters);
        return await response.value();
    }

    /**
     * get list of user product activity.
     * get user product activity list
     */
    async productGetActivityListRaw(requestParameters: UserAccountApiProductGetActivityListRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsUserActivityListItem>> {
        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling productGetActivityList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/products/{product_code}/activities`.replace(`{${"product_code"}}`, encodeURIComponent(String(requestParameters.productCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsUserActivityListItemFromJSON(jsonValue));
    }

    /**
     * get list of user product activity.
     * get user product activity list
     */
    async productGetActivityList(requestParameters: UserAccountApiProductGetActivityListRequest): Promise<MelcoModelsGenericList1MelcoModelsUserActivityListItem> {
        const response = await this.productGetActivityListRaw(requestParameters);
        return await response.value();
    }

    /**
     * get serial numbers reminders
     */
    async productLicensesGetReminderRaw(requestParameters: UserAccountApiProductLicensesGetReminderRequest): Promise<runtime.ApiResponse<MelcoModelsUserSerialNumberReminder>> {
        if (requestParameters.userSubscriptionId === null || requestParameters.userSubscriptionId === undefined) {
            throw new runtime.RequiredError('userSubscriptionId','Required parameter requestParameters.userSubscriptionId was null or undefined when calling productLicensesGetReminder.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/subscriptions/{user_subscription_id}/notifications/activation_reminder`.replace(`{${"user_subscription_id"}}`, encodeURIComponent(String(requestParameters.userSubscriptionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsUserSerialNumberReminderFromJSON(jsonValue));
    }

    /**
     * get serial numbers reminders
     */
    async productLicensesGetReminder(requestParameters: UserAccountApiProductLicensesGetReminderRequest): Promise<MelcoModelsUserSerialNumberReminder> {
        const response = await this.productLicensesGetReminderRaw(requestParameters);
        return await response.value();
    }

    /**
     * update serial numbers reminders
     */
    async productLicensesPutReminderRaw(requestParameters: UserAccountApiProductLicensesPutReminderRequest): Promise<runtime.ApiResponse<MelcoModelsUserSerialNumberReminder>> {
        if (requestParameters.userSubscriptionId === null || requestParameters.userSubscriptionId === undefined) {
            throw new runtime.RequiredError('userSubscriptionId','Required parameter requestParameters.userSubscriptionId was null or undefined when calling productLicensesPutReminder.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/account/subscriptions/{user_subscription_id}/notifications/activation_reminder`.replace(`{${"user_subscription_id"}}`, encodeURIComponent(String(requestParameters.userSubscriptionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsUserSerialNumberReminderToJSON(requestParameters.melcoModelsUserSerialNumberReminder),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsUserSerialNumberReminderFromJSON(jsonValue));
    }

    /**
     * update serial numbers reminders
     */
    async productLicensesPutReminder(requestParameters: UserAccountApiProductLicensesPutReminderRequest): Promise<MelcoModelsUserSerialNumberReminder> {
        const response = await this.productLicensesPutReminderRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get User Product Default settings
     */
    async productsGetProductDefaultsRaw(requestParameters: UserAccountApiProductsGetProductDefaultsRequest): Promise<runtime.ApiResponse<MelcoModelsProductDefaults>> {
        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling productsGetProductDefaults.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/products/{product_code}/defaults`.replace(`{${"product_code"}}`, encodeURIComponent(String(requestParameters.productCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsProductDefaultsFromJSON(jsonValue));
    }

    /**
     * Get User Product Default settings
     */
    async productsGetProductDefaults(requestParameters: UserAccountApiProductsGetProductDefaultsRequest): Promise<MelcoModelsProductDefaults> {
        const response = await this.productsGetProductDefaultsRaw(requestParameters);
        return await response.value();
    }

    /**
     * activate serial number
     */
    async subscriptionActivateRaw(requestParameters: UserAccountApiSubscriptionActivateRequest): Promise<runtime.ApiResponse<MelcoModelsBoolResponse>> {
        if (requestParameters.serialNumber === null || requestParameters.serialNumber === undefined) {
            throw new runtime.RequiredError('serialNumber','Required parameter requestParameters.serialNumber was null or undefined when calling subscriptionActivate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/products/subscriptions/{serial_number}/activate`.replace(`{${"serial_number"}}`, encodeURIComponent(String(requestParameters.serialNumber))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     * activate serial number
     */
    async subscriptionActivate(requestParameters: UserAccountApiSubscriptionActivateRequest): Promise<MelcoModelsBoolResponse> {
        const response = await this.subscriptionActivateRaw(requestParameters);
        return await response.value();
    }

    /**
     * assign serial number to user
     */
    async subscriptionAssignRaw(requestParameters: UserAccountApiSubscriptionAssignRequest): Promise<runtime.ApiResponse<MelcoModelsUserSerialNumber>> {
        if (requestParameters.userSubscriptionId === null || requestParameters.userSubscriptionId === undefined) {
            throw new runtime.RequiredError('userSubscriptionId','Required parameter requestParameters.userSubscriptionId was null or undefined when calling subscriptionAssign.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/account/subscriptions/{user_subscription_id}/assign`.replace(`{${"user_subscription_id"}}`, encodeURIComponent(String(requestParameters.userSubscriptionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsUserSubscriptionToJSON(requestParameters.melcoModelsUserSubscription),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsUserSerialNumberFromJSON(jsonValue));
    }

    /**
     * assign serial number to user
     */
    async subscriptionAssign(requestParameters: UserAccountApiSubscriptionAssignRequest): Promise<MelcoModelsUserSerialNumber> {
        const response = await this.subscriptionAssignRaw(requestParameters);
        return await response.value();
    }

    /**
     * edit serial number details
     */
    async subscriptionEditRaw(requestParameters: UserAccountApiSubscriptionEditRequest): Promise<runtime.ApiResponse<MelcoModelsUserSerialNumber>> {
        if (requestParameters.userSubscriptionId === null || requestParameters.userSubscriptionId === undefined) {
            throw new runtime.RequiredError('userSubscriptionId','Required parameter requestParameters.userSubscriptionId was null or undefined when calling subscriptionEdit.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/account/subscriptions/{user_subscription_id}`.replace(`{${"user_subscription_id"}}`, encodeURIComponent(String(requestParameters.userSubscriptionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsUserSerialNumberEditToJSON(requestParameters.melcoModelsUserSerialNumberEdit),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsUserSerialNumberFromJSON(jsonValue));
    }

    /**
     * edit serial number details
     */
    async subscriptionEdit(requestParameters: UserAccountApiSubscriptionEditRequest): Promise<MelcoModelsUserSerialNumber> {
        const response = await this.subscriptionEditRaw(requestParameters);
        return await response.value();
    }

    /**
     * extend trial
     */
    async subscriptionExtendTrialRaw(requestParameters: UserAccountApiSubscriptionExtendTrialRequest): Promise<runtime.ApiResponse<MelcoModelsBoolResponse>> {
        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling subscriptionExtendTrial.');
        }

        if (requestParameters.versionNumber === null || requestParameters.versionNumber === undefined) {
            throw new runtime.RequiredError('versionNumber','Required parameter requestParameters.versionNumber was null or undefined when calling subscriptionExtendTrial.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/account/products/{product_code}/{version_number}/extend_trial`.replace(`{${"product_code"}}`, encodeURIComponent(String(requestParameters.productCode))).replace(`{${"version_number"}}`, encodeURIComponent(String(requestParameters.versionNumber))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsExtendTrialRequestToJSON(requestParameters.melcoModelsExtendTrialRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     * extend trial
     */
    async subscriptionExtendTrial(requestParameters: UserAccountApiSubscriptionExtendTrialRequest): Promise<MelcoModelsBoolResponse> {
        const response = await this.subscriptionExtendTrialRaw(requestParameters);
        return await response.value();
    }

    /**
     * get serial number details
     */
    async subscriptionGetRaw(requestParameters: UserAccountApiSubscriptionGetRequest): Promise<runtime.ApiResponse<MelcoModelsUserSerialNumber>> {
        if (requestParameters.userSubscriptionId === null || requestParameters.userSubscriptionId === undefined) {
            throw new runtime.RequiredError('userSubscriptionId','Required parameter requestParameters.userSubscriptionId was null or undefined when calling subscriptionGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/subscriptions/{user_subscription_id}`.replace(`{${"user_subscription_id"}}`, encodeURIComponent(String(requestParameters.userSubscriptionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsUserSerialNumberFromJSON(jsonValue));
    }

    /**
     * get serial number details
     */
    async subscriptionGet(requestParameters: UserAccountApiSubscriptionGetRequest): Promise<MelcoModelsUserSerialNumber> {
        const response = await this.subscriptionGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * get serial number history
     */
    async subscriptionGetHistoryRaw(requestParameters: UserAccountApiSubscriptionGetHistoryRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsSerialNumberHistory>> {
        if (requestParameters.userSubscriptionId === null || requestParameters.userSubscriptionId === undefined) {
            throw new runtime.RequiredError('userSubscriptionId','Required parameter requestParameters.userSubscriptionId was null or undefined when calling subscriptionGetHistory.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/subscriptions/{user_subscription_id}/history`.replace(`{${"user_subscription_id"}}`, encodeURIComponent(String(requestParameters.userSubscriptionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsSerialNumberHistoryFromJSON(jsonValue));
    }

    /**
     * get serial number history
     */
    async subscriptionGetHistory(requestParameters: UserAccountApiSubscriptionGetHistoryRequest): Promise<MelcoModelsGenericList1MelcoModelsSerialNumberHistory> {
        const response = await this.subscriptionGetHistoryRaw(requestParameters);
        return await response.value();
    }

    /**
     * get license details by token Id
     */
    async subscriptionGetTokenByIdRaw(requestParameters: UserAccountApiSubscriptionGetTokenByIdRequest): Promise<runtime.ApiResponse<MelcoModelsSecurityToken>> {
        if (requestParameters.tokenId === null || requestParameters.tokenId === undefined) {
            throw new runtime.RequiredError('tokenId','Required parameter requestParameters.tokenId was null or undefined when calling subscriptionGetTokenById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/subscriptions/token/{token_id}`.replace(`{${"token_id"}}`, encodeURIComponent(String(requestParameters.tokenId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsSecurityTokenFromJSON(jsonValue));
    }

    /**
     * get license details by token Id
     */
    async subscriptionGetTokenById(requestParameters: UserAccountApiSubscriptionGetTokenByIdRequest): Promise<MelcoModelsSecurityToken> {
        const response = await this.subscriptionGetTokenByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * start  trial
     */
    async subscriptionStartTrialRaw(requestParameters: UserAccountApiSubscriptionStartTrialRequest): Promise<runtime.ApiResponse<MelcoModelsBoolResponse>> {
        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling subscriptionStartTrial.');
        }

        if (requestParameters.versionNumber === null || requestParameters.versionNumber === undefined) {
            throw new runtime.RequiredError('versionNumber','Required parameter requestParameters.versionNumber was null or undefined when calling subscriptionStartTrial.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/products/{product_code}/{version_number}/start_trial`.replace(`{${"product_code"}}`, encodeURIComponent(String(requestParameters.productCode))).replace(`{${"version_number"}}`, encodeURIComponent(String(requestParameters.versionNumber))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     * start  trial
     */
    async subscriptionStartTrial(requestParameters: UserAccountApiSubscriptionStartTrialRequest): Promise<MelcoModelsBoolResponse> {
        const response = await this.subscriptionStartTrialRaw(requestParameters);
        return await response.value();
    }

    /**
     * create new user in system and starts verification process
     * create new user
     */
    async userAccountCreateRaw(requestParameters: UserAccountApiUserAccountCreateRequest): Promise<runtime.ApiResponse<MelcoModelsSubUserDetail>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/account/users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsSubUserToJSON(requestParameters.melcoModelsSubUser),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsSubUserDetailFromJSON(jsonValue));
    }

    /**
     * create new user in system and starts verification process
     * create new user
     */
    async userAccountCreate(requestParameters: UserAccountApiUserAccountCreateRequest): Promise<MelcoModelsSubUserDetail> {
        const response = await this.userAccountCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * delete user
     */
    async userAccountDeleteRaw(requestParameters: UserAccountApiUserAccountDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userAccountDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/users/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete user
     */
    async userAccountDelete(requestParameters: UserAccountApiUserAccountDeleteRequest): Promise<void> {
        await this.userAccountDeleteRaw(requestParameters);
    }

    /**
     * duplicate user in the system
     * duplicate user
     */
    async userAccountDuplicateRaw(requestParameters: UserAccountApiUserAccountDuplicateRequest): Promise<runtime.ApiResponse<MelcoModelsSubUserDetail>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/account/users/duplicate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsDuplicateUserToJSON(requestParameters.melcoModelsDuplicateUser),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsSubUserDetailFromJSON(jsonValue));
    }

    /**
     * duplicate user in the system
     * duplicate user
     */
    async userAccountDuplicate(requestParameters: UserAccountApiUserAccountDuplicateRequest): Promise<MelcoModelsSubUserDetail> {
        const response = await this.userAccountDuplicateRaw(requestParameters);
        return await response.value();
    }

    /**
     * update user profile information
     * update user
     */
    async userAccountEditRaw(requestParameters: UserAccountApiUserAccountEditRequest): Promise<runtime.ApiResponse<MelcoModelsUser>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userAccountEdit.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/account/users/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsEditSubUserToJSON(requestParameters.melcoModelsEditSubUser),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsUserFromJSON(jsonValue));
    }

    /**
     * update user profile information
     * update user
     */
    async userAccountEdit(requestParameters: UserAccountApiUserAccountEditRequest): Promise<MelcoModelsUser> {
        const response = await this.userAccountEditRaw(requestParameters);
        return await response.value();
    }

    /**
     * edit user product permissions
     */
    async userAccountEditAllPermissionsRaw(requestParameters: UserAccountApiUserAccountEditAllPermissionsRequest): Promise<runtime.ApiResponse<MelcoModelsUserProductPermissions>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling userAccountEditAllPermissions.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/account/{user_id}/manage_access`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsUserProductPermissionsToJSON(requestParameters.melcoModelsUserProductPermissions),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsUserProductPermissionsFromJSON(jsonValue));
    }

    /**
     * edit user product permissions
     */
    async userAccountEditAllPermissions(requestParameters: UserAccountApiUserAccountEditAllPermissionsRequest): Promise<MelcoModelsUserProductPermissions> {
        const response = await this.userAccountEditAllPermissionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * force password change
     */
    async userAccountForcePasswordChangeRaw(requestParameters: UserAccountApiUserAccountForcePasswordChangeRequest): Promise<runtime.ApiResponse<MelcoModelsBoolResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/account/force_password`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsForcePasswordChangeToJSON(requestParameters.melcoModelsForcePasswordChange),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     * force password change
     */
    async userAccountForcePasswordChange(requestParameters: UserAccountApiUserAccountForcePasswordChangeRequest): Promise<MelcoModelsBoolResponse> {
        const response = await this.userAccountForcePasswordChangeRaw(requestParameters);
        return await response.value();
    }

    /**
     * get user product permissions
     */
    async userAccountGetAllPermissionsRaw(requestParameters: UserAccountApiUserAccountGetAllPermissionsRequest): Promise<runtime.ApiResponse<MelcoModelsUserProductPermissions>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling userAccountGetAllPermissions.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/{user_id}/manage_access`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsUserProductPermissionsFromJSON(jsonValue));
    }

    /**
     * get user product permissions
     */
    async userAccountGetAllPermissions(requestParameters: UserAccountApiUserAccountGetAllPermissionsRequest): Promise<MelcoModelsUserProductPermissions> {
        const response = await this.userAccountGetAllPermissionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * get sub user object
     * get sub user based on Id
     */
    async userAccountGetByIdRaw(requestParameters: UserAccountApiUserAccountGetByIdRequest): Promise<runtime.ApiResponse<MelcoModelsServiceUser>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userAccountGetById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/users/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsServiceUserFromJSON(jsonValue));
    }

    /**
     * get sub user object
     * get sub user based on Id
     */
    async userAccountGetById(requestParameters: UserAccountApiUserAccountGetByIdRequest): Promise<MelcoModelsServiceUser> {
        const response = await this.userAccountGetByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * search users
     * get user list
     */
    async userAccountGetListRaw(requestParameters: UserAccountApiUserAccountGetListRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsSubUserDetail>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.userName !== undefined) {
            queryParameters['user_name'] = requestParameters.userName;
        }

        if (requestParameters.includeParent !== undefined) {
            queryParameters['include_parent'] = requestParameters.includeParent;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsSubUserDetailFromJSON(jsonValue));
    }

    /**
     * search users
     * get user list
     */
    async userAccountGetList(requestParameters: UserAccountApiUserAccountGetListRequest): Promise<MelcoModelsGenericList1MelcoModelsSubUserDetail> {
        const response = await this.userAccountGetListRaw(requestParameters);
        return await response.value();
    }

    /**
     * get list of user products.
     * get user product list
     */
    async userAccountGetProductsListRaw(requestParameters: UserAccountApiUserAccountGetProductsListRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsUserProductListItem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userAccountGetProductsList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/users/{Id}/products`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsUserProductListItemFromJSON(jsonValue));
    }

    /**
     * get list of user products.
     * get user product list
     */
    async userAccountGetProductsList(requestParameters: UserAccountApiUserAccountGetProductsListRequest): Promise<MelcoModelsGenericList1MelcoModelsUserProductListItem> {
        const response = await this.userAccountGetProductsListRaw(requestParameters);
        return await response.value();
    }

    /**
     * regenerate password for user with username
     * regenerate password
     */
    async userAccountRegeneratePasswordRaw(requestParameters: UserAccountApiUserAccountRegeneratePasswordRequest): Promise<runtime.ApiResponse<MelcoModelsReGeneratePassword>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userAccountRegeneratePassword.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/users/{Id}/regenerate_password`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsReGeneratePasswordFromJSON(jsonValue));
    }

    /**
     * regenerate password for user with username
     * regenerate password
     */
    async userAccountRegeneratePassword(requestParameters: UserAccountApiUserAccountRegeneratePasswordRequest): Promise<MelcoModelsReGeneratePassword> {
        const response = await this.userAccountRegeneratePasswordRaw(requestParameters);
        return await response.value();
    }

    /**
     * resend invite to email
     * resend invite
     */
    async userAccountResendInviteRaw(requestParameters: UserAccountApiUserAccountResendInviteRequest): Promise<runtime.ApiResponse<MelcoModelsBoolResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userAccountResendInvite.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/users/{Id}/invite`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     * resend invite to email
     * resend invite
     */
    async userAccountResendInvite(requestParameters: UserAccountApiUserAccountResendInviteRequest): Promise<MelcoModelsBoolResponse> {
        const response = await this.userAccountResendInviteRaw(requestParameters);
        return await response.value();
    }

    /**
     * set password
     * set password
     */
    async userAccountSetPasswordRaw(requestParameters: UserAccountApiUserAccountSetPasswordRequest): Promise<runtime.ApiResponse<MelcoModelsBoolResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/account/set_password`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsSetPasswordToJSON(requestParameters.melcoModelsSetPassword),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     * set password
     * set password
     */
    async userAccountSetPassword(requestParameters: UserAccountApiUserAccountSetPasswordRequest): Promise<MelcoModelsBoolResponse> {
        const response = await this.userAccountSetPasswordRaw(requestParameters);
        return await response.value();
    }

    /**
     * delete user settings
     */
    async userDefaultsDeleteRaw(): Promise<runtime.ApiResponse<MelcoModelsUserDefaults>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/settings`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsUserDefaultsFromJSON(jsonValue));
    }

    /**
     * delete user settings
     */
    async userDefaultsDelete(): Promise<MelcoModelsUserDefaults> {
        const response = await this.userDefaultsDeleteRaw();
        return await response.value();
    }

    /**
     * get user account settings
     */
    async userDefaultsGetRaw(): Promise<runtime.ApiResponse<MelcoModelsUserDefaults>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsUserDefaultsFromJSON(jsonValue));
    }

    /**
     * get user account settings
     */
    async userDefaultsGet(): Promise<MelcoModelsUserDefaults> {
        const response = await this.userDefaultsGetRaw();
        return await response.value();
    }

    /**
     * update user
     */
    async userDefaultsUpdateRaw(requestParameters: UserAccountApiUserDefaultsUpdateRequest): Promise<runtime.ApiResponse<MelcoModelsUserDefaults>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/account/settings`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsUserDefaultsToJSON(requestParameters.melcoModelsUserDefaults),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsUserDefaultsFromJSON(jsonValue));
    }

    /**
     * update user
     */
    async userDefaultsUpdate(requestParameters: UserAccountApiUserDefaultsUpdateRequest): Promise<MelcoModelsUserDefaults> {
        const response = await this.userDefaultsUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     * delete notification
     */
    async userProductNotificationsDeleteRaw(requestParameters: UserAccountApiUserProductNotificationsDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userProductNotificationsDelete.');
        }

        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling userProductNotificationsDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/products/{product_code}/notifications/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"product_code"}}`, encodeURIComponent(String(requestParameters.productCode))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete notification
     */
    async userProductNotificationsDelete(requestParameters: UserAccountApiUserProductNotificationsDeleteRequest): Promise<void> {
        await this.userProductNotificationsDeleteRaw(requestParameters);
    }

    /**
     * delete notification by product code
     */
    async userProductNotificationsDeleteByProductRaw(requestParameters: UserAccountApiUserProductNotificationsDeleteByProductRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling userProductNotificationsDeleteByProduct.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/products/{product_code}/notifications`.replace(`{${"product_code"}}`, encodeURIComponent(String(requestParameters.productCode))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete notification by product code
     */
    async userProductNotificationsDeleteByProduct(requestParameters: UserAccountApiUserProductNotificationsDeleteByProductRequest): Promise<void> {
        await this.userProductNotificationsDeleteByProductRaw(requestParameters);
    }

    /**
     * get list of user products.
     * get user product notifications list
     */
    async userProductNotificationsGetListRaw(requestParameters: UserAccountApiUserProductNotificationsGetListRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsNotification>> {
        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling userProductNotificationsGetList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['user_Id'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/products/{product_code}/notifications`.replace(`{${"product_code"}}`, encodeURIComponent(String(requestParameters.productCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsNotificationFromJSON(jsonValue));
    }

    /**
     * get list of user products.
     * get user product notifications list
     */
    async userProductNotificationsGetList(requestParameters: UserAccountApiUserProductNotificationsGetListRequest): Promise<MelcoModelsGenericList1MelcoModelsNotification> {
        const response = await this.userProductNotificationsGetListRaw(requestParameters);
        return await response.value();
    }

}
