/* tslint:disable */
/* eslint-disable */
/**
 * Melco Reporting APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportingMelcoMachineStatisticsModelsGroupListItem
 */
export interface ReportingMelcoMachineStatisticsModelsGroupListItem {
    /**
     * 
     * @type {string}
     * @memberof ReportingMelcoMachineStatisticsModelsGroupListItem
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportingMelcoMachineStatisticsModelsGroupListItem
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReportingMelcoMachineStatisticsModelsGroupListItem
     */
    machine_count?: number;
    /**
     * 
     * @type {Date}
     * @memberof ReportingMelcoMachineStatisticsModelsGroupListItem
     */
    created_date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReportingMelcoMachineStatisticsModelsGroupListItem
     */
    updated_date?: Date | null;
}

export function ReportingMelcoMachineStatisticsModelsGroupListItemFromJSON(json: any): ReportingMelcoMachineStatisticsModelsGroupListItem {
    return ReportingMelcoMachineStatisticsModelsGroupListItemFromJSONTyped(json, false);
}

export function ReportingMelcoMachineStatisticsModelsGroupListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportingMelcoMachineStatisticsModelsGroupListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'machine_count': !exists(json, 'machine_count') ? undefined : json['machine_count'],
        'created_date': !exists(json, 'created_date') ? undefined : (new Date(json['created_date'])),
        'updated_date': !exists(json, 'updated_date') ? undefined : (json['updated_date'] === null ? null : new Date(json['updated_date'])),
    };
}

export function ReportingMelcoMachineStatisticsModelsGroupListItemToJSON(value?: ReportingMelcoMachineStatisticsModelsGroupListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'machine_count': value.machine_count,
        'created_date': value.created_date === undefined ? undefined : (value.created_date.toISOString()),
        'updated_date': value.updated_date === undefined ? undefined : (value.updated_date === null ? null : value.updated_date.toISOString()),
    };
}


