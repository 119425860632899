import { MelcoFusionModelsFusionDefaultAppSetting } from "melco-shared-logic";
import { useAPIServiceSettings } from "./useSettings.apiservice";
import { useFusionSettings } from "./useSettings.fusion";

export type Settings = {
  settings?: MelcoFusionModelsFusionDefaultAppSetting;
};

export const useSettings =
  process.env.REACT_APP_PRODUCT_VARIANT! === "fusion"
    ? useFusionSettings
    : useAPIServiceSettings;
