/* tslint:disable */
/* eslint-disable */
/**
 * Melco Reporting APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportingMelcoMachineStatisticsModelsMachineListItem
 */
export interface ReportingMelcoMachineStatisticsModelsMachineListItem {
    /**
     * 
     * @type {string}
     * @memberof ReportingMelcoMachineStatisticsModelsMachineListItem
     */
    serial_number?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportingMelcoMachineStatisticsModelsMachineListItem
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportingMelcoMachineStatisticsModelsMachineListItem
     */
    last_status?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ReportingMelcoMachineStatisticsModelsMachineListItem
     */
    last_sync_date?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ReportingMelcoMachineStatisticsModelsMachineListItem
     */
    registered_date?: Date | null;
}

export function ReportingMelcoMachineStatisticsModelsMachineListItemFromJSON(json: any): ReportingMelcoMachineStatisticsModelsMachineListItem {
    return ReportingMelcoMachineStatisticsModelsMachineListItemFromJSONTyped(json, false);
}

export function ReportingMelcoMachineStatisticsModelsMachineListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportingMelcoMachineStatisticsModelsMachineListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serial_number': !exists(json, 'serial_number') ? undefined : json['serial_number'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'last_status': !exists(json, 'last_status') ? undefined : json['last_status'],
        'last_sync_date': !exists(json, 'last_sync_date') ? undefined : (json['last_sync_date'] === null ? null : new Date(json['last_sync_date'])),
        'registered_date': !exists(json, 'registered_date') ? undefined : (json['registered_date'] === null ? null : new Date(json['registered_date'])),
    };
}

export function ReportingMelcoMachineStatisticsModelsMachineListItemToJSON(value?: ReportingMelcoMachineStatisticsModelsMachineListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serial_number': value.serial_number,
        'name': value.name,
        'last_status': value.last_status,
        'last_sync_date': value.last_sync_date === undefined ? undefined : (value.last_sync_date === null ? null : value.last_sync_date.toISOString()),
        'registered_date': value.registered_date === undefined ? undefined : (value.registered_date === null ? null : value.registered_date.toISOString()),
    };
}


