/* tslint:disable */
/* eslint-disable */
/**
 * Melco Reporting APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReportingMelcoMachineStatisticsModelsViewGroups,
    ReportingMelcoMachineStatisticsModelsViewGroupsFromJSON,
    ReportingMelcoMachineStatisticsModelsViewGroupsFromJSONTyped,
    ReportingMelcoMachineStatisticsModelsViewGroupsToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReportingMelcoMachineStatisticsModelsView
 */
export interface ReportingMelcoMachineStatisticsModelsView {
    /**
     * 
     * @type {string}
     * @memberof ReportingMelcoMachineStatisticsModelsView
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportingMelcoMachineStatisticsModelsView
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReportingMelcoMachineStatisticsModelsView
     */
    grouped_display?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ReportingMelcoMachineStatisticsModelsView
     */
    group_count?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportingMelcoMachineStatisticsModelsView
     */
    socket_endpoint?: string | null;
    /**
     * 
     * @type {Array<ReportingMelcoMachineStatisticsModelsViewGroups>}
     * @memberof ReportingMelcoMachineStatisticsModelsView
     */
    group_list?: Array<ReportingMelcoMachineStatisticsModelsViewGroups> | null;
}

export function ReportingMelcoMachineStatisticsModelsViewFromJSON(json: any): ReportingMelcoMachineStatisticsModelsView {
    return ReportingMelcoMachineStatisticsModelsViewFromJSONTyped(json, false);
}

export function ReportingMelcoMachineStatisticsModelsViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportingMelcoMachineStatisticsModelsView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'grouped_display': !exists(json, 'grouped_display') ? undefined : json['grouped_display'],
        'group_count': !exists(json, 'group_count') ? undefined : json['group_count'],
        'socket_endpoint': !exists(json, 'socket_endpoint') ? undefined : json['socket_endpoint'],
        'group_list': !exists(json, 'group_list') ? undefined : (json['group_list'] === null ? null : (json['group_list'] as Array<any>).map(ReportingMelcoMachineStatisticsModelsViewGroupsFromJSON)),
    };
}

export function ReportingMelcoMachineStatisticsModelsViewToJSON(value?: ReportingMelcoMachineStatisticsModelsView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'grouped_display': value.grouped_display,
        'group_count': value.group_count,
        'socket_endpoint': value.socket_endpoint,
        'group_list': value.group_list === undefined ? undefined : (value.group_list === null ? null : (value.group_list as Array<any>).map(ReportingMelcoMachineStatisticsModelsViewGroupsToJSON)),
    };
}


