/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoModelsReferences,
    MelcoModelsReferencesFromJSON,
    MelcoModelsReferencesFromJSONTyped,
    MelcoModelsReferencesToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoModelsImageReferences
 */
export interface MelcoModelsImageReferences {
    /**
     * 
     * @type {Array<MelcoModelsReferences>}
     * @memberof MelcoModelsImageReferences
     */
    blank_list?: Array<MelcoModelsReferences> | null;
    /**
     * 
     * @type {Array<MelcoModelsReferences>}
     * @memberof MelcoModelsImageReferences
     */
    custom_product_list?: Array<MelcoModelsReferences> | null;
}

export function MelcoModelsImageReferencesFromJSON(json: any): MelcoModelsImageReferences {
    return MelcoModelsImageReferencesFromJSONTyped(json, false);
}

export function MelcoModelsImageReferencesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsImageReferences {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'blank_list': !exists(json, 'blank_list') ? undefined : (json['blank_list'] === null ? null : (json['blank_list'] as Array<any>).map(MelcoModelsReferencesFromJSON)),
        'custom_product_list': !exists(json, 'custom_product_list') ? undefined : (json['custom_product_list'] === null ? null : (json['custom_product_list'] as Array<any>).map(MelcoModelsReferencesFromJSON)),
    };
}

export function MelcoModelsImageReferencesToJSON(value?: MelcoModelsImageReferences | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'blank_list': value.blank_list === undefined ? undefined : (value.blank_list === null ? null : (value.blank_list as Array<any>).map(MelcoModelsReferencesToJSON)),
        'custom_product_list': value.custom_product_list === undefined ? undefined : (value.custom_product_list === null ? null : (value.custom_product_list as Array<any>).map(MelcoModelsReferencesToJSON)),
    };
}


