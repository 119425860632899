import { isEmpty } from "lodash-es";
import { Button, PlusIcon } from "melco-ui";
import { useTranslation } from "react-i18next";
import { Key } from "antd/lib/table/interface";
import { useAddBlankPictures } from "../../../hooks/libraries/blanks/useAddBlankPictures";
import { FusionMelcoModelsUserImage } from "melco-shared-logic";

type BulkActionAddBlankPicturesProps = {
  allBlankPictures: FusionMelcoModelsUserImage[];
  selectedRowKeys: Key[];
  setSelectedRowKeys: (keys: Key[]) => void;
};

export const BulkActionAddBlankPictures: React.FC<
  BulkActionAddBlankPicturesProps
> = ({ allBlankPictures, selectedRowKeys, setSelectedRowKeys }) => {
  const { t } = useTranslation();

  const { addBlankPictures } = useAddBlankPictures();

  if (isEmpty(selectedRowKeys)) {
    return null;
  }

  return (
    <Button
      key="bulk-add-selected"
      icon={<PlusIcon />}
      onClick={() => {
        const blankPicturesToAdd = allBlankPictures.filter((bp) =>
          selectedRowKeys.includes(bp.id!)
        );

        const successfullyAddedPictures = addBlankPictures(blankPicturesToAdd);

        // keep items that were not added selected
        setSelectedRowKeys(
          selectedRowKeys.filter(
            (id) =>
              !successfullyAddedPictures
                .map(({ id }) => id)
                .includes(id.toString())
          )
        );
      }}
    >
      {t("blanks.actions.add_blank_pictures.actions.add_selected")}
    </Button>
  );
};
