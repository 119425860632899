/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MelcoModelsError,
    MelcoModelsErrorFromJSON,
    MelcoModelsErrorToJSON,
    MelcoModelsGenericList1MelcoModelsCountry,
    MelcoModelsGenericList1MelcoModelsCountryFromJSON,
    MelcoModelsGenericList1MelcoModelsCountryToJSON,
    MelcoModelsGenericList1MelcoModelsCountryState,
    MelcoModelsGenericList1MelcoModelsCountryStateFromJSON,
    MelcoModelsGenericList1MelcoModelsCountryStateToJSON,
    MelcoModelsGenericList1MelcoModelsCulture,
    MelcoModelsGenericList1MelcoModelsCultureFromJSON,
    MelcoModelsGenericList1MelcoModelsCultureToJSON,
    MelcoModelsGenericList1MelcoModelsCurrency,
    MelcoModelsGenericList1MelcoModelsCurrencyFromJSON,
    MelcoModelsGenericList1MelcoModelsCurrencyToJSON,
    MelcoModelsGenericList1MelcoModelsGender,
    MelcoModelsGenericList1MelcoModelsGenderFromJSON,
    MelcoModelsGenericList1MelcoModelsGenderToJSON,
    MelcoModelsGenericList1MelcoModelsKeyValue,
    MelcoModelsGenericList1MelcoModelsKeyValueFromJSON,
    MelcoModelsGenericList1MelcoModelsKeyValueToJSON,
    MelcoModelsGenericList1MelcoModelsLocaleGroup,
    MelcoModelsGenericList1MelcoModelsLocaleGroupFromJSON,
    MelcoModelsGenericList1MelcoModelsLocaleGroupToJSON,
    MelcoModelsGenericList1MelcoModelsReferralListItem,
    MelcoModelsGenericList1MelcoModelsReferralListItemFromJSON,
    MelcoModelsGenericList1MelcoModelsReferralListItemToJSON,
} from '../models';

export interface SystemDefaultsApiDefaultsGetDocumentRequest {
    id: string;
}

export interface SystemDefaultsApiDefaultsGetLocaleStringListRequest {
    filter?: string;
}

export interface SystemDefaultsApiDefaultsGetStateListRequest {
    countryList?: string;
}

export interface SystemDefaultsApiDefaultsSearchCulturesRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    searchText?: string;
}

/**
 * 
 */
export class SystemDefaultsApi extends runtime.BaseAPI {

    /**
     * get list supported countries
     * get list of countries.
     */
    async defaultsGetCountryListRaw(): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsCountry>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/defaults/countries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsCountryFromJSON(jsonValue));
    }

    /**
     * get list supported countries
     * get list of countries.
     */
    async defaultsGetCountryList(): Promise<MelcoModelsGenericList1MelcoModelsCountry> {
        const response = await this.defaultsGetCountryListRaw();
        return await response.value();
    }

    /**
     * get list of supported currencies
     * get list of currencies.
     */
    async defaultsGetCurrencyListRaw(): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsCurrency>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/defaults/currencies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsCurrencyFromJSON(jsonValue));
    }

    /**
     * get list of supported currencies
     * get list of currencies.
     */
    async defaultsGetCurrencyList(): Promise<MelcoModelsGenericList1MelcoModelsCurrency> {
        const response = await this.defaultsGetCurrencyListRaw();
        return await response.value();
    }

    /**
     * get document (supported types: terms)
     * get document
     */
    async defaultsGetDocumentRaw(requestParameters: SystemDefaultsApiDefaultsGetDocumentRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsKeyValue>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling defaultsGetDocument.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/defaults/documents/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsKeyValueFromJSON(jsonValue));
    }

    /**
     * get document (supported types: terms)
     * get document
     */
    async defaultsGetDocument(requestParameters: SystemDefaultsApiDefaultsGetDocumentRequest): Promise<MelcoModelsGenericList1MelcoModelsKeyValue> {
        const response = await this.defaultsGetDocumentRaw(requestParameters);
        return await response.value();
    }

    /**
     * get list of supported gender list
     * get list of genders.
     */
    async defaultsGetGenderListRaw(): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsGender>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/defaults/genders`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsGenderFromJSON(jsonValue));
    }

    /**
     * get list of supported gender list
     * get list of genders.
     */
    async defaultsGetGenderList(): Promise<MelcoModelsGenericList1MelcoModelsGender> {
        const response = await this.defaultsGetGenderListRaw();
        return await response.value();
    }

    /**
     * get list of locale groups and strings
     * get list of localization strings.
     */
    async defaultsGetLocaleStringListRaw(requestParameters: SystemDefaultsApiDefaultsGetLocaleStringListRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsLocaleGroup>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/defaults/localeStrings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsLocaleGroupFromJSON(jsonValue));
    }

    /**
     * get list of locale groups and strings
     * get list of localization strings.
     */
    async defaultsGetLocaleStringList(requestParameters: SystemDefaultsApiDefaultsGetLocaleStringListRequest): Promise<MelcoModelsGenericList1MelcoModelsLocaleGroup> {
        const response = await this.defaultsGetLocaleStringListRaw(requestParameters);
        return await response.value();
    }

    /**
     * get referrals list
     * get referrals list
     */
    async defaultsGetReferralListRaw(): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsReferralListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/defaults/referrals`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsReferralListItemFromJSON(jsonValue));
    }

    /**
     * get referrals list
     * get referrals list
     */
    async defaultsGetReferralList(): Promise<MelcoModelsGenericList1MelcoModelsReferralListItem> {
        const response = await this.defaultsGetReferralListRaw();
        return await response.value();
    }

    /**
     * get list supported states
     * get list of states.
     */
    async defaultsGetStateListRaw(requestParameters: SystemDefaultsApiDefaultsGetStateListRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsCountryState>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.countryList !== undefined) {
            queryParameters['CountryList'] = requestParameters.countryList;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/defaults/countries/states`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsCountryStateFromJSON(jsonValue));
    }

    /**
     * get list supported states
     * get list of states.
     */
    async defaultsGetStateList(requestParameters: SystemDefaultsApiDefaultsGetStateListRequest): Promise<MelcoModelsGenericList1MelcoModelsCountryState> {
        const response = await this.defaultsGetStateListRaw(requestParameters);
        return await response.value();
    }

    /**
     * list of cultures
     * search culture list
     */
    async defaultsSearchCulturesRaw(requestParameters: SystemDefaultsApiDefaultsSearchCulturesRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsCulture>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/defaults/cultures`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsCultureFromJSON(jsonValue));
    }

    /**
     * list of cultures
     * search culture list
     */
    async defaultsSearchCultures(requestParameters: SystemDefaultsApiDefaultsSearchCulturesRequest): Promise<MelcoModelsGenericList1MelcoModelsCulture> {
        const response = await this.defaultsSearchCulturesRaw(requestParameters);
        return await response.value();
    }

}
