/* tslint:disable */
/* eslint-disable */
/**
 * Melco Reporting APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReportingMelcoMachineStatisticsModelsGroupListItem,
    ReportingMelcoMachineStatisticsModelsGroupListItemFromJSON,
    ReportingMelcoMachineStatisticsModelsGroupListItemFromJSONTyped,
    ReportingMelcoMachineStatisticsModelsGroupListItemToJSON,
    ReportingMelcoModelsCustomWarning,
    ReportingMelcoModelsCustomWarningFromJSON,
    ReportingMelcoModelsCustomWarningFromJSONTyped,
    ReportingMelcoModelsCustomWarningToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsGroupListItem
 */
export interface ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsGroupListItem {
    /**
     * 
     * @type {Array<ReportingMelcoMachineStatisticsModelsGroupListItem>}
     * @memberof ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsGroupListItem
     */
    list: Array<ReportingMelcoMachineStatisticsModelsGroupListItem>;
    /**
     * 
     * @type {Array<ReportingMelcoModelsCustomWarning>}
     * @memberof ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsGroupListItem
     */
    warning_list?: Array<ReportingMelcoModelsCustomWarning> | null;
    /**
     * 
     * @type {number}
     * @memberof ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsGroupListItem
     */
    total: number;
}

export function ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsGroupListItemFromJSON(json: any): ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsGroupListItem {
    return ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsGroupListItemFromJSONTyped(json, false);
}

export function ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsGroupListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsGroupListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'list': ((json['list'] as Array<any>).map(ReportingMelcoMachineStatisticsModelsGroupListItemFromJSON)),
        'warning_list': !exists(json, 'warning_list') ? undefined : (json['warning_list'] === null ? null : (json['warning_list'] as Array<any>).map(ReportingMelcoModelsCustomWarningFromJSON)),
        'total': json['total'],
    };
}

export function ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsGroupListItemToJSON(value?: ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsGroupListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'list': ((value.list as Array<any>).map(ReportingMelcoMachineStatisticsModelsGroupListItemToJSON)),
        'warning_list': value.warning_list === undefined ? undefined : (value.warning_list === null ? null : (value.warning_list as Array<any>).map(ReportingMelcoModelsCustomWarningToJSON)),
        'total': value.total,
    };
}


