import dayjs from "dayjs";
import { sumBy } from "lodash";
import {
  MelcoModelsDesignDownloadDailySummary,
  MelcoModelsSessionDailySummary,
} from "melco-shared-logic";

type TotalDesignsAnalytics = {
  uniqueFiles: number;
  totalFiles: number;
};

export const calculateTotalDesignsAnalytics = (
  list: MelcoModelsDesignDownloadDailySummary[]
): TotalDesignsAnalytics => {
  return {
    uniqueFiles: sumBy(list, "unique_files"),
    totalFiles: sumBy(list, "total_files"),
  };
};

type TotalDesignAnalyticsMonth = {
  month: number;
  year: number;
  total: TotalDesignsAnalytics;
};

export const calculateTotalDesignsAnalyticsPerMonth = (
  list: MelcoModelsDesignDownloadDailySummary[]
): TotalDesignAnalyticsMonth[] => {
  const result: TotalDesignAnalyticsMonth[] = [];

  list.forEach((value) => {
    if (value.date) {
      const month = dayjs(value.date).month();
      const year = dayjs(value.date).year();
      const resultIndex = result.findIndex(
        (value) => value.month === month && value.year === year
      );

      if (resultIndex >= 0) {
        result[resultIndex].total.totalFiles += value.total_files || 0;
        result[resultIndex].total.uniqueFiles += value.unique_files || 0;
      } else {
        result.push({
          month,
          year,
          total: {
            totalFiles: value.total_files || 0,
            uniqueFiles: value.unique_files || 0,
          },
        });
      }
    }
  });

  return result;
};

type TotalSessionssAnalytics = {
  dropped: number;
  completed: number;
};

export const calculateTotalSessionssAnalytics = (
  list: MelcoModelsSessionDailySummary[]
): TotalSessionssAnalytics => {
  return {
    dropped: sumBy(list, "dropped"),
    completed: sumBy(list, "completed"),
  };
};

type TotalSessionsAnalyticsMonth = {
  month: number;
  year: number;
  total: TotalSessionssAnalytics;
};

export const calculateTotalSessionsAnalyticsPerMonth = (
  list: MelcoModelsSessionDailySummary[]
): TotalSessionsAnalyticsMonth[] => {
  const result: TotalSessionsAnalyticsMonth[] = [];

  list.forEach((value) => {
    if (value.date) {
      const month = dayjs(value.date).month();
      const year = dayjs(value.date).year();
      const resultIndex = result.findIndex(
        (value) => value.month === month && value.year === year
      );

      if (resultIndex >= 0) {
        result[resultIndex].total.dropped += value.dropped || 0;
        result[resultIndex].total.completed += value.completed || 0;
      } else {
        result.push({
          month,
          year,
          total: {
            dropped: value.dropped || 0,
            completed: value.completed || 0,
          },
        });
      }
    }
  });

  return result;
};

export const isDateWithinLast365Days = (current: Date): boolean => {
  const nowStartOfDay = dayjs().startOf("day");
  const currentStartOfDay = dayjs(current).startOf("day");

  const difference = nowStartOfDay.diff(currentStartOfDay, "day");

  const tooEarly = difference > 365;
  const tooLate = difference < 0; //Means in future

  if (tooLate || tooEarly) {
    return false;
  }

  return true;
};
