import { Formik, FormikHelpers } from "formik";
import {
  FusionMelcoFusionModelsCustomProduct,
  FusionMelcoFusionModelsUserBlank,
  useFormSubmit,
  FusionApi,
  FusionApiFusionCustomProductsUpdateDraftRequest,
  FusionMelcoFusionModelsCustomProductView,
  useAuthenticatedAPIConfig,
  UseApiQueryKey,
} from "melco-shared-logic";
import { QueryObserverResult, useQueryClient } from "react-query";
import { SubmitAction } from "../../header/SavePublishHeader";
import { DisplayProductDetailForm } from "./DisplayProductDetailForm";
import { DirtyFormPrompt } from "../../form/DirtyFormPrompt";
import { queryKeyForProduct } from "../../../hooks/customization/products/useProduct";
import { ALL_PUBLISHED_STATES } from "../../../helper/publishedState";

type ProductDetailFormProps = {
  product: FusionMelcoFusionModelsCustomProduct;
  refetch: () => Promise<
    QueryObserverResult<FusionMelcoFusionModelsCustomProduct, unknown>
  >;
  queryKey: UseApiQueryKey;
};

type ProductDetailFormValueType = {
  id: string;
  name: string;
  token: string;
  blank?: FusionMelcoFusionModelsUserBlank;
  view_list?: FusionMelcoFusionModelsCustomProductView[];
  configurator_version?: number;
  submitAction: SubmitAction;
};

export const productToInitialValues = (
  product: FusionMelcoFusionModelsCustomProduct
) => {
  const { id, name, token, blank, view_list, configurator_version } = product;
  const initialValues: ProductDetailFormValueType = {
    id: id!,
    name: name ?? "",
    token: token ?? "",
    blank: blank,
    view_list: view_list ?? undefined,
    configurator_version: configurator_version ?? undefined,
    submitAction: "save",
  };

  return initialValues;
};

export const ProductDetailForm: React.FC<ProductDetailFormProps> = ({
  product,
  refetch,
  queryKey,
}) => {
  const queryClient = useQueryClient();
  const authenticatedApiConfig = useAuthenticatedAPIConfig();
  const { id } = product;

  const onSubmit = async (
    values: ProductDetailFormValueType,
    formikHelpers: FormikHelpers<ProductDetailFormValueType>
  ) => {
    const { submitAction } = values;

    if (submitAction === "publish") {
      await onPublish(values, formikHelpers, { isManuallyResettingForm: true });
    } else {
      await onSave(values, formikHelpers, { isManuallyResettingForm: true });
    }
  };

  const handleSaveAndPublish = async (
    values: ProductDetailFormValueType,
    formikHelpers: FormikHelpers<ProductDetailFormValueType>,
    options: {
      publish: boolean;
    }
  ) => {
    const { publish } = options;
    const { name, token, blank, view_list, configurator_version } = values;

    const api = new FusionApi(authenticatedApiConfig());

    const blankIdOrEmptyObject = blank ? { id: blank.id } : {};

    const entityAttributes: FusionApiFusionCustomProductsUpdateDraftRequest = {
      id: id!,
      fusionMelcoFusionModelsUpdateCustomProduct: {
        name,
        token,
        blank: blankIdOrEmptyObject,
        view_list,
        configurator_version,
      },
    };

    let newEntity = await api.fusionCustomProductsUpdateDraft(entityAttributes);

    if (publish) {
      newEntity = await api.fusionCustomProductsPublish({
        id: id!,
      });
    }

    // update ui with new data
    formikHelpers.resetForm({
      values: productToInitialValues(newEntity),
    });
    queryClient.setQueryData(queryKey, newEntity);

    return {
      successMessages: [
        {
          message: "default",
        },
      ],
    };
  };

  const formSubmitWithPublish = (publish: boolean) => {
    return [
      async (
        values: ProductDetailFormValueType,
        formikHelpers: FormikHelpers<ProductDetailFormValueType>
      ) => {
        return handleSaveAndPublish(values, formikHelpers, {
          publish,
        });
      },
      {
        translationPrefix: [
          "customization.products.detail",
          publish ? "publish" : "save",
        ].join("."),
        onError: () => {
          // refetch entity from server
          queryClient.invalidateQueries(
            queryKeyForProduct(product.id!, ALL_PUBLISHED_STATES)
          );
        },
      },
    ] as const;
  };

  const onSave = useFormSubmit(...formSubmitWithPublish(false));
  const onPublish = useFormSubmit(...formSubmitWithPublish(true));

  const initialValues = productToInitialValues(product);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      <DirtyFormPrompt>
        <DisplayProductDetailForm product={product} refetch={refetch} />
      </DirtyFormPrompt>
    </Formik>
  );
};
