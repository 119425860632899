/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoModelsSerialNumberHistory
 */
export interface MelcoModelsSerialNumberHistory {
    /**
     * 
     * @type {number}
     * @memberof MelcoModelsSerialNumberHistory
     */
    history_type?: number;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsSerialNumberHistory
     */
    user_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsSerialNumberHistory
     */
    computer_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsSerialNumberHistory
     */
    operating_system?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsSerialNumberHistory
     */
    version_number?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MelcoModelsSerialNumberHistory
     */
    created_date?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsSerialNumberHistory
     */
    comments?: string | null;
}

export function MelcoModelsSerialNumberHistoryFromJSON(json: any): MelcoModelsSerialNumberHistory {
    return MelcoModelsSerialNumberHistoryFromJSONTyped(json, false);
}

export function MelcoModelsSerialNumberHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsSerialNumberHistory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'history_type': !exists(json, 'history_type') ? undefined : json['history_type'],
        'user_name': !exists(json, 'user_name') ? undefined : json['user_name'],
        'computer_name': !exists(json, 'computer_name') ? undefined : json['computer_name'],
        'operating_system': !exists(json, 'operating_system') ? undefined : json['operating_system'],
        'version_number': !exists(json, 'version_number') ? undefined : json['version_number'],
        'created_date': !exists(json, 'created_date') ? undefined : (json['created_date'] === null ? null : new Date(json['created_date'])),
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
    };
}

export function MelcoModelsSerialNumberHistoryToJSON(value?: MelcoModelsSerialNumberHistory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'history_type': value.history_type,
        'user_name': value.user_name,
        'computer_name': value.computer_name,
        'operating_system': value.operating_system,
        'version_number': value.version_number,
        'created_date': value.created_date === undefined ? undefined : (value.created_date === null ? null : value.created_date.toISOString()),
        'comments': value.comments,
    };
}


