import { useCallback } from "react";
import {
  MelcoModelsDesignDownloadDailySummary,
  ProductAnalyticsApi,
  useProductsAnalyticsAPI,
} from "melco-shared-logic";
import sortBy from "lodash-es/sortBy";

type DesignsData = {
  dailySummary: MelcoModelsDesignDownloadDailySummary[];
};

const initialValue = { dailySummary: [] };

export const useDesignsAnalytics = (
  startTimestamp?: Date,
  endTimestamp?: Date
) => {
  const handleRequest = useCallback(
    async (api: ProductAnalyticsApi) => {
      const response = await api.productAnalyticsDailyDesignSummary({
        productCode: process.env.REACT_APP_PRODUCT_CODE!,
        startTimestamp,
        endTimestamp,
      });

      const unsortedDailySummary = response.list;

      const dailySummary = sortBy(unsortedDailySummary, "date");

      return {
        dailySummary,
      };
    },
    [startTimestamp, endTimestamp]
  );

  return useProductsAnalyticsAPI<DesignsData>({
    initialValue,
    handleRequest,
  });
};
