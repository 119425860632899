/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoModelsReferences,
    MelcoModelsReferencesFromJSON,
    MelcoModelsReferencesFromJSONTyped,
    MelcoModelsReferencesToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoModelsColorCollectionReferences
 */
export interface MelcoModelsColorCollectionReferences {
    /**
     * 
     * @type {Array<MelcoModelsReferences>}
     * @memberof MelcoModelsColorCollectionReferences
     */
    design_list?: Array<MelcoModelsReferences> | null;
    /**
     * 
     * @type {Array<MelcoModelsReferences>}
     * @memberof MelcoModelsColorCollectionReferences
     */
    custom_product_list?: Array<MelcoModelsReferences> | null;
    /**
     * 
     * @type {Array<MelcoModelsReferences>}
     * @memberof MelcoModelsColorCollectionReferences
     */
    mapping_list?: Array<MelcoModelsReferences> | null;
    /**
     * 
     * @type {Array<MelcoModelsReferences>}
     * @memberof MelcoModelsColorCollectionReferences
     */
    global_mapping_list?: Array<MelcoModelsReferences> | null;
    /**
     * 
     * @type {Array<MelcoModelsReferences>}
     * @memberof MelcoModelsColorCollectionReferences
     */
    format_list?: Array<MelcoModelsReferences> | null;
}

export function MelcoModelsColorCollectionReferencesFromJSON(json: any): MelcoModelsColorCollectionReferences {
    return MelcoModelsColorCollectionReferencesFromJSONTyped(json, false);
}

export function MelcoModelsColorCollectionReferencesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsColorCollectionReferences {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'design_list': !exists(json, 'design_list') ? undefined : (json['design_list'] === null ? null : (json['design_list'] as Array<any>).map(MelcoModelsReferencesFromJSON)),
        'custom_product_list': !exists(json, 'custom_product_list') ? undefined : (json['custom_product_list'] === null ? null : (json['custom_product_list'] as Array<any>).map(MelcoModelsReferencesFromJSON)),
        'mapping_list': !exists(json, 'mapping_list') ? undefined : (json['mapping_list'] === null ? null : (json['mapping_list'] as Array<any>).map(MelcoModelsReferencesFromJSON)),
        'global_mapping_list': !exists(json, 'global_mapping_list') ? undefined : (json['global_mapping_list'] === null ? null : (json['global_mapping_list'] as Array<any>).map(MelcoModelsReferencesFromJSON)),
        'format_list': !exists(json, 'format_list') ? undefined : (json['format_list'] === null ? null : (json['format_list'] as Array<any>).map(MelcoModelsReferencesFromJSON)),
    };
}

export function MelcoModelsColorCollectionReferencesToJSON(value?: MelcoModelsColorCollectionReferences | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'design_list': value.design_list === undefined ? undefined : (value.design_list === null ? null : (value.design_list as Array<any>).map(MelcoModelsReferencesToJSON)),
        'custom_product_list': value.custom_product_list === undefined ? undefined : (value.custom_product_list === null ? null : (value.custom_product_list as Array<any>).map(MelcoModelsReferencesToJSON)),
        'mapping_list': value.mapping_list === undefined ? undefined : (value.mapping_list === null ? null : (value.mapping_list as Array<any>).map(MelcoModelsReferencesToJSON)),
        'global_mapping_list': value.global_mapping_list === undefined ? undefined : (value.global_mapping_list === null ? null : (value.global_mapping_list as Array<any>).map(MelcoModelsReferencesToJSON)),
        'format_list': value.format_list === undefined ? undefined : (value.format_list === null ? null : (value.format_list as Array<any>).map(MelcoModelsReferencesToJSON)),
    };
}


