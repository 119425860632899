/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoModelsLogEntry
 */
export interface MelcoModelsLogEntry {
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsLogEntry
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsLogEntry
     */
    operation_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsLogEntry
     */
    message?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MelcoModelsLogEntry
     */
    time_generated?: Date;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsLogEntry
     */
    app_role_instance?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsLogEntry
     */
    client_type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsLogEntry
     */
    client_ip?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsLogEntry
     */
    client_city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsLogEntry
     */
    client_state_or_province?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsLogEntry
     */
    client_country_or_region?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsLogEntry
     */
    properties?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsLogEntry
     */
    severity_level?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsLogEntry
     */
    details?: string | null;
}

export function MelcoModelsLogEntryFromJSON(json: any): MelcoModelsLogEntry {
    return MelcoModelsLogEntryFromJSONTyped(json, false);
}

export function MelcoModelsLogEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsLogEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'operation_id': !exists(json, 'operation_id') ? undefined : json['operation_id'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'time_generated': !exists(json, 'time_generated') ? undefined : (new Date(json['time_generated'])),
        'app_role_instance': !exists(json, 'app_role_instance') ? undefined : json['app_role_instance'],
        'client_type': !exists(json, 'client_type') ? undefined : json['client_type'],
        'client_ip': !exists(json, 'client_ip') ? undefined : json['client_ip'],
        'client_city': !exists(json, 'client_city') ? undefined : json['client_city'],
        'client_state_or_province': !exists(json, 'client_state_or_province') ? undefined : json['client_state_or_province'],
        'client_country_or_region': !exists(json, 'client_country_or_region') ? undefined : json['client_country_or_region'],
        'properties': !exists(json, 'properties') ? undefined : json['properties'],
        'severity_level': !exists(json, 'severity_level') ? undefined : json['severity_level'],
        'details': !exists(json, 'details') ? undefined : json['details'],
    };
}

export function MelcoModelsLogEntryToJSON(value?: MelcoModelsLogEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'operation_id': value.operation_id,
        'message': value.message,
        'time_generated': value.time_generated === undefined ? undefined : (value.time_generated.toISOString()),
        'app_role_instance': value.app_role_instance,
        'client_type': value.client_type,
        'client_ip': value.client_ip,
        'client_city': value.client_city,
        'client_state_or_province': value.client_state_or_province,
        'client_country_or_region': value.client_country_or_region,
        'properties': value.properties,
        'severity_level': value.severity_level,
        'details': value.details,
    };
}


