/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoModelsTransferSerialNumber
 */
export interface MelcoModelsTransferSerialNumber {
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsTransferSerialNumber
     */
    existing_user_reference_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsTransferSerialNumber
     */
    new_user_reference_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsTransferSerialNumber
     */
    serial_number?: string | null;
}

export function MelcoModelsTransferSerialNumberFromJSON(json: any): MelcoModelsTransferSerialNumber {
    return MelcoModelsTransferSerialNumberFromJSONTyped(json, false);
}

export function MelcoModelsTransferSerialNumberFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsTransferSerialNumber {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'existing_user_reference_id': !exists(json, 'existing_user_reference_id') ? undefined : json['existing_user_reference_id'],
        'new_user_reference_id': !exists(json, 'new_user_reference_id') ? undefined : json['new_user_reference_id'],
        'serial_number': !exists(json, 'serial_number') ? undefined : json['serial_number'],
    };
}

export function MelcoModelsTransferSerialNumberToJSON(value?: MelcoModelsTransferSerialNumber | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'existing_user_reference_id': value.existing_user_reference_id,
        'new_user_reference_id': value.new_user_reference_id,
        'serial_number': value.serial_number,
    };
}


