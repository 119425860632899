/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoFusionModelsFusionConfiguratorSetting
 */
export interface MelcoFusionModelsFusionConfiguratorSetting {
    /**
     * 
     * @type {number}
     * @memberof MelcoFusionModelsFusionConfiguratorSetting
     */
    version?: number;
}

export function MelcoFusionModelsFusionConfiguratorSettingFromJSON(json: any): MelcoFusionModelsFusionConfiguratorSetting {
    return MelcoFusionModelsFusionConfiguratorSettingFromJSONTyped(json, false);
}

export function MelcoFusionModelsFusionConfiguratorSettingFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoFusionModelsFusionConfiguratorSetting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'version': !exists(json, 'version') ? undefined : json['version'],
    };
}

export function MelcoFusionModelsFusionConfiguratorSettingToJSON(value?: MelcoFusionModelsFusionConfiguratorSetting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'version': value.version,
    };
}


