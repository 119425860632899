/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoModelsUserAddress,
    MelcoModelsUserAddressFromJSON,
    MelcoModelsUserAddressFromJSONTyped,
    MelcoModelsUserAddressToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoModelsUser
 */
export interface MelcoModelsUser {
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUser
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUser
     */
    user_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUser
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUser
     */
    first_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUser
     */
    last_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUser
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUser
     */
    company_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUser
     */
    website?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUser
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUser
     */
    gender_code?: string | null;
    /**
     * 
     * @type {MelcoModelsUserAddress}
     * @memberof MelcoModelsUser
     */
    address?: MelcoModelsUserAddress;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUser
     */
    api_key?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoModelsUser
     */
    service_terms?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUser
     */
    modified_by?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUser
     */
    account_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUser
     */
    parent_user_id?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoModelsUser
     */
    is_locked_out?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoModelsUser
     */
    is_disabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUser
     */
    melco_customer_id?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MelcoModelsUser
     */
    created_date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MelcoModelsUser
     */
    modified_date?: Date;
}

export function MelcoModelsUserFromJSON(json: any): MelcoModelsUser {
    return MelcoModelsUserFromJSONTyped(json, false);
}

export function MelcoModelsUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'user_name': !exists(json, 'user_name') ? undefined : json['user_name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'first_name': !exists(json, 'first_name') ? undefined : json['first_name'],
        'last_name': !exists(json, 'last_name') ? undefined : json['last_name'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'company_name': !exists(json, 'company_name') ? undefined : json['company_name'],
        'website': !exists(json, 'website') ? undefined : json['website'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'gender_code': !exists(json, 'gender_code') ? undefined : json['gender_code'],
        'address': !exists(json, 'address') ? undefined : MelcoModelsUserAddressFromJSON(json['address']),
        'api_key': !exists(json, 'api_key') ? undefined : json['api_key'],
        'service_terms': !exists(json, 'service_terms') ? undefined : json['service_terms'],
        'modified_by': !exists(json, 'modified_by') ? undefined : json['modified_by'],
        'account_name': !exists(json, 'account_name') ? undefined : json['account_name'],
        'parent_user_id': !exists(json, 'parent_user_id') ? undefined : json['parent_user_id'],
        'is_locked_out': !exists(json, 'is_locked_out') ? undefined : json['is_locked_out'],
        'is_disabled': !exists(json, 'is_disabled') ? undefined : json['is_disabled'],
        'melco_customer_id': !exists(json, 'melco_customer_id') ? undefined : json['melco_customer_id'],
        'created_date': !exists(json, 'created_date') ? undefined : (new Date(json['created_date'])),
        'modified_date': !exists(json, 'modified_date') ? undefined : (new Date(json['modified_date'])),
    };
}

export function MelcoModelsUserToJSON(value?: MelcoModelsUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'user_name': value.user_name,
        'email': value.email,
        'first_name': value.first_name,
        'last_name': value.last_name,
        'password': value.password,
        'company_name': value.company_name,
        'website': value.website,
        'phone': value.phone,
        'gender_code': value.gender_code,
        'address': MelcoModelsUserAddressToJSON(value.address),
        'api_key': value.api_key,
        'service_terms': value.service_terms,
        'modified_by': value.modified_by,
        'account_name': value.account_name,
        'parent_user_id': value.parent_user_id,
        'is_locked_out': value.is_locked_out,
        'is_disabled': value.is_disabled,
        'melco_customer_id': value.melco_customer_id,
        'created_date': value.created_date === undefined ? undefined : (value.created_date.toISOString()),
        'modified_date': value.modified_date === undefined ? undefined : (value.modified_date.toISOString()),
    };
}


