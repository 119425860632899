/* tslint:disable */
/* eslint-disable */
/**
 * Melco Efs APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EfsMelcoEfsModelsMappingFileAttribute
 */
export interface EfsMelcoEfsModelsMappingFileAttribute {
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoEfsModelsMappingFileAttribute
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoEfsModelsMappingFileAttribute
     */
    value?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoEfsModelsMappingFileAttribute
     */
    format_detail_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoEfsModelsMappingFileAttribute
     */
    exception_id?: string | null;
}

export function EfsMelcoEfsModelsMappingFileAttributeFromJSON(json: any): EfsMelcoEfsModelsMappingFileAttribute {
    return EfsMelcoEfsModelsMappingFileAttributeFromJSONTyped(json, false);
}

export function EfsMelcoEfsModelsMappingFileAttributeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EfsMelcoEfsModelsMappingFileAttribute {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'format_detail_id': !exists(json, 'format_detail_id') ? undefined : json['format_detail_id'],
        'exception_id': !exists(json, 'exception_id') ? undefined : json['exception_id'],
    };
}

export function EfsMelcoEfsModelsMappingFileAttributeToJSON(value?: EfsMelcoEfsModelsMappingFileAttribute | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'value': value.value,
        'format_detail_id': value.format_detail_id,
        'exception_id': value.exception_id,
    };
}


