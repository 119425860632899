/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoModelsCustomWarning,
    MelcoModelsCustomWarningFromJSON,
    MelcoModelsCustomWarningFromJSONTyped,
    MelcoModelsCustomWarningToJSON,
    MelcoModelsRole,
    MelcoModelsRoleFromJSON,
    MelcoModelsRoleFromJSONTyped,
    MelcoModelsRoleToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoModelsGenericList1MelcoModelsRole
 */
export interface MelcoModelsGenericList1MelcoModelsRole {
    /**
     * 
     * @type {Array<MelcoModelsRole>}
     * @memberof MelcoModelsGenericList1MelcoModelsRole
     */
    list: Array<MelcoModelsRole>;
    /**
     * 
     * @type {Array<MelcoModelsCustomWarning>}
     * @memberof MelcoModelsGenericList1MelcoModelsRole
     */
    warning_list?: Array<MelcoModelsCustomWarning> | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoModelsGenericList1MelcoModelsRole
     */
    total: number;
}

export function MelcoModelsGenericList1MelcoModelsRoleFromJSON(json: any): MelcoModelsGenericList1MelcoModelsRole {
    return MelcoModelsGenericList1MelcoModelsRoleFromJSONTyped(json, false);
}

export function MelcoModelsGenericList1MelcoModelsRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsGenericList1MelcoModelsRole {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'list': ((json['list'] as Array<any>).map(MelcoModelsRoleFromJSON)),
        'warning_list': !exists(json, 'warning_list') ? undefined : (json['warning_list'] === null ? null : (json['warning_list'] as Array<any>).map(MelcoModelsCustomWarningFromJSON)),
        'total': json['total'],
    };
}

export function MelcoModelsGenericList1MelcoModelsRoleToJSON(value?: MelcoModelsGenericList1MelcoModelsRole | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'list': ((value.list as Array<any>).map(MelcoModelsRoleToJSON)),
        'warning_list': value.warning_list === undefined ? undefined : (value.warning_list === null ? null : (value.warning_list as Array<any>).map(MelcoModelsCustomWarningToJSON)),
        'total': value.total,
    };
}


