/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoModelsColorListItem,
    MelcoModelsColorListItemFromJSON,
    MelcoModelsColorListItemFromJSONTyped,
    MelcoModelsColorListItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoModelsAddColorCollection
 */
export interface MelcoModelsAddColorCollection {
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsAddColorCollection
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsAddColorCollection
     */
    service?: string | null;
    /**
     * 
     * @type {Array<MelcoModelsColorListItem>}
     * @memberof MelcoModelsAddColorCollection
     */
    color_list?: Array<MelcoModelsColorListItem> | null;
}

export function MelcoModelsAddColorCollectionFromJSON(json: any): MelcoModelsAddColorCollection {
    return MelcoModelsAddColorCollectionFromJSONTyped(json, false);
}

export function MelcoModelsAddColorCollectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsAddColorCollection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'service': !exists(json, 'service') ? undefined : json['service'],
        'color_list': !exists(json, 'color_list') ? undefined : (json['color_list'] === null ? null : (json['color_list'] as Array<any>).map(MelcoModelsColorListItemFromJSON)),
    };
}

export function MelcoModelsAddColorCollectionToJSON(value?: MelcoModelsAddColorCollection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'service': value.service,
        'color_list': value.color_list === undefined ? undefined : (value.color_list === null ? null : (value.color_list as Array<any>).map(MelcoModelsColorListItemToJSON)),
    };
}


