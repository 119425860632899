/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MelcoFusionModelsBlankReferences,
    MelcoFusionModelsBlankReferencesFromJSON,
    MelcoFusionModelsBlankReferencesToJSON,
    MelcoFusionModelsCreateBlank,
    MelcoFusionModelsCreateBlankFromJSON,
    MelcoFusionModelsCreateBlankToJSON,
    MelcoFusionModelsUpdateBlank,
    MelcoFusionModelsUpdateBlankFromJSON,
    MelcoFusionModelsUpdateBlankToJSON,
    MelcoFusionModelsUserBlank,
    MelcoFusionModelsUserBlankFromJSON,
    MelcoFusionModelsUserBlankToJSON,
    MelcoModelsAddAlphabetCollection,
    MelcoModelsAddAlphabetCollectionFromJSON,
    MelcoModelsAddAlphabetCollectionToJSON,
    MelcoModelsAlphabetCollection,
    MelcoModelsAlphabetCollectionFromJSON,
    MelcoModelsAlphabetCollectionToJSON,
    MelcoModelsAlphabetCollectionReferences,
    MelcoModelsAlphabetCollectionReferencesFromJSON,
    MelcoModelsAlphabetCollectionReferencesToJSON,
    MelcoModelsAlphabetReferences,
    MelcoModelsAlphabetReferencesFromJSON,
    MelcoModelsAlphabetReferencesToJSON,
    MelcoModelsColorCollection,
    MelcoModelsColorCollectionFromJSON,
    MelcoModelsColorCollectionToJSON,
    MelcoModelsColorCollectionReferences,
    MelcoModelsColorCollectionReferencesFromJSON,
    MelcoModelsColorCollectionReferencesToJSON,
    MelcoModelsCreateDesignCollection,
    MelcoModelsCreateDesignCollectionFromJSON,
    MelcoModelsCreateDesignCollectionToJSON,
    MelcoModelsDesign,
    MelcoModelsDesignFromJSON,
    MelcoModelsDesignToJSON,
    MelcoModelsDesignCollection,
    MelcoModelsDesignCollectionFromJSON,
    MelcoModelsDesignCollectionToJSON,
    MelcoModelsDesignMetadata,
    MelcoModelsDesignMetadataFromJSON,
    MelcoModelsDesignMetadataToJSON,
    MelcoModelsDesignReferences,
    MelcoModelsDesignReferencesFromJSON,
    MelcoModelsDesignReferencesToJSON,
    MelcoModelsError,
    MelcoModelsErrorFromJSON,
    MelcoModelsErrorToJSON,
    MelcoModelsGenericList1MelcoFusionModelsUserBlankListItem,
    MelcoModelsGenericList1MelcoFusionModelsUserBlankListItemFromJSON,
    MelcoModelsGenericList1MelcoFusionModelsUserBlankListItemToJSON,
    MelcoModelsGenericList1MelcoModelsDesignCollection,
    MelcoModelsGenericList1MelcoModelsDesignCollectionFromJSON,
    MelcoModelsGenericList1MelcoModelsDesignCollectionToJSON,
    MelcoModelsGenericList1MelcoModelsDesignListItem,
    MelcoModelsGenericList1MelcoModelsDesignListItemFromJSON,
    MelcoModelsGenericList1MelcoModelsDesignListItemToJSON,
    MelcoModelsGenericList1MelcoModelsHoopListItem,
    MelcoModelsGenericList1MelcoModelsHoopListItemFromJSON,
    MelcoModelsGenericList1MelcoModelsHoopListItemToJSON,
    MelcoModelsGenericList1MelcoModelsSupplier,
    MelcoModelsGenericList1MelcoModelsSupplierFromJSON,
    MelcoModelsGenericList1MelcoModelsSupplierToJSON,
    MelcoModelsGenericList1MelcoModelsSupplierProduct,
    MelcoModelsGenericList1MelcoModelsSupplierProductFromJSON,
    MelcoModelsGenericList1MelcoModelsSupplierProductToJSON,
    MelcoModelsGenericList1MelcoModelsThread,
    MelcoModelsGenericList1MelcoModelsThreadFromJSON,
    MelcoModelsGenericList1MelcoModelsThreadToJSON,
    MelcoModelsGenericList1MelcoModelsUserAlphabet,
    MelcoModelsGenericList1MelcoModelsUserAlphabetFromJSON,
    MelcoModelsGenericList1MelcoModelsUserAlphabetToJSON,
    MelcoModelsGenericList1MelcoModelsUserImage,
    MelcoModelsGenericList1MelcoModelsUserImageFromJSON,
    MelcoModelsGenericList1MelcoModelsUserImageToJSON,
    MelcoModelsImageReferences,
    MelcoModelsImageReferencesFromJSON,
    MelcoModelsImageReferencesToJSON,
    MelcoModelsNotification,
    MelcoModelsNotificationFromJSON,
    MelcoModelsNotificationToJSON,
    MelcoModelsSupplier,
    MelcoModelsSupplierFromJSON,
    MelcoModelsSupplierToJSON,
    MelcoModelsSupplierProduct,
    MelcoModelsSupplierProductFromJSON,
    MelcoModelsSupplierProductToJSON,
    MelcoModelsUpdateAlphabetCollection,
    MelcoModelsUpdateAlphabetCollectionFromJSON,
    MelcoModelsUpdateAlphabetCollectionToJSON,
    MelcoModelsUpdateDesign,
    MelcoModelsUpdateDesignFromJSON,
    MelcoModelsUpdateDesignToJSON,
    MelcoModelsUpdateDesignCollection,
    MelcoModelsUpdateDesignCollectionFromJSON,
    MelcoModelsUpdateDesignCollectionToJSON,
    MelcoModelsUserAlphabet,
    MelcoModelsUserAlphabetFromJSON,
    MelcoModelsUserAlphabetToJSON,
    MelcoModelsUserImage,
    MelcoModelsUserImageFromJSON,
    MelcoModelsUserImageToJSON,
} from '../models';

export interface UserContentsApiAccountHoopSearchRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    publishState?: string;
    searchText?: string;
    name?: string;
    productList?: string;
    machineList?: string;
    categoryList?: string;
    version?: string;
}

export interface UserContentsApiAlphabetCollectionsReferencesRequest {
    id: string;
}

export interface UserContentsApiColorCollectionsReferencesRequest {
    id: string;
}

export interface UserContentsApiDesignCollectionsReferencesRequest {
    id: string;
}

export interface UserContentsApiThreadSupplierGetRequest {
    id: string;
    publishState?: string;
}

export interface UserContentsApiThreadSupplierProductsGetRequest {
    id: string;
    publishState?: string;
}

export interface UserContentsApiThreadSupplierProductsSearchRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    searchText?: string;
    name?: string;
    supplierId?: string;
    supplierName?: string;
    publishState?: string;
    supplierType?: string;
}

export interface UserContentsApiThreadSupplierSearchRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    searchText?: string;
    name?: string;
    publishState?: string;
}

export interface UserContentsApiThreadsSearchRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    publishState?: string;
    searchText?: string;
    searchTextFields?: string;
    name?: string;
    code?: string;
    argb?: string;
    supplierId?: string;
    productId?: string;
}

export interface UserContentsApiUserAlphabetCollectionUpdateRequest {
    id: string;
    melcoModelsUpdateAlphabetCollection?: MelcoModelsUpdateAlphabetCollection;
}

export interface UserContentsApiUserAlphabetCollectionsCreateRequest {
    melcoModelsAddAlphabetCollection?: MelcoModelsAddAlphabetCollection;
}

export interface UserContentsApiUserAlphabetCollectionsDeleteRequest {
    id: string;
    force?: boolean;
}

export interface UserContentsApiUserAlphabetCollectionsDuplicateRequest {
    id: string;
}

export interface UserContentsApiUserAlphabetCollectionsGetDefRequest {
    id: string;
    publishState?: string;
}

export interface UserContentsApiUserAlphabetDeleteRequest {
    id: string;
    force?: boolean;
}

export interface UserContentsApiUserAlphabetDownloadRequest {
    id: string;
    publishState?: string;
}

export interface UserContentsApiUserAlphabetDuplicateRequest {
    id: string;
}

export interface UserContentsApiUserAlphabetGetRequest {
    id: string;
    publishState?: string;
}

export interface UserContentsApiUserAlphabetPreviewRequest {
    id: string;
    width?: number;
    publishState?: string;
}

export interface UserContentsApiUserAlphabetReferencesRequest {
    id: string;
}

export interface UserContentsApiUserAlphabetSearchRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    searchText?: string;
    service?: string;
    publishState?: string;
}

export interface UserContentsApiUserAlphabetUpdateRequest {
    id: string;
    name?: string;
    recommendedText?: string;
    file?: Blob;
    binaryFileName?: string;
    binaryFileFileData?: string;
    binaryFileSignature?: string;
}

export interface UserContentsApiUserAlphabetsUploadRequest {
    service?: string;
    file?: Blob;
}

export interface UserContentsApiUserBlankCreateRequest {
    melcoFusionModelsCreateBlank?: MelcoFusionModelsCreateBlank;
}

export interface UserContentsApiUserBlankDeleteRequest {
    id: string;
    force?: boolean;
}

export interface UserContentsApiUserBlankDuplicateRequest {
    id: string;
}

export interface UserContentsApiUserBlankGetDefRequest {
    id: string;
    publishState?: string;
}

export interface UserContentsApiUserBlankReferencesRequest {
    id: string;
}

export interface UserContentsApiUserBlankSearchRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    searchText?: string;
    publishState?: string;
}

export interface UserContentsApiUserBlankUpdateRequest {
    id: string;
    melcoFusionModelsUpdateBlank?: MelcoFusionModelsUpdateBlank;
}

export interface UserContentsApiUserColorCollectionsDeleteRequest {
    id: string;
    force?: boolean;
}

export interface UserContentsApiUserColorCollectionsDownloadRequest {
    id: string;
    publishState?: string;
    productCode?: string;
}

export interface UserContentsApiUserColorCollectionsGetDefRequest {
    id: string;
    publishState?: string;
}

export interface UserContentsApiUserDesignCollectionUpdateRequest {
    id: string;
    melcoModelsUpdateDesignCollection?: MelcoModelsUpdateDesignCollection;
}

export interface UserContentsApiUserDesignCollectionsCreateRequest {
    melcoModelsCreateDesignCollection?: MelcoModelsCreateDesignCollection;
}

export interface UserContentsApiUserDesignCollectionsDeleteRequest {
    id: string;
    force?: boolean;
}

export interface UserContentsApiUserDesignCollectionsDuplicateRequest {
    id: string;
}

export interface UserContentsApiUserDesignCollectionsGetDefRequest {
    id: string;
    publishState?: string;
}

export interface UserContentsApiUserDesignCollectionsGetListRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    searchText?: string;
    publishState?: string;
}

export interface UserContentsApiUserDesignsDeleteRequest {
    id: string;
    force?: boolean;
}

export interface UserContentsApiUserDesignsDownloadRequest {
    id: string;
    publishState?: string;
}

export interface UserContentsApiUserDesignsDuplicateRequest {
    id: string;
}

export interface UserContentsApiUserDesignsGetDefRequest {
    id: string;
    publishState?: string;
}

export interface UserContentsApiUserDesignsMetadataRequest {
    file?: Blob;
}

export interface UserContentsApiUserDesignsPreviewRequest {
    id: string;
    width?: number;
    publishState?: string;
}

export interface UserContentsApiUserDesignsPrintQueueUpdateFileRequest {
    id: string;
    file?: Blob;
}

export interface UserContentsApiUserDesignsPrintQueueUploadRequest {
    file?: Blob;
}

export interface UserContentsApiUserDesignsReferencesRequest {
    id: string;
}

export interface UserContentsApiUserDesignsSearchRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    searchText?: string;
    publishState?: string;
    service?: string;
}

export interface UserContentsApiUserDesignsUpdateRequest {
    id: string;
    melcoModelsUpdateDesign?: MelcoModelsUpdateDesign;
}

export interface UserContentsApiUserDesignsUpdateFileRequest {
    id: string;
    file?: Blob;
}

export interface UserContentsApiUserDesignsUploadRequest {
    file?: Blob;
}

export interface UserContentsApiUserImageDeleteRequest {
    id: string;
    force?: boolean;
}

export interface UserContentsApiUserImageDownloadRequest {
    id: string;
    publishState?: string;
}

export interface UserContentsApiUserImageDuplicateRequest {
    id: string;
}

export interface UserContentsApiUserImageGetDefRequest {
    id: string;
    publishState?: string;
}

export interface UserContentsApiUserImagePreviewRequest {
    id: string;
    width?: number;
    publishState?: string;
}

export interface UserContentsApiUserImageReferencesRequest {
    id: string;
}

export interface UserContentsApiUserImageSearchRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    searchText?: string;
    publishState?: string;
}

export interface UserContentsApiUserImageUpdateRequest {
    id: string;
    name?: string;
    viewName?: string;
    colorName?: string;
    colorHex?: string;
    file?: Blob;
}

export interface UserContentsApiUserImageUploadRequest {
    name?: string;
    viewName?: string;
    colorName?: string;
    colorHex?: string;
    file?: Blob;
}

/**
 * 
 */
export class UserContentsApi extends runtime.BaseAPI {

    /**
     * return list of hoops
     * search hoops
     */
    async accountHoopSearchRaw(requestParameters: UserContentsApiAccountHoopSearchRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsHoopListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.productList !== undefined) {
            queryParameters['product_list'] = requestParameters.productList;
        }

        if (requestParameters.machineList !== undefined) {
            queryParameters['machine_list'] = requestParameters.machineList;
        }

        if (requestParameters.categoryList !== undefined) {
            queryParameters['category_list'] = requestParameters.categoryList;
        }

        if (requestParameters.version !== undefined) {
            queryParameters['version'] = requestParameters.version;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/hoops`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsHoopListItemFromJSON(jsonValue));
    }

    /**
     * return list of hoops
     * search hoops
     */
    async accountHoopSearch(requestParameters: UserContentsApiAccountHoopSearchRequest): Promise<MelcoModelsGenericList1MelcoModelsHoopListItem> {
        const response = await this.accountHoopSearchRaw(requestParameters);
        return await response.value();
    }

    /**
     * get alphabet collection references
     * get alphabet collection references
     */
    async alphabetCollectionsReferencesRaw(requestParameters: UserContentsApiAlphabetCollectionsReferencesRequest): Promise<runtime.ApiResponse<MelcoModelsAlphabetCollectionReferences>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling alphabetCollectionsReferences.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/alphabets/collections/{Id}/references`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsAlphabetCollectionReferencesFromJSON(jsonValue));
    }

    /**
     * get alphabet collection references
     * get alphabet collection references
     */
    async alphabetCollectionsReferences(requestParameters: UserContentsApiAlphabetCollectionsReferencesRequest): Promise<MelcoModelsAlphabetCollectionReferences> {
        const response = await this.alphabetCollectionsReferencesRaw(requestParameters);
        return await response.value();
    }

    /**
     * get color collection references
     * get color collection references
     */
    async colorCollectionsReferencesRaw(requestParameters: UserContentsApiColorCollectionsReferencesRequest): Promise<runtime.ApiResponse<MelcoModelsColorCollectionReferences>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling colorCollectionsReferences.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/colors/collections/{Id}/references`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsColorCollectionReferencesFromJSON(jsonValue));
    }

    /**
     * get color collection references
     * get color collection references
     */
    async colorCollectionsReferences(requestParameters: UserContentsApiColorCollectionsReferencesRequest): Promise<MelcoModelsColorCollectionReferences> {
        const response = await this.colorCollectionsReferencesRaw(requestParameters);
        return await response.value();
    }

    /**
     * get design collection references
     * get design collection references
     */
    async designCollectionsReferencesRaw(requestParameters: UserContentsApiDesignCollectionsReferencesRequest): Promise<runtime.ApiResponse<MelcoModelsDesignReferences>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling designCollectionsReferences.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/designs/collections/{Id}/references`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsDesignReferencesFromJSON(jsonValue));
    }

    /**
     * get design collection references
     * get design collection references
     */
    async designCollectionsReferences(requestParameters: UserContentsApiDesignCollectionsReferencesRequest): Promise<MelcoModelsDesignReferences> {
        const response = await this.designCollectionsReferencesRaw(requestParameters);
        return await response.value();
    }

    /**
     * get thread supplier
     * get thread supplier by Id
     */
    async threadSupplierGetRaw(requestParameters: UserContentsApiThreadSupplierGetRequest): Promise<runtime.ApiResponse<MelcoModelsSupplier>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling threadSupplierGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/threads/suppliers/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsSupplierFromJSON(jsonValue));
    }

    /**
     * get thread supplier
     * get thread supplier by Id
     */
    async threadSupplierGet(requestParameters: UserContentsApiThreadSupplierGetRequest): Promise<MelcoModelsSupplier> {
        const response = await this.threadSupplierGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * get thread product
     * get thread product by id
     */
    async threadSupplierProductsGetRaw(requestParameters: UserContentsApiThreadSupplierProductsGetRequest): Promise<runtime.ApiResponse<MelcoModelsSupplierProduct>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling threadSupplierProductsGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/threads/suppliers/products/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsSupplierProductFromJSON(jsonValue));
    }

    /**
     * get thread product
     * get thread product by id
     */
    async threadSupplierProductsGet(requestParameters: UserContentsApiThreadSupplierProductsGetRequest): Promise<MelcoModelsSupplierProduct> {
        const response = await this.threadSupplierProductsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * list of supported products
     * search thread supplier products
     */
    async threadSupplierProductsSearchRaw(requestParameters: UserContentsApiThreadSupplierProductsSearchRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsSupplierProduct>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.supplierId !== undefined) {
            queryParameters['supplier_id'] = requestParameters.supplierId;
        }

        if (requestParameters.supplierName !== undefined) {
            queryParameters['supplier_name'] = requestParameters.supplierName;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        if (requestParameters.supplierType !== undefined) {
            queryParameters['supplier_type'] = requestParameters.supplierType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/threads/suppliers/products`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsSupplierProductFromJSON(jsonValue));
    }

    /**
     * list of supported products
     * search thread supplier products
     */
    async threadSupplierProductsSearch(requestParameters: UserContentsApiThreadSupplierProductsSearchRequest): Promise<MelcoModelsGenericList1MelcoModelsSupplierProduct> {
        const response = await this.threadSupplierProductsSearchRaw(requestParameters);
        return await response.value();
    }

    /**
     * list of thread supplier
     * search thread supplier list
     */
    async threadSupplierSearchRaw(requestParameters: UserContentsApiThreadSupplierSearchRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsSupplier>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/threads/suppliers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsSupplierFromJSON(jsonValue));
    }

    /**
     * list of thread supplier
     * search thread supplier list
     */
    async threadSupplierSearch(requestParameters: UserContentsApiThreadSupplierSearchRequest): Promise<MelcoModelsGenericList1MelcoModelsSupplier> {
        const response = await this.threadSupplierSearchRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrun list of threads
     * search threads
     */
    async threadsSearchRaw(requestParameters: UserContentsApiThreadsSearchRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsThread>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.searchTextFields !== undefined) {
            queryParameters['search_text_fields'] = requestParameters.searchTextFields;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        if (requestParameters.argb !== undefined) {
            queryParameters['argb'] = requestParameters.argb;
        }

        if (requestParameters.supplierId !== undefined) {
            queryParameters['supplier_id'] = requestParameters.supplierId;
        }

        if (requestParameters.productId !== undefined) {
            queryParameters['product_id'] = requestParameters.productId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/threads`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsThreadFromJSON(jsonValue));
    }

    /**
     * retrun list of threads
     * search threads
     */
    async threadsSearch(requestParameters: UserContentsApiThreadsSearchRequest): Promise<MelcoModelsGenericList1MelcoModelsThread> {
        const response = await this.threadsSearchRaw(requestParameters);
        return await response.value();
    }

    /**
     * update existing alphabet collection
     */
    async userAlphabetCollectionUpdateRaw(requestParameters: UserContentsApiUserAlphabetCollectionUpdateRequest): Promise<runtime.ApiResponse<MelcoModelsAlphabetCollection>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userAlphabetCollectionUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/alphabets/collections/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsUpdateAlphabetCollectionToJSON(requestParameters.melcoModelsUpdateAlphabetCollection),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsAlphabetCollectionFromJSON(jsonValue));
    }

    /**
     * update existing alphabet collection
     */
    async userAlphabetCollectionUpdate(requestParameters: UserContentsApiUserAlphabetCollectionUpdateRequest): Promise<MelcoModelsAlphabetCollection> {
        const response = await this.userAlphabetCollectionUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Add collection item
     */
    async userAlphabetCollectionsCreateRaw(requestParameters: UserContentsApiUserAlphabetCollectionsCreateRequest): Promise<runtime.ApiResponse<MelcoModelsAlphabetCollection>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/alphabets/collections`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsAddAlphabetCollectionToJSON(requestParameters.melcoModelsAddAlphabetCollection),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsAlphabetCollectionFromJSON(jsonValue));
    }

    /**
     * Add collection item
     */
    async userAlphabetCollectionsCreate(requestParameters: UserContentsApiUserAlphabetCollectionsCreateRequest): Promise<MelcoModelsAlphabetCollection> {
        const response = await this.userAlphabetCollectionsCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete alphabet collection
     */
    async userAlphabetCollectionsDeleteRaw(requestParameters: UserContentsApiUserAlphabetCollectionsDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userAlphabetCollectionsDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.force !== undefined) {
            queryParameters['Force'] = requestParameters.force;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/alphabets/collections/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete alphabet collection
     */
    async userAlphabetCollectionsDelete(requestParameters: UserContentsApiUserAlphabetCollectionsDeleteRequest): Promise<void> {
        await this.userAlphabetCollectionsDeleteRaw(requestParameters);
    }

    /**
     * duplicate alphabet collection
     * duplicate alphabet collection
     */
    async userAlphabetCollectionsDuplicateRaw(requestParameters: UserContentsApiUserAlphabetCollectionsDuplicateRequest): Promise<runtime.ApiResponse<MelcoModelsAlphabetCollection>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userAlphabetCollectionsDuplicate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/alphabets/collections/{Id}/duplicate`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsAlphabetCollectionFromJSON(jsonValue));
    }

    /**
     * duplicate alphabet collection
     * duplicate alphabet collection
     */
    async userAlphabetCollectionsDuplicate(requestParameters: UserContentsApiUserAlphabetCollectionsDuplicateRequest): Promise<MelcoModelsAlphabetCollection> {
        const response = await this.userAlphabetCollectionsDuplicateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get alphabet collection by Id
     */
    async userAlphabetCollectionsGetDefRaw(requestParameters: UserContentsApiUserAlphabetCollectionsGetDefRequest): Promise<runtime.ApiResponse<MelcoModelsAlphabetCollection>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userAlphabetCollectionsGetDef.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/alphabets/collections/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsAlphabetCollectionFromJSON(jsonValue));
    }

    /**
     * Get alphabet collection by Id
     */
    async userAlphabetCollectionsGetDef(requestParameters: UserContentsApiUserAlphabetCollectionsGetDefRequest): Promise<MelcoModelsAlphabetCollection> {
        const response = await this.userAlphabetCollectionsGetDefRaw(requestParameters);
        return await response.value();
    }

    /**
     * delete alphabet
     */
    async userAlphabetDeleteRaw(requestParameters: UserContentsApiUserAlphabetDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userAlphabetDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.force !== undefined) {
            queryParameters['Force'] = requestParameters.force;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/alphabets/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete alphabet
     */
    async userAlphabetDelete(requestParameters: UserContentsApiUserAlphabetDeleteRequest): Promise<void> {
        await this.userAlphabetDeleteRaw(requestParameters);
    }

    /**
     * download alphabet
     * download alphabet
     */
    async userAlphabetDownloadRaw(requestParameters: UserContentsApiUserAlphabetDownloadRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userAlphabetDownload.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/alphabets/{Id}/download`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * download alphabet
     * download alphabet
     */
    async userAlphabetDownload(requestParameters: UserContentsApiUserAlphabetDownloadRequest): Promise<Blob> {
        const response = await this.userAlphabetDownloadRaw(requestParameters);
        return await response.value();
    }

    /**
     * duplicate alphabet
     * duplicate alphabet (alphabet must be in published state to call this endpoint)
     */
    async userAlphabetDuplicateRaw(requestParameters: UserContentsApiUserAlphabetDuplicateRequest): Promise<runtime.ApiResponse<MelcoFusionModelsUserBlank>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userAlphabetDuplicate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/alphabets/{Id}/duplicate`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoFusionModelsUserBlankFromJSON(jsonValue));
    }

    /**
     * duplicate alphabet
     * duplicate alphabet (alphabet must be in published state to call this endpoint)
     */
    async userAlphabetDuplicate(requestParameters: UserContentsApiUserAlphabetDuplicateRequest): Promise<MelcoFusionModelsUserBlank> {
        const response = await this.userAlphabetDuplicateRaw(requestParameters);
        return await response.value();
    }

    /**
     * get user alphabet
     * get user alphabet
     */
    async userAlphabetGetRaw(requestParameters: UserContentsApiUserAlphabetGetRequest): Promise<runtime.ApiResponse<MelcoModelsUserAlphabet>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userAlphabetGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/alphabets/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsUserAlphabetFromJSON(jsonValue));
    }

    /**
     * get user alphabet
     * get user alphabet
     */
    async userAlphabetGet(requestParameters: UserContentsApiUserAlphabetGetRequest): Promise<MelcoModelsUserAlphabet> {
        const response = await this.userAlphabetGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * get alphabet preview
     * get alphabet preview
     */
    async userAlphabetPreviewRaw(requestParameters: UserContentsApiUserAlphabetPreviewRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userAlphabetPreview.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.width !== undefined) {
            queryParameters['width'] = requestParameters.width;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/alphabets/{Id}/preview`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * get alphabet preview
     * get alphabet preview
     */
    async userAlphabetPreview(requestParameters: UserContentsApiUserAlphabetPreviewRequest): Promise<void> {
        await this.userAlphabetPreviewRaw(requestParameters);
    }

    /**
     * get alphabet references or depedencies
     * get alphabet references
     */
    async userAlphabetReferencesRaw(requestParameters: UserContentsApiUserAlphabetReferencesRequest): Promise<runtime.ApiResponse<MelcoModelsAlphabetReferences>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userAlphabetReferences.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/alphabets/{Id}/references`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsAlphabetReferencesFromJSON(jsonValue));
    }

    /**
     * get alphabet references or depedencies
     * get alphabet references
     */
    async userAlphabetReferences(requestParameters: UserContentsApiUserAlphabetReferencesRequest): Promise<MelcoModelsAlphabetReferences> {
        const response = await this.userAlphabetReferencesRaw(requestParameters);
        return await response.value();
    }

    /**
     * search user alphabets
     * search alphabets
     */
    async userAlphabetSearchRaw(requestParameters: UserContentsApiUserAlphabetSearchRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsUserAlphabet>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.service !== undefined) {
            queryParameters['service'] = requestParameters.service;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/alphabets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsUserAlphabetFromJSON(jsonValue));
    }

    /**
     * search user alphabets
     * search alphabets
     */
    async userAlphabetSearch(requestParameters: UserContentsApiUserAlphabetSearchRequest): Promise<MelcoModelsGenericList1MelcoModelsUserAlphabet> {
        const response = await this.userAlphabetSearchRaw(requestParameters);
        return await response.value();
    }

    /**
     * update existing alphabet
     * update existing alphabet
     */
    async userAlphabetUpdateRaw(requestParameters: UserContentsApiUserAlphabetUpdateRequest): Promise<runtime.ApiResponse<MelcoModelsUserAlphabet>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userAlphabetUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.name !== undefined) {
            formParams.append('Name', requestParameters.name as any);
        }

        if (requestParameters.recommendedText !== undefined) {
            formParams.append('RecommendedText', requestParameters.recommendedText as any);
        }

        if (requestParameters.file !== undefined) {
            formParams.append('File', requestParameters.file as any);
        }

        if (requestParameters.binaryFileName !== undefined) {
            formParams.append('BinaryFile.Name', requestParameters.binaryFileName as any);
        }

        if (requestParameters.binaryFileFileData !== undefined) {
            formParams.append('BinaryFile.FileData', requestParameters.binaryFileFileData as any);
        }

        if (requestParameters.binaryFileSignature !== undefined) {
            formParams.append('BinaryFile.Signature', requestParameters.binaryFileSignature as any);
        }

        const response = await this.request({
            path: `/alphabets/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsUserAlphabetFromJSON(jsonValue));
    }

    /**
     * update existing alphabet
     * update existing alphabet
     */
    async userAlphabetUpdate(requestParameters: UserContentsApiUserAlphabetUpdateRequest): Promise<MelcoModelsUserAlphabet> {
        const response = await this.userAlphabetUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     * upload alphabet
     * upload alphabet file
     */
    async userAlphabetsUploadRaw(requestParameters: UserContentsApiUserAlphabetsUploadRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsUserAlphabet>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.service !== undefined) {
            queryParameters['service'] = requestParameters.service;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/alphabets/upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsUserAlphabetFromJSON(jsonValue));
    }

    /**
     * upload alphabet
     * upload alphabet file
     */
    async userAlphabetsUpload(requestParameters: UserContentsApiUserAlphabetsUploadRequest): Promise<MelcoModelsGenericList1MelcoModelsUserAlphabet> {
        const response = await this.userAlphabetsUploadRaw(requestParameters);
        return await response.value();
    }

    /**
     * creates blank
     * create blank
     */
    async userBlankCreateRaw(requestParameters: UserContentsApiUserBlankCreateRequest): Promise<runtime.ApiResponse<MelcoFusionModelsUserBlank>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/blanks`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoFusionModelsCreateBlankToJSON(requestParameters.melcoFusionModelsCreateBlank),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoFusionModelsUserBlankFromJSON(jsonValue));
    }

    /**
     * creates blank
     * create blank
     */
    async userBlankCreate(requestParameters: UserContentsApiUserBlankCreateRequest): Promise<MelcoFusionModelsUserBlank> {
        const response = await this.userBlankCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * delete blank
     */
    async userBlankDeleteRaw(requestParameters: UserContentsApiUserBlankDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userBlankDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.force !== undefined) {
            queryParameters['Force'] = requestParameters.force;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/blanks/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete blank
     */
    async userBlankDelete(requestParameters: UserContentsApiUserBlankDeleteRequest): Promise<void> {
        await this.userBlankDeleteRaw(requestParameters);
    }

    /**
     * duplicate blank
     * duplicate blank
     */
    async userBlankDuplicateRaw(requestParameters: UserContentsApiUserBlankDuplicateRequest): Promise<runtime.ApiResponse<MelcoFusionModelsUserBlank>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userBlankDuplicate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/blanks/{Id}/duplicate`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoFusionModelsUserBlankFromJSON(jsonValue));
    }

    /**
     * duplicate blank
     * duplicate blank
     */
    async userBlankDuplicate(requestParameters: UserContentsApiUserBlankDuplicateRequest): Promise<MelcoFusionModelsUserBlank> {
        const response = await this.userBlankDuplicateRaw(requestParameters);
        return await response.value();
    }

    /**
     * get blank
     * get user blank
     */
    async userBlankGetDefRaw(requestParameters: UserContentsApiUserBlankGetDefRequest): Promise<runtime.ApiResponse<MelcoFusionModelsUserBlank>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userBlankGetDef.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/blanks/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoFusionModelsUserBlankFromJSON(jsonValue));
    }

    /**
     * get blank
     * get user blank
     */
    async userBlankGetDef(requestParameters: UserContentsApiUserBlankGetDefRequest): Promise<MelcoFusionModelsUserBlank> {
        const response = await this.userBlankGetDefRaw(requestParameters);
        return await response.value();
    }

    /**
     * get blank references or depedencies
     * get blank references
     */
    async userBlankReferencesRaw(requestParameters: UserContentsApiUserBlankReferencesRequest): Promise<runtime.ApiResponse<MelcoFusionModelsBlankReferences>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userBlankReferences.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/blanks/{Id}/references`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoFusionModelsBlankReferencesFromJSON(jsonValue));
    }

    /**
     * get blank references or depedencies
     * get blank references
     */
    async userBlankReferences(requestParameters: UserContentsApiUserBlankReferencesRequest): Promise<MelcoFusionModelsBlankReferences> {
        const response = await this.userBlankReferencesRaw(requestParameters);
        return await response.value();
    }

    /**
     * search user blanks
     */
    async userBlankSearchRaw(requestParameters: UserContentsApiUserBlankSearchRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoFusionModelsUserBlankListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/blanks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoFusionModelsUserBlankListItemFromJSON(jsonValue));
    }

    /**
     * search user blanks
     */
    async userBlankSearch(requestParameters: UserContentsApiUserBlankSearchRequest): Promise<MelcoModelsGenericList1MelcoFusionModelsUserBlankListItem> {
        const response = await this.userBlankSearchRaw(requestParameters);
        return await response.value();
    }

    /**
     * update existing blank
     * update existing blank
     */
    async userBlankUpdateRaw(requestParameters: UserContentsApiUserBlankUpdateRequest): Promise<runtime.ApiResponse<MelcoFusionModelsUserBlank>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userBlankUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/blanks/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoFusionModelsUpdateBlankToJSON(requestParameters.melcoFusionModelsUpdateBlank),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoFusionModelsUserBlankFromJSON(jsonValue));
    }

    /**
     * update existing blank
     * update existing blank
     */
    async userBlankUpdate(requestParameters: UserContentsApiUserBlankUpdateRequest): Promise<MelcoFusionModelsUserBlank> {
        const response = await this.userBlankUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     * delete color collection
     */
    async userColorCollectionsDeleteRaw(requestParameters: UserContentsApiUserColorCollectionsDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userColorCollectionsDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.force !== undefined) {
            queryParameters['Force'] = requestParameters.force;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/colors/collections/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete color collection
     */
    async userColorCollectionsDelete(requestParameters: UserContentsApiUserColorCollectionsDeleteRequest): Promise<void> {
        await this.userColorCollectionsDeleteRaw(requestParameters);
    }

    /**
     * download color collection
     * download color collection
     */
    async userColorCollectionsDownloadRaw(requestParameters: UserContentsApiUserColorCollectionsDownloadRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userColorCollectionsDownload.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        if (requestParameters.productCode !== undefined) {
            queryParameters['product_code'] = requestParameters.productCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/colors/collections/{Id}/download`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * download color collection
     * download color collection
     */
    async userColorCollectionsDownload(requestParameters: UserContentsApiUserColorCollectionsDownloadRequest): Promise<Blob> {
        const response = await this.userColorCollectionsDownloadRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get color collection by Id
     */
    async userColorCollectionsGetDefRaw(requestParameters: UserContentsApiUserColorCollectionsGetDefRequest): Promise<runtime.ApiResponse<MelcoModelsColorCollection>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userColorCollectionsGetDef.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/colors/collections/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsColorCollectionFromJSON(jsonValue));
    }

    /**
     * Get color collection by Id
     */
    async userColorCollectionsGetDef(requestParameters: UserContentsApiUserColorCollectionsGetDefRequest): Promise<MelcoModelsColorCollection> {
        const response = await this.userColorCollectionsGetDefRaw(requestParameters);
        return await response.value();
    }

    /**
     * update existing design collection
     */
    async userDesignCollectionUpdateRaw(requestParameters: UserContentsApiUserDesignCollectionUpdateRequest): Promise<runtime.ApiResponse<MelcoModelsDesignCollection>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userDesignCollectionUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/designs/collections/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsUpdateDesignCollectionToJSON(requestParameters.melcoModelsUpdateDesignCollection),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsDesignCollectionFromJSON(jsonValue));
    }

    /**
     * update existing design collection
     */
    async userDesignCollectionUpdate(requestParameters: UserContentsApiUserDesignCollectionUpdateRequest): Promise<MelcoModelsDesignCollection> {
        const response = await this.userDesignCollectionUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create design collection item
     */
    async userDesignCollectionsCreateRaw(requestParameters: UserContentsApiUserDesignCollectionsCreateRequest): Promise<runtime.ApiResponse<MelcoModelsDesignCollection>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/designs/collections`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsCreateDesignCollectionToJSON(requestParameters.melcoModelsCreateDesignCollection),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsDesignCollectionFromJSON(jsonValue));
    }

    /**
     * Create design collection item
     */
    async userDesignCollectionsCreate(requestParameters: UserContentsApiUserDesignCollectionsCreateRequest): Promise<MelcoModelsDesignCollection> {
        const response = await this.userDesignCollectionsCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete design collection
     */
    async userDesignCollectionsDeleteRaw(requestParameters: UserContentsApiUserDesignCollectionsDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userDesignCollectionsDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.force !== undefined) {
            queryParameters['Force'] = requestParameters.force;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/designs/collections/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete design collection
     */
    async userDesignCollectionsDelete(requestParameters: UserContentsApiUserDesignCollectionsDeleteRequest): Promise<void> {
        await this.userDesignCollectionsDeleteRaw(requestParameters);
    }

    /**
     * duplicate design collection
     * duplicate design collection
     */
    async userDesignCollectionsDuplicateRaw(requestParameters: UserContentsApiUserDesignCollectionsDuplicateRequest): Promise<runtime.ApiResponse<MelcoModelsDesignCollection>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userDesignCollectionsDuplicate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/designs/collections/{Id}/duplicate`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsDesignCollectionFromJSON(jsonValue));
    }

    /**
     * duplicate design collection
     * duplicate design collection
     */
    async userDesignCollectionsDuplicate(requestParameters: UserContentsApiUserDesignCollectionsDuplicateRequest): Promise<MelcoModelsDesignCollection> {
        const response = await this.userDesignCollectionsDuplicateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get design collection by id
     */
    async userDesignCollectionsGetDefRaw(requestParameters: UserContentsApiUserDesignCollectionsGetDefRequest): Promise<runtime.ApiResponse<MelcoModelsDesignCollection>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userDesignCollectionsGetDef.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/designs/collections/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsDesignCollectionFromJSON(jsonValue));
    }

    /**
     * Get design collection by id
     */
    async userDesignCollectionsGetDef(requestParameters: UserContentsApiUserDesignCollectionsGetDefRequest): Promise<MelcoModelsDesignCollection> {
        const response = await this.userDesignCollectionsGetDefRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get design collection list
     */
    async userDesignCollectionsGetListRaw(requestParameters: UserContentsApiUserDesignCollectionsGetListRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsDesignCollection>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/designs/collections`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsDesignCollectionFromJSON(jsonValue));
    }

    /**
     * Get design collection list
     */
    async userDesignCollectionsGetList(requestParameters: UserContentsApiUserDesignCollectionsGetListRequest): Promise<MelcoModelsGenericList1MelcoModelsDesignCollection> {
        const response = await this.userDesignCollectionsGetListRaw(requestParameters);
        return await response.value();
    }

    /**
     * delete design
     */
    async userDesignsDeleteRaw(requestParameters: UserContentsApiUserDesignsDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userDesignsDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.force !== undefined) {
            queryParameters['Force'] = requestParameters.force;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/designs/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete design
     */
    async userDesignsDelete(requestParameters: UserContentsApiUserDesignsDeleteRequest): Promise<void> {
        await this.userDesignsDeleteRaw(requestParameters);
    }

    /**
     * download design
     * download design
     */
    async userDesignsDownloadRaw(requestParameters: UserContentsApiUserDesignsDownloadRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userDesignsDownload.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/designs/{Id}/download`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * download design
     * download design
     */
    async userDesignsDownload(requestParameters: UserContentsApiUserDesignsDownloadRequest): Promise<Blob> {
        const response = await this.userDesignsDownloadRaw(requestParameters);
        return await response.value();
    }

    /**
     * duplicate design
     * duplicate design
     */
    async userDesignsDuplicateRaw(requestParameters: UserContentsApiUserDesignsDuplicateRequest): Promise<runtime.ApiResponse<MelcoModelsDesign>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userDesignsDuplicate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/designs/{Id}/duplicate`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsDesignFromJSON(jsonValue));
    }

    /**
     * duplicate design
     * duplicate design
     */
    async userDesignsDuplicate(requestParameters: UserContentsApiUserDesignsDuplicateRequest): Promise<MelcoModelsDesign> {
        const response = await this.userDesignsDuplicateRaw(requestParameters);
        return await response.value();
    }

    /**
     * get user design
     * get user design
     */
    async userDesignsGetDefRaw(requestParameters: UserContentsApiUserDesignsGetDefRequest): Promise<runtime.ApiResponse<MelcoModelsDesign>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userDesignsGetDef.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/designs/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsDesignFromJSON(jsonValue));
    }

    /**
     * get user design
     * get user design
     */
    async userDesignsGetDef(requestParameters: UserContentsApiUserDesignsGetDefRequest): Promise<MelcoModelsDesign> {
        const response = await this.userDesignsGetDefRaw(requestParameters);
        return await response.value();
    }

    /**
     * get design meta data
     * upload design and get meta data
     */
    async userDesignsMetadataRaw(requestParameters: UserContentsApiUserDesignsMetadataRequest): Promise<runtime.ApiResponse<MelcoModelsDesignMetadata>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/designs/metadata`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsDesignMetadataFromJSON(jsonValue));
    }

    /**
     * get design meta data
     * upload design and get meta data
     */
    async userDesignsMetadata(requestParameters: UserContentsApiUserDesignsMetadataRequest): Promise<MelcoModelsDesignMetadata> {
        const response = await this.userDesignsMetadataRaw(requestParameters);
        return await response.value();
    }

    /**
     * get design image
     * get design image
     */
    async userDesignsPreviewRaw(requestParameters: UserContentsApiUserDesignsPreviewRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userDesignsPreview.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.width !== undefined) {
            queryParameters['width'] = requestParameters.width;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/designs/{Id}/preview`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * get design image
     * get design image
     */
    async userDesignsPreview(requestParameters: UserContentsApiUserDesignsPreviewRequest): Promise<void> {
        await this.userDesignsPreviewRaw(requestParameters);
    }

    /**
     * update existing design file
     * update file for print queue design
     */
    async userDesignsPrintQueueUpdateFileRaw(requestParameters: UserContentsApiUserDesignsPrintQueueUpdateFileRequest): Promise<runtime.ApiResponse<MelcoModelsNotification>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userDesignsPrintQueueUpdateFile.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/designs/printqueue/{Id}/update`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsNotificationFromJSON(jsonValue));
    }

    /**
     * update existing design file
     * update file for print queue design
     */
    async userDesignsPrintQueueUpdateFile(requestParameters: UserContentsApiUserDesignsPrintQueueUpdateFileRequest): Promise<MelcoModelsNotification> {
        const response = await this.userDesignsPrintQueueUpdateFileRaw(requestParameters);
        return await response.value();
    }

    /**
     * upload print design
     * upload print design to queue
     */
    async userDesignsPrintQueueUploadRaw(requestParameters: UserContentsApiUserDesignsPrintQueueUploadRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/designs/printqueue/upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * upload print design
     * upload print design to queue
     */
    async userDesignsPrintQueueUpload(requestParameters: UserContentsApiUserDesignsPrintQueueUploadRequest): Promise<void> {
        await this.userDesignsPrintQueueUploadRaw(requestParameters);
    }

    /**
     * get design references or depedencies
     * get design references
     */
    async userDesignsReferencesRaw(requestParameters: UserContentsApiUserDesignsReferencesRequest): Promise<runtime.ApiResponse<MelcoModelsDesignReferences>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userDesignsReferences.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/designs/{Id}/references`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsDesignReferencesFromJSON(jsonValue));
    }

    /**
     * get design references or depedencies
     * get design references
     */
    async userDesignsReferences(requestParameters: UserContentsApiUserDesignsReferencesRequest): Promise<MelcoModelsDesignReferences> {
        const response = await this.userDesignsReferencesRaw(requestParameters);
        return await response.value();
    }

    /**
     * search user designs
     */
    async userDesignsSearchRaw(requestParameters: UserContentsApiUserDesignsSearchRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsDesignListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        if (requestParameters.service !== undefined) {
            queryParameters['service'] = requestParameters.service;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/designs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsDesignListItemFromJSON(jsonValue));
    }

    /**
     * search user designs
     */
    async userDesignsSearch(requestParameters: UserContentsApiUserDesignsSearchRequest): Promise<MelcoModelsGenericList1MelcoModelsDesignListItem> {
        const response = await this.userDesignsSearchRaw(requestParameters);
        return await response.value();
    }

    /**
     * update existing design metadata
     * update existing design metadata
     */
    async userDesignsUpdateRaw(requestParameters: UserContentsApiUserDesignsUpdateRequest): Promise<runtime.ApiResponse<MelcoModelsDesign>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userDesignsUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/designs/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsUpdateDesignToJSON(requestParameters.melcoModelsUpdateDesign),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsDesignFromJSON(jsonValue));
    }

    /**
     * update existing design metadata
     * update existing design metadata
     */
    async userDesignsUpdate(requestParameters: UserContentsApiUserDesignsUpdateRequest): Promise<MelcoModelsDesign> {
        const response = await this.userDesignsUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     * update existing design file
     * update existing design file
     */
    async userDesignsUpdateFileRaw(requestParameters: UserContentsApiUserDesignsUpdateFileRequest): Promise<runtime.ApiResponse<MelcoModelsDesign>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userDesignsUpdateFile.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/designs/{Id}/update`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsDesignFromJSON(jsonValue));
    }

    /**
     * update existing design file
     * update existing design file
     */
    async userDesignsUpdateFile(requestParameters: UserContentsApiUserDesignsUpdateFileRequest): Promise<MelcoModelsDesign> {
        const response = await this.userDesignsUpdateFileRaw(requestParameters);
        return await response.value();
    }

    /**
     * upload design
     * upload design
     */
    async userDesignsUploadRaw(requestParameters: UserContentsApiUserDesignsUploadRequest): Promise<runtime.ApiResponse<MelcoModelsDesign>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/designs/upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsDesignFromJSON(jsonValue));
    }

    /**
     * upload design
     * upload design
     */
    async userDesignsUpload(requestParameters: UserContentsApiUserDesignsUploadRequest): Promise<MelcoModelsDesign> {
        const response = await this.userDesignsUploadRaw(requestParameters);
        return await response.value();
    }

    /**
     * delete image
     */
    async userImageDeleteRaw(requestParameters: UserContentsApiUserImageDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userImageDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.force !== undefined) {
            queryParameters['Force'] = requestParameters.force;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/images/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete image
     */
    async userImageDelete(requestParameters: UserContentsApiUserImageDeleteRequest): Promise<void> {
        await this.userImageDeleteRaw(requestParameters);
    }

    /**
     * export/download image
     * download image
     */
    async userImageDownloadRaw(requestParameters: UserContentsApiUserImageDownloadRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userImageDownload.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/images/{Id}/download`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * export/download image
     * download image
     */
    async userImageDownload(requestParameters: UserContentsApiUserImageDownloadRequest): Promise<Blob> {
        const response = await this.userImageDownloadRaw(requestParameters);
        return await response.value();
    }

    /**
     * upload image
     * duplicate image
     */
    async userImageDuplicateRaw(requestParameters: UserContentsApiUserImageDuplicateRequest): Promise<runtime.ApiResponse<MelcoModelsUserImage>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userImageDuplicate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/images/{Id}/duplicate`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsUserImageFromJSON(jsonValue));
    }

    /**
     * upload image
     * duplicate image
     */
    async userImageDuplicate(requestParameters: UserContentsApiUserImageDuplicateRequest): Promise<MelcoModelsUserImage> {
        const response = await this.userImageDuplicateRaw(requestParameters);
        return await response.value();
    }

    /**
     * get user image
     * get user image
     */
    async userImageGetDefRaw(requestParameters: UserContentsApiUserImageGetDefRequest): Promise<runtime.ApiResponse<MelcoModelsUserImage>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userImageGetDef.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/images/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsUserImageFromJSON(jsonValue));
    }

    /**
     * get user image
     * get user image
     */
    async userImageGetDef(requestParameters: UserContentsApiUserImageGetDefRequest): Promise<MelcoModelsUserImage> {
        const response = await this.userImageGetDefRaw(requestParameters);
        return await response.value();
    }

    /**
     * get image
     * get image
     */
    async userImagePreviewRaw(requestParameters: UserContentsApiUserImagePreviewRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userImagePreview.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.width !== undefined) {
            queryParameters['width'] = requestParameters.width;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/images/{Id}/preview`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * get image
     * get image
     */
    async userImagePreview(requestParameters: UserContentsApiUserImagePreviewRequest): Promise<void> {
        await this.userImagePreviewRaw(requestParameters);
    }

    /**
     * get image references or depedencies
     * get image references
     */
    async userImageReferencesRaw(requestParameters: UserContentsApiUserImageReferencesRequest): Promise<runtime.ApiResponse<MelcoModelsImageReferences>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userImageReferences.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/images/{Id}/references`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsImageReferencesFromJSON(jsonValue));
    }

    /**
     * get image references or depedencies
     * get image references
     */
    async userImageReferences(requestParameters: UserContentsApiUserImageReferencesRequest): Promise<MelcoModelsImageReferences> {
        const response = await this.userImageReferencesRaw(requestParameters);
        return await response.value();
    }

    /**
     * search user images
     */
    async userImageSearchRaw(requestParameters: UserContentsApiUserImageSearchRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsUserImage>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/images`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsUserImageFromJSON(jsonValue));
    }

    /**
     * search user images
     */
    async userImageSearch(requestParameters: UserContentsApiUserImageSearchRequest): Promise<MelcoModelsGenericList1MelcoModelsUserImage> {
        const response = await this.userImageSearchRaw(requestParameters);
        return await response.value();
    }

    /**
     * update existing image
     * update existing image
     */
    async userImageUpdateRaw(requestParameters: UserContentsApiUserImageUpdateRequest): Promise<runtime.ApiResponse<MelcoModelsUserImage>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userImageUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.name !== undefined) {
            formParams.append('name', requestParameters.name as any);
        }

        if (requestParameters.viewName !== undefined) {
            formParams.append('view_name', requestParameters.viewName as any);
        }

        if (requestParameters.colorName !== undefined) {
            formParams.append('color_name', requestParameters.colorName as any);
        }

        if (requestParameters.colorHex !== undefined) {
            formParams.append('color_hex', requestParameters.colorHex as any);
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/images/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsUserImageFromJSON(jsonValue));
    }

    /**
     * update existing image
     * update existing image
     */
    async userImageUpdate(requestParameters: UserContentsApiUserImageUpdateRequest): Promise<MelcoModelsUserImage> {
        const response = await this.userImageUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     * upload image
     * upload image (jpg,jpeg and png)
     */
    async userImageUploadRaw(requestParameters: UserContentsApiUserImageUploadRequest): Promise<runtime.ApiResponse<MelcoModelsUserImage>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.name !== undefined) {
            formParams.append('name', requestParameters.name as any);
        }

        if (requestParameters.viewName !== undefined) {
            formParams.append('view_name', requestParameters.viewName as any);
        }

        if (requestParameters.colorName !== undefined) {
            formParams.append('color_name', requestParameters.colorName as any);
        }

        if (requestParameters.colorHex !== undefined) {
            formParams.append('color_hex', requestParameters.colorHex as any);
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/images/upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsUserImageFromJSON(jsonValue));
    }

    /**
     * upload image
     * upload image (jpg,jpeg and png)
     */
    async userImageUpload(requestParameters: UserContentsApiUserImageUploadRequest): Promise<MelcoModelsUserImage> {
        const response = await this.userImageUploadRaw(requestParameters);
        return await response.value();
    }

}
