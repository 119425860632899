import { useCallback } from "react";
import {
  FusionMelcoFusionModelsCustomProductView,
  MelcoFusionModelsUserBlankListItem,
} from "melco-shared-logic";
import { useField } from "formik";
import { useFixProductSchema } from "./useFixProductSchema";

export const useUpdateViewListWhenBlankChanges = () => {
  const [{ value: viewList }, , { setValue: setViewList }] = useField<
    FusionMelcoFusionModelsCustomProductView[] | undefined
  >("view_list");
  const [fixProductSchema, isLoading] = useFixProductSchema();

  const changeBlank = useCallback(
    async (blank: MelcoFusionModelsUserBlankListItem) => {
      const result = await fixProductSchema(blank, viewList);

      setViewList(result?.view_list ?? []);

      return result;
    },
    [fixProductSchema, viewList, setViewList]
  );

  return [changeBlank, isLoading] as const;
};
