/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoModelsDesignListItem,
    MelcoModelsDesignListItemFromJSON,
    MelcoModelsDesignListItemFromJSONTyped,
    MelcoModelsDesignListItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoModelsUpdateDesignCollection
 */
export interface MelcoModelsUpdateDesignCollection {
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUpdateDesignCollection
     */
    name?: string | null;
    /**
     * 
     * @type {Array<MelcoModelsDesignListItem>}
     * @memberof MelcoModelsUpdateDesignCollection
     */
    design_list?: Array<MelcoModelsDesignListItem> | null;
}

export function MelcoModelsUpdateDesignCollectionFromJSON(json: any): MelcoModelsUpdateDesignCollection {
    return MelcoModelsUpdateDesignCollectionFromJSONTyped(json, false);
}

export function MelcoModelsUpdateDesignCollectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsUpdateDesignCollection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'design_list': !exists(json, 'design_list') ? undefined : (json['design_list'] === null ? null : (json['design_list'] as Array<any>).map(MelcoModelsDesignListItemFromJSON)),
    };
}

export function MelcoModelsUpdateDesignCollectionToJSON(value?: MelcoModelsUpdateDesignCollection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'design_list': value.design_list === undefined ? undefined : (value.design_list === null ? null : (value.design_list as Array<any>).map(MelcoModelsDesignListItemToJSON)),
    };
}


