/* tslint:disable */
/* eslint-disable */
/**
 * Melco Efs APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EfsMelcoConstantsFulfillmentClientRunStatus,
    EfsMelcoConstantsFulfillmentClientRunStatusFromJSON,
    EfsMelcoConstantsFulfillmentClientRunStatusFromJSONTyped,
    EfsMelcoConstantsFulfillmentClientRunStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface EfsMelcoEfsModelsRunStatus
 */
export interface EfsMelcoEfsModelsRunStatus {
    /**
     * 
     * @type {Date}
     * @memberof EfsMelcoEfsModelsRunStatus
     */
    start_timestamp?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EfsMelcoEfsModelsRunStatus
     */
    end_timestamp?: Date;
    /**
     * 
     * @type {EfsMelcoConstantsFulfillmentClientRunStatus}
     * @memberof EfsMelcoEfsModelsRunStatus
     */
    status?: EfsMelcoConstantsFulfillmentClientRunStatus;
    /**
     * 
     * @type {number}
     * @memberof EfsMelcoEfsModelsRunStatus
     */
    files?: number;
}

export function EfsMelcoEfsModelsRunStatusFromJSON(json: any): EfsMelcoEfsModelsRunStatus {
    return EfsMelcoEfsModelsRunStatusFromJSONTyped(json, false);
}

export function EfsMelcoEfsModelsRunStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): EfsMelcoEfsModelsRunStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'start_timestamp': !exists(json, 'start_timestamp') ? undefined : (new Date(json['start_timestamp'])),
        'end_timestamp': !exists(json, 'end_timestamp') ? undefined : (new Date(json['end_timestamp'])),
        'status': !exists(json, 'status') ? undefined : EfsMelcoConstantsFulfillmentClientRunStatusFromJSON(json['status']),
        'files': !exists(json, 'files') ? undefined : json['files'],
    };
}

export function EfsMelcoEfsModelsRunStatusToJSON(value?: EfsMelcoEfsModelsRunStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'start_timestamp': value.start_timestamp === undefined ? undefined : (value.start_timestamp.toISOString()),
        'end_timestamp': value.end_timestamp === undefined ? undefined : (value.end_timestamp.toISOString()),
        'status': EfsMelcoConstantsFulfillmentClientRunStatusToJSON(value.status),
        'files': value.files,
    };
}


