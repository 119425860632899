import { useCallback } from "react";
import { useField } from "formik";
import { FusionMelcoModelsColorListItem } from "melco-shared-logic";
import { moveArray } from "../../../helper/moveArray";

export const useColorsSorting = () => {
  const [{ value: colors }, , { setValue: setColors, setError }] =
    useField<FusionMelcoModelsColorListItem[]>("color_list");

  const reorderColors = useCallback(
    (fromIndex: number, toIndex: number) => {
      setColors(moveArray(colors, fromIndex, toIndex));

      // reset field error as sorting changes the index positions which could lead to errors being assigned to the wrong field
      setError(undefined);
    },
    [colors, setColors, setError]
  );

  const colorsWithKey = (colors ?? []).map((c) => ({ ...c, key: c.id! }));

  return [colorsWithKey, reorderColors] as const;
};
