/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoModelsSupportStatus
 */
export interface MelcoModelsSupportStatus {
    /**
     * 
     * @type {Date}
     * @memberof MelcoModelsSupportStatus
     */
    activated_on?: Date;
    /**
     * 
     * @type {number}
     * @memberof MelcoModelsSupportStatus
     */
    minutes?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoModelsSupportStatus
     */
    is_active?: boolean;
}

export function MelcoModelsSupportStatusFromJSON(json: any): MelcoModelsSupportStatus {
    return MelcoModelsSupportStatusFromJSONTyped(json, false);
}

export function MelcoModelsSupportStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsSupportStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activated_on': !exists(json, 'activated_on') ? undefined : (new Date(json['activated_on'])),
        'minutes': !exists(json, 'minutes') ? undefined : json['minutes'],
        'is_active': !exists(json, 'is_active') ? undefined : json['is_active'],
    };
}

export function MelcoModelsSupportStatusToJSON(value?: MelcoModelsSupportStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'activated_on': value.activated_on === undefined ? undefined : (value.activated_on.toISOString()),
        'minutes': value.minutes,
        'is_active': value.is_active,
    };
}


