/* tslint:disable */
/* eslint-disable */
/**
 * Melco Reporting APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportingMelcoMachineStatisticsModelsViewerLink
 */
export interface ReportingMelcoMachineStatisticsModelsViewerLink {
    /**
     * 
     * @type {number}
     * @memberof ReportingMelcoMachineStatisticsModelsViewerLink
     */
    days?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportingMelcoMachineStatisticsModelsViewerLink
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportingMelcoMachineStatisticsModelsViewerLink
     */
    link?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ReportingMelcoMachineStatisticsModelsViewerLink
     */
    expires_on?: Date | null;
}

export function ReportingMelcoMachineStatisticsModelsViewerLinkFromJSON(json: any): ReportingMelcoMachineStatisticsModelsViewerLink {
    return ReportingMelcoMachineStatisticsModelsViewerLinkFromJSONTyped(json, false);
}

export function ReportingMelcoMachineStatisticsModelsViewerLinkFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportingMelcoMachineStatisticsModelsViewerLink {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'days': !exists(json, 'days') ? undefined : json['days'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'link': !exists(json, 'link') ? undefined : json['link'],
        'expires_on': !exists(json, 'expires_on') ? undefined : (json['expires_on'] === null ? null : new Date(json['expires_on'])),
    };
}

export function ReportingMelcoMachineStatisticsModelsViewerLinkToJSON(value?: ReportingMelcoMachineStatisticsModelsViewerLink | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'days': value.days,
        'password': value.password,
        'link': value.link,
        'expires_on': value.expires_on === undefined ? undefined : (value.expires_on === null ? null : value.expires_on.toISOString()),
    };
}


