/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoModelsProductLevelFeature,
    MelcoModelsProductLevelFeatureFromJSON,
    MelcoModelsProductLevelFeatureFromJSONTyped,
    MelcoModelsProductLevelFeatureToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoModelsUserProductLevel
 */
export interface MelcoModelsUserProductLevel {
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserProductLevel
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserProductLevel
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserProductLevel
     */
    short_code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserProductLevel
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserProductLevel
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoModelsUserProductLevel
     */
    is_trial_available?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MelcoModelsUserProductLevel
     */
    trial_days?: number;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserProductLevel
     */
    buy_url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserProductLevel
     */
    details_url?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoModelsUserProductLevel
     */
    is_saleable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoModelsUserProductLevel
     */
    is_virtually_secured?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserProductLevel
     */
    serial_number?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MelcoModelsUserProductLevel
     */
    activated_on?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoModelsUserProductLevel
     */
    seconds_to_expire_utc?: number;
    /**
     * 
     * @type {Array<MelcoModelsProductLevelFeature>}
     * @memberof MelcoModelsUserProductLevel
     */
    product_level_feature?: Array<MelcoModelsProductLevelFeature> | null;
}

export function MelcoModelsUserProductLevelFromJSON(json: any): MelcoModelsUserProductLevel {
    return MelcoModelsUserProductLevelFromJSONTyped(json, false);
}

export function MelcoModelsUserProductLevelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsUserProductLevel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'short_code': !exists(json, 'short_code') ? undefined : json['short_code'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'is_trial_available': !exists(json, 'is_trial_available') ? undefined : json['is_trial_available'],
        'trial_days': !exists(json, 'trial_days') ? undefined : json['trial_days'],
        'buy_url': !exists(json, 'buy_url') ? undefined : json['buy_url'],
        'details_url': !exists(json, 'details_url') ? undefined : json['details_url'],
        'is_saleable': !exists(json, 'is_saleable') ? undefined : json['is_saleable'],
        'is_virtually_secured': !exists(json, 'is_virtually_secured') ? undefined : json['is_virtually_secured'],
        'serial_number': !exists(json, 'serial_number') ? undefined : json['serial_number'],
        'activated_on': !exists(json, 'activated_on') ? undefined : (json['activated_on'] === null ? null : new Date(json['activated_on'])),
        'seconds_to_expire_utc': !exists(json, 'seconds_to_expire_utc') ? undefined : json['seconds_to_expire_utc'],
        'product_level_feature': !exists(json, 'product_level_feature') ? undefined : (json['product_level_feature'] === null ? null : (json['product_level_feature'] as Array<any>).map(MelcoModelsProductLevelFeatureFromJSON)),
    };
}

export function MelcoModelsUserProductLevelToJSON(value?: MelcoModelsUserProductLevel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'code': value.code,
        'short_code': value.short_code,
        'name': value.name,
        'description': value.description,
        'is_trial_available': value.is_trial_available,
        'trial_days': value.trial_days,
        'buy_url': value.buy_url,
        'details_url': value.details_url,
        'is_saleable': value.is_saleable,
        'is_virtually_secured': value.is_virtually_secured,
        'serial_number': value.serial_number,
        'activated_on': value.activated_on === undefined ? undefined : (value.activated_on === null ? null : value.activated_on.toISOString()),
        'seconds_to_expire_utc': value.seconds_to_expire_utc,
        'product_level_feature': value.product_level_feature === undefined ? undefined : (value.product_level_feature === null ? null : (value.product_level_feature as Array<any>).map(MelcoModelsProductLevelFeatureToJSON)),
    };
}


