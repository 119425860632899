import {
  DisplayFormErrors,
  InputField,
  FusionMelcoFusionModelsCustomProduct,
  FusionMelcoFusionModelsUserBlank,
  VerticalForm,
  DebugForm,
  useDrawerSelectField,
  useDrawer,
} from "melco-shared-logic";
import { useTranslation } from "react-i18next";
import { QueryObserverResult } from "react-query";
import { FullWidthSpace, Row, Col, DesignTokens } from "melco-ui";
import { SavePublishHeader } from "../../header/SavePublishHeader";
import { useRevertProduct } from "../../../hooks/customization/products/useRevertProduct";
import { ChooseBlankDrawer } from "./ChooseBlankDrawer";
import { DrawerSelectField } from "../../form/DrawerSelectField";
import { DisplayBlankDrawerSelectValue } from "./DisplayBlankDrawerSelectValue";
import { ProductConfiguration } from "./ProductConfiguration";
import { ConfiguratorVersionSetting } from "./ConfiguratorVersionSetting";

const { spacings } = DesignTokens;

type DisplayProductDetailFormProps = {
  product: FusionMelcoFusionModelsCustomProduct;
  refetch: () => Promise<
    QueryObserverResult<FusionMelcoFusionModelsCustomProduct, unknown>
  >;
};

export const DisplayProductDetailForm: React.FC<
  DisplayProductDetailFormProps
> = ({ product, refetch }) => {
  const {
    id,
    publish_state: publishState,
    created_date: createdDate,
    updated_date: updatedDate,
    publish_date: publishDate,
    warning_list: warningList,
  } = product;

  const { t } = useTranslation();
  const [revertProduct, isLoading, revertKey] = useRevertProduct(id!, refetch);

  const blankField = useDrawerSelectField<
    FusionMelcoFusionModelsUserBlank | undefined
  >("blank", t("customization.products.detail.form.label.blank"));
  const drawer = useDrawer([blankField]);

  return (
    <>
      <SavePublishHeader
        revertAction={revertProduct}
        isCurrentlyReverting={isLoading}
        publishedState={publishState!}
        createdDate={createdDate!}
        updatedDate={updatedDate}
        publishedDate={publishDate}
        warnings={warningList ?? undefined}
      />

      <VerticalForm>
        <FullWidthSpace direction="vertical">
          <div>
            <DisplayFormErrors />

            <Row gutter={[spacings.middle, 0]}>
              <Col xs={24} md={12}>
                <InputField
                  name="name"
                  label={t("customization.products.detail.form.label.name")}
                />
              </Col>
              <Col xs={24} md={12}>
                <InputField
                  name="token"
                  label={t("customization.products.detail.form.label.token")}
                />
              </Col>
            </Row>

            <Row gutter={[spacings.middle, 0]}>
              <Col xs={24} md={12}>
                <DrawerSelectField
                  type="choose"
                  field={blankField}
                  drawer={drawer}
                  render={(blank) =>
                    blank ? (
                      <DisplayBlankDrawerSelectValue blank={blank} />
                    ) : null
                  }
                />
              </Col>
              <Col xs={24} md={12}>
                <ConfiguratorVersionSetting />
              </Col>
            </Row>

            <ProductConfiguration key={revertKey} product={product} />
          </div>
        </FullWidthSpace>

        <ChooseBlankDrawer drawer={drawer} />
      </VerticalForm>

      {false && <DebugForm />}
    </>
  );
};
