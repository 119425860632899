/* tslint:disable */
/* eslint-disable */
/**
 * Melco Efs APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EfsMelcoEfsModelsRunListItem
 */
export interface EfsMelcoEfsModelsRunListItem {
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoEfsModelsRunListItem
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoEfsModelsRunListItem
     */
    computer_name?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof EfsMelcoEfsModelsRunListItem
     */
    start_time?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EfsMelcoEfsModelsRunListItem
     */
    end_time?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EfsMelcoEfsModelsRunListItem
     */
    client_start_time?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EfsMelcoEfsModelsRunListItem
     */
    client_end_time?: Date;
    /**
     * 
     * @type {number}
     * @memberof EfsMelcoEfsModelsRunListItem
     */
    jobs?: number;
    /**
     * 
     * @type {number}
     * @memberof EfsMelcoEfsModelsRunListItem
     */
    files?: number;
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoEfsModelsRunListItem
     */
    status?: string | null;
}

export function EfsMelcoEfsModelsRunListItemFromJSON(json: any): EfsMelcoEfsModelsRunListItem {
    return EfsMelcoEfsModelsRunListItemFromJSONTyped(json, false);
}

export function EfsMelcoEfsModelsRunListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): EfsMelcoEfsModelsRunListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'computer_name': !exists(json, 'computer_name') ? undefined : json['computer_name'],
        'start_time': !exists(json, 'start_time') ? undefined : (new Date(json['start_time'])),
        'end_time': !exists(json, 'end_time') ? undefined : (new Date(json['end_time'])),
        'client_start_time': !exists(json, 'client_start_time') ? undefined : (new Date(json['client_start_time'])),
        'client_end_time': !exists(json, 'client_end_time') ? undefined : (new Date(json['client_end_time'])),
        'jobs': !exists(json, 'jobs') ? undefined : json['jobs'],
        'files': !exists(json, 'files') ? undefined : json['files'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function EfsMelcoEfsModelsRunListItemToJSON(value?: EfsMelcoEfsModelsRunListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'computer_name': value.computer_name,
        'start_time': value.start_time === undefined ? undefined : (value.start_time.toISOString()),
        'end_time': value.end_time === undefined ? undefined : (value.end_time.toISOString()),
        'client_start_time': value.client_start_time === undefined ? undefined : (value.client_start_time.toISOString()),
        'client_end_time': value.client_end_time === undefined ? undefined : (value.client_end_time.toISOString()),
        'jobs': value.jobs,
        'files': value.files,
        'status': value.status,
    };
}


