import { useFormikContext } from "formik";
import {
  FusionMelcoModelsDesign,
  useAPIAction,
  UserContentsDraftPublishApi,
} from "melco-shared-logic";
import { QueryObserverResult } from "react-query";
import { designToInitialValues } from "../../../components/libraries/designs/DesignDetailForm";

export const useDesignUpdate = (
  designId: string,
  refetch: () => Promise<QueryObserverResult<FusionMelcoModelsDesign, unknown>>
) => {
  const { resetForm } = useFormikContext();

  const [updateDesign, isLoading] = useAPIAction(
    UserContentsDraftPublishApi,
    async (api, file: Blob) => {
      const data = await api.userDesignsUpdateDraftFile({
        id: designId,
        file,
      });

      return {
        successMessages: [
          {
            message: "default",
          },
        ],
        data,
      };
    },
    {
      translationPrefix: "designs.actions.update",
      onSuccess: async () => {
        const refetchResult = await refetch();

        resetForm({
          values: refetchResult.data
            ? designToInitialValues(refetchResult.data)
            : {},
        });
      },
    }
  );

  return {
    updateDesign,
    isLoading,
  } as const;
};
