/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoFusionModelsUserBlankListItem,
    MelcoFusionModelsUserBlankListItemFromJSON,
    MelcoFusionModelsUserBlankListItemFromJSONTyped,
    MelcoFusionModelsUserBlankListItemToJSON,
    MelcoModelsCustomWarning,
    MelcoModelsCustomWarningFromJSON,
    MelcoModelsCustomWarningFromJSONTyped,
    MelcoModelsCustomWarningToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoModelsGenericList1MelcoFusionModelsUserBlankListItem
 */
export interface MelcoModelsGenericList1MelcoFusionModelsUserBlankListItem {
    /**
     * 
     * @type {Array<MelcoFusionModelsUserBlankListItem>}
     * @memberof MelcoModelsGenericList1MelcoFusionModelsUserBlankListItem
     */
    list: Array<MelcoFusionModelsUserBlankListItem>;
    /**
     * 
     * @type {Array<MelcoModelsCustomWarning>}
     * @memberof MelcoModelsGenericList1MelcoFusionModelsUserBlankListItem
     */
    warning_list?: Array<MelcoModelsCustomWarning> | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoModelsGenericList1MelcoFusionModelsUserBlankListItem
     */
    total: number;
}

export function MelcoModelsGenericList1MelcoFusionModelsUserBlankListItemFromJSON(json: any): MelcoModelsGenericList1MelcoFusionModelsUserBlankListItem {
    return MelcoModelsGenericList1MelcoFusionModelsUserBlankListItemFromJSONTyped(json, false);
}

export function MelcoModelsGenericList1MelcoFusionModelsUserBlankListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsGenericList1MelcoFusionModelsUserBlankListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'list': ((json['list'] as Array<any>).map(MelcoFusionModelsUserBlankListItemFromJSON)),
        'warning_list': !exists(json, 'warning_list') ? undefined : (json['warning_list'] === null ? null : (json['warning_list'] as Array<any>).map(MelcoModelsCustomWarningFromJSON)),
        'total': json['total'],
    };
}

export function MelcoModelsGenericList1MelcoFusionModelsUserBlankListItemToJSON(value?: MelcoModelsGenericList1MelcoFusionModelsUserBlankListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'list': ((value.list as Array<any>).map(MelcoFusionModelsUserBlankListItemToJSON)),
        'warning_list': value.warning_list === undefined ? undefined : (value.warning_list === null ? null : (value.warning_list as Array<any>).map(MelcoModelsCustomWarningToJSON)),
        'total': value.total,
    };
}


