/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoModelsReferences,
    MelcoModelsReferencesFromJSON,
    MelcoModelsReferencesFromJSONTyped,
    MelcoModelsReferencesToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoModelsAlphabetReferences
 */
export interface MelcoModelsAlphabetReferences {
    /**
     * 
     * @type {Array<MelcoModelsReferences>}
     * @memberof MelcoModelsAlphabetReferences
     */
    design_list?: Array<MelcoModelsReferences> | null;
    /**
     * 
     * @type {Array<MelcoModelsReferences>}
     * @memberof MelcoModelsAlphabetReferences
     */
    custom_product_list?: Array<MelcoModelsReferences> | null;
    /**
     * 
     * @type {Array<MelcoModelsReferences>}
     * @memberof MelcoModelsAlphabetReferences
     */
    alphabet_collection_list?: Array<MelcoModelsReferences> | null;
}

export function MelcoModelsAlphabetReferencesFromJSON(json: any): MelcoModelsAlphabetReferences {
    return MelcoModelsAlphabetReferencesFromJSONTyped(json, false);
}

export function MelcoModelsAlphabetReferencesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsAlphabetReferences {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'design_list': !exists(json, 'design_list') ? undefined : (json['design_list'] === null ? null : (json['design_list'] as Array<any>).map(MelcoModelsReferencesFromJSON)),
        'custom_product_list': !exists(json, 'custom_product_list') ? undefined : (json['custom_product_list'] === null ? null : (json['custom_product_list'] as Array<any>).map(MelcoModelsReferencesFromJSON)),
        'alphabet_collection_list': !exists(json, 'alphabet_collection_list') ? undefined : (json['alphabet_collection_list'] === null ? null : (json['alphabet_collection_list'] as Array<any>).map(MelcoModelsReferencesFromJSON)),
    };
}

export function MelcoModelsAlphabetReferencesToJSON(value?: MelcoModelsAlphabetReferences | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'design_list': value.design_list === undefined ? undefined : (value.design_list === null ? null : (value.design_list as Array<any>).map(MelcoModelsReferencesToJSON)),
        'custom_product_list': value.custom_product_list === undefined ? undefined : (value.custom_product_list === null ? null : (value.custom_product_list as Array<any>).map(MelcoModelsReferencesToJSON)),
        'alphabet_collection_list': value.alphabet_collection_list === undefined ? undefined : (value.alphabet_collection_list === null ? null : (value.alphabet_collection_list as Array<any>).map(MelcoModelsReferencesToJSON)),
    };
}


