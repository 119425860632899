import {
  ColorInputField,
  DisplayFormErrors,
  FileUploadField,
  InputField,
  FusionMelcoModelsUserImage,
  VerticalForm,
} from "melco-shared-logic";
import { useTranslation } from "react-i18next";
import { QueryObserverResult } from "react-query";
import { SavePublishHeader } from "../../../header/SavePublishHeader";
import { useRevertBlankPicture } from "../../../../hooks/libraries/blanks/blankPictures/useRevertBlankPicture";
import {
  Col,
  DesignTokens,
  FullWidthSpace,
  Row,
  PreviewSider,
  Title,
} from "melco-ui";
import styled from "styled-components";
import { useBlankPictureThumbnailURL } from "../../../../hooks/libraries/blanks/blankPictures/useBlankPictureThumbnailURL";
import { Drawer as DrawerType } from "../../../../hooks/drawer/useGenericDrawer";
import { useBlankPictureReferencesCount } from "../../../../hooks/libraries/blanks/blankPictures/useBlankPictureReferencesCount";

const { spacings } = DesignTokens;

const PreviewImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const PreviewImage = styled.img`
  max-width: 100%;
`;

type DisplayBlankPictureDetailFormProps = {
  blankPicture: FusionMelcoModelsUserImage;
  refetch: () => Promise<
    QueryObserverResult<FusionMelcoModelsUserImage, unknown>
  >;
  referencesDrawer: DrawerType;
};

export const DisplayBlankPictureDetailForm: React.FC<
  DisplayBlankPictureDetailFormProps
> = ({ blankPicture, refetch, referencesDrawer }) => {
  const {
    id,
    publish_state: publishState,
    created_date: createdDate,
    updated_date: updatedDate,
    publish_date: publishDate,
    warning_list: warningList,
  } = blankPicture;

  const { t } = useTranslation();
  const [revertBlankPicture, isLoading] = useRevertBlankPicture(id!, refetch);
  const blankPicturePreviewSrc = useBlankPictureThumbnailURL(
    blankPicture,
    800,
    800
  );

  const { total: referencesCount } = useBlankPictureReferencesCount(id!);

  return (
    <>
      <SavePublishHeader
        revertAction={revertBlankPicture}
        isCurrentlyReverting={isLoading}
        publishedState={publishState!}
        createdDate={createdDate!}
        updatedDate={updatedDate}
        publishedDate={publishDate}
        warnings={warningList ?? undefined}
        referencesCount={referencesCount}
        referencesDrawer={referencesDrawer}
      />

      <Row gutter={[spacings.xlarge, spacings.xlarge]}>
        <Col xs={16}>
          <VerticalForm>
            <DisplayFormErrors />

            <InputField
              name="name"
              label={t(
                "libraries.blanks.blank_pictures.detail.form.label.name"
              )}
            />

            <FileUploadField
              name="file_name"
              label={t(
                "libraries.blanks.blank_pictures.detail.form.label.file_name"
              )}
              maxFiles={1}
            />

            <Row gutter={[spacings.middle, 0]}>
              <Col xs={24} md={12}>
                <ColorInputField
                  name="color_hex"
                  label={t(
                    "libraries.blanks.blank_pictures.detail.form.label.color_hex"
                  )}
                />
              </Col>
              <Col xs={24} md={12}>
                <InputField
                  name="color_name"
                  label={t(
                    "libraries.blanks.blank_pictures.detail.form.label.color_name"
                  )}
                />
              </Col>
            </Row>

            <InputField
              name="view_name"
              label={t(
                "libraries.blanks.blank_pictures.detail.form.label.view_name"
              )}
            />
          </VerticalForm>
        </Col>
        <Col xs={8}>
          <PreviewSider>
            <FullWidthSpace direction="vertical" size="small">
              <Title level={5}>
                {t(
                  "libraries.blanks.blank_pictures.detail.preview_sider.title"
                )}
              </Title>
              <PreviewImageWrapper>
                {blankPicturePreviewSrc && (
                  <PreviewImage
                    src={blankPicturePreviewSrc}
                    data-cy-id="blank-picture-preview-image"
                  />
                )}
              </PreviewImageWrapper>
            </FullWidthSpace>
          </PreviewSider>
        </Col>
      </Row>
    </>
  );
};
