/* tslint:disable */
/* eslint-disable */
/**
 * Melco Efs APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EfsMelcoModelsColorCollection,
    EfsMelcoModelsColorCollectionFromJSON,
    EfsMelcoModelsColorCollectionFromJSONTyped,
    EfsMelcoModelsColorCollectionToJSON,
    EfsMelcoModelsDesignElementColor,
    EfsMelcoModelsDesignElementColorFromJSON,
    EfsMelcoModelsDesignElementColorFromJSONTyped,
    EfsMelcoModelsDesignElementColorToJSON,
    EfsMelcoModelsDesignLetteringElement,
    EfsMelcoModelsDesignLetteringElementFromJSON,
    EfsMelcoModelsDesignLetteringElementFromJSONTyped,
    EfsMelcoModelsDesignLetteringElementToJSON,
} from './';

/**
 * 
 * @export
 * @interface EfsMelcoModelsDesignElement
 */
export interface EfsMelcoModelsDesignElement {
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoModelsDesignElement
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoModelsDesignElement
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoModelsDesignElement
     */
    type?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EfsMelcoModelsDesignElement
     */
    editable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EfsMelcoModelsDesignElement
     */
    is_required?: boolean;
    /**
     * 
     * @type {EfsMelcoModelsDesignElementColor}
     * @memberof EfsMelcoModelsDesignElement
     */
    color?: EfsMelcoModelsDesignElementColor;
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoModelsDesignElement
     */
    color_collection_id?: string | null;
    /**
     * 
     * @type {EfsMelcoModelsColorCollection}
     * @memberof EfsMelcoModelsDesignElement
     */
    color_collection?: EfsMelcoModelsColorCollection;
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoModelsDesignElement
     */
    default_color_id?: string | null;
    /**
     * 
     * @type {EfsMelcoModelsDesignLetteringElement}
     * @memberof EfsMelcoModelsDesignElement
     */
    lettering?: EfsMelcoModelsDesignLetteringElement;
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoModelsDesignElement
     */
    active_color_group_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoModelsDesignElement
     */
    lettering_id?: string | null;
}

export function EfsMelcoModelsDesignElementFromJSON(json: any): EfsMelcoModelsDesignElement {
    return EfsMelcoModelsDesignElementFromJSONTyped(json, false);
}

export function EfsMelcoModelsDesignElementFromJSONTyped(json: any, ignoreDiscriminator: boolean): EfsMelcoModelsDesignElement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'editable': !exists(json, 'editable') ? undefined : json['editable'],
        'is_required': !exists(json, 'is_required') ? undefined : json['is_required'],
        'color': !exists(json, 'color') ? undefined : EfsMelcoModelsDesignElementColorFromJSON(json['color']),
        'color_collection_id': !exists(json, 'color_collection_id') ? undefined : json['color_collection_id'],
        'color_collection': !exists(json, 'color_collection') ? undefined : EfsMelcoModelsColorCollectionFromJSON(json['color_collection']),
        'default_color_id': !exists(json, 'default_color_id') ? undefined : json['default_color_id'],
        'lettering': !exists(json, 'lettering') ? undefined : EfsMelcoModelsDesignLetteringElementFromJSON(json['lettering']),
        'active_color_group_id': !exists(json, 'active_color_group_id') ? undefined : json['active_color_group_id'],
        'lettering_id': !exists(json, 'lettering_id') ? undefined : json['lettering_id'],
    };
}

export function EfsMelcoModelsDesignElementToJSON(value?: EfsMelcoModelsDesignElement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'type': value.type,
        'editable': value.editable,
        'is_required': value.is_required,
        'color': EfsMelcoModelsDesignElementColorToJSON(value.color),
        'color_collection_id': value.color_collection_id,
        'color_collection': EfsMelcoModelsColorCollectionToJSON(value.color_collection),
        'default_color_id': value.default_color_id,
        'lettering': EfsMelcoModelsDesignLetteringElementToJSON(value.lettering),
        'active_color_group_id': value.active_color_group_id,
        'lettering_id': value.lettering_id,
    };
}


