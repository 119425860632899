/* tslint:disable */
/* eslint-disable */
/**
 * Melco Reporting APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReportingMelcoMachineStatisticsModelsViewGroups,
    ReportingMelcoMachineStatisticsModelsViewGroupsFromJSON,
    ReportingMelcoMachineStatisticsModelsViewGroupsFromJSONTyped,
    ReportingMelcoMachineStatisticsModelsViewGroupsToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReportingMelcoMachineStatisticsModelsCreateView
 */
export interface ReportingMelcoMachineStatisticsModelsCreateView {
    /**
     * 
     * @type {string}
     * @memberof ReportingMelcoMachineStatisticsModelsCreateView
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReportingMelcoMachineStatisticsModelsCreateView
     */
    grouped_display?: boolean;
    /**
     * 
     * @type {Array<ReportingMelcoMachineStatisticsModelsViewGroups>}
     * @memberof ReportingMelcoMachineStatisticsModelsCreateView
     */
    group_list?: Array<ReportingMelcoMachineStatisticsModelsViewGroups> | null;
}

export function ReportingMelcoMachineStatisticsModelsCreateViewFromJSON(json: any): ReportingMelcoMachineStatisticsModelsCreateView {
    return ReportingMelcoMachineStatisticsModelsCreateViewFromJSONTyped(json, false);
}

export function ReportingMelcoMachineStatisticsModelsCreateViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportingMelcoMachineStatisticsModelsCreateView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'grouped_display': !exists(json, 'grouped_display') ? undefined : json['grouped_display'],
        'group_list': !exists(json, 'group_list') ? undefined : (json['group_list'] === null ? null : (json['group_list'] as Array<any>).map(ReportingMelcoMachineStatisticsModelsViewGroupsFromJSON)),
    };
}

export function ReportingMelcoMachineStatisticsModelsCreateViewToJSON(value?: ReportingMelcoMachineStatisticsModelsCreateView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'grouped_display': value.grouped_display,
        'group_list': value.group_list === undefined ? undefined : (value.group_list === null ? null : (value.group_list as Array<any>).map(ReportingMelcoMachineStatisticsModelsViewGroupsToJSON)),
    };
}


