/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoModelsDeviceInfo
 */
export interface MelcoModelsDeviceInfo {
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsDeviceInfo
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsDeviceInfo
     */
    serial_number: string;
}

export function MelcoModelsDeviceInfoFromJSON(json: any): MelcoModelsDeviceInfo {
    return MelcoModelsDeviceInfoFromJSONTyped(json, false);
}

export function MelcoModelsDeviceInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsDeviceInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'serial_number': json['serial_number'],
    };
}

export function MelcoModelsDeviceInfoToJSON(value?: MelcoModelsDeviceInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'serial_number': value.serial_number,
    };
}


