import { useEffect, useState } from "react";
import { useAuthenticatedAPIConfig } from "./useAPIConfig";
import { initialResponse, successfulResponse, APIState } from "../shared";
import {
  APIError,
  useErrorMessageMapping,
} from "../error/useErrorMessageMapping";
import { useErrorNotification } from "../error/useErrorNotification";
import { BaseAPI, Configuration } from "../../client-shared";

const prepareErrorResponse = (error: any) => {
  return {
    errors: {
      global: [
        {
          message: "global.error.action.title",
          details: error,
        },
      ],
      fields: [],
    },
    isLoading: false,
  };
};

export type APIOptionsWithoutAPIClass<T, A> = {
  initialValue: T;
  handleRequest: (api: A) => Promise<T>;
};

export const useAPI = <T, A extends BaseAPI >({
  initialValue,
  APIClass,
  handleRequest,
}: APIOptionsWithoutAPIClass<T, A> & {
  APIClass: new (configuration: Configuration) => A;
}): T & APIState => {
  const mapErrors = useErrorMessageMapping();
  const errorNotification = useErrorNotification();

  const authenticatedApiConfig = useAuthenticatedAPIConfig();

  const [response, setResponse] = useState<T & APIState>({
    ...initialResponse,
    ...initialValue,
  });

  useEffect(() => {
    const performAPIRequest = async () => {
      try {
        const api = new APIClass(authenticatedApiConfig());

        setResponse({
          ...successfulResponse,
          ...(await handleRequest(api)),
        });
      } catch (error) {
        const {
          feedbackMessage: { title, message },
        } = await mapErrors(error as APIError[]);

        errorNotification(title, message);

        const errorResponse = prepareErrorResponse(error);

        setResponse({
          ...errorResponse,
          ...initialValue,
        });
      }
    };

    performAPIRequest();
  }, [
    APIClass,
    initialValue,
    handleRequest,
    mapErrors,
    errorNotification,
    authenticatedApiConfig,
  ]);

  return response;
};
