import { MelcoModelsReferences } from "melco-shared-logic";
import { Button, EditIcon, Table, ValueOrFallback } from "melco-ui";
import { useTranslation } from "react-i18next";
import { ReferencesListTitle } from "./ReferencesListTitle";

export type DesignsReferencesListProps = {
  designList?: MelcoModelsReferences[];
};

const DETAIL_VIEW_PATH = "/libraries/designs";

export const DesignsReferencesList: React.FC<DesignsReferencesListProps> = ({
  designList,
}) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("global.references.designs.list.columns.name"),
      key: "name",
      dataIndex: "name",
      render: (_: string, item: MelcoModelsReferences) => (
        <ValueOrFallback
          value={item.name}
          fallback={t("global.fallback.empty")}
        />
      ),
    },
    {
      align: "right" as const,
      render: (_: string, item: MelcoModelsReferences) => (
        <Button
          icon={<EditIcon />}
          size="small"
          href={[DETAIL_VIEW_PATH, item.id].join("/")}
          target="_blank"
        >
          {t("global.references.actions.edit.button")}
        </Button>
      ),
    },
  ];

  return (
    <>
      <ReferencesListTitle level={5}>
        {t("global.references.designs.title")}
      </ReferencesListTitle>
      <Table
        showSorterTooltip={false}
        dataSource={designList}
        columns={columns}
        rowKey="id"
        pagination={false}
        noDataMessage={t("global.references.designs.list.empty")}
      />
    </>
  );
};
