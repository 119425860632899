/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoModelsUserProductListItem
 */
export interface MelcoModelsUserProductListItem {
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserProductListItem
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserProductListItem
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserProductListItem
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserProductListItem
     */
    security_type?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoModelsUserProductListItem
     */
    devices_allowed?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoModelsUserProductListItem
     */
    max_devices_allowed?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoModelsUserProductListItem
     */
    offline_seconds_allowed?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoModelsUserProductListItem
     */
    seconds_to_relogin?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoModelsUserProductListItem
     */
    account_verification_required?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoModelsUserProductListItem
     */
    send_notification_emails?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MelcoModelsUserProductListItem
     */
    legal_policy_version?: number;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserProductListItem
     */
    auto_login_endpoint?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserProductListItem
     */
    app_startup_endpoint?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoModelsUserProductListItem
     */
    is_demo_available?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoModelsUserProductListItem
     */
    is_internal?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserProductListItem
     */
    svg_icon_url?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoModelsUserProductListItem
     */
    is_accept_terms?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoModelsUserProductListItem
     */
    is_impersonation?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoModelsUserProductListItem
     */
    is_trial_available?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof MelcoModelsUserProductListItem
     */
    expires_on?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoModelsUserProductListItem
     */
    is_removable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoModelsUserProductListItem
     */
    is_display?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoModelsUserProductListItem
     */
    assign_on_create?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserProductListItem
     */
    demo_url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserProductListItem
     */
    info_url?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MelcoModelsUserProductListItem
     */
    permissions?: Array<string> | null;
}

export function MelcoModelsUserProductListItemFromJSON(json: any): MelcoModelsUserProductListItem {
    return MelcoModelsUserProductListItemFromJSONTyped(json, false);
}

export function MelcoModelsUserProductListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsUserProductListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': !exists(json, 'code') ? undefined : json['code'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'security_type': !exists(json, 'security_type') ? undefined : json['security_type'],
        'devices_allowed': !exists(json, 'devices_allowed') ? undefined : json['devices_allowed'],
        'max_devices_allowed': !exists(json, 'max_devices_allowed') ? undefined : json['max_devices_allowed'],
        'offline_seconds_allowed': !exists(json, 'offline_seconds_allowed') ? undefined : json['offline_seconds_allowed'],
        'seconds_to_relogin': !exists(json, 'seconds_to_relogin') ? undefined : json['seconds_to_relogin'],
        'account_verification_required': !exists(json, 'account_verification_required') ? undefined : json['account_verification_required'],
        'send_notification_emails': !exists(json, 'send_notification_emails') ? undefined : json['send_notification_emails'],
        'legal_policy_version': !exists(json, 'legal_policy_version') ? undefined : json['legal_policy_version'],
        'auto_login_endpoint': !exists(json, 'auto_login_endpoint') ? undefined : json['auto_login_endpoint'],
        'app_startup_endpoint': !exists(json, 'app_startup_endpoint') ? undefined : json['app_startup_endpoint'],
        'is_demo_available': !exists(json, 'is_demo_available') ? undefined : json['is_demo_available'],
        'is_internal': !exists(json, 'is_internal') ? undefined : json['is_internal'],
        'svg_icon_url': !exists(json, 'svg_icon_url') ? undefined : json['svg_icon_url'],
        'is_accept_terms': !exists(json, 'is_accept_terms') ? undefined : json['is_accept_terms'],
        'is_impersonation': !exists(json, 'is_impersonation') ? undefined : json['is_impersonation'],
        'is_trial_available': !exists(json, 'is_trial_available') ? undefined : json['is_trial_available'],
        'expires_on': !exists(json, 'expires_on') ? undefined : (json['expires_on'] === null ? null : new Date(json['expires_on'])),
        'is_removable': !exists(json, 'is_removable') ? undefined : json['is_removable'],
        'is_display': !exists(json, 'is_display') ? undefined : json['is_display'],
        'assign_on_create': !exists(json, 'assign_on_create') ? undefined : json['assign_on_create'],
        'demo_url': !exists(json, 'demo_url') ? undefined : json['demo_url'],
        'info_url': !exists(json, 'info_url') ? undefined : json['info_url'],
        'permissions': !exists(json, 'permissions') ? undefined : json['permissions'],
    };
}

export function MelcoModelsUserProductListItemToJSON(value?: MelcoModelsUserProductListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'name': value.name,
        'description': value.description,
        'security_type': value.security_type,
        'devices_allowed': value.devices_allowed,
        'max_devices_allowed': value.max_devices_allowed,
        'offline_seconds_allowed': value.offline_seconds_allowed,
        'seconds_to_relogin': value.seconds_to_relogin,
        'account_verification_required': value.account_verification_required,
        'send_notification_emails': value.send_notification_emails,
        'legal_policy_version': value.legal_policy_version,
        'auto_login_endpoint': value.auto_login_endpoint,
        'app_startup_endpoint': value.app_startup_endpoint,
        'is_demo_available': value.is_demo_available,
        'is_internal': value.is_internal,
        'svg_icon_url': value.svg_icon_url,
        'is_accept_terms': value.is_accept_terms,
        'is_impersonation': value.is_impersonation,
        'is_trial_available': value.is_trial_available,
        'expires_on': value.expires_on === undefined ? undefined : (value.expires_on === null ? null : value.expires_on.toISOString()),
        'is_removable': value.is_removable,
        'is_display': value.is_display,
        'assign_on_create': value.assign_on_create,
        'demo_url': value.demo_url,
        'info_url': value.info_url,
        'permissions': value.permissions,
    };
}


