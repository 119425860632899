import { useEffect } from "react";
import { useField } from "formik";
import { FusionMelcoModelsUserAlphabet } from "melco-shared-logic";
import isEmpty from "lodash-es/isEmpty";

export const useResetDefaultFont = (formikPrefix: string) => {
  const [{ value: availableFonts }] = useField<FusionMelcoModelsUserAlphabet[]>(
    `${formikPrefix}.lettering.alphabet_list`
  );

  const [{ value: defaultFontId }, , { setValue: setDefaultFontId }] =
    useField<string>(`${formikPrefix}.lettering.default_alphabet_id`);

  const availableFontIds = (availableFonts ?? []).map((font) => font.id);

  useEffect(() => {
    if (!defaultFontId || isEmpty(defaultFontId)) {
      const lastSelectedFontId = availableFontIds[0];

      if (lastSelectedFontId) {
        // set last selected font as default if no font has been selected before
        setDefaultFontId(lastSelectedFontId);
      }

      return;
    }

    if (!availableFontIds.includes(defaultFontId)) {
      setDefaultFontId("");
    }
  }, [availableFontIds, defaultFontId, setDefaultFontId]);
};
