import { FusionApi, useAPIAction } from "melco-shared-logic";

export const useAddProduct = () =>
  useAPIAction(
    FusionApi,
    async (api) => {
      const data = await api.fusionCustomProductsCreateDraft({
        fusionMelcoFusionModelsCreateCustomProduct: {},
      });

      return {
        successMessages: [
          {
            message: "default",
          },
        ],
        data,
      };
    },
    {
      translationPrefix: "products.actions.add",
    }
  );
