import { useField } from "formik";
import { FusionMelcoModelsUserAlphabet } from "melco-shared-logic";
import { DefaultFontSelect } from "./DefaultFontSelect";

type DefaultFontFieldProps = {
  formikPrefix: string;
  allFonts: FusionMelcoModelsUserAlphabet[];
  isLoading: boolean;
};

export const DefaultFontField: React.FC<DefaultFontFieldProps> = ({
  formikPrefix,
  allFonts,
  isLoading,
}) => {
  const [{ value: availableFonts }] = useField<FusionMelcoModelsUserAlphabet[]>(
    `${formikPrefix}.lettering.alphabet_list`
  );

  const fontIds = (availableFonts ?? []).map((font) => font.id!);

  return (
    <DefaultFontSelect
      formikPrefix={formikPrefix}
      fontIds={fontIds}
      allFonts={allFonts}
      isLoading={isLoading}
    />
  );
};
