/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoModelsUserAddress
 */
export interface MelcoModelsUserAddress {
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserAddress
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserAddress
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserAddress
     */
    street1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserAddress
     */
    street2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserAddress
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserAddress
     */
    postal_code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserAddress
     */
    state_code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserAddress
     */
    country_code?: string | null;
}

export function MelcoModelsUserAddressFromJSON(json: any): MelcoModelsUserAddress {
    return MelcoModelsUserAddressFromJSONTyped(json, false);
}

export function MelcoModelsUserAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsUserAddress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'street1': !exists(json, 'street1') ? undefined : json['street1'],
        'street2': !exists(json, 'street2') ? undefined : json['street2'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'postal_code': !exists(json, 'postal_code') ? undefined : json['postal_code'],
        'state_code': !exists(json, 'state_code') ? undefined : json['state_code'],
        'country_code': !exists(json, 'country_code') ? undefined : json['country_code'],
    };
}

export function MelcoModelsUserAddressToJSON(value?: MelcoModelsUserAddress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'street1': value.street1,
        'street2': value.street2,
        'city': value.city,
        'postal_code': value.postal_code,
        'state_code': value.state_code,
        'country_code': value.country_code,
    };
}


