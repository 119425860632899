/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoModelsAlphabetCollectionDefaultAlphabet,
    MelcoModelsAlphabetCollectionDefaultAlphabetFromJSON,
    MelcoModelsAlphabetCollectionDefaultAlphabetFromJSONTyped,
    MelcoModelsAlphabetCollectionDefaultAlphabetToJSON,
    MelcoModelsAlphabetListItem,
    MelcoModelsAlphabetListItemFromJSON,
    MelcoModelsAlphabetListItemFromJSONTyped,
    MelcoModelsAlphabetListItemToJSON,
    MelcoModelsCustomWarning,
    MelcoModelsCustomWarningFromJSON,
    MelcoModelsCustomWarningFromJSONTyped,
    MelcoModelsCustomWarningToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoModelsAlphabetCollection
 */
export interface MelcoModelsAlphabetCollection {
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsAlphabetCollection
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsAlphabetCollection
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoModelsAlphabetCollection
     */
    alphabet_count?: number;
    /**
     * 
     * @type {Array<MelcoModelsCustomWarning>}
     * @memberof MelcoModelsAlphabetCollection
     */
    warning_list?: Array<MelcoModelsCustomWarning> | null;
    /**
     * 
     * @type {Array<MelcoModelsAlphabetListItem>}
     * @memberof MelcoModelsAlphabetCollection
     */
    alphabet_list?: Array<MelcoModelsAlphabetListItem> | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoModelsAlphabetCollection
     */
    publish_state?: number;
    /**
     * 
     * @type {Date}
     * @memberof MelcoModelsAlphabetCollection
     */
    created_date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MelcoModelsAlphabetCollection
     */
    publish_date?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof MelcoModelsAlphabetCollection
     */
    updated_date?: Date | null;
    /**
     * 
     * @type {MelcoModelsAlphabetCollectionDefaultAlphabet}
     * @memberof MelcoModelsAlphabetCollection
     */
    default_alphabet?: MelcoModelsAlphabetCollectionDefaultAlphabet;
}

export function MelcoModelsAlphabetCollectionFromJSON(json: any): MelcoModelsAlphabetCollection {
    return MelcoModelsAlphabetCollectionFromJSONTyped(json, false);
}

export function MelcoModelsAlphabetCollectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsAlphabetCollection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'alphabet_count': !exists(json, 'alphabet_count') ? undefined : json['alphabet_count'],
        'warning_list': !exists(json, 'warning_list') ? undefined : (json['warning_list'] === null ? null : (json['warning_list'] as Array<any>).map(MelcoModelsCustomWarningFromJSON)),
        'alphabet_list': !exists(json, 'alphabet_list') ? undefined : (json['alphabet_list'] === null ? null : (json['alphabet_list'] as Array<any>).map(MelcoModelsAlphabetListItemFromJSON)),
        'publish_state': !exists(json, 'publish_state') ? undefined : json['publish_state'],
        'created_date': !exists(json, 'created_date') ? undefined : (new Date(json['created_date'])),
        'publish_date': !exists(json, 'publish_date') ? undefined : (json['publish_date'] === null ? null : new Date(json['publish_date'])),
        'updated_date': !exists(json, 'updated_date') ? undefined : (json['updated_date'] === null ? null : new Date(json['updated_date'])),
        'default_alphabet': !exists(json, 'default_alphabet') ? undefined : MelcoModelsAlphabetCollectionDefaultAlphabetFromJSON(json['default_alphabet']),
    };
}

export function MelcoModelsAlphabetCollectionToJSON(value?: MelcoModelsAlphabetCollection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'alphabet_count': value.alphabet_count,
        'warning_list': value.warning_list === undefined ? undefined : (value.warning_list === null ? null : (value.warning_list as Array<any>).map(MelcoModelsCustomWarningToJSON)),
        'alphabet_list': value.alphabet_list === undefined ? undefined : (value.alphabet_list === null ? null : (value.alphabet_list as Array<any>).map(MelcoModelsAlphabetListItemToJSON)),
        'publish_state': value.publish_state,
        'created_date': value.created_date === undefined ? undefined : (value.created_date.toISOString()),
        'publish_date': value.publish_date === undefined ? undefined : (value.publish_date === null ? null : value.publish_date.toISOString()),
        'updated_date': value.updated_date === undefined ? undefined : (value.updated_date === null ? null : value.updated_date.toISOString()),
        'default_alphabet': MelcoModelsAlphabetCollectionDefaultAlphabetToJSON(value.default_alphabet),
    };
}


