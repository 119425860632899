/* tslint:disable */
/* eslint-disable */
/**
 * Melco Efs APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    EfsMelcoEfsModelsCreateFormat,
    EfsMelcoEfsModelsCreateFormatFromJSON,
    EfsMelcoEfsModelsCreateFormatToJSON,
    EfsMelcoEfsModelsCreateMapping,
    EfsMelcoEfsModelsCreateMappingFromJSON,
    EfsMelcoEfsModelsCreateMappingToJSON,
    EfsMelcoEfsModelsCreateUserSetting,
    EfsMelcoEfsModelsCreateUserSettingFromJSON,
    EfsMelcoEfsModelsCreateUserSettingToJSON,
    EfsMelcoEfsModelsEfsDefaultAppSetting,
    EfsMelcoEfsModelsEfsDefaultAppSettingFromJSON,
    EfsMelcoEfsModelsEfsDefaultAppSettingToJSON,
    EfsMelcoEfsModelsEfsUserResponseSetting,
    EfsMelcoEfsModelsEfsUserResponseSettingFromJSON,
    EfsMelcoEfsModelsEfsUserResponseSettingToJSON,
    EfsMelcoEfsModelsFormat,
    EfsMelcoEfsModelsFormatFromJSON,
    EfsMelcoEfsModelsFormatToJSON,
    EfsMelcoEfsModelsJob,
    EfsMelcoEfsModelsJobFromJSON,
    EfsMelcoEfsModelsJobToJSON,
    EfsMelcoEfsModelsMapping,
    EfsMelcoEfsModelsMappingFromJSON,
    EfsMelcoEfsModelsMappingToJSON,
    EfsMelcoEfsModelsQueueJob,
    EfsMelcoEfsModelsQueueJobFromJSON,
    EfsMelcoEfsModelsQueueJobToJSON,
    EfsMelcoEfsModelsRun,
    EfsMelcoEfsModelsRunFromJSON,
    EfsMelcoEfsModelsRunToJSON,
    EfsMelcoEfsModelsRunJobDetails,
    EfsMelcoEfsModelsRunJobDetailsFromJSON,
    EfsMelcoEfsModelsRunJobDetailsToJSON,
    EfsMelcoEfsModelsRunStatus,
    EfsMelcoEfsModelsRunStatusFromJSON,
    EfsMelcoEfsModelsRunStatusToJSON,
    EfsMelcoModelsBoolResponse,
    EfsMelcoModelsBoolResponseFromJSON,
    EfsMelcoModelsBoolResponseToJSON,
    EfsMelcoModelsDesign,
    EfsMelcoModelsDesignFromJSON,
    EfsMelcoModelsDesignToJSON,
    EfsMelcoModelsError,
    EfsMelcoModelsErrorFromJSON,
    EfsMelcoModelsErrorToJSON,
    EfsMelcoModelsGenericList1MelcoEfsModelsEfsDefaultFormat,
    EfsMelcoModelsGenericList1MelcoEfsModelsEfsDefaultFormatFromJSON,
    EfsMelcoModelsGenericList1MelcoEfsModelsEfsDefaultFormatToJSON,
    EfsMelcoModelsGenericList1MelcoEfsModelsExceptionListItem,
    EfsMelcoModelsGenericList1MelcoEfsModelsExceptionListItemFromJSON,
    EfsMelcoModelsGenericList1MelcoEfsModelsExceptionListItemToJSON,
    EfsMelcoModelsGenericList1MelcoEfsModelsGlobalFormatListItem,
    EfsMelcoModelsGenericList1MelcoEfsModelsGlobalFormatListItemFromJSON,
    EfsMelcoModelsGenericList1MelcoEfsModelsGlobalFormatListItemToJSON,
    EfsMelcoModelsGenericList1MelcoEfsModelsMappingListItem,
    EfsMelcoModelsGenericList1MelcoEfsModelsMappingListItemFromJSON,
    EfsMelcoModelsGenericList1MelcoEfsModelsMappingListItemToJSON,
    EfsMelcoModelsGenericList1MelcoEfsModelsOutputFormat,
    EfsMelcoModelsGenericList1MelcoEfsModelsOutputFormatFromJSON,
    EfsMelcoModelsGenericList1MelcoEfsModelsOutputFormatToJSON,
    EfsMelcoModelsGenericList1MelcoEfsModelsQueueJob,
    EfsMelcoModelsGenericList1MelcoEfsModelsQueueJobFromJSON,
    EfsMelcoModelsGenericList1MelcoEfsModelsQueueJobToJSON,
    EfsMelcoModelsGenericList1MelcoEfsModelsQueueProcessor,
    EfsMelcoModelsGenericList1MelcoEfsModelsQueueProcessorFromJSON,
    EfsMelcoModelsGenericList1MelcoEfsModelsQueueProcessorToJSON,
    EfsMelcoModelsGenericList1MelcoEfsModelsRunListItem,
    EfsMelcoModelsGenericList1MelcoEfsModelsRunListItemFromJSON,
    EfsMelcoModelsGenericList1MelcoEfsModelsRunListItemToJSON,
    EfsMelcoModelsGenericList1MelcoEfsModelsUserFormatListItem,
    EfsMelcoModelsGenericList1MelcoEfsModelsUserFormatListItemFromJSON,
    EfsMelcoModelsGenericList1MelcoEfsModelsUserFormatListItemToJSON,
    EfsMelcoModelsGenericList1MelcoModelsProductUserListItem,
    EfsMelcoModelsGenericList1MelcoModelsProductUserListItemFromJSON,
    EfsMelcoModelsGenericList1MelcoModelsProductUserListItemToJSON,
    EfsMelcoModelsGenericList1MelcoModelsUserAlphabet,
    EfsMelcoModelsGenericList1MelcoModelsUserAlphabetFromJSON,
    EfsMelcoModelsGenericList1MelcoModelsUserAlphabetToJSON,
} from '../models';

export interface ProductionFulfillmentApiAccountProductSettingsEditEfsUserSettingRequest {
    efsMelcoEfsModelsCreateUserSetting?: EfsMelcoEfsModelsCreateUserSetting;
}

export interface ProductionFulfillmentApiProductionFulfillmentDefaultOutputFormatsGetListRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    searchText?: string;
}

export interface ProductionFulfillmentApiProductionFulfillmentDesignsGetRequest {
    id: string;
}

export interface ProductionFulfillmentApiProductionFulfillmentExceptionsGetListRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    searchText?: string;
}

export interface ProductionFulfillmentApiProductionFulfillmentFormatsCreateRequest {
    efsMelcoEfsModelsCreateFormat?: EfsMelcoEfsModelsCreateFormat;
}

export interface ProductionFulfillmentApiProductionFulfillmentFormatsDeleteRequest {
    id: string;
}

export interface ProductionFulfillmentApiProductionFulfillmentFormatsEditRequest {
    id: string;
    efsMelcoEfsModelsCreateFormat?: EfsMelcoEfsModelsCreateFormat;
}

export interface ProductionFulfillmentApiProductionFulfillmentFormatsGetFormatRequest {
    id: string;
}

export interface ProductionFulfillmentApiProductionFulfillmentFormatsGetListRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    searchText?: string;
}

export interface ProductionFulfillmentApiProductionFulfillmentFormatsGetUserListRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    searchText?: string;
}

export interface ProductionFulfillmentApiProductionFulfillmentFormatsUploadRequest {
    file?: Blob;
}

export interface ProductionFulfillmentApiProductionFulfillmentJobsDeleteQueuedJobRequest {
    jobId: string;
    processor?: string;
}

export interface ProductionFulfillmentApiProductionFulfillmentJobsGetJobListRequest {
    clientId?: string;
    offset?: number;
    limit?: number;
    sortBy?: string;
    searchText?: string;
}

export interface ProductionFulfillmentApiProductionFulfillmentJobsGetQueuedJobRequest {
    jobId: string;
}

export interface ProductionFulfillmentApiProductionFulfillmentJobsRunRequest {
    efsMelcoEfsModelsJob?: EfsMelcoEfsModelsJob;
}

export interface ProductionFulfillmentApiProductionFulfillmentMappingsCreateRequest {
    efsMelcoEfsModelsCreateMapping?: EfsMelcoEfsModelsCreateMapping;
}

export interface ProductionFulfillmentApiProductionFulfillmentMappingsDeleteRequest {
    id: string;
}

export interface ProductionFulfillmentApiProductionFulfillmentMappingsDuplicateRequest {
    id: string;
}

export interface ProductionFulfillmentApiProductionFulfillmentMappingsEditRequest {
    id: string;
    efsMelcoEfsModelsCreateMapping?: EfsMelcoEfsModelsCreateMapping;
}

export interface ProductionFulfillmentApiProductionFulfillmentMappingsGetListRequest {
    formatId?: string;
    global?: boolean;
    offset?: number;
    limit?: number;
    sortBy?: string;
    searchText?: string;
}

export interface ProductionFulfillmentApiProductionFulfillmentMappingsGetMappingsRequest {
    id: string;
}

export interface ProductionFulfillmentApiProductionFulfillmentMappingsValidateAlphabetRequest {
    mappingId?: string;
    designId?: string;
    letteringElementId?: string;
}

export interface ProductionFulfillmentApiProductionFulfillmentOutputFormatsGetListRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    searchText?: string;
}

export interface ProductionFulfillmentApiProductionFulfillmentQueueDownloadRequest {
    id: string;
    userId?: number;
}

export interface ProductionFulfillmentApiProductionFulfillmentQueueGetRequest {
    processorId: string;
}

export interface ProductionFulfillmentApiProductionFulfillmentQueueGetUserFontsRequest {
    userId: number;
}

export interface ProductionFulfillmentApiProductionFulfillmentQueueProcessorsNotificationsRequest {
    processorId: string;
    type: string;
}

export interface ProductionFulfillmentApiProductionFulfillmentQueueProcessorsStatusRequest {
    processorId: string;
}

export interface ProductionFulfillmentApiProductionFulfillmentQueueUpdateRequest {
    clientId: string;
    efsMelcoEfsModelsQueueJob?: EfsMelcoEfsModelsQueueJob;
}

export interface ProductionFulfillmentApiProductionFulfillmentRunsDownloadLogsDataRequest {
    id: string;
    jobId: string;
}

export interface ProductionFulfillmentApiProductionFulfillmentRunsGetRunRequest {
    id: string;
}

export interface ProductionFulfillmentApiProductionFulfillmentRunsGetRunDetailsRequest {
    id: string;
    jobId: string;
}

export interface ProductionFulfillmentApiProductionFulfillmentRunsStatusRequest {
    id: string;
    efsMelcoEfsModelsRunStatus?: EfsMelcoEfsModelsRunStatus;
}

/**
 * 
 */
export class ProductionFulfillmentApi extends runtime.BaseAPI {

    /**
     */
    async accountProductSettingsDeleteEfsUserSettingRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/settings/app`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async accountProductSettingsDeleteEfsUserSetting(): Promise<void> {
        await this.accountProductSettingsDeleteEfsUserSettingRaw();
    }

    /**
     */
    async accountProductSettingsEditEfsUserSettingRaw(requestParameters: ProductionFulfillmentApiAccountProductSettingsEditEfsUserSettingRequest): Promise<runtime.ApiResponse<EfsMelcoEfsModelsEfsUserResponseSetting>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/production/fulfillment/settings/app`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EfsMelcoEfsModelsCreateUserSettingToJSON(requestParameters.efsMelcoEfsModelsCreateUserSetting),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EfsMelcoEfsModelsEfsUserResponseSettingFromJSON(jsonValue));
    }

    /**
     */
    async accountProductSettingsEditEfsUserSetting(requestParameters: ProductionFulfillmentApiAccountProductSettingsEditEfsUserSettingRequest): Promise<EfsMelcoEfsModelsEfsUserResponseSetting> {
        const response = await this.accountProductSettingsEditEfsUserSettingRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async accountProductSettingsGetEfsUserSettingRaw(): Promise<runtime.ApiResponse<EfsMelcoEfsModelsEfsUserResponseSetting>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/settings/app`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EfsMelcoEfsModelsEfsUserResponseSettingFromJSON(jsonValue));
    }

    /**
     */
    async accountProductSettingsGetEfsUserSetting(): Promise<EfsMelcoEfsModelsEfsUserResponseSetting> {
        const response = await this.accountProductSettingsGetEfsUserSettingRaw();
        return await response.value();
    }

    /**
     */
    async productSettingsGetEfsDefaultAppSettingRaw(): Promise<runtime.ApiResponse<EfsMelcoEfsModelsEfsDefaultAppSetting>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/defaults/settings/app`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EfsMelcoEfsModelsEfsDefaultAppSettingFromJSON(jsonValue));
    }

    /**
     */
    async productSettingsGetEfsDefaultAppSetting(): Promise<EfsMelcoEfsModelsEfsDefaultAppSetting> {
        const response = await this.productSettingsGetEfsDefaultAppSettingRaw();
        return await response.value();
    }

    /**
     */
    async productionFulfillmentDefaultOutputFormatsGetListRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentDefaultOutputFormatsGetListRequest): Promise<runtime.ApiResponse<EfsMelcoModelsGenericList1MelcoEfsModelsEfsDefaultFormat>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/defaults/output/formats`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EfsMelcoModelsGenericList1MelcoEfsModelsEfsDefaultFormatFromJSON(jsonValue));
    }

    /**
     */
    async productionFulfillmentDefaultOutputFormatsGetList(requestParameters: ProductionFulfillmentApiProductionFulfillmentDefaultOutputFormatsGetListRequest): Promise<EfsMelcoModelsGenericList1MelcoEfsModelsEfsDefaultFormat> {
        const response = await this.productionFulfillmentDefaultOutputFormatsGetListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async productionFulfillmentDesignsGetRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentDesignsGetRequest): Promise<runtime.ApiResponse<EfsMelcoModelsDesign>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productionFulfillmentDesignsGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/designs/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EfsMelcoModelsDesignFromJSON(jsonValue));
    }

    /**
     */
    async productionFulfillmentDesignsGet(requestParameters: ProductionFulfillmentApiProductionFulfillmentDesignsGetRequest): Promise<EfsMelcoModelsDesign> {
        const response = await this.productionFulfillmentDesignsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async productionFulfillmentExceptionsGetListRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentExceptionsGetListRequest): Promise<runtime.ApiResponse<EfsMelcoModelsGenericList1MelcoEfsModelsExceptionListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/exceptions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EfsMelcoModelsGenericList1MelcoEfsModelsExceptionListItemFromJSON(jsonValue));
    }

    /**
     */
    async productionFulfillmentExceptionsGetList(requestParameters: ProductionFulfillmentApiProductionFulfillmentExceptionsGetListRequest): Promise<EfsMelcoModelsGenericList1MelcoEfsModelsExceptionListItem> {
        const response = await this.productionFulfillmentExceptionsGetListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async productionFulfillmentFormatsCreateRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentFormatsCreateRequest): Promise<runtime.ApiResponse<EfsMelcoEfsModelsFormat>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/production/fulfillment/formats`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EfsMelcoEfsModelsCreateFormatToJSON(requestParameters.efsMelcoEfsModelsCreateFormat),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EfsMelcoEfsModelsFormatFromJSON(jsonValue));
    }

    /**
     */
    async productionFulfillmentFormatsCreate(requestParameters: ProductionFulfillmentApiProductionFulfillmentFormatsCreateRequest): Promise<EfsMelcoEfsModelsFormat> {
        const response = await this.productionFulfillmentFormatsCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async productionFulfillmentFormatsDeleteRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentFormatsDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productionFulfillmentFormatsDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/formats/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async productionFulfillmentFormatsDelete(requestParameters: ProductionFulfillmentApiProductionFulfillmentFormatsDeleteRequest): Promise<void> {
        await this.productionFulfillmentFormatsDeleteRaw(requestParameters);
    }

    /**
     */
    async productionFulfillmentFormatsEditRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentFormatsEditRequest): Promise<runtime.ApiResponse<EfsMelcoEfsModelsFormat>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productionFulfillmentFormatsEdit.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/production/fulfillment/formats/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EfsMelcoEfsModelsCreateFormatToJSON(requestParameters.efsMelcoEfsModelsCreateFormat),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EfsMelcoEfsModelsFormatFromJSON(jsonValue));
    }

    /**
     */
    async productionFulfillmentFormatsEdit(requestParameters: ProductionFulfillmentApiProductionFulfillmentFormatsEditRequest): Promise<EfsMelcoEfsModelsFormat> {
        const response = await this.productionFulfillmentFormatsEditRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async productionFulfillmentFormatsGetFormatRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentFormatsGetFormatRequest): Promise<runtime.ApiResponse<EfsMelcoEfsModelsFormat>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productionFulfillmentFormatsGetFormat.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/formats/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EfsMelcoEfsModelsFormatFromJSON(jsonValue));
    }

    /**
     */
    async productionFulfillmentFormatsGetFormat(requestParameters: ProductionFulfillmentApiProductionFulfillmentFormatsGetFormatRequest): Promise<EfsMelcoEfsModelsFormat> {
        const response = await this.productionFulfillmentFormatsGetFormatRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async productionFulfillmentFormatsGetListRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentFormatsGetListRequest): Promise<runtime.ApiResponse<EfsMelcoModelsGenericList1MelcoEfsModelsGlobalFormatListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/defaults/formats`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EfsMelcoModelsGenericList1MelcoEfsModelsGlobalFormatListItemFromJSON(jsonValue));
    }

    /**
     */
    async productionFulfillmentFormatsGetList(requestParameters: ProductionFulfillmentApiProductionFulfillmentFormatsGetListRequest): Promise<EfsMelcoModelsGenericList1MelcoEfsModelsGlobalFormatListItem> {
        const response = await this.productionFulfillmentFormatsGetListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async productionFulfillmentFormatsGetUserListRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentFormatsGetUserListRequest): Promise<runtime.ApiResponse<EfsMelcoModelsGenericList1MelcoEfsModelsUserFormatListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/formats`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EfsMelcoModelsGenericList1MelcoEfsModelsUserFormatListItemFromJSON(jsonValue));
    }

    /**
     */
    async productionFulfillmentFormatsGetUserList(requestParameters: ProductionFulfillmentApiProductionFulfillmentFormatsGetUserListRequest): Promise<EfsMelcoModelsGenericList1MelcoEfsModelsUserFormatListItem> {
        const response = await this.productionFulfillmentFormatsGetUserListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async productionFulfillmentFormatsUploadRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentFormatsUploadRequest): Promise<runtime.ApiResponse<EfsMelcoEfsModelsFormat>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/production/fulfillment/formats/upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EfsMelcoEfsModelsFormatFromJSON(jsonValue));
    }

    /**
     */
    async productionFulfillmentFormatsUpload(requestParameters: ProductionFulfillmentApiProductionFulfillmentFormatsUploadRequest): Promise<EfsMelcoEfsModelsFormat> {
        const response = await this.productionFulfillmentFormatsUploadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async productionFulfillmentJobsDeleteQueuedJobRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentJobsDeleteQueuedJobRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling productionFulfillmentJobsDeleteQueuedJob.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.processor !== undefined) {
            queryParameters['processor'] = requestParameters.processor;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/jobs/{jobId}`.replace(`{${"jobId"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async productionFulfillmentJobsDeleteQueuedJob(requestParameters: ProductionFulfillmentApiProductionFulfillmentJobsDeleteQueuedJobRequest): Promise<void> {
        await this.productionFulfillmentJobsDeleteQueuedJobRaw(requestParameters);
    }

    /**
     */
    async productionFulfillmentJobsGetJobListRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentJobsGetJobListRequest): Promise<runtime.ApiResponse<EfsMelcoModelsGenericList1MelcoEfsModelsQueueJob>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.clientId !== undefined) {
            queryParameters['clientId'] = requestParameters.clientId;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/jobs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EfsMelcoModelsGenericList1MelcoEfsModelsQueueJobFromJSON(jsonValue));
    }

    /**
     */
    async productionFulfillmentJobsGetJobList(requestParameters: ProductionFulfillmentApiProductionFulfillmentJobsGetJobListRequest): Promise<EfsMelcoModelsGenericList1MelcoEfsModelsQueueJob> {
        const response = await this.productionFulfillmentJobsGetJobListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async productionFulfillmentJobsGetQueuedJobRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentJobsGetQueuedJobRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling productionFulfillmentJobsGetQueuedJob.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/jobs/{jobId}`.replace(`{${"jobId"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async productionFulfillmentJobsGetQueuedJob(requestParameters: ProductionFulfillmentApiProductionFulfillmentJobsGetQueuedJobRequest): Promise<void> {
        await this.productionFulfillmentJobsGetQueuedJobRaw(requestParameters);
    }

    /**
     */
    async productionFulfillmentJobsRunRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentJobsRunRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/production/fulfillment/jobs/run`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EfsMelcoEfsModelsJobToJSON(requestParameters.efsMelcoEfsModelsJob),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async productionFulfillmentJobsRun(requestParameters: ProductionFulfillmentApiProductionFulfillmentJobsRunRequest): Promise<void> {
        await this.productionFulfillmentJobsRunRaw(requestParameters);
    }

    /**
     */
    async productionFulfillmentMappingsCreateRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentMappingsCreateRequest): Promise<runtime.ApiResponse<EfsMelcoEfsModelsMapping>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/production/fulfillment/mappings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EfsMelcoEfsModelsCreateMappingToJSON(requestParameters.efsMelcoEfsModelsCreateMapping),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EfsMelcoEfsModelsMappingFromJSON(jsonValue));
    }

    /**
     */
    async productionFulfillmentMappingsCreate(requestParameters: ProductionFulfillmentApiProductionFulfillmentMappingsCreateRequest): Promise<EfsMelcoEfsModelsMapping> {
        const response = await this.productionFulfillmentMappingsCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async productionFulfillmentMappingsDeleteRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentMappingsDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productionFulfillmentMappingsDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/mappings/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async productionFulfillmentMappingsDelete(requestParameters: ProductionFulfillmentApiProductionFulfillmentMappingsDeleteRequest): Promise<void> {
        await this.productionFulfillmentMappingsDeleteRaw(requestParameters);
    }

    /**
     */
    async productionFulfillmentMappingsDuplicateRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentMappingsDuplicateRequest): Promise<runtime.ApiResponse<EfsMelcoEfsModelsMapping>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productionFulfillmentMappingsDuplicate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/mappings/{Id}/duplicate`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EfsMelcoEfsModelsMappingFromJSON(jsonValue));
    }

    /**
     */
    async productionFulfillmentMappingsDuplicate(requestParameters: ProductionFulfillmentApiProductionFulfillmentMappingsDuplicateRequest): Promise<EfsMelcoEfsModelsMapping> {
        const response = await this.productionFulfillmentMappingsDuplicateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async productionFulfillmentMappingsEditRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentMappingsEditRequest): Promise<runtime.ApiResponse<EfsMelcoEfsModelsMapping>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productionFulfillmentMappingsEdit.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/production/fulfillment/mappings/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EfsMelcoEfsModelsCreateMappingToJSON(requestParameters.efsMelcoEfsModelsCreateMapping),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EfsMelcoEfsModelsMappingFromJSON(jsonValue));
    }

    /**
     */
    async productionFulfillmentMappingsEdit(requestParameters: ProductionFulfillmentApiProductionFulfillmentMappingsEditRequest): Promise<EfsMelcoEfsModelsMapping> {
        const response = await this.productionFulfillmentMappingsEditRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async productionFulfillmentMappingsGetListRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentMappingsGetListRequest): Promise<runtime.ApiResponse<EfsMelcoModelsGenericList1MelcoEfsModelsMappingListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.formatId !== undefined) {
            queryParameters['formatId'] = requestParameters.formatId;
        }

        if (requestParameters.global !== undefined) {
            queryParameters['global'] = requestParameters.global;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/mappings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EfsMelcoModelsGenericList1MelcoEfsModelsMappingListItemFromJSON(jsonValue));
    }

    /**
     */
    async productionFulfillmentMappingsGetList(requestParameters: ProductionFulfillmentApiProductionFulfillmentMappingsGetListRequest): Promise<EfsMelcoModelsGenericList1MelcoEfsModelsMappingListItem> {
        const response = await this.productionFulfillmentMappingsGetListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async productionFulfillmentMappingsGetMappingsRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentMappingsGetMappingsRequest): Promise<runtime.ApiResponse<EfsMelcoEfsModelsMapping>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productionFulfillmentMappingsGetMappings.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/mappings/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EfsMelcoEfsModelsMappingFromJSON(jsonValue));
    }

    /**
     */
    async productionFulfillmentMappingsGetMappings(requestParameters: ProductionFulfillmentApiProductionFulfillmentMappingsGetMappingsRequest): Promise<EfsMelcoEfsModelsMapping> {
        const response = await this.productionFulfillmentMappingsGetMappingsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async productionFulfillmentMappingsValidateAlphabetRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentMappingsValidateAlphabetRequest): Promise<runtime.ApiResponse<EfsMelcoModelsBoolResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.mappingId !== undefined) {
            queryParameters['mapping_id'] = requestParameters.mappingId;
        }

        if (requestParameters.designId !== undefined) {
            queryParameters['design_id'] = requestParameters.designId;
        }

        if (requestParameters.letteringElementId !== undefined) {
            queryParameters['lettering_element_id'] = requestParameters.letteringElementId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/mappings/alphabet/validate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EfsMelcoModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     */
    async productionFulfillmentMappingsValidateAlphabet(requestParameters: ProductionFulfillmentApiProductionFulfillmentMappingsValidateAlphabetRequest): Promise<EfsMelcoModelsBoolResponse> {
        const response = await this.productionFulfillmentMappingsValidateAlphabetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async productionFulfillmentOutputFormatsGetListRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentOutputFormatsGetListRequest): Promise<runtime.ApiResponse<EfsMelcoModelsGenericList1MelcoEfsModelsOutputFormat>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/output/formats`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EfsMelcoModelsGenericList1MelcoEfsModelsOutputFormatFromJSON(jsonValue));
    }

    /**
     */
    async productionFulfillmentOutputFormatsGetList(requestParameters: ProductionFulfillmentApiProductionFulfillmentOutputFormatsGetListRequest): Promise<EfsMelcoModelsGenericList1MelcoEfsModelsOutputFormat> {
        const response = await this.productionFulfillmentOutputFormatsGetListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async productionFulfillmentQueueDownloadRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentQueueDownloadRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productionFulfillmentQueueDownload.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/queue/fonts/{Id}/download`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async productionFulfillmentQueueDownload(requestParameters: ProductionFulfillmentApiProductionFulfillmentQueueDownloadRequest): Promise<Blob> {
        const response = await this.productionFulfillmentQueueDownloadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async productionFulfillmentQueueGetRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentQueueGetRequest): Promise<runtime.ApiResponse<EfsMelcoEfsModelsQueueJob>> {
        if (requestParameters.processorId === null || requestParameters.processorId === undefined) {
            throw new runtime.RequiredError('processorId','Required parameter requestParameters.processorId was null or undefined when calling productionFulfillmentQueueGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/queue/jobs/{processorId}`.replace(`{${"processorId"}}`, encodeURIComponent(String(requestParameters.processorId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EfsMelcoEfsModelsQueueJobFromJSON(jsonValue));
    }

    /**
     */
    async productionFulfillmentQueueGet(requestParameters: ProductionFulfillmentApiProductionFulfillmentQueueGetRequest): Promise<EfsMelcoEfsModelsQueueJob> {
        const response = await this.productionFulfillmentQueueGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async productionFulfillmentQueueGetEfsUserListRaw(): Promise<runtime.ApiResponse<EfsMelcoModelsGenericList1MelcoModelsProductUserListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/queue/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EfsMelcoModelsGenericList1MelcoModelsProductUserListItemFromJSON(jsonValue));
    }

    /**
     */
    async productionFulfillmentQueueGetEfsUserList(): Promise<EfsMelcoModelsGenericList1MelcoModelsProductUserListItem> {
        const response = await this.productionFulfillmentQueueGetEfsUserListRaw();
        return await response.value();
    }

    /**
     */
    async productionFulfillmentQueueGetUserFontsRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentQueueGetUserFontsRequest): Promise<runtime.ApiResponse<EfsMelcoModelsGenericList1MelcoModelsUserAlphabet>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling productionFulfillmentQueueGetUserFonts.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/queue/fonts/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EfsMelcoModelsGenericList1MelcoModelsUserAlphabetFromJSON(jsonValue));
    }

    /**
     */
    async productionFulfillmentQueueGetUserFonts(requestParameters: ProductionFulfillmentApiProductionFulfillmentQueueGetUserFontsRequest): Promise<EfsMelcoModelsGenericList1MelcoModelsUserAlphabet> {
        const response = await this.productionFulfillmentQueueGetUserFontsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async productionFulfillmentQueueProcessorsListRaw(): Promise<runtime.ApiResponse<EfsMelcoModelsGenericList1MelcoEfsModelsQueueProcessor>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/queue/processors`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EfsMelcoModelsGenericList1MelcoEfsModelsQueueProcessorFromJSON(jsonValue));
    }

    /**
     */
    async productionFulfillmentQueueProcessorsList(): Promise<EfsMelcoModelsGenericList1MelcoEfsModelsQueueProcessor> {
        const response = await this.productionFulfillmentQueueProcessorsListRaw();
        return await response.value();
    }

    /**
     */
    async productionFulfillmentQueueProcessorsNotificationsRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentQueueProcessorsNotificationsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.processorId === null || requestParameters.processorId === undefined) {
            throw new runtime.RequiredError('processorId','Required parameter requestParameters.processorId was null or undefined when calling productionFulfillmentQueueProcessorsNotifications.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling productionFulfillmentQueueProcessorsNotifications.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/queue/processors/{processorId}/notifications/{type}`.replace(`{${"processorId"}}`, encodeURIComponent(String(requestParameters.processorId))).replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async productionFulfillmentQueueProcessorsNotifications(requestParameters: ProductionFulfillmentApiProductionFulfillmentQueueProcessorsNotificationsRequest): Promise<void> {
        await this.productionFulfillmentQueueProcessorsNotificationsRaw(requestParameters);
    }

    /**
     */
    async productionFulfillmentQueueProcessorsStatusRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentQueueProcessorsStatusRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.processorId === null || requestParameters.processorId === undefined) {
            throw new runtime.RequiredError('processorId','Required parameter requestParameters.processorId was null or undefined when calling productionFulfillmentQueueProcessorsStatus.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/queue/processors/{processorId}`.replace(`{${"processorId"}}`, encodeURIComponent(String(requestParameters.processorId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async productionFulfillmentQueueProcessorsStatus(requestParameters: ProductionFulfillmentApiProductionFulfillmentQueueProcessorsStatusRequest): Promise<void> {
        await this.productionFulfillmentQueueProcessorsStatusRaw(requestParameters);
    }

    /**
     */
    async productionFulfillmentQueueUpdateRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentQueueUpdateRequest): Promise<runtime.ApiResponse<EfsMelcoEfsModelsQueueJob>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling productionFulfillmentQueueUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/production/fulfillment/queue/jobs/{clientId}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EfsMelcoEfsModelsQueueJobToJSON(requestParameters.efsMelcoEfsModelsQueueJob),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EfsMelcoEfsModelsQueueJobFromJSON(jsonValue));
    }

    /**
     */
    async productionFulfillmentQueueUpdate(requestParameters: ProductionFulfillmentApiProductionFulfillmentQueueUpdateRequest): Promise<EfsMelcoEfsModelsQueueJob> {
        const response = await this.productionFulfillmentQueueUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async productionFulfillmentRunsDownloadLogsDataRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentRunsDownloadLogsDataRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productionFulfillmentRunsDownloadLogsData.');
        }

        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling productionFulfillmentRunsDownloadLogsData.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/runs/{Id}/job/{job_id}/download`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"job_id"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async productionFulfillmentRunsDownloadLogsData(requestParameters: ProductionFulfillmentApiProductionFulfillmentRunsDownloadLogsDataRequest): Promise<Blob> {
        const response = await this.productionFulfillmentRunsDownloadLogsDataRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async productionFulfillmentRunsGetListRaw(): Promise<runtime.ApiResponse<EfsMelcoModelsGenericList1MelcoEfsModelsRunListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/runs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EfsMelcoModelsGenericList1MelcoEfsModelsRunListItemFromJSON(jsonValue));
    }

    /**
     */
    async productionFulfillmentRunsGetList(): Promise<EfsMelcoModelsGenericList1MelcoEfsModelsRunListItem> {
        const response = await this.productionFulfillmentRunsGetListRaw();
        return await response.value();
    }

    /**
     */
    async productionFulfillmentRunsGetRunRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentRunsGetRunRequest): Promise<runtime.ApiResponse<EfsMelcoEfsModelsRun>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productionFulfillmentRunsGetRun.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/runs/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EfsMelcoEfsModelsRunFromJSON(jsonValue));
    }

    /**
     */
    async productionFulfillmentRunsGetRun(requestParameters: ProductionFulfillmentApiProductionFulfillmentRunsGetRunRequest): Promise<EfsMelcoEfsModelsRun> {
        const response = await this.productionFulfillmentRunsGetRunRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async productionFulfillmentRunsGetRunDetailsRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentRunsGetRunDetailsRequest): Promise<runtime.ApiResponse<EfsMelcoEfsModelsRunJobDetails>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productionFulfillmentRunsGetRunDetails.');
        }

        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling productionFulfillmentRunsGetRunDetails.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/production/fulfillment/runs/{Id}/job/{job_id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"job_id"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EfsMelcoEfsModelsRunJobDetailsFromJSON(jsonValue));
    }

    /**
     */
    async productionFulfillmentRunsGetRunDetails(requestParameters: ProductionFulfillmentApiProductionFulfillmentRunsGetRunDetailsRequest): Promise<EfsMelcoEfsModelsRunJobDetails> {
        const response = await this.productionFulfillmentRunsGetRunDetailsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async productionFulfillmentRunsStatusRaw(requestParameters: ProductionFulfillmentApiProductionFulfillmentRunsStatusRequest): Promise<runtime.ApiResponse<EfsMelcoModelsBoolResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productionFulfillmentRunsStatus.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/production/fulfillment/runs/{Id}/status`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EfsMelcoEfsModelsRunStatusToJSON(requestParameters.efsMelcoEfsModelsRunStatus),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EfsMelcoModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     */
    async productionFulfillmentRunsStatus(requestParameters: ProductionFulfillmentApiProductionFulfillmentRunsStatusRequest): Promise<EfsMelcoModelsBoolResponse> {
        const response = await this.productionFulfillmentRunsStatusRaw(requestParameters);
        return await response.value();
    }

}
