import { useTranslation } from "react-i18next";
import {
  FusionMelcoFusionModelsCustomProduct,
  FusionMelcoFusionModelsCustomProductView,
} from "melco-shared-logic";
import { Tabs, Title } from "melco-ui";
import { useField } from "formik";
import { ProductViewTab } from "./ProductViewTab";

const { TabPane } = Tabs;

type ProductConfigurationProps = {
  product: FusionMelcoFusionModelsCustomProduct;
};

export const ProductConfiguration: React.FC<ProductConfigurationProps> = ({
  product,
}) => {
  const { t } = useTranslation();

  const [{ value: blankId }] = useField<string | undefined>("blank.id");

  const [{ value: views }] = useField<
    FusionMelcoFusionModelsCustomProductView[] | undefined
  >("view_list");

  return (
    <>
      <Title level={5}>
        {t("customization.products.detail.form.canvas.title")}
      </Title>

      <Tabs destroyInactiveTabPane>
        {(views ?? []).map((view, viewIndex) => (
          <TabPane key={`${blankId}-${view.name}`} tab={view.name}>
            <ProductViewTab
              product={product}
              view={view}
              formikPrefix={`view_list[${viewIndex}]`}
            />
          </TabPane>
        ))}
      </Tabs>
    </>
  );
};
