/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoModelsJwtAuth
 */
export interface MelcoModelsJwtAuth {
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsJwtAuth
     */
    user_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsJwtAuth
     */
    password?: string | null;
}

export function MelcoModelsJwtAuthFromJSON(json: any): MelcoModelsJwtAuth {
    return MelcoModelsJwtAuthFromJSONTyped(json, false);
}

export function MelcoModelsJwtAuthFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsJwtAuth {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user_name': !exists(json, 'user_name') ? undefined : json['user_name'],
        'password': !exists(json, 'password') ? undefined : json['password'],
    };
}

export function MelcoModelsJwtAuthToJSON(value?: MelcoModelsJwtAuth | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_name': value.user_name,
        'password': value.password,
    };
}


