/* tslint:disable */
/* eslint-disable */
/**
 * Melco Efs APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EfsMelcoModelsBoolResponse
 */
export interface EfsMelcoModelsBoolResponse {
    /**
     * 
     * @type {boolean}
     * @memberof EfsMelcoModelsBoolResponse
     */
    result?: boolean;
}

export function EfsMelcoModelsBoolResponseFromJSON(json: any): EfsMelcoModelsBoolResponse {
    return EfsMelcoModelsBoolResponseFromJSONTyped(json, false);
}

export function EfsMelcoModelsBoolResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EfsMelcoModelsBoolResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : json['result'],
    };
}

export function EfsMelcoModelsBoolResponseToJSON(value?: EfsMelcoModelsBoolResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': value.result,
    };
}


