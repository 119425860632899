/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoModelsCustomWarning,
    MelcoModelsCustomWarningFromJSON,
    MelcoModelsCustomWarningFromJSONTyped,
    MelcoModelsCustomWarningToJSON,
    MelcoModelsEmailListItem,
    MelcoModelsEmailListItemFromJSON,
    MelcoModelsEmailListItemFromJSONTyped,
    MelcoModelsEmailListItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoModelsGenericList1MelcoModelsEmailListItem
 */
export interface MelcoModelsGenericList1MelcoModelsEmailListItem {
    /**
     * 
     * @type {Array<MelcoModelsEmailListItem>}
     * @memberof MelcoModelsGenericList1MelcoModelsEmailListItem
     */
    list: Array<MelcoModelsEmailListItem>;
    /**
     * 
     * @type {Array<MelcoModelsCustomWarning>}
     * @memberof MelcoModelsGenericList1MelcoModelsEmailListItem
     */
    warning_list?: Array<MelcoModelsCustomWarning> | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoModelsGenericList1MelcoModelsEmailListItem
     */
    total: number;
}

export function MelcoModelsGenericList1MelcoModelsEmailListItemFromJSON(json: any): MelcoModelsGenericList1MelcoModelsEmailListItem {
    return MelcoModelsGenericList1MelcoModelsEmailListItemFromJSONTyped(json, false);
}

export function MelcoModelsGenericList1MelcoModelsEmailListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsGenericList1MelcoModelsEmailListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'list': ((json['list'] as Array<any>).map(MelcoModelsEmailListItemFromJSON)),
        'warning_list': !exists(json, 'warning_list') ? undefined : (json['warning_list'] === null ? null : (json['warning_list'] as Array<any>).map(MelcoModelsCustomWarningFromJSON)),
        'total': json['total'],
    };
}

export function MelcoModelsGenericList1MelcoModelsEmailListItemToJSON(value?: MelcoModelsGenericList1MelcoModelsEmailListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'list': ((value.list as Array<any>).map(MelcoModelsEmailListItemToJSON)),
        'warning_list': value.warning_list === undefined ? undefined : (value.warning_list === null ? null : (value.warning_list as Array<any>).map(MelcoModelsCustomWarningToJSON)),
        'total': value.total,
    };
}


