import { useAPIAction, UserContentsDraftPublishApi } from "melco-shared-logic";

export const useAddBlank = () =>
  useAPIAction(
    UserContentsDraftPublishApi,
    async (api) => {
      const data = await api.userBlankCreateDraft({
        melcoFusionModelsCreateBlank: {},
      });

      return {
        successMessages: [
          {
            message: "default",
          },
        ],
        data,
      };
    },
    {
      translationPrefix: "blanks.actions.add",
    }
  );
