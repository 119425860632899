/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoModelsUserDeviceListItem
 */
export interface MelcoModelsUserDeviceListItem {
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserDeviceListItem
     */
    device_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserDeviceListItem
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserDeviceListItem
     */
    operating_system_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserDeviceListItem
     */
    operating_system_version?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserDeviceListItem
     */
    cpu_architecture?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoModelsUserDeviceListItem
     */
    logged_off?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof MelcoModelsUserDeviceListItem
     */
    logged_on?: Date;
    /**
     * 
     * @type {number}
     * @memberof MelcoModelsUserDeviceListItem
     */
    seconds_to_expire?: number;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserDeviceListItem
     */
    user_subscription_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserDeviceListItem
     */
    license_display_name?: string | null;
}

export function MelcoModelsUserDeviceListItemFromJSON(json: any): MelcoModelsUserDeviceListItem {
    return MelcoModelsUserDeviceListItemFromJSONTyped(json, false);
}

export function MelcoModelsUserDeviceListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsUserDeviceListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'device_id': !exists(json, 'device_id') ? undefined : json['device_id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'operating_system_name': !exists(json, 'operating_system_name') ? undefined : json['operating_system_name'],
        'operating_system_version': !exists(json, 'operating_system_version') ? undefined : json['operating_system_version'],
        'cpu_architecture': !exists(json, 'cpu_architecture') ? undefined : json['cpu_architecture'],
        'logged_off': !exists(json, 'logged_off') ? undefined : json['logged_off'],
        'logged_on': !exists(json, 'logged_on') ? undefined : (new Date(json['logged_on'])),
        'seconds_to_expire': !exists(json, 'seconds_to_expire') ? undefined : json['seconds_to_expire'],
        'user_subscription_id': !exists(json, 'user_subscription_id') ? undefined : json['user_subscription_id'],
        'license_display_name': !exists(json, 'license_display_name') ? undefined : json['license_display_name'],
    };
}

export function MelcoModelsUserDeviceListItemToJSON(value?: MelcoModelsUserDeviceListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'device_id': value.device_id,
        'name': value.name,
        'operating_system_name': value.operating_system_name,
        'operating_system_version': value.operating_system_version,
        'cpu_architecture': value.cpu_architecture,
        'logged_off': value.logged_off,
        'logged_on': value.logged_on === undefined ? undefined : (value.logged_on.toISOString()),
        'seconds_to_expire': value.seconds_to_expire,
        'user_subscription_id': value.user_subscription_id,
        'license_display_name': value.license_display_name,
    };
}


