import { useEffect, useState } from "react";
import { GridElement, StitchElement } from "@melco/renderer";
import { FusionMelcoModelsDesign } from "melco-shared-logic";
import {
  FusionElementConfigurationChangedAction,
  FusionSetBlankPictureAndDesignAction,
  FusionZoomToFitAction,
} from "./FusionRendererActions";
import {
  ElementConfiguration,
  getRenderResourceByURL,
  useRenderer,
} from "./useRenderer";
import { useBasicSettings } from "../settings/useBasicSettings";

export type OriginAndGridElements = {
  grid?: GridElement;
  origin?: GridElement;
};

export const useDesignRenderer = (
  design: FusionMelcoModelsDesign,
  elementConfiguration: ElementConfiguration,
  showOrigin?: boolean
) => {
  const { settings, isLoading: isSettingsLoading } = useBasicSettings();

  const [gridElements, setGridElements] = useState<
    OriginAndGridElements | undefined
  >(undefined);

  const canvasId = `canvas-design-${design.id}`;

  const renderer = useRenderer(canvasId, elementConfiguration);

  const { isLoading, resources, rendererDispatch, canvas, factory } = renderer;

  useEffect(() => {
    if (showOrigin && !isSettingsLoading && factory) {
      const gridSize = settings?.measurement_unit === "english" ? 254 : 100;

      const grid = factory.createGridElement({
        showOrigin: false,
        showGrid: true,
        gridSize,
        gridSubDivs: 10,
      });
      const origin = factory.createGridElement({
        showOrigin: true,
        showGrid: false,
      });

      setGridElements({ grid, origin });
    }
  }, [factory, showOrigin, settings, isSettingsLoading, setGridElements]);

  const rendererDesignElement = design.rfm_url
    ? getRenderResourceByURL(resources, design.rfm_url)
    : undefined;

  useEffect(() => {
    if (isLoading || !rendererDesignElement) {
      return;
    }

    rendererDispatch(
      new FusionSetBlankPictureAndDesignAction(
        undefined,
        rendererDesignElement as StitchElement,
        undefined,
        gridElements
      )
    );
  }, [isLoading, rendererDesignElement, rendererDispatch, gridElements]);

  useEffect(() => {
    if (isLoading || !rendererDesignElement) {
      return;
    }

    rendererDispatch(
      new FusionElementConfigurationChangedAction(
        rendererDesignElement as StitchElement,
        elementConfiguration,
        resources
      )
    );
  }, [
    isLoading,
    resources,
    rendererDesignElement,
    elementConfiguration,
    rendererDispatch,
    gridElements,
  ]);

  // handle canvas size changes
  useEffect(() => {
    if (canvas && renderer.renderer) {
      const resizeObserver = new ResizeObserver(() => {
        if (renderer.renderer) {
          renderer.renderer.handleCanvasSizeChanged();
        }

        // force recalculation of new viewport
        const { clientWidth, clientHeight } = canvas;

        if (renderer.elementUtil) {
          rendererDispatch(
            new FusionZoomToFitAction(
              undefined,
              clientWidth,
              clientHeight,
              renderer.elementUtil
            )
          );
        }
      });

      resizeObserver.observe(canvas);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [
    canvas,
    renderer.renderer,
    renderer.elementUtil,
    rendererDispatch,
    rendererDesignElement,
  ]);

  return renderer;
};
