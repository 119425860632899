/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoModelsCreateStatusRecord
 */
export interface MelcoModelsCreateStatusRecord {
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsCreateStatusRecord
     */
    id?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MelcoModelsCreateStatusRecord
     */
    app_time_stamp?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoModelsCreateStatusRecord
     */
    app_status?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsCreateStatusRecord
     */
    app_run_location?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoModelsCreateStatusRecord
     */
    duration?: number;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsCreateStatusRecord
     */
    status_message?: string | null;
}

export function MelcoModelsCreateStatusRecordFromJSON(json: any): MelcoModelsCreateStatusRecord {
    return MelcoModelsCreateStatusRecordFromJSONTyped(json, false);
}

export function MelcoModelsCreateStatusRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsCreateStatusRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'app_time_stamp': !exists(json, 'app_time_stamp') ? undefined : (new Date(json['app_time_stamp'])),
        'app_status': !exists(json, 'app_status') ? undefined : json['app_status'],
        'app_run_location': !exists(json, 'app_run_location') ? undefined : json['app_run_location'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'status_message': !exists(json, 'status_message') ? undefined : json['status_message'],
    };
}

export function MelcoModelsCreateStatusRecordToJSON(value?: MelcoModelsCreateStatusRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'app_time_stamp': value.app_time_stamp === undefined ? undefined : (value.app_time_stamp.toISOString()),
        'app_status': value.app_status,
        'app_run_location': value.app_run_location,
        'duration': value.duration,
        'status_message': value.status_message,
    };
}


