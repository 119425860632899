/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoModelsNotification
 */
export interface MelcoModelsNotification {
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsNotification
     */
    event_type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsNotification
     */
    desc?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsNotification
     */
    status?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsNotification
     */
    message?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MelcoModelsNotification
     */
    created_on?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MelcoModelsNotification
     */
    completed_on?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsNotification
     */
    source?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsNotification
     */
    source_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsNotification
     */
    id?: string | null;
}

export function MelcoModelsNotificationFromJSON(json: any): MelcoModelsNotification {
    return MelcoModelsNotificationFromJSONTyped(json, false);
}

export function MelcoModelsNotificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsNotification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'event_type': !exists(json, 'event_type') ? undefined : json['event_type'],
        'desc': !exists(json, 'desc') ? undefined : json['desc'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'created_on': !exists(json, 'created_on') ? undefined : (new Date(json['created_on'])),
        'completed_on': !exists(json, 'completed_on') ? undefined : (json['completed_on'] === null ? null : new Date(json['completed_on'])),
        'source': !exists(json, 'source') ? undefined : json['source'],
        'source_id': !exists(json, 'source_id') ? undefined : json['source_id'],
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function MelcoModelsNotificationToJSON(value?: MelcoModelsNotification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'event_type': value.event_type,
        'desc': value.desc,
        'status': value.status,
        'message': value.message,
        'created_on': value.created_on === undefined ? undefined : (value.created_on.toISOString()),
        'completed_on': value.completed_on === undefined ? undefined : (value.completed_on === null ? null : value.completed_on.toISOString()),
        'source': value.source,
        'source_id': value.source_id,
        'id': value.id,
    };
}


