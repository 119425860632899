/* tslint:disable */
/* eslint-disable */
/**
 * Melco Reporting APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ReportingMelcoMachineStatisticsModelsAuthViewerLink,
    ReportingMelcoMachineStatisticsModelsAuthViewerLinkFromJSON,
    ReportingMelcoMachineStatisticsModelsAuthViewerLinkToJSON,
    ReportingMelcoMachineStatisticsModelsCreateViewerLink,
    ReportingMelcoMachineStatisticsModelsCreateViewerLinkFromJSON,
    ReportingMelcoMachineStatisticsModelsCreateViewerLinkToJSON,
    ReportingMelcoMachineStatisticsModelsValidateAccessToken,
    ReportingMelcoMachineStatisticsModelsValidateAccessTokenFromJSON,
    ReportingMelcoMachineStatisticsModelsValidateAccessTokenToJSON,
    ReportingMelcoMachineStatisticsModelsValidateResponse,
    ReportingMelcoMachineStatisticsModelsValidateResponseFromJSON,
    ReportingMelcoMachineStatisticsModelsValidateResponseToJSON,
    ReportingMelcoMachineStatisticsModelsView,
    ReportingMelcoMachineStatisticsModelsViewFromJSON,
    ReportingMelcoMachineStatisticsModelsViewToJSON,
    ReportingMelcoMachineStatisticsModelsViewerLink,
    ReportingMelcoMachineStatisticsModelsViewerLinkFromJSON,
    ReportingMelcoMachineStatisticsModelsViewerLinkToJSON,
    ReportingMelcoModelsError,
    ReportingMelcoModelsErrorFromJSON,
    ReportingMelcoModelsErrorToJSON,
} from '../models';

export interface ViewerLinkApiStatisticalViewerLinkAuthRequest {
    id: string;
    reportingMelcoMachineStatisticsModelsAuthViewerLink?: ReportingMelcoMachineStatisticsModelsAuthViewerLink;
}

export interface ViewerLinkApiStatisticalViewerLinkCreateRequest {
    id: string;
    reportingMelcoMachineStatisticsModelsCreateViewerLink?: ReportingMelcoMachineStatisticsModelsCreateViewerLink;
}

export interface ViewerLinkApiStatisticalViewerLinkDeleteRequest {
    id: string;
}

export interface ViewerLinkApiStatisticalViewerLinkGetRequest {
    id: string;
}

export interface ViewerLinkApiStatisticalViewerLinkValidateRequest {
    reportingMelcoMachineStatisticsModelsValidateAccessToken?: ReportingMelcoMachineStatisticsModelsValidateAccessToken;
}

/**
 * 
 */
export class ViewerLinkApi extends runtime.BaseAPI {

    /**
     */
    async statisticalViewerLinkAuthRaw(requestParameters: ViewerLinkApiStatisticalViewerLinkAuthRequest): Promise<runtime.ApiResponse<ReportingMelcoMachineStatisticsModelsView>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statisticalViewerLinkAuth.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/v1/machines/status/views/{id}/viewerlink`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReportingMelcoMachineStatisticsModelsAuthViewerLinkToJSON(requestParameters.reportingMelcoMachineStatisticsModelsAuthViewerLink),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportingMelcoMachineStatisticsModelsViewFromJSON(jsonValue));
    }

    /**
     */
    async statisticalViewerLinkAuth(requestParameters: ViewerLinkApiStatisticalViewerLinkAuthRequest): Promise<ReportingMelcoMachineStatisticsModelsView> {
        const response = await this.statisticalViewerLinkAuthRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async statisticalViewerLinkCreateRaw(requestParameters: ViewerLinkApiStatisticalViewerLinkCreateRequest): Promise<runtime.ApiResponse<ReportingMelcoMachineStatisticsModelsViewerLink>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statisticalViewerLinkCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/v1/machines/views/{id}/viewerlink`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReportingMelcoMachineStatisticsModelsCreateViewerLinkToJSON(requestParameters.reportingMelcoMachineStatisticsModelsCreateViewerLink),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportingMelcoMachineStatisticsModelsViewerLinkFromJSON(jsonValue));
    }

    /**
     */
    async statisticalViewerLinkCreate(requestParameters: ViewerLinkApiStatisticalViewerLinkCreateRequest): Promise<ReportingMelcoMachineStatisticsModelsViewerLink> {
        const response = await this.statisticalViewerLinkCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async statisticalViewerLinkDeleteRaw(requestParameters: ViewerLinkApiStatisticalViewerLinkDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statisticalViewerLinkDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/machines/views/{id}/viewerlink`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async statisticalViewerLinkDelete(requestParameters: ViewerLinkApiStatisticalViewerLinkDeleteRequest): Promise<void> {
        await this.statisticalViewerLinkDeleteRaw(requestParameters);
    }

    /**
     */
    async statisticalViewerLinkGetRaw(requestParameters: ViewerLinkApiStatisticalViewerLinkGetRequest): Promise<runtime.ApiResponse<ReportingMelcoMachineStatisticsModelsViewerLink>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statisticalViewerLinkGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/machines/views/{id}/viewerlink`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportingMelcoMachineStatisticsModelsViewerLinkFromJSON(jsonValue));
    }

    /**
     */
    async statisticalViewerLinkGet(requestParameters: ViewerLinkApiStatisticalViewerLinkGetRequest): Promise<ReportingMelcoMachineStatisticsModelsViewerLink> {
        const response = await this.statisticalViewerLinkGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async statisticalViewerLinkValidateRaw(requestParameters: ViewerLinkApiStatisticalViewerLinkValidateRequest): Promise<runtime.ApiResponse<ReportingMelcoMachineStatisticsModelsValidateResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/v1/machines/status/views/viewerlink/accesstoken/validate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReportingMelcoMachineStatisticsModelsValidateAccessTokenToJSON(requestParameters.reportingMelcoMachineStatisticsModelsValidateAccessToken),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportingMelcoMachineStatisticsModelsValidateResponseFromJSON(jsonValue));
    }

    /**
     */
    async statisticalViewerLinkValidate(requestParameters: ViewerLinkApiStatisticalViewerLinkValidateRequest): Promise<ReportingMelcoMachineStatisticsModelsValidateResponse> {
        const response = await this.statisticalViewerLinkValidateRaw(requestParameters);
        return await response.value();
    }

}
