import {
  BulkActionContainerCol,
  Button,
  Col,
  PlusIcon,
  RemoveIcon,
  Table,
  TableActionBarRow,
} from "melco-ui";
import { useTranslation } from "react-i18next";
import {
  BulkActionCount,
  MelcoModelsDesignListItem,
  Search,
  ViewContext,
  useTableSelection,
} from "melco-shared-logic";
import { useDesigns } from "../../../hooks/libraries/designs/useDesigns";
import { designsListColumns } from "../../libraries/designs/DesignsList";
import { useAddDesigns } from "../../../hooks/libraries/designCollections/useAddDesigns";
import { BulkActionAddDesigns } from "./BulkActionAddDesigns";

export const AddDesignList: React.FC = () => {
  const { addDesigns, removeDesigns, isAlreadyAdded } = useAddDesigns();

  const { selectedRowKeys, setSelectedRowKeys, rowSelection, onRow } =
    useTableSelection<MelcoModelsDesignListItem>(() => {});

  const {
    isLoading,
    isFetching,
    data,
    isPreviousData,
    errorComponent,
    onTableConfigChange,
    pagination,
    searchQueryChange,
  } = useDesigns("published");

  const { t } = useTranslation();

  const columns = [
    ...designsListColumns(t, ViewContext.Drawer),
    {
      title: "",
      key: "action",
      dataIndex: "action",
      align: "right" as const,
      render: (_: string, design: MelcoModelsDesignListItem) => {
        return isAlreadyAdded(design) ? (
          <Button
            icon={<RemoveIcon />}
            size="small"
            onClick={() => removeDesigns([{ id: design.id! }])}
          >
            {t(
              "libraries.design_collections.actions.add_designs.actions.remove"
            )}
          </Button>
        ) : (
          <Button
            type="primary"
            icon={<PlusIcon />}
            size="small"
            onClick={() => addDesigns([design])}
          >
            {t("libraries.design_collections.actions.add_designs.actions.add")}
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <TableActionBarRow>
        <Col>
          <Search
            onSearchInputChange={(query: string) => searchQueryChange(query)}
          />
        </Col>
        <BulkActionContainerCol>
          <BulkActionCount selectedCount={selectedRowKeys.length} />
          <BulkActionAddDesigns
            allDesigns={data ?? []}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
          />
        </BulkActionContainerCol>
      </TableActionBarRow>
      <Table
        showSorterTooltip={false}
        sticky
        loading={isPreviousData ? isFetching : isLoading}
        dataSource={data}
        columns={columns}
        onChange={onTableConfigChange}
        pagination={pagination}
        rowKey="id"
        rowSelection={rowSelection}
        onRow={onRow}
        noDataMessage={t("designs_list.empty")}
        errorComponent={errorComponent}
      />
    </>
  );
};
