import React from "react";
import styled, { css } from "styled-components";
import { PlusCircleIcon } from "../../icons/icons";
import { DesignTokens } from "../../../styles/design-tokens";
import { Tag } from "../../tag/Tag";

const { spacings, colors, borders } = DesignTokens;

const Wrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !["disabled"].includes(prop),
})<Pick<TagSelectProps, "disabled">>`
  padding: ${spacings.xsmall}px;
  border: ${borders.width.normal}px solid;
  border-color: ${colors.gray.middle};
  display: flex;
  gap: ${spacings.xsmall}px;
  flex-wrap: wrap;

  .ant-tag {
    margin: 0;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      && {
        background-color: ${colors.gray.light};

        .ant-tag {
          background-color: ${colors.gray.light};
          color: ${colors.main.disabled};
        }

        .ant-tag-close-icon,
        .ant-tag-close-icon:hover {
          color: ${colors.main.disabled};
          cursor: not-allowed;
        }
      }
    `}
`;

const ActionTag = styled(Tag).withConfig({
  shouldForwardProp: (prop) => !["disabled"].includes(prop),
})<Pick<TagSelectProps, "disabled">>`
  border-style: dashed;
  background-color: ${colors.main.negative};
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      && {
        cursor: not-allowed;
        background-color: ${colors.gray.light};
      }
    `}
`;

type TagSelectValue = {
  label: string;
  value: string;
};

export type TagSelectProps = {
  actionLabel: string;
  onActionClick: () => void;
  onRemove: (value: string) => void;
  value?: TagSelectValue[];
  disabled?: boolean;
};

export const TagSelect: React.FC<TagSelectProps> = ({
  actionLabel,
  value,
  onActionClick,
  onRemove,
  disabled,
}) => {
  return (
    <Wrapper disabled={disabled}>
      {value &&
        value.map((value, index) => (
          <Tag
            key={index}
            closable
            onClose={!disabled ? () => onRemove(value.value) : undefined}
          >
            {value.label}
          </Tag>
        ))}
      <ActionTag
        icon={<PlusCircleIcon />}
        onClick={!disabled ? onActionClick : undefined}
        disabled={disabled}
      >
        {actionLabel}
      </ActionTag>
    </Wrapper>
  );
};
