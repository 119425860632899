/* tslint:disable */
/* eslint-disable */
/**
 * Melco Reporting APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReportingMelcoMachineStatisticsModelsMachineListItem,
    ReportingMelcoMachineStatisticsModelsMachineListItemFromJSON,
    ReportingMelcoMachineStatisticsModelsMachineListItemFromJSONTyped,
    ReportingMelcoMachineStatisticsModelsMachineListItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReportingMelcoMachineStatisticsModelsViewGroups
 */
export interface ReportingMelcoMachineStatisticsModelsViewGroups {
    /**
     * 
     * @type {string}
     * @memberof ReportingMelcoMachineStatisticsModelsViewGroups
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportingMelcoMachineStatisticsModelsViewGroups
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReportingMelcoMachineStatisticsModelsViewGroups
     */
    machine_count?: number;
    /**
     * 
     * @type {Date}
     * @memberof ReportingMelcoMachineStatisticsModelsViewGroups
     */
    created_date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReportingMelcoMachineStatisticsModelsViewGroups
     */
    updated_date?: Date | null;
    /**
     * 
     * @type {Array<ReportingMelcoMachineStatisticsModelsMachineListItem>}
     * @memberof ReportingMelcoMachineStatisticsModelsViewGroups
     */
    machine_list?: Array<ReportingMelcoMachineStatisticsModelsMachineListItem> | null;
}

export function ReportingMelcoMachineStatisticsModelsViewGroupsFromJSON(json: any): ReportingMelcoMachineStatisticsModelsViewGroups {
    return ReportingMelcoMachineStatisticsModelsViewGroupsFromJSONTyped(json, false);
}

export function ReportingMelcoMachineStatisticsModelsViewGroupsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportingMelcoMachineStatisticsModelsViewGroups {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'machine_count': !exists(json, 'machine_count') ? undefined : json['machine_count'],
        'created_date': !exists(json, 'created_date') ? undefined : (new Date(json['created_date'])),
        'updated_date': !exists(json, 'updated_date') ? undefined : (json['updated_date'] === null ? null : new Date(json['updated_date'])),
        'machine_list': !exists(json, 'machine_list') ? undefined : (json['machine_list'] === null ? null : (json['machine_list'] as Array<any>).map(ReportingMelcoMachineStatisticsModelsMachineListItemFromJSON)),
    };
}

export function ReportingMelcoMachineStatisticsModelsViewGroupsToJSON(value?: ReportingMelcoMachineStatisticsModelsViewGroups | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'machine_count': value.machine_count,
        'created_date': value.created_date === undefined ? undefined : (value.created_date.toISOString()),
        'updated_date': value.updated_date === undefined ? undefined : (value.updated_date === null ? null : value.updated_date.toISOString()),
        'machine_list': value.machine_list === undefined ? undefined : (value.machine_list === null ? null : (value.machine_list as Array<any>).map(ReportingMelcoMachineStatisticsModelsMachineListItemToJSON)),
    };
}


