/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoModelsChangeUserName
 */
export interface MelcoModelsChangeUserName {
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsChangeUserName
     */
    current_password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsChangeUserName
     */
    user_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsChangeUserName
     */
    new_user_name?: string | null;
}

export function MelcoModelsChangeUserNameFromJSON(json: any): MelcoModelsChangeUserName {
    return MelcoModelsChangeUserNameFromJSONTyped(json, false);
}

export function MelcoModelsChangeUserNameFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsChangeUserName {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'current_password': !exists(json, 'current_password') ? undefined : json['current_password'],
        'user_name': !exists(json, 'user_name') ? undefined : json['user_name'],
        'new_user_name': !exists(json, 'new_user_name') ? undefined : json['new_user_name'],
    };
}

export function MelcoModelsChangeUserNameToJSON(value?: MelcoModelsChangeUserName | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'current_password': value.current_password,
        'user_name': value.user_name,
        'new_user_name': value.new_user_name,
    };
}


