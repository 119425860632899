import {
  FusionMelcoModelsUserImage,
  useAPIAction,
  UserContentsDraftPublishApi,
} from "melco-shared-logic";
import { useFormikContext } from "formik";
import { QueryObserverResult } from "react-query";
import { blankPictureToInitialValues } from "../../../../components/libraries/blanks/blankPictures/BlankPictureDetailForm";

export const useRevertBlankPicture = (
  blankPictureId: string,
  refetch: () => Promise<
    QueryObserverResult<FusionMelcoModelsUserImage, unknown>
  >
) => {
  const { resetForm } = useFormikContext();

  return useAPIAction(
    UserContentsDraftPublishApi,
    async (api) => {
      const data = await api.userImageRevert({
        id: blankPictureId,
      });

      return {
        successMessages: [
          {
            message: "default",
          },
        ],
        data,
      };
    },
    {
      translationPrefix: "libraries.blanks.blank_pictures.detail.revert",
      onSuccess: async () => {
        const refetchResult = await refetch();

        resetForm({
          values: refetchResult.data
            ? blankPictureToInitialValues(refetchResult.data)
            : {},
        });
      },
    }
  );
};
