/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoModelsUserActivityListItem
 */
export interface MelcoModelsUserActivityListItem {
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserActivityListItem
     */
    activity?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoModelsUserActivityListItem
     */
    is_successful?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserActivityListItem
     */
    ip_address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserActivityListItem
     */
    browser?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserActivityListItem
     */
    device_name?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MelcoModelsUserActivityListItem
     */
    created_on?: Date;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserActivityListItem
     */
    comments?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserActivityListItem
     */
    app_version?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserActivityListItem
     */
    os_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserActivityListItem
     */
    os_version?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserActivityListItem
     */
    os_architecture?: string | null;
}

export function MelcoModelsUserActivityListItemFromJSON(json: any): MelcoModelsUserActivityListItem {
    return MelcoModelsUserActivityListItemFromJSONTyped(json, false);
}

export function MelcoModelsUserActivityListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsUserActivityListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activity': !exists(json, 'activity') ? undefined : json['activity'],
        'is_successful': !exists(json, 'is_successful') ? undefined : json['is_successful'],
        'ip_address': !exists(json, 'ip_address') ? undefined : json['ip_address'],
        'browser': !exists(json, 'browser') ? undefined : json['browser'],
        'device_name': !exists(json, 'device_name') ? undefined : json['device_name'],
        'created_on': !exists(json, 'created_on') ? undefined : (new Date(json['created_on'])),
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
        'app_version': !exists(json, 'app_version') ? undefined : json['app_version'],
        'os_name': !exists(json, 'os_name') ? undefined : json['os_name'],
        'os_version': !exists(json, 'os_version') ? undefined : json['os_version'],
        'os_architecture': !exists(json, 'os_architecture') ? undefined : json['os_architecture'],
    };
}

export function MelcoModelsUserActivityListItemToJSON(value?: MelcoModelsUserActivityListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'activity': value.activity,
        'is_successful': value.is_successful,
        'ip_address': value.ip_address,
        'browser': value.browser,
        'device_name': value.device_name,
        'created_on': value.created_on === undefined ? undefined : (value.created_on.toISOString()),
        'comments': value.comments,
        'app_version': value.app_version,
        'os_name': value.os_name,
        'os_version': value.os_version,
        'os_architecture': value.os_architecture,
    };
}


