/* tslint:disable */
/* eslint-disable */
/**
 * Melco Efs APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EfsMelcoEfsModelsQueueProcessor
 */
export interface EfsMelcoEfsModelsQueueProcessor {
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoEfsModelsQueueProcessor
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoEfsModelsQueueProcessor
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EfsMelcoEfsModelsQueueProcessor
     */
    jobs_completed?: number;
    /**
     * 
     * @type {number}
     * @memberof EfsMelcoEfsModelsQueueProcessor
     */
    jobs_failed?: number;
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoEfsModelsQueueProcessor
     */
    status?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof EfsMelcoEfsModelsQueueProcessor
     */
    last_reported?: Date;
}

export function EfsMelcoEfsModelsQueueProcessorFromJSON(json: any): EfsMelcoEfsModelsQueueProcessor {
    return EfsMelcoEfsModelsQueueProcessorFromJSONTyped(json, false);
}

export function EfsMelcoEfsModelsQueueProcessorFromJSONTyped(json: any, ignoreDiscriminator: boolean): EfsMelcoEfsModelsQueueProcessor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'jobs_completed': !exists(json, 'jobs_completed') ? undefined : json['jobs_completed'],
        'jobs_failed': !exists(json, 'jobs_failed') ? undefined : json['jobs_failed'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'last_reported': !exists(json, 'last_reported') ? undefined : (new Date(json['last_reported'])),
    };
}

export function EfsMelcoEfsModelsQueueProcessorToJSON(value?: EfsMelcoEfsModelsQueueProcessor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'jobs_completed': value.jobs_completed,
        'jobs_failed': value.jobs_failed,
        'status': value.status,
        'last_reported': value.last_reported === undefined ? undefined : (value.last_reported.toISOString()),
    };
}


