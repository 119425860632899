/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoModelsSessionDailySummary
 */
export interface MelcoModelsSessionDailySummary {
    /**
     * 
     * @type {Date}
     * @memberof MelcoModelsSessionDailySummary
     */
    date?: Date;
    /**
     * 
     * @type {number}
     * @memberof MelcoModelsSessionDailySummary
     */
    dropped?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoModelsSessionDailySummary
     */
    completed?: number;
}

export function MelcoModelsSessionDailySummaryFromJSON(json: any): MelcoModelsSessionDailySummary {
    return MelcoModelsSessionDailySummaryFromJSONTyped(json, false);
}

export function MelcoModelsSessionDailySummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsSessionDailySummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'dropped': !exists(json, 'dropped') ? undefined : json['dropped'],
        'completed': !exists(json, 'completed') ? undefined : json['completed'],
    };
}

export function MelcoModelsSessionDailySummaryToJSON(value?: MelcoModelsSessionDailySummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'dropped': value.dropped,
        'completed': value.completed,
    };
}


