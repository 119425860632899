import { isEmpty } from "lodash-es";
import { Button, RemoveIcon } from "melco-ui";
import { useTranslation } from "react-i18next";
import { Key } from "antd/lib/table/interface";
import { useAddBlankPictures } from "../../../hooks/libraries/blanks/useAddBlankPictures";
import { FusionMelcoModelsUserImage } from "melco-shared-logic";

type BulkActionRemoveBlankPicturesProps = {
  allBlankPictures: FusionMelcoModelsUserImage[];
  selectedRowKeys: Key[];
  setSelectedRowKeys: (keys: Key[]) => void;
};

export const BulkActionRemoveBlankPictures: React.FC<
  BulkActionRemoveBlankPicturesProps
> = ({ allBlankPictures, selectedRowKeys, setSelectedRowKeys }) => {
  const { t } = useTranslation();

  const { removeBlankPictures } = useAddBlankPictures();

  if (isEmpty(selectedRowKeys)) {
    return null;
  }

  return (
    <Button
      key="bulk-remove"
      icon={<RemoveIcon />}
      onClick={() => {
        const toRemove = allBlankPictures.filter((bp) =>
          selectedRowKeys.includes(bp.id!)
        );

        removeBlankPictures(toRemove);
        setSelectedRowKeys([]);
      }}
    >
      {t("blanks.actions.add_blank_pictures.actions.remove")}
    </Button>
  );
};
