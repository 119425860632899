/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoModelsUserProductTrial
 */
export interface MelcoModelsUserProductTrial {
    /**
     * 
     * @type {Date}
     * @memberof MelcoModelsUserProductTrial
     */
    expires_on?: Date | null;
}

export function MelcoModelsUserProductTrialFromJSON(json: any): MelcoModelsUserProductTrial {
    return MelcoModelsUserProductTrialFromJSONTyped(json, false);
}

export function MelcoModelsUserProductTrialFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsUserProductTrial {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'expires_on': !exists(json, 'expires_on') ? undefined : (json['expires_on'] === null ? null : new Date(json['expires_on'])),
    };
}

export function MelcoModelsUserProductTrialToJSON(value?: MelcoModelsUserProductTrial | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'expires_on': value.expires_on === undefined ? undefined : (value.expires_on === null ? null : value.expires_on.toISOString()),
    };
}


