/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MelcoModelsApiKey,
    MelcoModelsApiKeyFromJSON,
    MelcoModelsApiKeyToJSON,
    MelcoModelsBoolResponse,
    MelcoModelsBoolResponseFromJSON,
    MelcoModelsBoolResponseToJSON,
    MelcoModelsBridgeSerialNumber,
    MelcoModelsBridgeSerialNumberFromJSON,
    MelcoModelsBridgeSerialNumberToJSON,
    MelcoModelsChangeLicenseLevel,
    MelcoModelsChangeLicenseLevelFromJSON,
    MelcoModelsChangeLicenseLevelToJSON,
    MelcoModelsCreateApiKey,
    MelcoModelsCreateApiKeyFromJSON,
    MelcoModelsCreateApiKeyToJSON,
    MelcoModelsCreateHoop,
    MelcoModelsCreateHoopFromJSON,
    MelcoModelsCreateHoopToJSON,
    MelcoModelsCreateSupplier,
    MelcoModelsCreateSupplierFromJSON,
    MelcoModelsCreateSupplierToJSON,
    MelcoModelsCreateSupplierProduct,
    MelcoModelsCreateSupplierProductFromJSON,
    MelcoModelsCreateSupplierProductToJSON,
    MelcoModelsEmail,
    MelcoModelsEmailFromJSON,
    MelcoModelsEmailToJSON,
    MelcoModelsError,
    MelcoModelsErrorFromJSON,
    MelcoModelsErrorToJSON,
    MelcoModelsGenericList1MelcoModelsApiKey,
    MelcoModelsGenericList1MelcoModelsApiKeyFromJSON,
    MelcoModelsGenericList1MelcoModelsApiKeyToJSON,
    MelcoModelsGenericList1MelcoModelsCategories,
    MelcoModelsGenericList1MelcoModelsCategoriesFromJSON,
    MelcoModelsGenericList1MelcoModelsCategoriesToJSON,
    MelcoModelsGenericList1MelcoModelsEmailListItem,
    MelcoModelsGenericList1MelcoModelsEmailListItemFromJSON,
    MelcoModelsGenericList1MelcoModelsEmailListItemToJSON,
    MelcoModelsGenericList1MelcoModelsHoopListItem,
    MelcoModelsGenericList1MelcoModelsHoopListItemFromJSON,
    MelcoModelsGenericList1MelcoModelsHoopListItemToJSON,
    MelcoModelsGenericList1MelcoModelsLocaleModule,
    MelcoModelsGenericList1MelcoModelsLocaleModuleFromJSON,
    MelcoModelsGenericList1MelcoModelsLocaleModuleToJSON,
    MelcoModelsGenericList1MelcoModelsLocaleString,
    MelcoModelsGenericList1MelcoModelsLocaleStringFromJSON,
    MelcoModelsGenericList1MelcoModelsLocaleStringToJSON,
    MelcoModelsGenericList1MelcoModelsLocaleStringListItem,
    MelcoModelsGenericList1MelcoModelsLocaleStringListItemFromJSON,
    MelcoModelsGenericList1MelcoModelsLocaleStringListItemToJSON,
    MelcoModelsGenericList1MelcoModelsSerialNumberHistory,
    MelcoModelsGenericList1MelcoModelsSerialNumberHistoryFromJSON,
    MelcoModelsGenericList1MelcoModelsSerialNumberHistoryToJSON,
    MelcoModelsGenericList1MelcoModelsSupplier,
    MelcoModelsGenericList1MelcoModelsSupplierFromJSON,
    MelcoModelsGenericList1MelcoModelsSupplierToJSON,
    MelcoModelsGenericList1MelcoModelsSupplierProduct,
    MelcoModelsGenericList1MelcoModelsSupplierProductFromJSON,
    MelcoModelsGenericList1MelcoModelsSupplierProductToJSON,
    MelcoModelsHoop,
    MelcoModelsHoopFromJSON,
    MelcoModelsHoopToJSON,
    MelcoModelsLegacySerialNumber,
    MelcoModelsLegacySerialNumberFromJSON,
    MelcoModelsLegacySerialNumberToJSON,
    MelcoModelsLicenseCreateResponse,
    MelcoModelsLicenseCreateResponseFromJSON,
    MelcoModelsLicenseCreateResponseToJSON,
    MelcoModelsLocaleString,
    MelcoModelsLocaleStringFromJSON,
    MelcoModelsLocaleStringToJSON,
    MelcoModelsSupplier,
    MelcoModelsSupplierFromJSON,
    MelcoModelsSupplierToJSON,
    MelcoModelsSupplierProduct,
    MelcoModelsSupplierProductFromJSON,
    MelcoModelsSupplierProductToJSON,
    MelcoModelsTransferSerialNumber,
    MelcoModelsTransferSerialNumberFromJSON,
    MelcoModelsTransferSerialNumberToJSON,
    MelcoModelsUpdateSupplier,
    MelcoModelsUpdateSupplierFromJSON,
    MelcoModelsUpdateSupplierToJSON,
    MelcoModelsUpdateSupplierProduct,
    MelcoModelsUpdateSupplierProductFromJSON,
    MelcoModelsUpdateSupplierProductToJSON,
    MelcoModelsUpgradeLicense,
    MelcoModelsUpgradeLicenseFromJSON,
    MelcoModelsUpgradeLicenseToJSON,
    MelcoModelsUserLicense,
    MelcoModelsUserLicenseFromJSON,
    MelcoModelsUserLicenseToJSON,
    MelcoModelsUserProductPermissions,
    MelcoModelsUserProductPermissionsFromJSON,
    MelcoModelsUserProductPermissionsToJSON,
    MelcoModelsUserSerialNumber,
    MelcoModelsUserSerialNumberFromJSON,
    MelcoModelsUserSerialNumberToJSON,
    MelcoModelsUserSerialNumberResponse,
    MelcoModelsUserSerialNumberResponseFromJSON,
    MelcoModelsUserSerialNumberResponseToJSON,
} from '../models';

export interface ControlCenterApiAdminApiKeysCreateRequest {
    melcoModelsCreateApiKey?: MelcoModelsCreateApiKey;
}

export interface ControlCenterApiAdminApiKeysDeleteRequest {
    id: string;
}

export interface ControlCenterApiAdminApiKeysGetListRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    productCode?: string;
    searchText?: string;
}

export interface ControlCenterApiGetBySerialNumberRequest {
    serialNumber: string;
}

export interface ControlCenterApiGetHistoryBySerialNumberRequest {
    serialNumber: string;
}

export interface ControlCenterApiLicensesManagerRequest {
    melcoModelsUserLicense?: MelcoModelsUserLicense;
}

export interface ControlCenterApiLicensesManagerChangeEmailRequest {
    melcoModelsBridgeSerialNumber?: MelcoModelsBridgeSerialNumber;
}

export interface ControlCenterApiLicensesManagerUpdateRequest {
    melcoModelsChangeLicenseLevel?: MelcoModelsChangeLicenseLevel;
}

export interface ControlCenterApiLicensesManagerUpgradeRequest {
    melcoModelsUpgradeLicense?: MelcoModelsUpgradeLicense;
}

export interface ControlCenterApiManageCategoriesSearchRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    searchText?: string;
    publishState?: string;
    categoryType?: string;
}

export interface ControlCenterApiManageGetEmailRequest {
    id: string;
}

export interface ControlCenterApiManageHoopsCreateDraftRequest {
    melcoModelsCreateHoop?: MelcoModelsCreateHoop;
}

export interface ControlCenterApiManageHoopsDeleteRequest {
    id: string;
}

export interface ControlCenterApiManageHoopsDuplicateDraftRequest {
    id: string;
}

export interface ControlCenterApiManageHoopsGetDefRequest {
    id: string;
    publishState?: string;
}

export interface ControlCenterApiManageHoopsPublishRequest {
    id: string;
}

export interface ControlCenterApiManageHoopsRevertRequest {
    id: string;
}

export interface ControlCenterApiManageHoopsSearchRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    publishState?: string;
    searchText?: string;
    name?: string;
    productList?: string;
    machineList?: string;
    categoryList?: string;
    version?: string;
}

export interface ControlCenterApiManageHoopsUpdateRequest {
    id: string;
    melcoModelsCreateHoop?: MelcoModelsCreateHoop;
}

export interface ControlCenterApiManageHoopsUploadRequest {
    id: string;
    file?: Blob;
}

export interface ControlCenterApiManageLocalizationEditRequest {
    id: string;
    moduleCode: string;
    melcoModelsLocaleString?: MelcoModelsLocaleString;
}

export interface ControlCenterApiManageLocalizationExportRequest {
    moduleCode: string;
    cultureCode?: string;
}

export interface ControlCenterApiManageLocalizationGetRequest {
    id: string;
    moduleCode: string;
}

export interface ControlCenterApiManageLocalizationGetListRequest {
    moduleCode: string;
    cultureCode?: string;
    searchText?: string;
    offset?: number;
    limit?: number;
    sortBy?: string;
}

export interface ControlCenterApiManageLocalizationImportRequest {
    moduleCode: string;
    cultureCode?: string;
    file?: Blob;
}

export interface ControlCenterApiManageSearchEmailsRequest {
    searchText?: string;
    offset?: number;
    limit?: number;
    sortBy?: string;
    emailAddress?: string;
    productCodeList?: string;
    startTimestamp?: Date;
    endTimestamp?: Date;
}

export interface ControlCenterApiManageSupplierDeleteRequest {
    id: string;
}

export interface ControlCenterApiManageSupplierDraftCreateRequest {
    melcoModelsCreateSupplier?: MelcoModelsCreateSupplier;
}

export interface ControlCenterApiManageSupplierDraftUpdateRequest {
    id: string;
    melcoModelsUpdateSupplier?: MelcoModelsUpdateSupplier;
}

export interface ControlCenterApiManageSupplierDuplicateDraftRequest {
    id: string;
}

export interface ControlCenterApiManageSupplierGetRequest {
    id: string;
    publishState?: string;
}

export interface ControlCenterApiManageSupplierProductDeleteRequest {
    id: string;
}

export interface ControlCenterApiManageSupplierProductDraftCreateRequest {
    melcoModelsCreateSupplierProduct?: MelcoModelsCreateSupplierProduct;
}

export interface ControlCenterApiManageSupplierProductDraftUpdateRequest {
    id: string;
    melcoModelsUpdateSupplierProduct?: MelcoModelsUpdateSupplierProduct;
}

export interface ControlCenterApiManageSupplierProductDuplicateDraftRequest {
    id: string;
}

export interface ControlCenterApiManageSupplierProductPublishRequest {
    id: string;
}

export interface ControlCenterApiManageSupplierProductRevertRequest {
    id: string;
}

export interface ControlCenterApiManageSupplierProductsGetRequest {
    id: string;
    publishState?: string;
}

export interface ControlCenterApiManageSupplierProductsSearchRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    searchText?: string;
    name?: string;
    supplierId?: string;
    supplierName?: string;
    publishState?: string;
    supplierType?: string;
}

export interface ControlCenterApiManageSupplierPublishRequest {
    id: string;
}

export interface ControlCenterApiManageSupplierRevertRequest {
    id: string;
}

export interface ControlCenterApiManageSupplierSearchRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    searchText?: string;
    name?: string;
    publishState?: string;
    supplierType?: string;
}

export interface ControlCenterApiResendEmailSerialNumberRequest {
    serialNumber: string;
}

export interface ControlCenterApiTransferSerialNumberRequest {
    melcoModelsTransferSerialNumber?: MelcoModelsTransferSerialNumber;
}

export interface ControlCenterApiUserAccountEditPermissionRequest {
    userId: string;
    melcoModelsUserProductPermissions?: MelcoModelsUserProductPermissions;
}

export interface ControlCenterApiUserAccountGetPermissionRequest {
    userId: string;
}

/**
 * 
 */
export class ControlCenterApi extends runtime.BaseAPI {

    /**
     * Create New Api Key
     */
    async adminApiKeysCreateRaw(requestParameters: ControlCenterApiAdminApiKeysCreateRequest): Promise<runtime.ApiResponse<MelcoModelsApiKey>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/admin/apikeys`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsCreateApiKeyToJSON(requestParameters.melcoModelsCreateApiKey),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsApiKeyFromJSON(jsonValue));
    }

    /**
     * Create New Api Key
     */
    async adminApiKeysCreate(requestParameters: ControlCenterApiAdminApiKeysCreateRequest): Promise<MelcoModelsApiKey> {
        const response = await this.adminApiKeysCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete Api Key
     */
    async adminApiKeysDeleteRaw(requestParameters: ControlCenterApiAdminApiKeysDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling adminApiKeysDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/apikeys/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Api Key
     */
    async adminApiKeysDelete(requestParameters: ControlCenterApiAdminApiKeysDeleteRequest): Promise<void> {
        await this.adminApiKeysDeleteRaw(requestParameters);
    }

    /**
     * Get ApiKey List
     */
    async adminApiKeysGetListRaw(requestParameters: ControlCenterApiAdminApiKeysGetListRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsApiKey>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.productCode !== undefined) {
            queryParameters['product_code'] = requestParameters.productCode;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/apikeys`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsApiKeyFromJSON(jsonValue));
    }

    /**
     * Get ApiKey List
     */
    async adminApiKeysGetList(requestParameters: ControlCenterApiAdminApiKeysGetListRequest): Promise<MelcoModelsGenericList1MelcoModelsApiKey> {
        const response = await this.adminApiKeysGetListRaw(requestParameters);
        return await response.value();
    }

    /**
     * get serial number
     */
    async getBySerialNumberRaw(requestParameters: ControlCenterApiGetBySerialNumberRequest): Promise<runtime.ApiResponse<MelcoModelsUserSerialNumber>> {
        if (requestParameters.serialNumber === null || requestParameters.serialNumber === undefined) {
            throw new runtime.RequiredError('serialNumber','Required parameter requestParameters.serialNumber was null or undefined when calling getBySerialNumber.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/products/licenses/{serial_number}`.replace(`{${"serial_number"}}`, encodeURIComponent(String(requestParameters.serialNumber))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsUserSerialNumberFromJSON(jsonValue));
    }

    /**
     * get serial number
     */
    async getBySerialNumber(requestParameters: ControlCenterApiGetBySerialNumberRequest): Promise<MelcoModelsUserSerialNumber> {
        const response = await this.getBySerialNumberRaw(requestParameters);
        return await response.value();
    }

    /**
     * get history by serial number
     */
    async getHistoryBySerialNumberRaw(requestParameters: ControlCenterApiGetHistoryBySerialNumberRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsSerialNumberHistory>> {
        if (requestParameters.serialNumber === null || requestParameters.serialNumber === undefined) {
            throw new runtime.RequiredError('serialNumber','Required parameter requestParameters.serialNumber was null or undefined when calling getHistoryBySerialNumber.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/products/licenses/{serial_number}/history`.replace(`{${"serial_number"}}`, encodeURIComponent(String(requestParameters.serialNumber))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsSerialNumberHistoryFromJSON(jsonValue));
    }

    /**
     * get history by serial number
     */
    async getHistoryBySerialNumber(requestParameters: ControlCenterApiGetHistoryBySerialNumberRequest): Promise<MelcoModelsGenericList1MelcoModelsSerialNumberHistory> {
        const response = await this.getHistoryBySerialNumberRaw(requestParameters);
        return await response.value();
    }

    /**
     * create license
     */
    async licensesManagerRaw(requestParameters: ControlCenterApiLicensesManagerRequest): Promise<runtime.ApiResponse<MelcoModelsLicenseCreateResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/manage/license/manager`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsUserLicenseToJSON(requestParameters.melcoModelsUserLicense),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsLicenseCreateResponseFromJSON(jsonValue));
    }

    /**
     * create license
     */
    async licensesManager(requestParameters: ControlCenterApiLicensesManagerRequest): Promise<MelcoModelsLicenseCreateResponse> {
        const response = await this.licensesManagerRaw(requestParameters);
        return await response.value();
    }

    /**
     * change email
     */
    async licensesManagerChangeEmailRaw(requestParameters: ControlCenterApiLicensesManagerChangeEmailRequest): Promise<runtime.ApiResponse<MelcoModelsBoolResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/manage/license/manager/change_email`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsBridgeSerialNumberToJSON(requestParameters.melcoModelsBridgeSerialNumber),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     * change email
     */
    async licensesManagerChangeEmail(requestParameters: ControlCenterApiLicensesManagerChangeEmailRequest): Promise<MelcoModelsBoolResponse> {
        const response = await this.licensesManagerChangeEmailRaw(requestParameters);
        return await response.value();
    }

    /**
     * generate legacy license
     */
    async licensesManagerLegacyLicenseRaw(): Promise<runtime.ApiResponse<MelcoModelsLegacySerialNumber>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/license/manager/legacy_license`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsLegacySerialNumberFromJSON(jsonValue));
    }

    /**
     * generate legacy license
     */
    async licensesManagerLegacyLicense(): Promise<MelcoModelsLegacySerialNumber> {
        const response = await this.licensesManagerLegacyLicenseRaw();
        return await response.value();
    }

    /**
     * change level for license
     */
    async licensesManagerUpdateRaw(requestParameters: ControlCenterApiLicensesManagerUpdateRequest): Promise<runtime.ApiResponse<MelcoModelsUserSerialNumberResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/manage/license/manager/update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsChangeLicenseLevelToJSON(requestParameters.melcoModelsChangeLicenseLevel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsUserSerialNumberResponseFromJSON(jsonValue));
    }

    /**
     * change level for license
     */
    async licensesManagerUpdate(requestParameters: ControlCenterApiLicensesManagerUpdateRequest): Promise<MelcoModelsUserSerialNumberResponse> {
        const response = await this.licensesManagerUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     * upgrade license
     */
    async licensesManagerUpgradeRaw(requestParameters: ControlCenterApiLicensesManagerUpgradeRequest): Promise<runtime.ApiResponse<MelcoModelsLicenseCreateResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/manage/license/manager/upgrade`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsUpgradeLicenseToJSON(requestParameters.melcoModelsUpgradeLicense),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsLicenseCreateResponseFromJSON(jsonValue));
    }

    /**
     * upgrade license
     */
    async licensesManagerUpgrade(requestParameters: ControlCenterApiLicensesManagerUpgradeRequest): Promise<MelcoModelsLicenseCreateResponse> {
        const response = await this.licensesManagerUpgradeRaw(requestParameters);
        return await response.value();
    }

    /**
     * list of categories
     * get categories list
     */
    async manageCategoriesSearchRaw(requestParameters: ControlCenterApiManageCategoriesSearchRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsCategories>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        if (requestParameters.categoryType !== undefined) {
            queryParameters['category_type'] = requestParameters.categoryType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsCategoriesFromJSON(jsonValue));
    }

    /**
     * list of categories
     * get categories list
     */
    async manageCategoriesSearch(requestParameters: ControlCenterApiManageCategoriesSearchRequest): Promise<MelcoModelsGenericList1MelcoModelsCategories> {
        const response = await this.manageCategoriesSearchRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get email from cloud
     */
    async manageGetEmailRaw(requestParameters: ControlCenterApiManageGetEmailRequest): Promise<runtime.ApiResponse<MelcoModelsEmail>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageGetEmail.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/history/emails/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsEmailFromJSON(jsonValue));
    }

    /**
     * Get email from cloud
     */
    async manageGetEmail(requestParameters: ControlCenterApiManageGetEmailRequest): Promise<MelcoModelsEmail> {
        const response = await this.manageGetEmailRaw(requestParameters);
        return await response.value();
    }

    /**
     * creates hoop
     * create hoop
     */
    async manageHoopsCreateDraftRaw(requestParameters: ControlCenterApiManageHoopsCreateDraftRequest): Promise<runtime.ApiResponse<MelcoModelsHoop>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/manage/hoops/draft`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsCreateHoopToJSON(requestParameters.melcoModelsCreateHoop),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsHoopFromJSON(jsonValue));
    }

    /**
     * creates hoop
     * create hoop
     */
    async manageHoopsCreateDraft(requestParameters: ControlCenterApiManageHoopsCreateDraftRequest): Promise<MelcoModelsHoop> {
        const response = await this.manageHoopsCreateDraftRaw(requestParameters);
        return await response.value();
    }

    /**
     * delete hoop
     */
    async manageHoopsDeleteRaw(requestParameters: ControlCenterApiManageHoopsDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageHoopsDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/hoops/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete hoop
     */
    async manageHoopsDelete(requestParameters: ControlCenterApiManageHoopsDeleteRequest): Promise<void> {
        await this.manageHoopsDeleteRaw(requestParameters);
    }

    /**
     * duplicate hoop
     * duplicate hoop draft
     */
    async manageHoopsDuplicateDraftRaw(requestParameters: ControlCenterApiManageHoopsDuplicateDraftRequest): Promise<runtime.ApiResponse<MelcoModelsHoop>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageHoopsDuplicateDraft.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/hoops/{Id}/duplicate/draft`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsHoopFromJSON(jsonValue));
    }

    /**
     * duplicate hoop
     * duplicate hoop draft
     */
    async manageHoopsDuplicateDraft(requestParameters: ControlCenterApiManageHoopsDuplicateDraftRequest): Promise<MelcoModelsHoop> {
        const response = await this.manageHoopsDuplicateDraftRaw(requestParameters);
        return await response.value();
    }

    /**
     * get hoop
     * get hoop by Id
     */
    async manageHoopsGetDefRaw(requestParameters: ControlCenterApiManageHoopsGetDefRequest): Promise<runtime.ApiResponse<MelcoModelsHoop>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageHoopsGetDef.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/hoops/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsHoopFromJSON(jsonValue));
    }

    /**
     * get hoop
     * get hoop by Id
     */
    async manageHoopsGetDef(requestParameters: ControlCenterApiManageHoopsGetDefRequest): Promise<MelcoModelsHoop> {
        const response = await this.manageHoopsGetDefRaw(requestParameters);
        return await response.value();
    }

    /**
     * publish hoop
     * publish hoop
     */
    async manageHoopsPublishRaw(requestParameters: ControlCenterApiManageHoopsPublishRequest): Promise<runtime.ApiResponse<MelcoModelsHoop>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageHoopsPublish.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/hoops/{Id}/publish`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsHoopFromJSON(jsonValue));
    }

    /**
     * publish hoop
     * publish hoop
     */
    async manageHoopsPublish(requestParameters: ControlCenterApiManageHoopsPublishRequest): Promise<MelcoModelsHoop> {
        const response = await this.manageHoopsPublishRaw(requestParameters);
        return await response.value();
    }

    /**
     * revert unpublished changes
     */
    async manageHoopsRevertRaw(requestParameters: ControlCenterApiManageHoopsRevertRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageHoopsRevert.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/hoops/{Id}/revert`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * revert unpublished changes
     */
    async manageHoopsRevert(requestParameters: ControlCenterApiManageHoopsRevertRequest): Promise<void> {
        await this.manageHoopsRevertRaw(requestParameters);
    }

    /**
     * get hoop
     * get hoop list
     */
    async manageHoopsSearchRaw(requestParameters: ControlCenterApiManageHoopsSearchRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsHoopListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.productList !== undefined) {
            queryParameters['product_list'] = requestParameters.productList;
        }

        if (requestParameters.machineList !== undefined) {
            queryParameters['machine_list'] = requestParameters.machineList;
        }

        if (requestParameters.categoryList !== undefined) {
            queryParameters['category_list'] = requestParameters.categoryList;
        }

        if (requestParameters.version !== undefined) {
            queryParameters['version'] = requestParameters.version;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/hoops`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsHoopListItemFromJSON(jsonValue));
    }

    /**
     * get hoop
     * get hoop list
     */
    async manageHoopsSearch(requestParameters: ControlCenterApiManageHoopsSearchRequest): Promise<MelcoModelsGenericList1MelcoModelsHoopListItem> {
        const response = await this.manageHoopsSearchRaw(requestParameters);
        return await response.value();
    }

    /**
     * update existing hoop draft
     */
    async manageHoopsUpdateRaw(requestParameters: ControlCenterApiManageHoopsUpdateRequest): Promise<runtime.ApiResponse<MelcoModelsHoop>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageHoopsUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/manage/hoops/{Id}/draft`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsCreateHoopToJSON(requestParameters.melcoModelsCreateHoop),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsHoopFromJSON(jsonValue));
    }

    /**
     * update existing hoop draft
     */
    async manageHoopsUpdate(requestParameters: ControlCenterApiManageHoopsUpdateRequest): Promise<MelcoModelsHoop> {
        const response = await this.manageHoopsUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     * upload hoop asset
     * upload hoop asset
     */
    async manageHoopsUploadRaw(requestParameters: ControlCenterApiManageHoopsUploadRequest): Promise<runtime.ApiResponse<MelcoModelsHoop>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageHoopsUpload.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/manage/hoops/{Id}/draft/upload`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsHoopFromJSON(jsonValue));
    }

    /**
     * upload hoop asset
     * upload hoop asset
     */
    async manageHoopsUpload(requestParameters: ControlCenterApiManageHoopsUploadRequest): Promise<MelcoModelsHoop> {
        const response = await this.manageHoopsUploadRaw(requestParameters);
        return await response.value();
    }

    /**
     * Edit locale string by id
     */
    async manageLocalizationEditRaw(requestParameters: ControlCenterApiManageLocalizationEditRequest): Promise<runtime.ApiResponse<MelcoModelsLocaleString>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageLocalizationEdit.');
        }

        if (requestParameters.moduleCode === null || requestParameters.moduleCode === undefined) {
            throw new runtime.RequiredError('moduleCode','Required parameter requestParameters.moduleCode was null or undefined when calling manageLocalizationEdit.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/manage/localization/{module_code}/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"module_code"}}`, encodeURIComponent(String(requestParameters.moduleCode))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsLocaleStringToJSON(requestParameters.melcoModelsLocaleString),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsLocaleStringFromJSON(jsonValue));
    }

    /**
     * Edit locale string by id
     */
    async manageLocalizationEdit(requestParameters: ControlCenterApiManageLocalizationEditRequest): Promise<MelcoModelsLocaleString> {
        const response = await this.manageLocalizationEditRaw(requestParameters);
        return await response.value();
    }

    /**
     * export list of localization strings as csv
     */
    async manageLocalizationExportRaw(requestParameters: ControlCenterApiManageLocalizationExportRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.moduleCode === null || requestParameters.moduleCode === undefined) {
            throw new runtime.RequiredError('moduleCode','Required parameter requestParameters.moduleCode was null or undefined when calling manageLocalizationExport.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.cultureCode !== undefined) {
            queryParameters['culture_code'] = requestParameters.cultureCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/localization/{module_code}/export`.replace(`{${"module_code"}}`, encodeURIComponent(String(requestParameters.moduleCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * export list of localization strings as csv
     */
    async manageLocalizationExport(requestParameters: ControlCenterApiManageLocalizationExportRequest): Promise<Blob> {
        const response = await this.manageLocalizationExportRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get string by id
     */
    async manageLocalizationGetRaw(requestParameters: ControlCenterApiManageLocalizationGetRequest): Promise<runtime.ApiResponse<MelcoModelsLocaleString>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageLocalizationGet.');
        }

        if (requestParameters.moduleCode === null || requestParameters.moduleCode === undefined) {
            throw new runtime.RequiredError('moduleCode','Required parameter requestParameters.moduleCode was null or undefined when calling manageLocalizationGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/localization/{module_code}/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"module_code"}}`, encodeURIComponent(String(requestParameters.moduleCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsLocaleStringFromJSON(jsonValue));
    }

    /**
     * Get string by id
     */
    async manageLocalizationGet(requestParameters: ControlCenterApiManageLocalizationGetRequest): Promise<MelcoModelsLocaleString> {
        const response = await this.manageLocalizationGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get list of strings
     */
    async manageLocalizationGetListRaw(requestParameters: ControlCenterApiManageLocalizationGetListRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsLocaleStringListItem>> {
        if (requestParameters.moduleCode === null || requestParameters.moduleCode === undefined) {
            throw new runtime.RequiredError('moduleCode','Required parameter requestParameters.moduleCode was null or undefined when calling manageLocalizationGetList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.cultureCode !== undefined) {
            queryParameters['culture_code'] = requestParameters.cultureCode;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/localization/{module_code}`.replace(`{${"module_code"}}`, encodeURIComponent(String(requestParameters.moduleCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsLocaleStringListItemFromJSON(jsonValue));
    }

    /**
     * Get list of strings
     */
    async manageLocalizationGetList(requestParameters: ControlCenterApiManageLocalizationGetListRequest): Promise<MelcoModelsGenericList1MelcoModelsLocaleStringListItem> {
        const response = await this.manageLocalizationGetListRaw(requestParameters);
        return await response.value();
    }

    /**
     * get module list
     */
    async manageLocalizationGetModuleListRaw(): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsLocaleModule>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/localization/modules`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsLocaleModuleFromJSON(jsonValue));
    }

    /**
     * get module list
     */
    async manageLocalizationGetModuleList(): Promise<MelcoModelsGenericList1MelcoModelsLocaleModule> {
        const response = await this.manageLocalizationGetModuleListRaw();
        return await response.value();
    }

    /**
     * import list of localization strings
     */
    async manageLocalizationImportRaw(requestParameters: ControlCenterApiManageLocalizationImportRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsLocaleString>> {
        if (requestParameters.moduleCode === null || requestParameters.moduleCode === undefined) {
            throw new runtime.RequiredError('moduleCode','Required parameter requestParameters.moduleCode was null or undefined when calling manageLocalizationImport.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.cultureCode !== undefined) {
            queryParameters['culture_code'] = requestParameters.cultureCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/manage/localization/{module_code}/import`.replace(`{${"module_code"}}`, encodeURIComponent(String(requestParameters.moduleCode))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsLocaleStringFromJSON(jsonValue));
    }

    /**
     * import list of localization strings
     */
    async manageLocalizationImport(requestParameters: ControlCenterApiManageLocalizationImportRequest): Promise<MelcoModelsGenericList1MelcoModelsLocaleString> {
        const response = await this.manageLocalizationImportRaw(requestParameters);
        return await response.value();
    }

    /**
     * Search emails
     */
    async manageSearchEmailsRaw(requestParameters: ControlCenterApiManageSearchEmailsRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsEmailListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.emailAddress !== undefined) {
            queryParameters['email_address'] = requestParameters.emailAddress;
        }

        if (requestParameters.productCodeList !== undefined) {
            queryParameters['product_code_list'] = requestParameters.productCodeList;
        }

        if (requestParameters.startTimestamp !== undefined) {
            queryParameters['start_timestamp'] = (requestParameters.startTimestamp as any).toISOString();
        }

        if (requestParameters.endTimestamp !== undefined) {
            queryParameters['end_timestamp'] = (requestParameters.endTimestamp as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/history/emails`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsEmailListItemFromJSON(jsonValue));
    }

    /**
     * Search emails
     */
    async manageSearchEmails(requestParameters: ControlCenterApiManageSearchEmailsRequest): Promise<MelcoModelsGenericList1MelcoModelsEmailListItem> {
        const response = await this.manageSearchEmailsRaw(requestParameters);
        return await response.value();
    }

    /**
     * delete supplier
     */
    async manageSupplierDeleteRaw(requestParameters: ControlCenterApiManageSupplierDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageSupplierDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/suppliers/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete supplier
     */
    async manageSupplierDelete(requestParameters: ControlCenterApiManageSupplierDeleteRequest): Promise<void> {
        await this.manageSupplierDeleteRaw(requestParameters);
    }

    /**
     * create supplier draft
     * Create new supplier draft
     */
    async manageSupplierDraftCreateRaw(requestParameters: ControlCenterApiManageSupplierDraftCreateRequest): Promise<runtime.ApiResponse<MelcoModelsSupplier>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/manage/suppliers/draft`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsCreateSupplierToJSON(requestParameters.melcoModelsCreateSupplier),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsSupplierFromJSON(jsonValue));
    }

    /**
     * create supplier draft
     * Create new supplier draft
     */
    async manageSupplierDraftCreate(requestParameters: ControlCenterApiManageSupplierDraftCreateRequest): Promise<MelcoModelsSupplier> {
        const response = await this.manageSupplierDraftCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * update existing supplier draft
     */
    async manageSupplierDraftUpdateRaw(requestParameters: ControlCenterApiManageSupplierDraftUpdateRequest): Promise<runtime.ApiResponse<MelcoModelsSupplier>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageSupplierDraftUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/manage/suppliers/{Id}/draft`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsUpdateSupplierToJSON(requestParameters.melcoModelsUpdateSupplier),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsSupplierFromJSON(jsonValue));
    }

    /**
     * update existing supplier draft
     */
    async manageSupplierDraftUpdate(requestParameters: ControlCenterApiManageSupplierDraftUpdateRequest): Promise<MelcoModelsSupplier> {
        const response = await this.manageSupplierDraftUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     * duplicate supplier
     * duplicate supplier draft
     */
    async manageSupplierDuplicateDraftRaw(requestParameters: ControlCenterApiManageSupplierDuplicateDraftRequest): Promise<runtime.ApiResponse<MelcoModelsSupplier>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageSupplierDuplicateDraft.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/suppliers/{Id}/duplicate/draft`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsSupplierFromJSON(jsonValue));
    }

    /**
     * duplicate supplier
     * duplicate supplier draft
     */
    async manageSupplierDuplicateDraft(requestParameters: ControlCenterApiManageSupplierDuplicateDraftRequest): Promise<MelcoModelsSupplier> {
        const response = await this.manageSupplierDuplicateDraftRaw(requestParameters);
        return await response.value();
    }

    /**
     * get supplier
     * get supplier by Id
     */
    async manageSupplierGetRaw(requestParameters: ControlCenterApiManageSupplierGetRequest): Promise<runtime.ApiResponse<MelcoModelsSupplier>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageSupplierGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/suppliers/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsSupplierFromJSON(jsonValue));
    }

    /**
     * get supplier
     * get supplier by Id
     */
    async manageSupplierGet(requestParameters: ControlCenterApiManageSupplierGetRequest): Promise<MelcoModelsSupplier> {
        const response = await this.manageSupplierGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * delete supplier product
     */
    async manageSupplierProductDeleteRaw(requestParameters: ControlCenterApiManageSupplierProductDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageSupplierProductDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/suppliers/products/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete supplier product
     */
    async manageSupplierProductDelete(requestParameters: ControlCenterApiManageSupplierProductDeleteRequest): Promise<void> {
        await this.manageSupplierProductDeleteRaw(requestParameters);
    }

    /**
     * create supplier product draft
     * Create new supplier product draft
     */
    async manageSupplierProductDraftCreateRaw(requestParameters: ControlCenterApiManageSupplierProductDraftCreateRequest): Promise<runtime.ApiResponse<MelcoModelsSupplierProduct>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/manage/suppliers/products/draft`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsCreateSupplierProductToJSON(requestParameters.melcoModelsCreateSupplierProduct),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsSupplierProductFromJSON(jsonValue));
    }

    /**
     * create supplier product draft
     * Create new supplier product draft
     */
    async manageSupplierProductDraftCreate(requestParameters: ControlCenterApiManageSupplierProductDraftCreateRequest): Promise<MelcoModelsSupplierProduct> {
        const response = await this.manageSupplierProductDraftCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * update existing supplier product draft
     */
    async manageSupplierProductDraftUpdateRaw(requestParameters: ControlCenterApiManageSupplierProductDraftUpdateRequest): Promise<runtime.ApiResponse<MelcoModelsSupplierProduct>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageSupplierProductDraftUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/manage/suppliers/products/{Id}/draft`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsUpdateSupplierProductToJSON(requestParameters.melcoModelsUpdateSupplierProduct),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsSupplierProductFromJSON(jsonValue));
    }

    /**
     * update existing supplier product draft
     */
    async manageSupplierProductDraftUpdate(requestParameters: ControlCenterApiManageSupplierProductDraftUpdateRequest): Promise<MelcoModelsSupplierProduct> {
        const response = await this.manageSupplierProductDraftUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     * duplicate supplier product
     * duplicate supplier product draft
     */
    async manageSupplierProductDuplicateDraftRaw(requestParameters: ControlCenterApiManageSupplierProductDuplicateDraftRequest): Promise<runtime.ApiResponse<MelcoModelsSupplierProduct>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageSupplierProductDuplicateDraft.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/suppliers/products/{Id}/duplicate/draft`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsSupplierProductFromJSON(jsonValue));
    }

    /**
     * duplicate supplier product
     * duplicate supplier product draft
     */
    async manageSupplierProductDuplicateDraft(requestParameters: ControlCenterApiManageSupplierProductDuplicateDraftRequest): Promise<MelcoModelsSupplierProduct> {
        const response = await this.manageSupplierProductDuplicateDraftRaw(requestParameters);
        return await response.value();
    }

    /**
     * publish supplier product
     * publish supplier product
     */
    async manageSupplierProductPublishRaw(requestParameters: ControlCenterApiManageSupplierProductPublishRequest): Promise<runtime.ApiResponse<MelcoModelsSupplierProduct>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageSupplierProductPublish.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/suppliers/products/{Id}/publish`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsSupplierProductFromJSON(jsonValue));
    }

    /**
     * publish supplier product
     * publish supplier product
     */
    async manageSupplierProductPublish(requestParameters: ControlCenterApiManageSupplierProductPublishRequest): Promise<MelcoModelsSupplierProduct> {
        const response = await this.manageSupplierProductPublishRaw(requestParameters);
        return await response.value();
    }

    /**
     * revert unpublished changes
     */
    async manageSupplierProductRevertRaw(requestParameters: ControlCenterApiManageSupplierProductRevertRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageSupplierProductRevert.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/suppliers/products/{Id}/revert`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * revert unpublished changes
     */
    async manageSupplierProductRevert(requestParameters: ControlCenterApiManageSupplierProductRevertRequest): Promise<void> {
        await this.manageSupplierProductRevertRaw(requestParameters);
    }

    /**
     * get supplier product
     * get supplier product by id
     */
    async manageSupplierProductsGetRaw(requestParameters: ControlCenterApiManageSupplierProductsGetRequest): Promise<runtime.ApiResponse<MelcoModelsSupplierProduct>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageSupplierProductsGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/suppliers/products/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsSupplierProductFromJSON(jsonValue));
    }

    /**
     * get supplier product
     * get supplier product by id
     */
    async manageSupplierProductsGet(requestParameters: ControlCenterApiManageSupplierProductsGetRequest): Promise<MelcoModelsSupplierProduct> {
        const response = await this.manageSupplierProductsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * list of supported products
     * search supplier products
     */
    async manageSupplierProductsSearchRaw(requestParameters: ControlCenterApiManageSupplierProductsSearchRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsSupplierProduct>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.supplierId !== undefined) {
            queryParameters['supplier_id'] = requestParameters.supplierId;
        }

        if (requestParameters.supplierName !== undefined) {
            queryParameters['supplier_name'] = requestParameters.supplierName;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        if (requestParameters.supplierType !== undefined) {
            queryParameters['supplier_type'] = requestParameters.supplierType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/suppliers/products`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsSupplierProductFromJSON(jsonValue));
    }

    /**
     * list of supported products
     * search supplier products
     */
    async manageSupplierProductsSearch(requestParameters: ControlCenterApiManageSupplierProductsSearchRequest): Promise<MelcoModelsGenericList1MelcoModelsSupplierProduct> {
        const response = await this.manageSupplierProductsSearchRaw(requestParameters);
        return await response.value();
    }

    /**
     * publish supplier
     * publish supplier
     */
    async manageSupplierPublishRaw(requestParameters: ControlCenterApiManageSupplierPublishRequest): Promise<runtime.ApiResponse<MelcoModelsSupplier>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageSupplierPublish.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/suppliers/{Id}/publish`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsSupplierFromJSON(jsonValue));
    }

    /**
     * publish supplier
     * publish supplier
     */
    async manageSupplierPublish(requestParameters: ControlCenterApiManageSupplierPublishRequest): Promise<MelcoModelsSupplier> {
        const response = await this.manageSupplierPublishRaw(requestParameters);
        return await response.value();
    }

    /**
     * revert unpublished changes
     */
    async manageSupplierRevertRaw(requestParameters: ControlCenterApiManageSupplierRevertRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling manageSupplierRevert.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/suppliers/{Id}/revert`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * revert unpublished changes
     */
    async manageSupplierRevert(requestParameters: ControlCenterApiManageSupplierRevertRequest): Promise<void> {
        await this.manageSupplierRevertRaw(requestParameters);
    }

    /**
     * list of supplier
     * search supplier list
     */
    async manageSupplierSearchRaw(requestParameters: ControlCenterApiManageSupplierSearchRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsSupplier>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        if (requestParameters.supplierType !== undefined) {
            queryParameters['supplier_type'] = requestParameters.supplierType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/suppliers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsSupplierFromJSON(jsonValue));
    }

    /**
     * list of supplier
     * search supplier list
     */
    async manageSupplierSearch(requestParameters: ControlCenterApiManageSupplierSearchRequest): Promise<MelcoModelsGenericList1MelcoModelsSupplier> {
        const response = await this.manageSupplierSearchRaw(requestParameters);
        return await response.value();
    }

    /**
     * resend email mapped to serial number
     */
    async resendEmailSerialNumberRaw(requestParameters: ControlCenterApiResendEmailSerialNumberRequest): Promise<runtime.ApiResponse<MelcoModelsBoolResponse>> {
        if (requestParameters.serialNumber === null || requestParameters.serialNumber === undefined) {
            throw new runtime.RequiredError('serialNumber','Required parameter requestParameters.serialNumber was null or undefined when calling resendEmailSerialNumber.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/products/licenses/{serial_number}/resend_email`.replace(`{${"serial_number"}}`, encodeURIComponent(String(requestParameters.serialNumber))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     * resend email mapped to serial number
     */
    async resendEmailSerialNumber(requestParameters: ControlCenterApiResendEmailSerialNumberRequest): Promise<MelcoModelsBoolResponse> {
        const response = await this.resendEmailSerialNumberRaw(requestParameters);
        return await response.value();
    }

    /**
     * transfer serial number
     */
    async transferSerialNumberRaw(requestParameters: ControlCenterApiTransferSerialNumberRequest): Promise<runtime.ApiResponse<MelcoModelsBoolResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/manage/products/licenses/transfer`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsTransferSerialNumberToJSON(requestParameters.melcoModelsTransferSerialNumber),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     * transfer serial number
     */
    async transferSerialNumber(requestParameters: ControlCenterApiTransferSerialNumberRequest): Promise<MelcoModelsBoolResponse> {
        const response = await this.transferSerialNumberRaw(requestParameters);
        return await response.value();
    }

    /**
     * edit user permissions for control center
     */
    async userAccountEditPermissionRaw(requestParameters: ControlCenterApiUserAccountEditPermissionRequest): Promise<runtime.ApiResponse<MelcoModelsUserProductPermissions>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling userAccountEditPermission.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/manage/account/{user_id}/manage_access`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsUserProductPermissionsToJSON(requestParameters.melcoModelsUserProductPermissions),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsUserProductPermissionsFromJSON(jsonValue));
    }

    /**
     * edit user permissions for control center
     */
    async userAccountEditPermission(requestParameters: ControlCenterApiUserAccountEditPermissionRequest): Promise<MelcoModelsUserProductPermissions> {
        const response = await this.userAccountEditPermissionRaw(requestParameters);
        return await response.value();
    }

    /**
     * get user product permissions from control center
     */
    async userAccountGetPermissionRaw(requestParameters: ControlCenterApiUserAccountGetPermissionRequest): Promise<runtime.ApiResponse<MelcoModelsUserProductPermissions>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling userAccountGetPermission.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/manage/account/{user_id}/manage_access`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsUserProductPermissionsFromJSON(jsonValue));
    }

    /**
     * get user product permissions from control center
     */
    async userAccountGetPermission(requestParameters: ControlCenterApiUserAccountGetPermissionRequest): Promise<MelcoModelsUserProductPermissions> {
        const response = await this.userAccountGetPermissionRaw(requestParameters);
        return await response.value();
    }

}
