import { useCallback } from "react";
import { UserManagementApi } from "../../api";
import { AuthenticationApi } from "../../api/apis/AuthenticationApi";
import { useBaseAPIConfig } from "../api/useAPIConfig";

export const useSupportToken = () => {
  const baseApiConfig = useBaseAPIConfig();

  return useCallback(
    async (supportToken: string) => {
      const authenticationAPI = new AuthenticationApi(
        baseApiConfig({ skipRedirectToLoginIfUnauthorized: true })
      );

      try {
        const response = await authenticationAPI.supportValidateToken({
          melcoModelsSupportToken: { token: supportToken },
        });

        let {
          user_name: eMail,
          token,
          user_id: userId,
          first_name: firstName,
          last_name: lastName,
          account_name: accountName,
          parent_user_id: parentUserId,
          impersonation_for_id: impersonationForId,
        } = response;

        if (impersonationForId && token) {
          const userAPI = new UserManagementApi(
            baseApiConfig({
              token,
              impersonationForId,
              skipRedirectToLoginIfUnauthorized: true,
            })
          );

          const impersonatedUser = await userAPI.userGetUser({
            id: impersonationForId,
          });

          userId = impersonatedUser.id ?? "";
          eMail = impersonatedUser.email;
          firstName = impersonatedUser.first_name ?? "";
          lastName = impersonatedUser.last_name ?? "";
          accountName = impersonatedUser.account_name ?? "";
          parentUserId = impersonatedUser.parent_user_id ?? undefined;
        }

        return {
          isValidToken: true,
          user: {
            eMail: eMail ?? "",
            token: token ?? "",
            userId: userId ?? "",
            firstName: firstName ?? "",
            lastName: lastName ?? "",
            accountName: accountName ?? "",
            parentUserId: parentUserId ?? undefined,
            impersonationForId: impersonationForId ?? undefined,
          },
        };
      } catch (e) {
        console.error(e);
      }

      return { isValidToken: false };
    },
    [baseApiConfig]
  );
};
