/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoModelsUpdateProduct
 */
export interface MelcoModelsUpdateProduct {
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUpdateProduct
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUpdateProduct
     */
    app_startup_endpoint?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUpdateProduct
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoModelsUpdateProduct
     */
    devices_allowed?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoModelsUpdateProduct
     */
    max_devices_allowed?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoModelsUpdateProduct
     */
    offline_seconds_allowed?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoModelsUpdateProduct
     */
    seconds_to_relogin?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoModelsUpdateProduct
     */
    legal_policy_version?: number;
}

export function MelcoModelsUpdateProductFromJSON(json: any): MelcoModelsUpdateProduct {
    return MelcoModelsUpdateProductFromJSONTyped(json, false);
}

export function MelcoModelsUpdateProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsUpdateProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'app_startup_endpoint': !exists(json, 'app_startup_endpoint') ? undefined : json['app_startup_endpoint'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'devices_allowed': !exists(json, 'devices_allowed') ? undefined : json['devices_allowed'],
        'max_devices_allowed': !exists(json, 'max_devices_allowed') ? undefined : json['max_devices_allowed'],
        'offline_seconds_allowed': !exists(json, 'offline_seconds_allowed') ? undefined : json['offline_seconds_allowed'],
        'seconds_to_relogin': !exists(json, 'seconds_to_relogin') ? undefined : json['seconds_to_relogin'],
        'legal_policy_version': !exists(json, 'legal_policy_version') ? undefined : json['legal_policy_version'],
    };
}

export function MelcoModelsUpdateProductToJSON(value?: MelcoModelsUpdateProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'app_startup_endpoint': value.app_startup_endpoint,
        'description': value.description,
        'devices_allowed': value.devices_allowed,
        'max_devices_allowed': value.max_devices_allowed,
        'offline_seconds_allowed': value.offline_seconds_allowed,
        'seconds_to_relogin': value.seconds_to_relogin,
        'legal_policy_version': value.legal_policy_version,
    };
}


