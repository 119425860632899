import {
  Alert,
  Button,
  DeleteIcon,
  LinkIcon,
  Menu,
  MoreIcon,
  Space,
} from "melco-ui";
import { Dropdown } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { useDeleteColorCollections } from "../../../hooks/libraries/colorCollections/useDeleteColorCollections";
import { useShouldShowWarning } from "../../../hooks/items/useShouldShowWarning";
import {
  FusionMelcoModelsColorCollection,
  useConfirm,
} from "melco-shared-logic";
import { Drawer as DrawerType } from "../../../hooks/drawer/useGenericDrawer";

type ColorCollectionDetailMoreActionButtonProps = {
  colorCollection: FusionMelcoModelsColorCollection;
  referencesDrawer: DrawerType;
};

export const ColorCollectionDetailMoreActionButton: React.FC<
  ColorCollectionDetailMoreActionButtonProps
> = ({ colorCollection, referencesDrawer }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const deleteColorCollection = useDeleteColorCollections();
  const shouldShowWarning = useShouldShowWarning();

  const confirmDelete = useConfirm(
    async () => {
      //TODO: Handle case if colorCollection.id doesnt exist
      if (colorCollection.id) {
        const successfullyDeletedIds = await deleteColorCollection([
          colorCollection.id,
        ]);

        // redirect to list view in case the entity was deleted successfully
        if (!isEmpty(successfullyDeletedIds)) {
          navigate(`/libraries/color-collections/embroidery`);
        }
      }
    },
    {
      translationPrefix: "color_collections.actions.delete",
      translationOptions: {
        count: 1,
      },
      content: shouldShowWarning(colorCollection) && (
        <Space direction="vertical" size="small">
          {t("color_collections.actions.delete.confirmation.message", {
            count: 1,
          })}
          <Alert
            type="warning"
            withBackground
            description={t(
              "color_collections.actions.delete.confirmation.warning",
              {
                count: 1,
              }
            )}
            showIcon={false}
          />
        </Space>
      ),
    }
  );

  const MoreActions = (
    <Menu>
      <Menu.Item
        key="more-actions-references"
        onClick={() => {
          referencesDrawer.openDrawer();
        }}
      >
        <LinkIcon /> {t("color_collections.actions.references.button")}
      </Menu.Item>
      <Menu.Item
        key="more-actions-delete"
        danger
        onClick={() => {
          confirmDelete();
        }}
      >
        <DeleteIcon /> {t("color_collections.actions.delete.button")}
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      overlay={MoreActions}
      trigger={["click"]}
      placement="bottomRight"
      arrow
    >
      <Button
        key="color-collections-more-actions"
        icon={<MoreIcon />}
        data-cy-id="color-collections-more-actions"
      />
    </Dropdown>
  );
};
