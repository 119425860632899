/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoModelsProductVersionListItem
 */
export interface MelcoModelsProductVersionListItem {
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsProductVersionListItem
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsProductVersionListItem
     */
    version_number?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MelcoModelsProductVersionListItem
     */
    created_date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MelcoModelsProductVersionListItem
     */
    updated_date?: Date | null;
}

export function MelcoModelsProductVersionListItemFromJSON(json: any): MelcoModelsProductVersionListItem {
    return MelcoModelsProductVersionListItemFromJSONTyped(json, false);
}

export function MelcoModelsProductVersionListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsProductVersionListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'version_number': !exists(json, 'version_number') ? undefined : json['version_number'],
        'created_date': !exists(json, 'created_date') ? undefined : (new Date(json['created_date'])),
        'updated_date': !exists(json, 'updated_date') ? undefined : (json['updated_date'] === null ? null : new Date(json['updated_date'])),
    };
}

export function MelcoModelsProductVersionListItemToJSON(value?: MelcoModelsProductVersionListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'version_number': value.version_number,
        'created_date': value.created_date === undefined ? undefined : (value.created_date.toISOString()),
        'updated_date': value.updated_date === undefined ? undefined : (value.updated_date === null ? null : value.updated_date.toISOString()),
    };
}


