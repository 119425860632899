/* tslint:disable */
/* eslint-disable */
/**
 * Melco Efs APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EfsMelcoModelsGenericList1MelcoEfsModelsEfsDefaultFormat,
    EfsMelcoModelsGenericList1MelcoEfsModelsEfsDefaultFormatFromJSON,
    EfsMelcoModelsGenericList1MelcoEfsModelsEfsDefaultFormatFromJSONTyped,
    EfsMelcoModelsGenericList1MelcoEfsModelsEfsDefaultFormatToJSON,
    EfsMelcoModelsSelectListItem,
    EfsMelcoModelsSelectListItemFromJSON,
    EfsMelcoModelsSelectListItemFromJSONTyped,
    EfsMelcoModelsSelectListItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface EfsMelcoEfsModelsEfsDefaultAppSetting
 */
export interface EfsMelcoEfsModelsEfsDefaultAppSetting {
    /**
     * 
     * @type {Array<EfsMelcoModelsSelectListItem>}
     * @memberof EfsMelcoEfsModelsEfsDefaultAppSetting
     */
    measurements?: Array<EfsMelcoModelsSelectListItem> | null;
    /**
     * 
     * @type {EfsMelcoModelsGenericList1MelcoEfsModelsEfsDefaultFormat}
     * @memberof EfsMelcoEfsModelsEfsDefaultAppSetting
     */
    output_format_list?: EfsMelcoModelsGenericList1MelcoEfsModelsEfsDefaultFormat;
    /**
     * 
     * @type {boolean}
     * @memberof EfsMelcoEfsModelsEfsDefaultAppSetting
     */
    enable_folder_grouping?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EfsMelcoEfsModelsEfsDefaultAppSetting
     */
    enable_debug?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EfsMelcoEfsModelsEfsDefaultAppSetting
     */
    enable_advanced_options?: boolean;
}

export function EfsMelcoEfsModelsEfsDefaultAppSettingFromJSON(json: any): EfsMelcoEfsModelsEfsDefaultAppSetting {
    return EfsMelcoEfsModelsEfsDefaultAppSettingFromJSONTyped(json, false);
}

export function EfsMelcoEfsModelsEfsDefaultAppSettingFromJSONTyped(json: any, ignoreDiscriminator: boolean): EfsMelcoEfsModelsEfsDefaultAppSetting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'measurements': !exists(json, 'measurements') ? undefined : (json['measurements'] === null ? null : (json['measurements'] as Array<any>).map(EfsMelcoModelsSelectListItemFromJSON)),
        'output_format_list': !exists(json, 'output_format_list') ? undefined : EfsMelcoModelsGenericList1MelcoEfsModelsEfsDefaultFormatFromJSON(json['output_format_list']),
        'enable_folder_grouping': !exists(json, 'enable_folder_grouping') ? undefined : json['enable_folder_grouping'],
        'enable_debug': !exists(json, 'enable_debug') ? undefined : json['enable_debug'],
        'enable_advanced_options': !exists(json, 'enable_advanced_options') ? undefined : json['enable_advanced_options'],
    };
}

export function EfsMelcoEfsModelsEfsDefaultAppSettingToJSON(value?: EfsMelcoEfsModelsEfsDefaultAppSetting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'measurements': value.measurements === undefined ? undefined : (value.measurements === null ? null : (value.measurements as Array<any>).map(EfsMelcoModelsSelectListItemToJSON)),
        'output_format_list': EfsMelcoModelsGenericList1MelcoEfsModelsEfsDefaultFormatToJSON(value.output_format_list),
        'enable_folder_grouping': value.enable_folder_grouping,
        'enable_debug': value.enable_debug,
        'enable_advanced_options': value.enable_advanced_options,
    };
}


