import { useState } from "react";
import {
  DisplayFormErrors,
  InputField,
  FusionMelcoModelsDesign,
  VerticalForm,
  DebugForm,
} from "melco-shared-logic";
import { useTranslation } from "react-i18next";
import { QueryObserverResult } from "react-query";
import {
  Col,
  DesignTokens,
  FullWidthSpace,
  InfoIcon,
  Popover,
  Row,
  Title,
  CanvasActionGroup,
  CanvasActionGroupItem,
  CanvasPanel,
} from "melco-ui";
import styled from "styled-components";
import { SavePublishHeader } from "../../header/SavePublishHeader";
import { useRevertDesign } from "../../../hooks/libraries/designs/useRevertDesign";
import { DesignInfos } from "./DesignInfos";
import { DesignCanvasWorkingArea } from "./DesignCanvasWorkingArea";
import { ElementList } from "./ElementList";
import { ElementDetailPanel } from "./ElementDetailPanel";
import { DesignUpdateField } from "./DesignUpdateField";
import { RenderedDesign } from "../../renderer/RenderedDesign";
import { Drawer as DrawerType } from "../../../hooks/drawer/useGenericDrawer";
import { useDesignReferencesCount } from "../../../hooks/libraries/designs/useDesignReferencesCount";
import { ZoomToDesignButtonPortal } from "./ZoomToDesignButtonPortal";
import { useDesignElementConfigurationFromFormState } from "../../../hooks/libraries/designs/useDesignElementConfigurationFromFormState";

const { spacings } = DesignTokens;

const DesignWrapper = styled.div`
  display: flex;
  margin: 0 -${spacings.xlarge}px;
`;

const LeftCanvasPanel = styled(CanvasPanel)`
  width: 300px;
`;

type DisplayDesignDetailFormProps = {
  design: FusionMelcoModelsDesign;
  refetch: () => Promise<QueryObserverResult<FusionMelcoModelsDesign, unknown>>;
  referencesDrawer?: DrawerType;
};

export const DisplayDesignDetailForm: React.FC<
  DisplayDesignDetailFormProps
> = ({ design, refetch, referencesDrawer }) => {
  const {
    id,
    publish_state: publishState,
    created_date: createdDate,
    updated_date: updatedDate,
    publish_date: publishDate,
    warning_list: warningList,
  } = design;

  const { t } = useTranslation();
  const [revertDesign, isLoading] = useRevertDesign(id!, refetch);

  const elementConfiguration = useDesignElementConfigurationFromFormState(
    design.rfm_url!
  );

  const [currentDesignElementIndex, setCurrentDesignElementIndex] = useState<
    number | undefined
  >(undefined);

  const { total: referencesCount } = useDesignReferencesCount(id!);

  return (
    <>
      <SavePublishHeader
        revertAction={revertDesign}
        isCurrentlyReverting={isLoading}
        publishedState={publishState!}
        createdDate={createdDate!}
        updatedDate={updatedDate}
        publishedDate={publishDate}
        warnings={warningList ?? undefined}
        referencesCount={referencesCount}
        referencesDrawer={referencesDrawer}
      />

      <VerticalForm>
        <FullWidthSpace direction="vertical">
          <div>
            <DisplayFormErrors />

            <Row gutter={[spacings.middle, 0]}>
              <Col xs={24} md={12}>
                <InputField
                  name="name"
                  label={t("libraries.designs.detail.form.label.name")}
                />
              </Col>
              <Col xs={24} md={12}>
                <DesignUpdateField design={design} refetch={refetch} />
              </Col>
            </Row>

            <Title level={5}>
              {t("libraries.designs.detail.form.canvas.title")}
            </Title>

            <DesignWrapper>
              <LeftCanvasPanel
                elevation="right"
                data-cy-id="design-element-list-panel"
              >
                <ElementList
                  setCurrentDesignElementIndex={setCurrentDesignElementIndex}
                />
              </LeftCanvasPanel>

              <DesignCanvasWorkingArea
                actionGroups={
                  <CanvasActionGroup positionY="top" positionX="right">
                    <ZoomToDesignButtonPortal />

                    <Popover
                      content={<DesignInfos design={design} />}
                      title={t(
                        "libraries.designs.detail.form.design_infos.title"
                      )}
                      trigger="click"
                      placement="topRight"
                    >
                      <CanvasActionGroupItem>
                        <InfoIcon data-cy-id="design-infos" />
                      </CanvasActionGroupItem>
                    </Popover>
                  </CanvasActionGroup>
                }
              >
                <RenderedDesign
                  design={design}
                  elementConfiguration={elementConfiguration}
                />
              </DesignCanvasWorkingArea>

              {currentDesignElementIndex != null && (
                <ElementDetailPanel
                  formikPrefix={[
                    "element_list",
                    currentDesignElementIndex,
                  ].join(".")}
                  setCurrentDesignElementIndex={setCurrentDesignElementIndex}
                />
              )}
            </DesignWrapper>
          </div>
        </FullWidthSpace>
      </VerticalForm>

      {false && <DebugForm />}
    </>
  );
};
