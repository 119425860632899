/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoModelsBridgeSerialNumber
 */
export interface MelcoModelsBridgeSerialNumber {
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsBridgeSerialNumber
     */
    new_email_address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsBridgeSerialNumber
     */
    serial_number?: string | null;
}

export function MelcoModelsBridgeSerialNumberFromJSON(json: any): MelcoModelsBridgeSerialNumber {
    return MelcoModelsBridgeSerialNumberFromJSONTyped(json, false);
}

export function MelcoModelsBridgeSerialNumberFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsBridgeSerialNumber {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'new_email_address': !exists(json, 'new_email_address') ? undefined : json['new_email_address'],
        'serial_number': !exists(json, 'serial_number') ? undefined : json['serial_number'],
    };
}

export function MelcoModelsBridgeSerialNumberToJSON(value?: MelcoModelsBridgeSerialNumber | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'new_email_address': value.new_email_address,
        'serial_number': value.serial_number,
    };
}


