/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoModelsLocaleGroup
 */
export interface MelcoModelsLocaleGroup {
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsLocaleGroup
     */
    name?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof MelcoModelsLocaleGroup
     */
    strings?: { [key: string]: string; } | null;
}

export function MelcoModelsLocaleGroupFromJSON(json: any): MelcoModelsLocaleGroup {
    return MelcoModelsLocaleGroupFromJSONTyped(json, false);
}

export function MelcoModelsLocaleGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsLocaleGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'strings': !exists(json, 'strings') ? undefined : json['strings'],
    };
}

export function MelcoModelsLocaleGroupToJSON(value?: MelcoModelsLocaleGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'strings': value.strings,
    };
}


