import { Formik, FormikHelpers } from "formik";
import {
  FusionMelcoModelsUserImage,
  UseApiQueryKey,
  useAuthenticatedAPIConfig,
  useFormSubmit,
  UserContentsDraftPublishApi,
  UserContentsDraftPublishApiUserImageUpdateDraftRequest,
} from "melco-shared-logic";
import { QueryObserverResult, useQueryClient } from "react-query";
import isEmpty from "lodash-es/isEmpty";
import { SubmitAction } from "../../../header/SavePublishHeader";
import { DisplayBlankPictureDetailForm } from "./DisplayBlankPictureDetailForm";
import { Drawer as DrawerType } from "../../../../hooks/drawer/useGenericDrawer";
import { DirtyFormPrompt } from "../../../form/DirtyFormPrompt";
import { queryKeyForBlankPicture } from "../../../../hooks/libraries/blanks/blankPictures/useBlankPicture";
import { ALL_PUBLISHED_STATES } from "../../../../helper/publishedState";

type BlankPictureDetailFormProps = {
  blankPicture: FusionMelcoModelsUserImage;
  refetch: () => Promise<
    QueryObserverResult<FusionMelcoModelsUserImage, unknown>
  >;
  referencesDrawer: DrawerType;
  queryKey: UseApiQueryKey;
};

type BlankPictureDetailFormValueType = {
  id: string;
  name: string;
  file_name?: File[] | undefined;
  color_name: string | undefined;
  color_hex: string | undefined;
  view_name: string | undefined;
  submitAction: SubmitAction;
};

export const blankPictureToInitialValues = (
  blankPicture: FusionMelcoModelsUserImage
) => {
  const { id, name, color_name, color_hex, view_name } = blankPicture;
  const initialValues: BlankPictureDetailFormValueType = {
    id: id!,
    name: name ?? "",
    file_name: undefined,
    color_name: color_name ?? "",
    color_hex: color_hex ?? "",
    view_name: view_name ?? "",
    submitAction: "save",
  };

  return initialValues;
};

export const BlankPictureDetailForm: React.FC<BlankPictureDetailFormProps> = ({
  blankPicture,
  refetch,
  referencesDrawer,
  queryKey,
}) => {
  const queryClient = useQueryClient();
  const authenticatedApiConfig = useAuthenticatedAPIConfig();
  const { id } = blankPicture;

  const onSubmit = async (
    values: BlankPictureDetailFormValueType,
    formikHelpers: FormikHelpers<BlankPictureDetailFormValueType>
  ) => {
    const { submitAction } = values;

    if (submitAction === "publish") {
      await onPublish(values, formikHelpers, { isManuallyResettingForm: true });
    } else {
      await onSave(values, formikHelpers, { isManuallyResettingForm: true });
    }
  };

  const handleSaveAndPublish = async (
    values: BlankPictureDetailFormValueType,
    formikHelpers: FormikHelpers<BlankPictureDetailFormValueType>,
    options: {
      publish: boolean;
    }
  ) => {
    const { publish } = options;
    const { name, file_name, color_name, color_hex, view_name } = values;

    const api = new UserContentsDraftPublishApi(authenticatedApiConfig());

    const entityAttributes: UserContentsDraftPublishApiUserImageUpdateDraftRequest =
      {
        id: id!,
        name,
        colorName: color_name,
        viewName: view_name,
        file: !isEmpty(file_name) ? file_name![0] : undefined,
      };
    if (color_hex) {
      entityAttributes.colorHex = color_hex;
    }

    let newEntity = await api.userImageUpdateDraft(entityAttributes);

    if (publish) {
      newEntity = await api.userImagePublish({
        id: id!,
      });
    }

    // update ui with new data
    formikHelpers.resetForm({
      values: blankPictureToInitialValues(newEntity),
    });
    queryClient.setQueryData(queryKey, newEntity);

    return {
      successMessages: [
        {
          message: "default",
        },
      ],
    };
  };

  const formSubmitWithPublish = (publish: boolean) => {
    return [
      async (
        values: BlankPictureDetailFormValueType,
        formikHelpers: FormikHelpers<BlankPictureDetailFormValueType>
      ) => {
        return handleSaveAndPublish(values, formikHelpers, {
          publish,
        });
      },
      {
        translationPrefix: [
          "libraries.blanks.blank_pictures.detail",
          publish ? "publish" : "save",
        ].join("."),
        onError: () => {
          // refetch entity from server
          queryClient.invalidateQueries(
            queryKeyForBlankPicture(blankPicture.id!, ALL_PUBLISHED_STATES)
          );
        },
      },
    ] as const;
  };

  const onSave = useFormSubmit(...formSubmitWithPublish(false));
  const onPublish = useFormSubmit(...formSubmitWithPublish(true));

  const initialValues = blankPictureToInitialValues(blankPicture);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      <DirtyFormPrompt>
        <DisplayBlankPictureDetailForm
          blankPicture={blankPicture}
          refetch={refetch}
          referencesDrawer={referencesDrawer}
        />
      </DirtyFormPrompt>
    </Formik>
  );
};
