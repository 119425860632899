/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoModelsLegacySerialNumber
 */
export interface MelcoModelsLegacySerialNumber {
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsLegacySerialNumber
     */
    serial_number?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsLegacySerialNumber
     */
    product_code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsLegacySerialNumber
     */
    product_name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoModelsLegacySerialNumber
     */
    product_version?: number;
    /**
     * 
     * @type {Date}
     * @memberof MelcoModelsLegacySerialNumber
     */
    created_date?: Date;
}

export function MelcoModelsLegacySerialNumberFromJSON(json: any): MelcoModelsLegacySerialNumber {
    return MelcoModelsLegacySerialNumberFromJSONTyped(json, false);
}

export function MelcoModelsLegacySerialNumberFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsLegacySerialNumber {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serial_number': !exists(json, 'serial_number') ? undefined : json['serial_number'],
        'product_code': !exists(json, 'product_code') ? undefined : json['product_code'],
        'product_name': !exists(json, 'product_name') ? undefined : json['product_name'],
        'product_version': !exists(json, 'product_version') ? undefined : json['product_version'],
        'created_date': !exists(json, 'created_date') ? undefined : (new Date(json['created_date'])),
    };
}

export function MelcoModelsLegacySerialNumberToJSON(value?: MelcoModelsLegacySerialNumber | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serial_number': value.serial_number,
        'product_code': value.product_code,
        'product_name': value.product_name,
        'product_version': value.product_version,
        'created_date': value.created_date === undefined ? undefined : (value.created_date.toISOString()),
    };
}


