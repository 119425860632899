/* tslint:disable */
/* eslint-disable */
/**
 * Melco Reporting APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportingMelcoMachineStatisticsModelsMachineDesignListItem
 */
export interface ReportingMelcoMachineStatisticsModelsMachineDesignListItem {
    /**
     * 
     * @type {string}
     * @memberof ReportingMelcoMachineStatisticsModelsMachineDesignListItem
     */
    file_name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReportingMelcoMachineStatisticsModelsMachineDesignListItem
     */
    sew_count?: number;
    /**
     * 
     * @type {Date}
     * @memberof ReportingMelcoMachineStatisticsModelsMachineDesignListItem
     */
    last_sew_date?: Date;
}

export function ReportingMelcoMachineStatisticsModelsMachineDesignListItemFromJSON(json: any): ReportingMelcoMachineStatisticsModelsMachineDesignListItem {
    return ReportingMelcoMachineStatisticsModelsMachineDesignListItemFromJSONTyped(json, false);
}

export function ReportingMelcoMachineStatisticsModelsMachineDesignListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportingMelcoMachineStatisticsModelsMachineDesignListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'file_name': !exists(json, 'file_name') ? undefined : json['file_name'],
        'sew_count': !exists(json, 'sew_count') ? undefined : json['sew_count'],
        'last_sew_date': !exists(json, 'last_sew_date') ? undefined : (new Date(json['last_sew_date'])),
    };
}

export function ReportingMelcoMachineStatisticsModelsMachineDesignListItemToJSON(value?: ReportingMelcoMachineStatisticsModelsMachineDesignListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'file_name': value.file_name,
        'sew_count': value.sew_count,
        'last_sew_date': value.last_sew_date === undefined ? undefined : (value.last_sew_date.toISOString()),
    };
}


