import { isEmpty } from "lodash-es";
import { Alert, Button, DeleteIcon, Space } from "melco-ui";
import { useTranslation } from "react-i18next";
import { useDeleteFonts } from "../../../hooks/libraries/fonts/useDeleteFonts";
import { Key } from "antd/lib/table/interface";
import { FusionMelcoModelsUserAlphabet, useConfirm } from "melco-shared-logic";
import { useShowWarningForRowKeys } from "../../../hooks/table/useShowWarningForRowKeys";

type BulkActionDeleteFontsProps<T> = {
  selectedRowKeys: Key[];
  setSelectedRowKeys: (keys: Key[]) => void;
  refetch?: () => Promise<any>;
  data?: T[];
};

export const BulkActionDeleteFonts: React.FC<
  BulkActionDeleteFontsProps<FusionMelcoModelsUserAlphabet>
> = ({ selectedRowKeys, setSelectedRowKeys, refetch, data }) => {
  const { t } = useTranslation();
  const deleteFonts = useDeleteFonts();
  const showWarningForRowKeys = useShowWarningForRowKeys(selectedRowKeys, data);

  const confirmDelete = useConfirm(
    async () => {
      const successfullyDeletedIds = await deleteFonts(
        selectedRowKeys.map((key) => key.toString())
      );

      if (refetch) {
        refetch();
      }

      // keep items that were not deleted selected
      setSelectedRowKeys(
        selectedRowKeys.filter(
          (id) => !successfullyDeletedIds.includes(id.toString())
        )
      );
    },
    {
      translationPrefix: "fonts.actions.delete",
      translationOptions: {
        count: selectedRowKeys.length,
      },
      content: showWarningForRowKeys.length && (
        <Space direction="vertical" size="small">
          {t("fonts.actions.delete.confirmation.message", {
            count: selectedRowKeys.length,
          })}
          <Alert
            type="warning"
            withBackground
            description={t("fonts.actions.delete.confirmation.warning", {
              count: showWarningForRowKeys.length,
            })}
            showIcon={false}
          />
        </Space>
      ),
    }
  );

  if (isEmpty(selectedRowKeys)) {
    return null;
  }

  return (
    <Button
      key="bulk-delete"
      icon={<DeleteIcon />}
      onClick={() => {
        confirmDelete();
      }}
    >
      {t("fonts.actions.delete.button")}
    </Button>
  );
};
