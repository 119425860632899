/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoModelsPermissionsSubListItem,
    MelcoModelsPermissionsSubListItemFromJSON,
    MelcoModelsPermissionsSubListItemFromJSONTyped,
    MelcoModelsPermissionsSubListItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoModelsUserProductAccess
 */
export interface MelcoModelsUserProductAccess {
    /**
     * 
     * @type {boolean}
     * @memberof MelcoModelsUserProductAccess
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserProductAccess
     */
    product_code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserProductAccess
     */
    product_name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoModelsUserProductAccess
     */
    is_removable?: boolean;
    /**
     * 
     * @type {Array<MelcoModelsPermissionsSubListItem>}
     * @memberof MelcoModelsUserProductAccess
     */
    permissions?: Array<MelcoModelsPermissionsSubListItem> | null;
}

export function MelcoModelsUserProductAccessFromJSON(json: any): MelcoModelsUserProductAccess {
    return MelcoModelsUserProductAccessFromJSONTyped(json, false);
}

export function MelcoModelsUserProductAccessFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsUserProductAccess {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active': !exists(json, 'active') ? undefined : json['active'],
        'product_code': !exists(json, 'product_code') ? undefined : json['product_code'],
        'product_name': !exists(json, 'product_name') ? undefined : json['product_name'],
        'is_removable': !exists(json, 'is_removable') ? undefined : json['is_removable'],
        'permissions': !exists(json, 'permissions') ? undefined : (json['permissions'] === null ? null : (json['permissions'] as Array<any>).map(MelcoModelsPermissionsSubListItemFromJSON)),
    };
}

export function MelcoModelsUserProductAccessToJSON(value?: MelcoModelsUserProductAccess | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active': value.active,
        'product_code': value.product_code,
        'product_name': value.product_name,
        'is_removable': value.is_removable,
        'permissions': value.permissions === undefined ? undefined : (value.permissions === null ? null : (value.permissions as Array<any>).map(MelcoModelsPermissionsSubListItemToJSON)),
    };
}


