/* tslint:disable */
/* eslint-disable */
/**
 * Melco Reporting APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ReportingMelcoMachineStatisticsModelsCreateView,
    ReportingMelcoMachineStatisticsModelsCreateViewFromJSON,
    ReportingMelcoMachineStatisticsModelsCreateViewToJSON,
    ReportingMelcoMachineStatisticsModelsUpdateView,
    ReportingMelcoMachineStatisticsModelsUpdateViewFromJSON,
    ReportingMelcoMachineStatisticsModelsUpdateViewToJSON,
    ReportingMelcoMachineStatisticsModelsView,
    ReportingMelcoMachineStatisticsModelsViewFromJSON,
    ReportingMelcoMachineStatisticsModelsViewToJSON,
    ReportingMelcoModelsError,
    ReportingMelcoModelsErrorFromJSON,
    ReportingMelcoModelsErrorToJSON,
    ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsViewListItem,
    ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsViewListItemFromJSON,
    ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsViewListItemToJSON,
} from '../models';

export interface ViewsApiStatisticalViewCreateRequest {
    reportingMelcoMachineStatisticsModelsCreateView?: ReportingMelcoMachineStatisticsModelsCreateView;
}

export interface ViewsApiStatisticalViewDeleteRequest {
    id: string;
}

export interface ViewsApiStatisticalViewEditRequest {
    id: string;
    reportingMelcoMachineStatisticsModelsUpdateView?: ReportingMelcoMachineStatisticsModelsUpdateView;
}

export interface ViewsApiStatisticalViewGetRequest {
    id: string;
}

export interface ViewsApiStatisticalViewSearchRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    searchText?: string;
}

/**
 * 
 */
export class ViewsApi extends runtime.BaseAPI {

    /**
     */
    async statisticalViewCreateRaw(requestParameters: ViewsApiStatisticalViewCreateRequest): Promise<runtime.ApiResponse<ReportingMelcoMachineStatisticsModelsView>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/v1/machines/view`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReportingMelcoMachineStatisticsModelsCreateViewToJSON(requestParameters.reportingMelcoMachineStatisticsModelsCreateView),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportingMelcoMachineStatisticsModelsViewFromJSON(jsonValue));
    }

    /**
     */
    async statisticalViewCreate(requestParameters: ViewsApiStatisticalViewCreateRequest): Promise<ReportingMelcoMachineStatisticsModelsView> {
        const response = await this.statisticalViewCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async statisticalViewDeleteRaw(requestParameters: ViewsApiStatisticalViewDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statisticalViewDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/machines/view/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async statisticalViewDelete(requestParameters: ViewsApiStatisticalViewDeleteRequest): Promise<void> {
        await this.statisticalViewDeleteRaw(requestParameters);
    }

    /**
     */
    async statisticalViewEditRaw(requestParameters: ViewsApiStatisticalViewEditRequest): Promise<runtime.ApiResponse<ReportingMelcoMachineStatisticsModelsView>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statisticalViewEdit.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/v1/machines/view/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ReportingMelcoMachineStatisticsModelsUpdateViewToJSON(requestParameters.reportingMelcoMachineStatisticsModelsUpdateView),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportingMelcoMachineStatisticsModelsViewFromJSON(jsonValue));
    }

    /**
     */
    async statisticalViewEdit(requestParameters: ViewsApiStatisticalViewEditRequest): Promise<ReportingMelcoMachineStatisticsModelsView> {
        const response = await this.statisticalViewEditRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async statisticalViewGetRaw(requestParameters: ViewsApiStatisticalViewGetRequest): Promise<runtime.ApiResponse<ReportingMelcoMachineStatisticsModelsView>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statisticalViewGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/machines/view/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportingMelcoMachineStatisticsModelsViewFromJSON(jsonValue));
    }

    /**
     */
    async statisticalViewGet(requestParameters: ViewsApiStatisticalViewGetRequest): Promise<ReportingMelcoMachineStatisticsModelsView> {
        const response = await this.statisticalViewGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async statisticalViewSearchRaw(requestParameters: ViewsApiStatisticalViewSearchRequest): Promise<runtime.ApiResponse<ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsViewListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/machines/view`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsViewListItemFromJSON(jsonValue));
    }

    /**
     */
    async statisticalViewSearch(requestParameters: ViewsApiStatisticalViewSearchRequest): Promise<ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsViewListItem> {
        const response = await this.statisticalViewSearchRaw(requestParameters);
        return await response.value();
    }

}
