/* tslint:disable */
/* eslint-disable */
/**
 * Melco Efs APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EfsMelcoEfsModelsRunJob
 */
export interface EfsMelcoEfsModelsRunJob {
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoEfsModelsRunJob
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoEfsModelsRunJob
     */
    job_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoEfsModelsRunJob
     */
    file_name?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof EfsMelcoEfsModelsRunJob
     */
    start_time?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EfsMelcoEfsModelsRunJob
     */
    end_time?: Date;
}

export function EfsMelcoEfsModelsRunJobFromJSON(json: any): EfsMelcoEfsModelsRunJob {
    return EfsMelcoEfsModelsRunJobFromJSONTyped(json, false);
}

export function EfsMelcoEfsModelsRunJobFromJSONTyped(json: any, ignoreDiscriminator: boolean): EfsMelcoEfsModelsRunJob {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'job_name': !exists(json, 'job_name') ? undefined : json['job_name'],
        'file_name': !exists(json, 'file_name') ? undefined : json['file_name'],
        'start_time': !exists(json, 'start_time') ? undefined : (new Date(json['start_time'])),
        'end_time': !exists(json, 'end_time') ? undefined : (new Date(json['end_time'])),
    };
}

export function EfsMelcoEfsModelsRunJobToJSON(value?: EfsMelcoEfsModelsRunJob | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'job_name': value.job_name,
        'file_name': value.file_name,
        'start_time': value.start_time === undefined ? undefined : (value.start_time.toISOString()),
        'end_time': value.end_time === undefined ? undefined : (value.end_time.toISOString()),
    };
}


