/* tslint:disable */
/* eslint-disable */
/**
 * Melco Efs APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EfsMelcoEfsModelsRunJobDetails
 */
export interface EfsMelcoEfsModelsRunJobDetails {
    /**
     * 
     * @type {Array<string>}
     * @memberof EfsMelcoEfsModelsRunJobDetails
     */
    logs?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoEfsModelsRunJobDetails
     */
    xml_data?: string | null;
}

export function EfsMelcoEfsModelsRunJobDetailsFromJSON(json: any): EfsMelcoEfsModelsRunJobDetails {
    return EfsMelcoEfsModelsRunJobDetailsFromJSONTyped(json, false);
}

export function EfsMelcoEfsModelsRunJobDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): EfsMelcoEfsModelsRunJobDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'logs': !exists(json, 'logs') ? undefined : json['logs'],
        'xml_data': !exists(json, 'xml_data') ? undefined : json['xml_data'],
    };
}

export function EfsMelcoEfsModelsRunJobDetailsToJSON(value?: EfsMelcoEfsModelsRunJobDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'logs': value.logs,
        'xml_data': value.xml_data,
    };
}


