/* tslint:disable */
/* eslint-disable */
/**
 * Melco Efs APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EfsMelcoEfsModelsMappingOutputAttribute
 */
export interface EfsMelcoEfsModelsMappingOutputAttribute {
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoEfsModelsMappingOutputAttribute
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoEfsModelsMappingOutputAttribute
     */
    type?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EfsMelcoEfsModelsMappingOutputAttribute
     */
    format_detail_id?: number;
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoEfsModelsMappingOutputAttribute
     */
    value?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoEfsModelsMappingOutputAttribute
     */
    config_option?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoEfsModelsMappingOutputAttribute
     */
    config_value?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EfsMelcoEfsModelsMappingOutputAttribute
     */
    exception_id?: number;
}

export function EfsMelcoEfsModelsMappingOutputAttributeFromJSON(json: any): EfsMelcoEfsModelsMappingOutputAttribute {
    return EfsMelcoEfsModelsMappingOutputAttributeFromJSONTyped(json, false);
}

export function EfsMelcoEfsModelsMappingOutputAttributeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EfsMelcoEfsModelsMappingOutputAttribute {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'format_detail_id': !exists(json, 'format_detail_id') ? undefined : json['format_detail_id'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'config_option': !exists(json, 'config_option') ? undefined : json['config_option'],
        'config_value': !exists(json, 'config_value') ? undefined : json['config_value'],
        'exception_id': !exists(json, 'exception_id') ? undefined : json['exception_id'],
    };
}

export function EfsMelcoEfsModelsMappingOutputAttributeToJSON(value?: EfsMelcoEfsModelsMappingOutputAttribute | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'type': value.type,
        'format_detail_id': value.format_detail_id,
        'value': value.value,
        'config_option': value.config_option,
        'config_value': value.config_value,
        'exception_id': value.exception_id,
    };
}


