/* tslint:disable */
/* eslint-disable */
/**
 * Melco Efs APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EfsMelcoEfsModelsMappingOutputElement,
    EfsMelcoEfsModelsMappingOutputElementFromJSON,
    EfsMelcoEfsModelsMappingOutputElementFromJSONTyped,
    EfsMelcoEfsModelsMappingOutputElementToJSON,
    EfsMelcoEfsModelsMappingOutputPrintSettings,
    EfsMelcoEfsModelsMappingOutputPrintSettingsFromJSON,
    EfsMelcoEfsModelsMappingOutputPrintSettingsFromJSONTyped,
    EfsMelcoEfsModelsMappingOutputPrintSettingsToJSON,
} from './';

/**
 * 
 * @export
 * @interface EfsMelcoEfsModelsMappingOutput
 */
export interface EfsMelcoEfsModelsMappingOutput {
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoEfsModelsMappingOutput
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoEfsModelsMappingOutput
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoEfsModelsMappingOutput
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoEfsModelsMappingOutput
     */
    design_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoEfsModelsMappingOutput
     */
    config_type?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EfsMelcoEfsModelsMappingOutput
     */
    patch?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoEfsModelsMappingOutput
     */
    patch_template?: string | null;
    /**
     * 
     * @type {EfsMelcoEfsModelsMappingOutputPrintSettings}
     * @memberof EfsMelcoEfsModelsMappingOutput
     */
    print_settings?: EfsMelcoEfsModelsMappingOutputPrintSettings;
    /**
     * 
     * @type {Array<EfsMelcoEfsModelsMappingOutputElement>}
     * @memberof EfsMelcoEfsModelsMappingOutput
     */
    elements?: Array<EfsMelcoEfsModelsMappingOutputElement> | null;
    /**
     * 
     * @type {boolean}
     * @memberof EfsMelcoEfsModelsMappingOutput
     */
    output_production_file_on_emptydata?: boolean;
}

export function EfsMelcoEfsModelsMappingOutputFromJSON(json: any): EfsMelcoEfsModelsMappingOutput {
    return EfsMelcoEfsModelsMappingOutputFromJSONTyped(json, false);
}

export function EfsMelcoEfsModelsMappingOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): EfsMelcoEfsModelsMappingOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'design_id': !exists(json, 'design_id') ? undefined : json['design_id'],
        'config_type': !exists(json, 'config_type') ? undefined : json['config_type'],
        'patch': !exists(json, 'patch') ? undefined : json['patch'],
        'patch_template': !exists(json, 'patch_template') ? undefined : json['patch_template'],
        'print_settings': !exists(json, 'print_settings') ? undefined : EfsMelcoEfsModelsMappingOutputPrintSettingsFromJSON(json['print_settings']),
        'elements': !exists(json, 'elements') ? undefined : (json['elements'] === null ? null : (json['elements'] as Array<any>).map(EfsMelcoEfsModelsMappingOutputElementFromJSON)),
        'output_production_file_on_emptydata': !exists(json, 'output_production_file_on_emptydata') ? undefined : json['output_production_file_on_emptydata'],
    };
}

export function EfsMelcoEfsModelsMappingOutputToJSON(value?: EfsMelcoEfsModelsMappingOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'type': value.type,
        'design_id': value.design_id,
        'config_type': value.config_type,
        'patch': value.patch,
        'patch_template': value.patch_template,
        'print_settings': EfsMelcoEfsModelsMappingOutputPrintSettingsToJSON(value.print_settings),
        'elements': value.elements === undefined ? undefined : (value.elements === null ? null : (value.elements as Array<any>).map(EfsMelcoEfsModelsMappingOutputElementToJSON)),
        'output_production_file_on_emptydata': value.output_production_file_on_emptydata,
    };
}


