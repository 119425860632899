/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoModelsCustomWarning,
    MelcoModelsCustomWarningFromJSON,
    MelcoModelsCustomWarningFromJSONTyped,
    MelcoModelsCustomWarningToJSON,
    MelcoModelsUserListItem,
    MelcoModelsUserListItemFromJSON,
    MelcoModelsUserListItemFromJSONTyped,
    MelcoModelsUserListItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoModelsGenericList1MelcoModelsUserListItem
 */
export interface MelcoModelsGenericList1MelcoModelsUserListItem {
    /**
     * 
     * @type {Array<MelcoModelsUserListItem>}
     * @memberof MelcoModelsGenericList1MelcoModelsUserListItem
     */
    list: Array<MelcoModelsUserListItem>;
    /**
     * 
     * @type {Array<MelcoModelsCustomWarning>}
     * @memberof MelcoModelsGenericList1MelcoModelsUserListItem
     */
    warning_list?: Array<MelcoModelsCustomWarning> | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoModelsGenericList1MelcoModelsUserListItem
     */
    total: number;
}

export function MelcoModelsGenericList1MelcoModelsUserListItemFromJSON(json: any): MelcoModelsGenericList1MelcoModelsUserListItem {
    return MelcoModelsGenericList1MelcoModelsUserListItemFromJSONTyped(json, false);
}

export function MelcoModelsGenericList1MelcoModelsUserListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsGenericList1MelcoModelsUserListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'list': ((json['list'] as Array<any>).map(MelcoModelsUserListItemFromJSON)),
        'warning_list': !exists(json, 'warning_list') ? undefined : (json['warning_list'] === null ? null : (json['warning_list'] as Array<any>).map(MelcoModelsCustomWarningFromJSON)),
        'total': json['total'],
    };
}

export function MelcoModelsGenericList1MelcoModelsUserListItemToJSON(value?: MelcoModelsGenericList1MelcoModelsUserListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'list': ((value.list as Array<any>).map(MelcoModelsUserListItemToJSON)),
        'warning_list': value.warning_list === undefined ? undefined : (value.warning_list === null ? null : (value.warning_list as Array<any>).map(MelcoModelsCustomWarningToJSON)),
        'total': value.total,
    };
}


