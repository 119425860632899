import {
  FusionMelcoModelsUserImage as FusionMelcoFusionModelsUserBlank,
  useAPIAction,
  UserContentsDraftPublishApi,
} from "melco-shared-logic";
import { useFormikContext } from "formik";
import { QueryObserverResult } from "react-query";
import { blankToInitialValues } from "../../../components/libraries/blanks/BlankDetailForm";

export const useRevertBlank = (
  blankId: string,
  refetch: () => Promise<
    QueryObserverResult<FusionMelcoFusionModelsUserBlank, unknown>
  >
) => {
  const { resetForm } = useFormikContext();

  return useAPIAction(
    UserContentsDraftPublishApi,
    async (api) => {
      const data = await api.userBlankRevert({
        id: blankId,
      });

      return {
        successMessages: [
          {
            message: "default",
          },
        ],
        data,
      };
    },
    {
      translationPrefix: "libraries.blanks.blanks.detail.revert",
      onSuccess: async () => {
        const refetchResult = await refetch();

        resetForm({
          values: refetchResult.data
            ? blankToInitialValues(refetchResult.data)
            : {},
        });
      },
    }
  );
};
