import {
  BulkActionContainerCol,
  Col,
  DesignTokens,
  Table,
  TableActionBarRow,
  ValueOrFallback,
} from "melco-ui";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  BulkActionCount,
  FormattedDate,
  MelcoModelsDesignListItem,
  Search,
  useTableSelection,
  TableCellLink,
  ViewContext,
} from "melco-shared-logic";
import { PublishedState } from "../../state/PublishedState";
import { useDesigns } from "../../../hooks/libraries/designs/useDesigns";
import { DesignsListThumbnail } from "./DesignsListThumbnail";
import { BulkActionDeleteDesigns } from "./BulkActionDeleteDesigns";

const { spacings } = DesignTokens;

const DETAIL_VIEW_PATH = "/libraries/designs";

export const designsListColumns = (
  t: TFunction,
  context: ViewContext = ViewContext.List
) => {
  return [
    {
      title: "",
      key: "thumbnail",
      dataIndex: "thumbnail",
      render: (_: string, item: MelcoModelsDesignListItem) => (
        <DesignsListThumbnail design={item} />
      ),
      width: spacings.xlarge + 2 * spacings.middle, // 2 * spacings.middle to compensate for table padding
      height: spacings.xlarge + 2 * spacings.middle, // 2 * spacings.middle to compensate for table padding
    },
    {
      title: t("designs_list.columns.name"),
      key: "name",
      dataIndex: "name",
      sorter: true,
      render: (_: string, item: MelcoModelsDesignListItem) => (
        <ValueOrFallback
          value={item.name}
          render={(value) =>
            context === ViewContext.List ? (
              <TableCellLink to={[DETAIL_VIEW_PATH, item.id].join("/")}>
                {value}
              </TableCellLink>
            ) : (
              <>{value}</>
            )
          }
          fallback={t("global.fallback.empty")}
        />
      ),
    },
    {
      title: t("designs_list.columns.created_date"),
      key: "created_date",
      dataIndex: "created_date",
      sorter: true,
      render: (_: string, item: MelcoModelsDesignListItem) => (
        <FormattedDate date={item.created_date} />
      ),
    },
    {
      title: t("designs_list.columns.updated_date"),
      key: "updated_date",
      dataIndex: "updated_date",
      sorter: true,
      render: (_: string, item: MelcoModelsDesignListItem) => (
        <FormattedDate date={item.updated_date} />
      ),
    },
  ];
};

export const DesignsList: React.FC = () => {
  const {
    isLoading,
    isFetching,
    data,
    isPreviousData,
    errorComponent,
    onTableConfigChange,
    pagination,
    searchQueryChange,
    refetch,
  } = useDesigns();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const { selectedRowKeys, setSelectedRowKeys, rowSelection, onRow } =
    useTableSelection((row: MelcoModelsDesignListItem) => {
      navigate([DETAIL_VIEW_PATH, row.id].join("/"));
    });

  const columns = [
    ...designsListColumns(t),
    {
      title: t("designs_list.columns.publish_state"),
      key: "publish_state",
      dataIndex: "publish_state",
      sorter: true,
      render: (_: string, item: MelcoModelsDesignListItem) => {
        return <PublishedState publishedState={item.publish_state ?? 1} />;
      },
    },
  ];

  return (
    <>
      <TableActionBarRow>
        <Col>
          <Search
            onSearchInputChange={(query: string) => searchQueryChange(query)}
          />
        </Col>
        <BulkActionContainerCol>
          <BulkActionCount selectedCount={selectedRowKeys.length} />
          <BulkActionDeleteDesigns
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            refetch={refetch}
            data={data}
          />
        </BulkActionContainerCol>
      </TableActionBarRow>
      <Table
        showSorterTooltip={false}
        sticky
        loading={isPreviousData ? isFetching : isLoading}
        dataSource={data}
        columns={columns}
        onChange={onTableConfigChange}
        pagination={pagination}
        rowKey="id"
        rowSelection={rowSelection}
        onRow={onRow}
        rowClassName={() => "clickable"}
        noDataMessage={t("designs_list.empty")}
        errorComponent={errorComponent}
      />
    </>
  );
};
