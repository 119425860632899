/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoModelsReferralListItem
 */
export interface MelcoModelsReferralListItem {
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsReferralListItem
     */
    code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsReferralListItem
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsReferralListItem
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsReferralListItem
     */
    type?: string | null;
}

export function MelcoModelsReferralListItemFromJSON(json: any): MelcoModelsReferralListItem {
    return MelcoModelsReferralListItemFromJSONTyped(json, false);
}

export function MelcoModelsReferralListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsReferralListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': !exists(json, 'code') ? undefined : json['code'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function MelcoModelsReferralListItemToJSON(value?: MelcoModelsReferralListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'name': value.name,
        'description': value.description,
        'type': value.type,
    };
}


