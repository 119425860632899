/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MelcoModelsActivateSupport,
    MelcoModelsActivateSupportFromJSON,
    MelcoModelsActivateSupportToJSON,
    MelcoModelsCreateSupportToken,
    MelcoModelsCreateSupportTokenFromJSON,
    MelcoModelsCreateSupportTokenToJSON,
    MelcoModelsError,
    MelcoModelsErrorFromJSON,
    MelcoModelsErrorToJSON,
    MelcoModelsSupportStatus,
    MelcoModelsSupportStatusFromJSON,
    MelcoModelsSupportStatusToJSON,
    MelcoModelsSupportToken,
    MelcoModelsSupportTokenFromJSON,
    MelcoModelsSupportTokenToJSON,
} from '../models';

export interface SupportApiSupportActivateRequest {
    melcoModelsActivateSupport?: MelcoModelsActivateSupport;
}

export interface SupportApiSupportGenerateTokenRequest {
    melcoModelsCreateSupportToken?: MelcoModelsCreateSupportToken;
}

/**
 * 
 */
export class SupportApi extends runtime.BaseAPI {

    /**
     * Activate support
     */
    async supportActivateRaw(requestParameters: SupportApiSupportActivateRequest): Promise<runtime.ApiResponse<MelcoModelsSupportStatus>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/account/support/activate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsActivateSupportToJSON(requestParameters.melcoModelsActivateSupport),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsSupportStatusFromJSON(jsonValue));
    }

    /**
     * Activate support
     */
    async supportActivate(requestParameters: SupportApiSupportActivateRequest): Promise<MelcoModelsSupportStatus> {
        const response = await this.supportActivateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deactivate support
     */
    async supportDeactivateRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/support/deactivate`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deactivate support
     */
    async supportDeactivate(): Promise<void> {
        await this.supportDeactivateRaw();
    }

    /**
     * Generate impersonation token
     */
    async supportGenerateTokenRaw(requestParameters: SupportApiSupportGenerateTokenRequest): Promise<runtime.ApiResponse<MelcoModelsSupportToken>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/account/support/generate_token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsCreateSupportTokenToJSON(requestParameters.melcoModelsCreateSupportToken),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsSupportTokenFromJSON(jsonValue));
    }

    /**
     * Generate impersonation token
     */
    async supportGenerateToken(requestParameters: SupportApiSupportGenerateTokenRequest): Promise<MelcoModelsSupportToken> {
        const response = await this.supportGenerateTokenRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get user support status
     */
    async supportStatusRaw(): Promise<runtime.ApiResponse<MelcoModelsSupportStatus>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/support/status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsSupportStatusFromJSON(jsonValue));
    }

    /**
     * Get user support status
     */
    async supportStatus(): Promise<MelcoModelsSupportStatus> {
        const response = await this.supportStatusRaw();
        return await response.value();
    }

}
