/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoModelsPermissionsListItem,
    MelcoModelsPermissionsListItemFromJSON,
    MelcoModelsPermissionsListItemFromJSONTyped,
    MelcoModelsPermissionsListItemToJSON,
    MelcoModelsUserProductTrial,
    MelcoModelsUserProductTrialFromJSON,
    MelcoModelsUserProductTrialFromJSONTyped,
    MelcoModelsUserProductTrialToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoModelsAppStartUp
 */
export interface MelcoModelsAppStartUp {
    /**
     * 
     * @type {boolean}
     * @memberof MelcoModelsAppStartUp
     */
    show_service_terms?: boolean;
    /**
     * 
     * @type {Array<MelcoModelsPermissionsListItem>}
     * @memberof MelcoModelsAppStartUp
     */
    permission_list?: Array<MelcoModelsPermissionsListItem> | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsAppStartUp
     */
    default_language?: string | null;
    /**
     * 
     * @type {MelcoModelsUserProductTrial}
     * @memberof MelcoModelsAppStartUp
     */
    trial?: MelcoModelsUserProductTrial;
}

export function MelcoModelsAppStartUpFromJSON(json: any): MelcoModelsAppStartUp {
    return MelcoModelsAppStartUpFromJSONTyped(json, false);
}

export function MelcoModelsAppStartUpFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsAppStartUp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'show_service_terms': !exists(json, 'show_service_terms') ? undefined : json['show_service_terms'],
        'permission_list': !exists(json, 'permission_list') ? undefined : (json['permission_list'] === null ? null : (json['permission_list'] as Array<any>).map(MelcoModelsPermissionsListItemFromJSON)),
        'default_language': !exists(json, 'default_language') ? undefined : json['default_language'],
        'trial': !exists(json, 'trial') ? undefined : MelcoModelsUserProductTrialFromJSON(json['trial']),
    };
}

export function MelcoModelsAppStartUpToJSON(value?: MelcoModelsAppStartUp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'show_service_terms': value.show_service_terms,
        'permission_list': value.permission_list === undefined ? undefined : (value.permission_list === null ? null : (value.permission_list as Array<any>).map(MelcoModelsPermissionsListItemToJSON)),
        'default_language': value.default_language,
        'trial': MelcoModelsUserProductTrialToJSON(value.trial),
    };
}


