import { useCallback } from "react";
import { useField } from "formik";
import {
  FusionMelcoModelsDesignElement,
  ElementListHelper,
} from "melco-shared-logic";

const { isLettering, isColor, isNotActiveGroupColor } = ElementListHelper;

export const useElementList = () => {
  const [{ value: elementList }] = useField<
    FusionMelcoModelsDesignElement[] | undefined
  >("element_list");

  const letteringObjects = (elementList ?? []).filter(
    (e) => isLettering(e) && isNotActiveGroupColor(e)
  );
  const colorObjects = (elementList ?? []).filter(
    (e) => isColor(e) && isNotActiveGroupColor(e)
  );

  const globalIndex = useCallback(
    (element: FusionMelcoModelsDesignElement) =>
      (elementList ?? []).indexOf(element),
    [elementList]
  );

  const groupIndex = useCallback(
    (element: FusionMelcoModelsDesignElement) =>
      (isLettering(element) ? letteringObjects : colorObjects).indexOf(
        element
      ) + 1,
    [letteringObjects, colorObjects]
  );

  return { letteringObjects, colorObjects, groupIndex, globalIndex };
};
