import {
  BulkActionContainerCol,
  Button,
  Col,
  PlusIcon,
  RemoveIcon,
  Table,
  TableActionBarRow,
} from "melco-ui";
import { useTranslation } from "react-i18next";
import { useBlankPictures } from "../../../hooks/libraries/blanks/blankPictures/useBlankPictures";
import { blankPictureColumns } from "./blankPictures/BlankPicturesList";
import {
  BulkActionCount,
  FormattedDate,
  FusionMelcoModelsUserImage,
  Search,
  ViewContext,
  useTableSelection,
} from "melco-shared-logic";
import {
  MAX_BLANK_PICTURE_COUNT,
  MAX_VIEW_COUNT,
  useAddBlankPictures,
} from "../../../hooks/libraries/blanks/useAddBlankPictures";
import { BulkActionAddBlankPictures } from "./BulkActionAddBlankPictures";
import { BlankPictureMaximumAlert } from "./BlankPictureMaximumAlert";

export const AddBlankPicturesList: React.FC = () => {
  const {
    isLoading,
    isFetching,
    data,
    isPreviousData,
    errorComponent,
    onTableConfigChange,
    pagination,
    searchQueryChange,
  } = useBlankPictures("published");

  const { t } = useTranslation();

  const {
    blankPictureCount,
    viewsCount,
    addBlankPictures,
    removeBlankPictures,
    isAlreadyAdded,
  } = useAddBlankPictures();

  const { selectedRowKeys, setSelectedRowKeys, rowSelection, onRow } =
    useTableSelection<FusionMelcoModelsUserImage>(() => {});

  const columns = [
    ...blankPictureColumns(t, ViewContext.Drawer),
    {
      title: t("blanks_list.columns.updated_date"),
      key: "updated_date",
      dataIndex: "updated_date",
      sorter: true,
      render: (_: string, blankPicture: FusionMelcoModelsUserImage) => (
        <FormattedDate date={blankPicture.updated_date} />
      ),
    },
    {
      title: "",
      key: "action",
      dataIndex: "action",
      align: "right" as const,
      render: (_: string, blankPicture: FusionMelcoModelsUserImage) => {
        return isAlreadyAdded(blankPicture) ? (
          <Button
            icon={<RemoveIcon />}
            size="small"
            onClick={() => removeBlankPictures([blankPicture])}
          >
            {t("blanks.actions.add_blank_pictures.actions.remove")}
          </Button>
        ) : (
          <Button
            type="primary"
            icon={<PlusIcon />}
            size="small"
            onClick={() => addBlankPictures([blankPicture])}
          >
            {t("blanks.actions.add_blank_pictures.actions.add")}
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <BlankPictureMaximumAlert
        message={t("blanks.actions.add_blank_pictures.alert.max_amount", {
          pictureCount: blankPictureCount,
          maxPictureCount: MAX_BLANK_PICTURE_COUNT,
          viewsCount,
          maxViewsCount: MAX_VIEW_COUNT,
        })}
      />

      <TableActionBarRow>
        <Col>
          <Search
            onSearchInputChange={(query: string) => searchQueryChange(query)}
          />
        </Col>
        <BulkActionContainerCol>
          <BulkActionCount selectedCount={selectedRowKeys.length} />
          <BulkActionAddBlankPictures
            allBlankPictures={data ?? []}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
          />
        </BulkActionContainerCol>
      </TableActionBarRow>
      <Table
        showSorterTooltip={false}
        sticky
        loading={isPreviousData ? isFetching : isLoading}
        dataSource={data}
        columns={columns}
        onChange={onTableConfigChange}
        pagination={pagination}
        rowKey="id"
        rowSelection={rowSelection}
        onRow={onRow}
        noDataMessage={t("blank_pictures_list.empty")}
        errorComponent={errorComponent}
      />
    </>
  );
};
