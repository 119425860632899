/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoModelsDeviceLogoffRequest
 */
export interface MelcoModelsDeviceLogoffRequest {
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsDeviceLogoffRequest
     */
    device_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsDeviceLogoffRequest
     */
    requesting_device_name?: string | null;
}

export function MelcoModelsDeviceLogoffRequestFromJSON(json: any): MelcoModelsDeviceLogoffRequest {
    return MelcoModelsDeviceLogoffRequestFromJSONTyped(json, false);
}

export function MelcoModelsDeviceLogoffRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsDeviceLogoffRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'device_id': !exists(json, 'device_id') ? undefined : json['device_id'],
        'requesting_device_name': !exists(json, 'requesting_device_name') ? undefined : json['requesting_device_name'],
    };
}

export function MelcoModelsDeviceLogoffRequestToJSON(value?: MelcoModelsDeviceLogoffRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'device_id': value.device_id,
        'requesting_device_name': value.requesting_device_name,
    };
}


