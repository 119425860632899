/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoModelsLocaleCultureString
 */
export interface MelcoModelsLocaleCultureString {
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsLocaleCultureString
     */
    culture_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsLocaleCultureString
     */
    culture_code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsLocaleCultureString
     */
    text?: string | null;
}

export function MelcoModelsLocaleCultureStringFromJSON(json: any): MelcoModelsLocaleCultureString {
    return MelcoModelsLocaleCultureStringFromJSONTyped(json, false);
}

export function MelcoModelsLocaleCultureStringFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsLocaleCultureString {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'culture_id': !exists(json, 'culture_id') ? undefined : json['culture_id'],
        'culture_code': !exists(json, 'culture_code') ? undefined : json['culture_code'],
        'text': !exists(json, 'text') ? undefined : json['text'],
    };
}

export function MelcoModelsLocaleCultureStringToJSON(value?: MelcoModelsLocaleCultureString | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'culture_id': value.culture_id,
        'culture_code': value.culture_code,
        'text': value.text,
    };
}


