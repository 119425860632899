/* tslint:disable */
/* eslint-disable */
/**
 * Melco Fusion APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    FusionMelcoFusionModelsCreateCustomProduct,
    FusionMelcoFusionModelsCreateCustomProductFromJSON,
    FusionMelcoFusionModelsCreateCustomProductToJSON,
    FusionMelcoFusionModelsCustomProduct,
    FusionMelcoFusionModelsCustomProductFromJSON,
    FusionMelcoFusionModelsCustomProductToJSON,
    FusionMelcoFusionModelsUpdateCustomProduct,
    FusionMelcoFusionModelsUpdateCustomProductFromJSON,
    FusionMelcoFusionModelsUpdateCustomProductToJSON,
    FusionMelcoModelsError,
    FusionMelcoModelsErrorFromJSON,
    FusionMelcoModelsErrorToJSON,
    FusionMelcoModelsGenericList1MelcoFusionModelsCustomProductListItem,
    FusionMelcoModelsGenericList1MelcoFusionModelsCustomProductListItemFromJSON,
    FusionMelcoModelsGenericList1MelcoFusionModelsCustomProductListItemToJSON,
} from '../models';

export interface FusionApiFusionCustomProductsCreateDraftRequest {
    fusionMelcoFusionModelsCreateCustomProduct?: FusionMelcoFusionModelsCreateCustomProduct;
}

export interface FusionApiFusionCustomProductsDefaultPreviewRequest {
    id: string;
    width?: number;
    publishState?: string;
    colorName?: string;
    viewName?: string;
}

export interface FusionApiFusionCustomProductsDefaultTokenPreviewRequest {
    productToken: string;
    width?: number;
    publishState?: string;
    colorName?: string;
    viewName?: string;
}

export interface FusionApiFusionCustomProductsDeleteRequest {
    id: string;
    force?: boolean;
}

export interface FusionApiFusionCustomProductsDuplicateDraftRequest {
    id: string;
}

export interface FusionApiFusionCustomProductsFixSchemaRequest {
    fusionMelcoFusionModelsCustomProduct?: FusionMelcoFusionModelsCustomProduct;
}

export interface FusionApiFusionCustomProductsGetByTokenRequest {
    token: string;
}

export interface FusionApiFusionCustomProductsGetDefRequest {
    id: string;
    publishState?: string;
}

export interface FusionApiFusionCustomProductsPreviewRequest {
    id: string;
    imageId: string;
    width?: number;
    publishState?: string;
}

export interface FusionApiFusionCustomProductsPublishRequest {
    id: string;
}

export interface FusionApiFusionCustomProductsRevertRequest {
    id: string;
    force?: boolean;
}

export interface FusionApiFusionCustomProductsSearchRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    searchText?: string;
    publishState?: string;
}

export interface FusionApiFusionCustomProductsUpdateDraftRequest {
    id: string;
    fusionMelcoFusionModelsUpdateCustomProduct?: FusionMelcoFusionModelsUpdateCustomProduct;
}

/**
 * 
 */
export class FusionApi extends runtime.BaseAPI {

    /**
     */
    async fusionCustomProductsCreateDraftRaw(requestParameters: FusionApiFusionCustomProductsCreateDraftRequest): Promise<runtime.ApiResponse<FusionMelcoFusionModelsCustomProduct>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/fusion/custom-products/draft`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FusionMelcoFusionModelsCreateCustomProductToJSON(requestParameters.fusionMelcoFusionModelsCreateCustomProduct),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FusionMelcoFusionModelsCustomProductFromJSON(jsonValue));
    }

    /**
     */
    async fusionCustomProductsCreateDraft(requestParameters: FusionApiFusionCustomProductsCreateDraftRequest): Promise<FusionMelcoFusionModelsCustomProduct> {
        const response = await this.fusionCustomProductsCreateDraftRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async fusionCustomProductsDefaultPreviewRaw(requestParameters: FusionApiFusionCustomProductsDefaultPreviewRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fusionCustomProductsDefaultPreview.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.width !== undefined) {
            queryParameters['width'] = requestParameters.width;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        if (requestParameters.colorName !== undefined) {
            queryParameters['color_name'] = requestParameters.colorName;
        }

        if (requestParameters.viewName !== undefined) {
            queryParameters['view_name'] = requestParameters.viewName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/fusion/custom-products/{Id}/preview`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async fusionCustomProductsDefaultPreview(requestParameters: FusionApiFusionCustomProductsDefaultPreviewRequest): Promise<void> {
        await this.fusionCustomProductsDefaultPreviewRaw(requestParameters);
    }

    /**
     */
    async fusionCustomProductsDefaultTokenPreviewRaw(requestParameters: FusionApiFusionCustomProductsDefaultTokenPreviewRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.productToken === null || requestParameters.productToken === undefined) {
            throw new runtime.RequiredError('productToken','Required parameter requestParameters.productToken was null or undefined when calling fusionCustomProductsDefaultTokenPreview.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.width !== undefined) {
            queryParameters['width'] = requestParameters.width;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        if (requestParameters.colorName !== undefined) {
            queryParameters['color_name'] = requestParameters.colorName;
        }

        if (requestParameters.viewName !== undefined) {
            queryParameters['view_name'] = requestParameters.viewName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/fusion/custom-products/{product_token}/token_preview`.replace(`{${"product_token"}}`, encodeURIComponent(String(requestParameters.productToken))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async fusionCustomProductsDefaultTokenPreview(requestParameters: FusionApiFusionCustomProductsDefaultTokenPreviewRequest): Promise<void> {
        await this.fusionCustomProductsDefaultTokenPreviewRaw(requestParameters);
    }

    /**
     */
    async fusionCustomProductsDeleteRaw(requestParameters: FusionApiFusionCustomProductsDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fusionCustomProductsDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.force !== undefined) {
            queryParameters['Force'] = requestParameters.force;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/fusion/custom-products/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async fusionCustomProductsDelete(requestParameters: FusionApiFusionCustomProductsDeleteRequest): Promise<void> {
        await this.fusionCustomProductsDeleteRaw(requestParameters);
    }

    /**
     */
    async fusionCustomProductsDuplicateDraftRaw(requestParameters: FusionApiFusionCustomProductsDuplicateDraftRequest): Promise<runtime.ApiResponse<FusionMelcoFusionModelsCustomProduct>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fusionCustomProductsDuplicateDraft.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/fusion/custom-products/{Id}/duplicate/draft`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FusionMelcoFusionModelsCustomProductFromJSON(jsonValue));
    }

    /**
     */
    async fusionCustomProductsDuplicateDraft(requestParameters: FusionApiFusionCustomProductsDuplicateDraftRequest): Promise<FusionMelcoFusionModelsCustomProduct> {
        const response = await this.fusionCustomProductsDuplicateDraftRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async fusionCustomProductsFixSchemaRaw(requestParameters: FusionApiFusionCustomProductsFixSchemaRequest): Promise<runtime.ApiResponse<FusionMelcoFusionModelsCustomProduct>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/fusion/custom-products/fixschema`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FusionMelcoFusionModelsCustomProductToJSON(requestParameters.fusionMelcoFusionModelsCustomProduct),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FusionMelcoFusionModelsCustomProductFromJSON(jsonValue));
    }

    /**
     */
    async fusionCustomProductsFixSchema(requestParameters: FusionApiFusionCustomProductsFixSchemaRequest): Promise<FusionMelcoFusionModelsCustomProduct> {
        const response = await this.fusionCustomProductsFixSchemaRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async fusionCustomProductsGetByTokenRaw(requestParameters: FusionApiFusionCustomProductsGetByTokenRequest): Promise<runtime.ApiResponse<FusionMelcoFusionModelsCustomProduct>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling fusionCustomProductsGetByToken.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/fusion/custom-products/token/{token}`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FusionMelcoFusionModelsCustomProductFromJSON(jsonValue));
    }

    /**
     */
    async fusionCustomProductsGetByToken(requestParameters: FusionApiFusionCustomProductsGetByTokenRequest): Promise<FusionMelcoFusionModelsCustomProduct> {
        const response = await this.fusionCustomProductsGetByTokenRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async fusionCustomProductsGetDefRaw(requestParameters: FusionApiFusionCustomProductsGetDefRequest): Promise<runtime.ApiResponse<FusionMelcoFusionModelsCustomProduct>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fusionCustomProductsGetDef.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/fusion/custom-products/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FusionMelcoFusionModelsCustomProductFromJSON(jsonValue));
    }

    /**
     */
    async fusionCustomProductsGetDef(requestParameters: FusionApiFusionCustomProductsGetDefRequest): Promise<FusionMelcoFusionModelsCustomProduct> {
        const response = await this.fusionCustomProductsGetDefRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async fusionCustomProductsPreviewRaw(requestParameters: FusionApiFusionCustomProductsPreviewRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fusionCustomProductsPreview.');
        }

        if (requestParameters.imageId === null || requestParameters.imageId === undefined) {
            throw new runtime.RequiredError('imageId','Required parameter requestParameters.imageId was null or undefined when calling fusionCustomProductsPreview.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.width !== undefined) {
            queryParameters['width'] = requestParameters.width;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/fusion/custom-products/{Id}/preview/{Image_Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"Image_Id"}}`, encodeURIComponent(String(requestParameters.imageId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async fusionCustomProductsPreview(requestParameters: FusionApiFusionCustomProductsPreviewRequest): Promise<void> {
        await this.fusionCustomProductsPreviewRaw(requestParameters);
    }

    /**
     */
    async fusionCustomProductsPublishRaw(requestParameters: FusionApiFusionCustomProductsPublishRequest): Promise<runtime.ApiResponse<FusionMelcoFusionModelsCustomProduct>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fusionCustomProductsPublish.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/fusion/custom-products/{Id}/publish`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FusionMelcoFusionModelsCustomProductFromJSON(jsonValue));
    }

    /**
     */
    async fusionCustomProductsPublish(requestParameters: FusionApiFusionCustomProductsPublishRequest): Promise<FusionMelcoFusionModelsCustomProduct> {
        const response = await this.fusionCustomProductsPublishRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async fusionCustomProductsRevertRaw(requestParameters: FusionApiFusionCustomProductsRevertRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fusionCustomProductsRevert.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.force !== undefined) {
            queryParameters['Force'] = requestParameters.force;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/fusion/custom-products/{Id}/revert`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async fusionCustomProductsRevert(requestParameters: FusionApiFusionCustomProductsRevertRequest): Promise<void> {
        await this.fusionCustomProductsRevertRaw(requestParameters);
    }

    /**
     */
    async fusionCustomProductsSearchRaw(requestParameters: FusionApiFusionCustomProductsSearchRequest): Promise<runtime.ApiResponse<FusionMelcoModelsGenericList1MelcoFusionModelsCustomProductListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/fusion/custom-products`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FusionMelcoModelsGenericList1MelcoFusionModelsCustomProductListItemFromJSON(jsonValue));
    }

    /**
     */
    async fusionCustomProductsSearch(requestParameters: FusionApiFusionCustomProductsSearchRequest): Promise<FusionMelcoModelsGenericList1MelcoFusionModelsCustomProductListItem> {
        const response = await this.fusionCustomProductsSearchRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async fusionCustomProductsUpdateDraftRaw(requestParameters: FusionApiFusionCustomProductsUpdateDraftRequest): Promise<runtime.ApiResponse<FusionMelcoFusionModelsCustomProduct>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fusionCustomProductsUpdateDraft.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/fusion/custom-products/{Id}/draft`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FusionMelcoFusionModelsUpdateCustomProductToJSON(requestParameters.fusionMelcoFusionModelsUpdateCustomProduct),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FusionMelcoFusionModelsCustomProductFromJSON(jsonValue));
    }

    /**
     */
    async fusionCustomProductsUpdateDraft(requestParameters: FusionApiFusionCustomProductsUpdateDraftRequest): Promise<FusionMelcoFusionModelsCustomProduct> {
        const response = await this.fusionCustomProductsUpdateDraftRaw(requestParameters);
        return await response.value();
    }

}
