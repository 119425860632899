import { DesignTokens } from "melco-ui";
import { FusionMelcoModelsUserImage } from "melco-shared-logic";
import { useBlankPictureThumbnailURL } from "../../../../hooks/libraries/blanks/blankPictures/useBlankPictureThumbnailURL";
import { ListThumbnail } from "../../../shared/ListThumbnail";

const { spacings } = DesignTokens;

type BlankPicturesListThumbnailProps = {
  blankPicture: FusionMelcoModelsUserImage;
};

export const BlankPicturesListThumbnail: React.FC<
  BlankPicturesListThumbnailProps
> = ({ blankPicture }) => {
  const thumbnailSrc = useBlankPictureThumbnailURL(
    blankPicture,
    spacings.xlarge,
    spacings.xlarge
  );

  return <ListThumbnail thumbnailSrc={thumbnailSrc ?? undefined} />;
};
