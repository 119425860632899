/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoModelsUserSerialNumber,
    MelcoModelsUserSerialNumberFromJSON,
    MelcoModelsUserSerialNumberFromJSONTyped,
    MelcoModelsUserSerialNumberToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoModelsSecurityToken
 */
export interface MelcoModelsSecurityToken {
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsSecurityToken
     */
    token?: string | null;
    /**
     * 
     * @type {MelcoModelsUserSerialNumber}
     * @memberof MelcoModelsSecurityToken
     */
    subscription?: MelcoModelsUserSerialNumber;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsSecurityToken
     */
    user_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsSecurityToken
     */
    parent_user_id?: string | null;
}

export function MelcoModelsSecurityTokenFromJSON(json: any): MelcoModelsSecurityToken {
    return MelcoModelsSecurityTokenFromJSONTyped(json, false);
}

export function MelcoModelsSecurityTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsSecurityToken {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': !exists(json, 'token') ? undefined : json['token'],
        'subscription': !exists(json, 'subscription') ? undefined : MelcoModelsUserSerialNumberFromJSON(json['subscription']),
        'user_id': !exists(json, 'user_id') ? undefined : json['user_id'],
        'parent_user_id': !exists(json, 'parent_user_id') ? undefined : json['parent_user_id'],
    };
}

export function MelcoModelsSecurityTokenToJSON(value?: MelcoModelsSecurityToken | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
        'subscription': MelcoModelsUserSerialNumberToJSON(value.subscription),
        'user_id': value.user_id,
        'parent_user_id': value.parent_user_id,
    };
}


