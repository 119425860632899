/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MelcoModelsBoolResponse,
    MelcoModelsBoolResponseFromJSON,
    MelcoModelsBoolResponseToJSON,
    MelcoModelsChangeAnonymousUserName,
    MelcoModelsChangeAnonymousUserNameFromJSON,
    MelcoModelsChangeAnonymousUserNameToJSON,
    MelcoModelsChangePassword,
    MelcoModelsChangePasswordFromJSON,
    MelcoModelsChangePasswordToJSON,
    MelcoModelsChangeUserName,
    MelcoModelsChangeUserNameFromJSON,
    MelcoModelsChangeUserNameToJSON,
    MelcoModelsEmailOptInListItem,
    MelcoModelsEmailOptInListItemFromJSON,
    MelcoModelsEmailOptInListItemToJSON,
    MelcoModelsError,
    MelcoModelsErrorFromJSON,
    MelcoModelsErrorToJSON,
    MelcoModelsGenericList1MelcoModelsEmailOptInListItem,
    MelcoModelsGenericList1MelcoModelsEmailOptInListItemFromJSON,
    MelcoModelsGenericList1MelcoModelsEmailOptInListItemToJSON,
    MelcoModelsGenericList1MelcoModelsUserListItem,
    MelcoModelsGenericList1MelcoModelsUserListItemFromJSON,
    MelcoModelsGenericList1MelcoModelsUserListItemToJSON,
    MelcoModelsGenericList1MelcoModelsUserProductListItem,
    MelcoModelsGenericList1MelcoModelsUserProductListItemFromJSON,
    MelcoModelsGenericList1MelcoModelsUserProductListItemToJSON,
    MelcoModelsResendCode,
    MelcoModelsResendCodeFromJSON,
    MelcoModelsResendCodeToJSON,
    MelcoModelsResetPassword,
    MelcoModelsResetPasswordFromJSON,
    MelcoModelsResetPasswordToJSON,
    MelcoModelsUser,
    MelcoModelsUserFromJSON,
    MelcoModelsUserToJSON,
    MelcoModelsUserProduct,
    MelcoModelsUserProductFromJSON,
    MelcoModelsUserProductToJSON,
} from '../models';

export interface UserManagementApiDeviceDeleteDeviceRequest {
    productCode: string;
    deviceId: string;
    id?: string;
}

export interface UserManagementApiDeviceDeleteDeviceByUserRequest {
    id: string;
    productCode: string;
    deviceId: string;
}

export interface UserManagementApiLegalPolicyAcceptLegalPolicyRequest {
    id: string;
    productCode: string;
}

export interface UserManagementApiLegalPolicyDeclineLegalPolicyRequest {
    id: string;
    productCode: string;
}

export interface UserManagementApiLegalPolicyGetOptInListRequest {
    id: string;
    productCode: string;
}

export interface UserManagementApiLegalPolicyNewsletterRequest {
    id: string;
    productCode: string;
}

export interface UserManagementApiProductAssignProductRequest {
    id: string;
    productCode: string;
}

export interface UserManagementApiProductGetListRequest {
    id: string;
}

export interface UserManagementApiProductGetProductRequest {
    id: string;
    productCode: string;
}

export interface UserManagementApiProductRemoveProductAccessRequest {
    id: string;
    productCode: string;
}

export interface UserManagementApiUserChangePasswordRequest {
    id: string;
    melcoModelsChangePassword?: MelcoModelsChangePassword;
}

export interface UserManagementApiUserChangeUserNameRequest {
    id: string;
    melcoModelsChangeUserName?: MelcoModelsChangeUserName;
}

export interface UserManagementApiUserChangeUserNameAnonymouslyRequest {
    melcoModelsChangeAnonymousUserName?: MelcoModelsChangeAnonymousUserName;
}

export interface UserManagementApiUserEditRequest {
    id: string;
    melcoModelsUser?: MelcoModelsUser;
}

export interface UserManagementApiUserForgotPasswordRequest {
    username?: string;
}

export interface UserManagementApiUserGetListRequest {
    searchText?: string;
    firstName?: string;
    lastName?: string;
    userName?: string;
    email?: string;
    serialNumber?: string;
    countryList?: string;
    stateList?: string;
    sortBy?: string;
    accountName?: string;
    allUsers?: boolean;
}

export interface UserManagementApiUserGetUserRequest {
    id: string;
}

export interface UserManagementApiUserIsAvailableRequest {
    username?: string;
}

export interface UserManagementApiUserRegisterRequest {
    melcoModelsUser?: MelcoModelsUser;
}

export interface UserManagementApiUserResendCodeRequest {
    username?: string;
}

export interface UserManagementApiUserResendForgotPasswordCodeRequest {
    username?: string;
}

export interface UserManagementApiUserResetPasswordRequest {
    melcoModelsResetPassword?: MelcoModelsResetPassword;
}

export interface UserManagementApiUserUnlockRequest {
    id: string;
}

export interface UserManagementApiUserVerifyRequest {
    username?: string;
    code?: string;
}

export interface UserManagementApiUserVerifyForgotPwdRequest {
    username?: string;
    code?: string;
}

/**
 * 
 */
export class UserManagementApi extends runtime.BaseAPI {

    /**
     * deactivate or log off device
     */
    async deviceDeleteDeviceRaw(requestParameters: UserManagementApiDeviceDeleteDeviceRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling deviceDeleteDevice.');
        }

        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling deviceDeleteDevice.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.id !== undefined) {
            queryParameters['Id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/account/products/{product_code}/devices/{device_Id}`.replace(`{${"product_code"}}`, encodeURIComponent(String(requestParameters.productCode))).replace(`{${"device_Id"}}`, encodeURIComponent(String(requestParameters.deviceId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * deactivate or log off device
     */
    async deviceDeleteDevice(requestParameters: UserManagementApiDeviceDeleteDeviceRequest): Promise<void> {
        await this.deviceDeleteDeviceRaw(requestParameters);
    }

    /**
     * delete device by user(obsolete)
     */
    async deviceDeleteDeviceByUserRaw(requestParameters: UserManagementApiDeviceDeleteDeviceByUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deviceDeleteDeviceByUser.');
        }

        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling deviceDeleteDeviceByUser.');
        }

        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling deviceDeleteDeviceByUser.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/{Id}/products/{product_code}/devices/{device_Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"product_code"}}`, encodeURIComponent(String(requestParameters.productCode))).replace(`{${"device_Id"}}`, encodeURIComponent(String(requestParameters.deviceId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete device by user(obsolete)
     */
    async deviceDeleteDeviceByUser(requestParameters: UserManagementApiDeviceDeleteDeviceByUserRequest): Promise<void> {
        await this.deviceDeleteDeviceByUserRaw(requestParameters);
    }

    /**
     * accept product legal policy (terms of use and privacy policy)
     */
    async legalPolicyAcceptLegalPolicyRaw(requestParameters: UserManagementApiLegalPolicyAcceptLegalPolicyRequest): Promise<runtime.ApiResponse<MelcoModelsEmailOptInListItem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling legalPolicyAcceptLegalPolicy.');
        }

        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling legalPolicyAcceptLegalPolicy.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/{Id}/products/{product_code}/legalpolicy/accept`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"product_code"}}`, encodeURIComponent(String(requestParameters.productCode))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsEmailOptInListItemFromJSON(jsonValue));
    }

    /**
     * accept product legal policy (terms of use and privacy policy)
     */
    async legalPolicyAcceptLegalPolicy(requestParameters: UserManagementApiLegalPolicyAcceptLegalPolicyRequest): Promise<MelcoModelsEmailOptInListItem> {
        const response = await this.legalPolicyAcceptLegalPolicyRaw(requestParameters);
        return await response.value();
    }

    /**
     * accept product legal policy (terms of use and privacy policy)
     */
    async legalPolicyDeclineLegalPolicyRaw(requestParameters: UserManagementApiLegalPolicyDeclineLegalPolicyRequest): Promise<runtime.ApiResponse<MelcoModelsEmailOptInListItem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling legalPolicyDeclineLegalPolicy.');
        }

        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling legalPolicyDeclineLegalPolicy.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/{Id}/products/{product_code}/legalpolicy/decline`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"product_code"}}`, encodeURIComponent(String(requestParameters.productCode))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsEmailOptInListItemFromJSON(jsonValue));
    }

    /**
     * accept product legal policy (terms of use and privacy policy)
     */
    async legalPolicyDeclineLegalPolicy(requestParameters: UserManagementApiLegalPolicyDeclineLegalPolicyRequest): Promise<MelcoModelsEmailOptInListItem> {
        const response = await this.legalPolicyDeclineLegalPolicyRaw(requestParameters);
        return await response.value();
    }

    /**
     * get list of user legal policy acceptance
     */
    async legalPolicyGetOptInListRaw(requestParameters: UserManagementApiLegalPolicyGetOptInListRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsEmailOptInListItem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling legalPolicyGetOptInList.');
        }

        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling legalPolicyGetOptInList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/{Id}/products/{product_code}/legalpolicy`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"product_code"}}`, encodeURIComponent(String(requestParameters.productCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsEmailOptInListItemFromJSON(jsonValue));
    }

    /**
     * get list of user legal policy acceptance
     */
    async legalPolicyGetOptInList(requestParameters: UserManagementApiLegalPolicyGetOptInListRequest): Promise<MelcoModelsGenericList1MelcoModelsEmailOptInListItem> {
        const response = await this.legalPolicyGetOptInListRaw(requestParameters);
        return await response.value();
    }

    /**
     * opt in for marketing newsletter
     */
    async legalPolicyNewsletterRaw(requestParameters: UserManagementApiLegalPolicyNewsletterRequest): Promise<runtime.ApiResponse<MelcoModelsEmailOptInListItem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling legalPolicyNewsletter.');
        }

        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling legalPolicyNewsletter.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/{Id}/products/{product_code}/newsletter`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"product_code"}}`, encodeURIComponent(String(requestParameters.productCode))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsEmailOptInListItemFromJSON(jsonValue));
    }

    /**
     * opt in for marketing newsletter
     */
    async legalPolicyNewsletter(requestParameters: UserManagementApiLegalPolicyNewsletterRequest): Promise<MelcoModelsEmailOptInListItem> {
        const response = await this.legalPolicyNewsletterRaw(requestParameters);
        return await response.value();
    }

    /**
     * allow access to product
     */
    async productAssignProductRaw(requestParameters: UserManagementApiProductAssignProductRequest): Promise<runtime.ApiResponse<MelcoModelsBoolResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productAssignProduct.');
        }

        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling productAssignProduct.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/{Id}/products/{product_code}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"product_code"}}`, encodeURIComponent(String(requestParameters.productCode))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     * allow access to product
     */
    async productAssignProduct(requestParameters: UserManagementApiProductAssignProductRequest): Promise<MelcoModelsBoolResponse> {
        const response = await this.productAssignProductRaw(requestParameters);
        return await response.value();
    }

    /**
     * get list of user products.
     * get user product list
     */
    async productGetListRaw(requestParameters: UserManagementApiProductGetListRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsUserProductListItem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productGetList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/{Id}/products`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsUserProductListItemFromJSON(jsonValue));
    }

    /**
     * get list of user products.
     * get user product list
     */
    async productGetList(requestParameters: UserManagementApiProductGetListRequest): Promise<MelcoModelsGenericList1MelcoModelsUserProductListItem> {
        const response = await this.productGetListRaw(requestParameters);
        return await response.value();
    }

    /**
     * get list of user products.
     * get user product
     */
    async productGetProductRaw(requestParameters: UserManagementApiProductGetProductRequest): Promise<runtime.ApiResponse<MelcoModelsUserProduct>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productGetProduct.');
        }

        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling productGetProduct.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/{Id}/products/{product_code}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"product_code"}}`, encodeURIComponent(String(requestParameters.productCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsUserProductFromJSON(jsonValue));
    }

    /**
     * get list of user products.
     * get user product
     */
    async productGetProduct(requestParameters: UserManagementApiProductGetProductRequest): Promise<MelcoModelsUserProduct> {
        const response = await this.productGetProductRaw(requestParameters);
        return await response.value();
    }

    /**
     * delete product access
     */
    async productRemoveProductAccessRaw(requestParameters: UserManagementApiProductRemoveProductAccessRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productRemoveProductAccess.');
        }

        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling productRemoveProductAccess.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/{Id}/products/{product_code}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"product_code"}}`, encodeURIComponent(String(requestParameters.productCode))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete product access
     */
    async productRemoveProductAccess(requestParameters: UserManagementApiProductRemoveProductAccessRequest): Promise<void> {
        await this.productRemoveProductAccessRaw(requestParameters);
    }

    /**
     * change user password
     * change / recover user password
     */
    async userChangePasswordRaw(requestParameters: UserManagementApiUserChangePasswordRequest): Promise<runtime.ApiResponse<MelcoModelsBoolResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userChangePassword.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/users/{Id}/changepassword`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsChangePasswordToJSON(requestParameters.melcoModelsChangePassword),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     * change user password
     * change / recover user password
     */
    async userChangePassword(requestParameters: UserManagementApiUserChangePasswordRequest): Promise<MelcoModelsBoolResponse> {
        const response = await this.userChangePasswordRaw(requestParameters);
        return await response.value();
    }

    /**
     * change user name and start the verification process
     * allow user to change user name ( requires authorization)
     */
    async userChangeUserNameRaw(requestParameters: UserManagementApiUserChangeUserNameRequest): Promise<runtime.ApiResponse<MelcoModelsBoolResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userChangeUserName.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/users/{Id}/username`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsChangeUserNameToJSON(requestParameters.melcoModelsChangeUserName),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     * change user name and start the verification process
     * allow user to change user name ( requires authorization)
     */
    async userChangeUserName(requestParameters: UserManagementApiUserChangeUserNameRequest): Promise<MelcoModelsBoolResponse> {
        const response = await this.userChangeUserNameRaw(requestParameters);
        return await response.value();
    }

    /**
     * change user name and start the verification process
     * change user name for unverified users
     */
    async userChangeUserNameAnonymouslyRaw(requestParameters: UserManagementApiUserChangeUserNameAnonymouslyRequest): Promise<runtime.ApiResponse<MelcoModelsBoolResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/users/username`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsChangeAnonymousUserNameToJSON(requestParameters.melcoModelsChangeAnonymousUserName),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     * change user name and start the verification process
     * change user name for unverified users
     */
    async userChangeUserNameAnonymously(requestParameters: UserManagementApiUserChangeUserNameAnonymouslyRequest): Promise<MelcoModelsBoolResponse> {
        const response = await this.userChangeUserNameAnonymouslyRaw(requestParameters);
        return await response.value();
    }

    /**
     * update user profile information
     * update user
     */
    async userEditRaw(requestParameters: UserManagementApiUserEditRequest): Promise<runtime.ApiResponse<MelcoModelsUser>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userEdit.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/users/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsUserToJSON(requestParameters.melcoModelsUser),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsUserFromJSON(jsonValue));
    }

    /**
     * update user profile information
     * update user
     */
    async userEdit(requestParameters: UserManagementApiUserEditRequest): Promise<MelcoModelsUser> {
        const response = await this.userEditRaw(requestParameters);
        return await response.value();
    }

    /**
     * start password recovery proccess by requesting verification code
     * start password recovery proccess
     */
    async userForgotPasswordRaw(requestParameters: UserManagementApiUserForgotPasswordRequest): Promise<runtime.ApiResponse<MelcoModelsBoolResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.username !== undefined) {
            queryParameters['username'] = requestParameters.username;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/forgotpassword`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     * start password recovery proccess by requesting verification code
     * start password recovery proccess
     */
    async userForgotPassword(requestParameters: UserManagementApiUserForgotPasswordRequest): Promise<MelcoModelsBoolResponse> {
        const response = await this.userForgotPasswordRaw(requestParameters);
        return await response.value();
    }

    /**
     * get list of users. requires admin privileges
     * get list of users
     */
    async userGetListRaw(requestParameters: UserManagementApiUserGetListRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsUserListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.firstName !== undefined) {
            queryParameters['first_name'] = requestParameters.firstName;
        }

        if (requestParameters.lastName !== undefined) {
            queryParameters['last_name'] = requestParameters.lastName;
        }

        if (requestParameters.userName !== undefined) {
            queryParameters['user_name'] = requestParameters.userName;
        }

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        if (requestParameters.serialNumber !== undefined) {
            queryParameters['serial_number'] = requestParameters.serialNumber;
        }

        if (requestParameters.countryList !== undefined) {
            queryParameters['country_list'] = requestParameters.countryList;
        }

        if (requestParameters.stateList !== undefined) {
            queryParameters['state_list'] = requestParameters.stateList;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.accountName !== undefined) {
            queryParameters['account_name'] = requestParameters.accountName;
        }

        if (requestParameters.allUsers !== undefined) {
            queryParameters['all_users'] = requestParameters.allUsers;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsUserListItemFromJSON(jsonValue));
    }

    /**
     * get list of users. requires admin privileges
     * get list of users
     */
    async userGetList(requestParameters: UserManagementApiUserGetListRequest): Promise<MelcoModelsGenericList1MelcoModelsUserListItem> {
        const response = await this.userGetListRaw(requestParameters);
        return await response.value();
    }

    /**
     * get user object
     * get user object
     */
    async userGetUserRaw(requestParameters: UserManagementApiUserGetUserRequest): Promise<runtime.ApiResponse<MelcoModelsUser>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userGetUser.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsUserFromJSON(jsonValue));
    }

    /**
     * get user object
     * get user object
     */
    async userGetUser(requestParameters: UserManagementApiUserGetUserRequest): Promise<MelcoModelsUser> {
        const response = await this.userGetUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * checks the user existence
     * check for user existence
     */
    async userIsAvailableRaw(requestParameters: UserManagementApiUserIsAvailableRequest): Promise<runtime.ApiResponse<MelcoModelsBoolResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.username !== undefined) {
            queryParameters['username'] = requestParameters.username;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/available`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     * checks the user existence
     * check for user existence
     */
    async userIsAvailable(requestParameters: UserManagementApiUserIsAvailableRequest): Promise<MelcoModelsBoolResponse> {
        const response = await this.userIsAvailableRaw(requestParameters);
        return await response.value();
    }

    /**
     * create new user in system and starts verification process
     * register new user
     */
    async userRegisterRaw(requestParameters: UserManagementApiUserRegisterRequest): Promise<runtime.ApiResponse<MelcoModelsUser>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsUserToJSON(requestParameters.melcoModelsUser),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsUserFromJSON(jsonValue));
    }

    /**
     * create new user in system and starts verification process
     * register new user
     */
    async userRegister(requestParameters: UserManagementApiUserRegisterRequest): Promise<MelcoModelsUser> {
        const response = await this.userRegisterRaw(requestParameters);
        return await response.value();
    }

    /**
     * resend user verification code
     * resend verification code
     */
    async userResendCodeRaw(requestParameters: UserManagementApiUserResendCodeRequest): Promise<runtime.ApiResponse<MelcoModelsResendCode>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.username !== undefined) {
            queryParameters['username'] = requestParameters.username;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/resendcode`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsResendCodeFromJSON(jsonValue));
    }

    /**
     * resend user verification code
     * resend verification code
     */
    async userResendCode(requestParameters: UserManagementApiUserResendCodeRequest): Promise<MelcoModelsResendCode> {
        const response = await this.userResendCodeRaw(requestParameters);
        return await response.value();
    }

    /**
     * request new forgot password code
     * resend forgot password code
     */
    async userResendForgotPasswordCodeRaw(requestParameters: UserManagementApiUserResendForgotPasswordCodeRequest): Promise<runtime.ApiResponse<MelcoModelsResendCode>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.username !== undefined) {
            queryParameters['username'] = requestParameters.username;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/forgotpassword/resendcode`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsResendCodeFromJSON(jsonValue));
    }

    /**
     * request new forgot password code
     * resend forgot password code
     */
    async userResendForgotPasswordCode(requestParameters: UserManagementApiUserResendForgotPasswordCodeRequest): Promise<MelcoModelsResendCode> {
        const response = await this.userResendForgotPasswordCodeRaw(requestParameters);
        return await response.value();
    }

    /**
     * reset user password
     * reset user password
     */
    async userResetPasswordRaw(requestParameters: UserManagementApiUserResetPasswordRequest): Promise<runtime.ApiResponse<MelcoModelsBoolResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/users/forgotpassword/reset`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsResetPasswordToJSON(requestParameters.melcoModelsResetPassword),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     * reset user password
     * reset user password
     */
    async userResetPassword(requestParameters: UserManagementApiUserResetPasswordRequest): Promise<MelcoModelsBoolResponse> {
        const response = await this.userResetPasswordRaw(requestParameters);
        return await response.value();
    }

    /**
     * unlock user account
     * unlock user account
     */
    async userUnlockRaw(requestParameters: UserManagementApiUserUnlockRequest): Promise<runtime.ApiResponse<MelcoModelsBoolResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userUnlock.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/{Id}/unlock`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     * unlock user account
     * unlock user account
     */
    async userUnlock(requestParameters: UserManagementApiUserUnlockRequest): Promise<MelcoModelsBoolResponse> {
        const response = await this.userUnlockRaw(requestParameters);
        return await response.value();
    }

    /**
     * verify user account
     * verify user account
     */
    async userVerifyRaw(requestParameters: UserManagementApiUserVerifyRequest): Promise<runtime.ApiResponse<MelcoModelsBoolResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.username !== undefined) {
            queryParameters['username'] = requestParameters.username;
        }

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/verify`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     * verify user account
     * verify user account
     */
    async userVerify(requestParameters: UserManagementApiUserVerifyRequest): Promise<MelcoModelsBoolResponse> {
        const response = await this.userVerifyRaw(requestParameters);
        return await response.value();
    }

    /**
     * verify forgot password code so user can change password
     * verify forgot password code
     */
    async userVerifyForgotPwdRaw(requestParameters: UserManagementApiUserVerifyForgotPwdRequest): Promise<runtime.ApiResponse<MelcoModelsBoolResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.username !== undefined) {
            queryParameters['username'] = requestParameters.username;
        }

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/forgotpassword/verify`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     * verify forgot password code so user can change password
     * verify forgot password code
     */
    async userVerifyForgotPwd(requestParameters: UserManagementApiUserVerifyForgotPwdRequest): Promise<MelcoModelsBoolResponse> {
        const response = await this.userVerifyForgotPwdRaw(requestParameters);
        return await response.value();
    }

}
