/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoModelsUserDefaults
 */
export interface MelcoModelsUserDefaults {
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserDefaults
     */
    default_language?: string | null;
}

export function MelcoModelsUserDefaultsFromJSON(json: any): MelcoModelsUserDefaults {
    return MelcoModelsUserDefaultsFromJSONTyped(json, false);
}

export function MelcoModelsUserDefaultsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsUserDefaults {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'default_language': !exists(json, 'default_language') ? undefined : json['default_language'],
    };
}

export function MelcoModelsUserDefaultsToJSON(value?: MelcoModelsUserDefaults | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'default_language': value.default_language,
    };
}


