/* tslint:disable */
/* eslint-disable */
/**
 * Melco Efs APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EfsMelcoConstantsFulfillmentClientRunStatus {
    InProgress = 'InProgress',
    Cancelled = 'Cancelled',
    Completed = 'Completed'
}

export function EfsMelcoConstantsFulfillmentClientRunStatusFromJSON(json: any): EfsMelcoConstantsFulfillmentClientRunStatus {
    return EfsMelcoConstantsFulfillmentClientRunStatusFromJSONTyped(json, false);
}

export function EfsMelcoConstantsFulfillmentClientRunStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): EfsMelcoConstantsFulfillmentClientRunStatus {
    return json as EfsMelcoConstantsFulfillmentClientRunStatus;
}

export function EfsMelcoConstantsFulfillmentClientRunStatusToJSON(value?: EfsMelcoConstantsFulfillmentClientRunStatus | null): any {
    return value as any;
}

