import { isEmpty } from "lodash-es";
import { Button, PlusIcon } from "melco-ui";
import { useTranslation } from "react-i18next";
import { Key } from "antd/lib/table/interface";
import { useAddDesigns } from "../../../hooks/libraries/designCollections/useAddDesigns";
import { MelcoModelsDesignListItem } from "melco-shared-logic";

type BulkActionAddDesignsProps = {
  allDesigns: MelcoModelsDesignListItem[];
  selectedRowKeys: Key[];
  setSelectedRowKeys: (keys: Key[]) => void;
};

export const BulkActionAddDesigns: React.FC<BulkActionAddDesignsProps> = ({
  allDesigns,
  selectedRowKeys,
  setSelectedRowKeys,
}) => {
  const { t } = useTranslation();

  const { addDesigns } = useAddDesigns();

  if (isEmpty(selectedRowKeys)) {
    return null;
  }

  return (
    <Button
      key="bulk-add-selected"
      icon={<PlusIcon />}
      onClick={() => {
        const designsToAdd = allDesigns.filter((d) =>
          selectedRowKeys.includes(d.id!)
        );

        addDesigns(designsToAdd);

        setSelectedRowKeys([]);
      }}
    >
      {t(
        "libraries.design_collections.actions.add_designs.actions.add_selected"
      )}
    </Button>
  );
};
