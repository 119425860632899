import { useField } from "formik";
import { FusionMelcoFusionModelsCustomProductImageDesign } from "melco-shared-logic";
import { useProductCanvasImage } from "../../../hooks/customization/products/useProductCanvasImage";
import { useUpdateRendererMetaData } from "../../../hooks/customization/products/useUpdateRendererMetaData";
import { RenderedProduct } from "../../renderer/RenderedProduct";

type ProductViewCanvasRendererProps = {
  formikPrefix: string;
  activeImageId: string;
  activeImageIndex: number;
};

export const ProductViewCanvasRenderer: React.FC<
  ProductViewCanvasRendererProps
> = ({ formikPrefix, activeImageId, activeImageIndex }) => {
  const [{ value: productImageDesign }] = useField<
    FusionMelcoFusionModelsCustomProductImageDesign | undefined
  >(`${formikPrefix}.color_list[${activeImageIndex}].design_list[0]`);

  const blankPictureUrl = useProductCanvasImage(activeImageId);

  const updateRendererMetaData = useUpdateRendererMetaData(
    `${formikPrefix}.color_list`
  );

  if (!blankPictureUrl) {
    return null;
  }

  return (
    <RenderedProduct
      key={`${formikPrefix}-${activeImageId}`}
      viewId={activeImageId}
      blankPictureUrl={blankPictureUrl}
      productImageDesign={productImageDesign}
      updateRendererMetaData={updateRendererMetaData}
    />
  );
};
