/* tslint:disable */
/* eslint-disable */
/**
 * Melco Reporting APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReportingMelcoMachineStatisticsModelsStatisticListItem,
    ReportingMelcoMachineStatisticsModelsStatisticListItemFromJSON,
    ReportingMelcoMachineStatisticsModelsStatisticListItemFromJSONTyped,
    ReportingMelcoMachineStatisticsModelsStatisticListItemToJSON,
    ReportingMelcoModelsCustomWarning,
    ReportingMelcoModelsCustomWarningFromJSON,
    ReportingMelcoModelsCustomWarningFromJSONTyped,
    ReportingMelcoModelsCustomWarningToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsStatisticListItem
 */
export interface ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsStatisticListItem {
    /**
     * 
     * @type {Array<ReportingMelcoMachineStatisticsModelsStatisticListItem>}
     * @memberof ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsStatisticListItem
     */
    list: Array<ReportingMelcoMachineStatisticsModelsStatisticListItem>;
    /**
     * 
     * @type {Array<ReportingMelcoModelsCustomWarning>}
     * @memberof ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsStatisticListItem
     */
    warning_list?: Array<ReportingMelcoModelsCustomWarning> | null;
    /**
     * 
     * @type {number}
     * @memberof ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsStatisticListItem
     */
    total: number;
}

export function ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsStatisticListItemFromJSON(json: any): ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsStatisticListItem {
    return ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsStatisticListItemFromJSONTyped(json, false);
}

export function ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsStatisticListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsStatisticListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'list': ((json['list'] as Array<any>).map(ReportingMelcoMachineStatisticsModelsStatisticListItemFromJSON)),
        'warning_list': !exists(json, 'warning_list') ? undefined : (json['warning_list'] === null ? null : (json['warning_list'] as Array<any>).map(ReportingMelcoModelsCustomWarningFromJSON)),
        'total': json['total'],
    };
}

export function ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsStatisticListItemToJSON(value?: ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsStatisticListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'list': ((value.list as Array<any>).map(ReportingMelcoMachineStatisticsModelsStatisticListItemToJSON)),
        'warning_list': value.warning_list === undefined ? undefined : (value.warning_list === null ? null : (value.warning_list as Array<any>).map(ReportingMelcoModelsCustomWarningToJSON)),
        'total': value.total,
    };
}


