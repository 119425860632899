import { useField } from "formik";
import { FusionMelcoModelsCanvasPreview } from "melco-shared-logic";

export const useProductCanvasImage = (viewId: string) => {
  const [{ value: canvasPreviews }] = useField<
    FusionMelcoModelsCanvasPreview[] | undefined
  >("blank.canvas_previews");

  if (!canvasPreviews) {
    return undefined;
  }

  const canvasPreview = canvasPreviews.find((cp) => cp.id === viewId);

  return canvasPreview?.url ?? undefined;
};
