import {
  BulkActionContainerCol,
  Col,
  DesignTokens,
  LabeledColorSwatch,
  Table,
  TableActionBarRow,
  ValueOrFallback,
} from "melco-ui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  BulkActionCount,
  FormattedDate,
  FusionMelcoModelsUserImage,
  Search,
  useTableSelection,
  TableCellLink,
  ViewContext,
} from "melco-shared-logic";
import { TFunction } from "i18next";
import { BulkActionDeleteBlankPictures } from "./BulkActionDeleteBlankPictures";
import { PublishedState } from "../../../state/PublishedState";
import { BlankPicturesListThumbnail } from "./BlankPicturesListThumbnail";
import { useBlankPictures } from "../../../../hooks/libraries/blanks/blankPictures/useBlankPictures";

const { spacings } = DesignTokens;

const DETAIL_VIEW_PATH = "/libraries/blank-pictures";

export const blankPictureColumns = (
  t: TFunction,
  context: ViewContext = ViewContext.List
) => {
  // use local sorting for ViewContext.Detail
  const sorter = (dataIndex: string) => {
    if (context === ViewContext.Detail) {
      return (a: any, b: any) =>
        String(a[dataIndex]).localeCompare(String(b[dataIndex]));
    }

    return true;
  };

  return [
    {
      title: "",
      key: "thumbnail",
      dataIndex: "thumbnail",
      render: (_: string, item: FusionMelcoModelsUserImage) => (
        <BlankPicturesListThumbnail blankPicture={item} />
      ),
      width: spacings.xlarge + 2 * spacings.middle, // 2 * spacings.middle to compensate for table padding
      height: spacings.xlarge + 2 * spacings.middle, // 2 * spacings.middle to compensate for table padding
    },
    {
      title: t("blank_pictures_list.columns.name"),
      key: "name",
      dataIndex: "name",
      sorter: sorter("name"),
      render: (_: string, item: FusionMelcoModelsUserImage) => (
        <ValueOrFallback
          value={item.name}
          render={(value) =>
            context === ViewContext.List ? (
              <TableCellLink to={[DETAIL_VIEW_PATH, item.id].join("/")}>
                {value}
              </TableCellLink>
            ) : (
              <>{value}</>
            )
          }
          fallback={t("global.fallback.empty")}
        />
      ),
    },
    {
      title: t("blank_pictures_list.columns.view"),
      key: "view_name",
      dataIndex: "view_name",
      sorter: sorter("view_name"),
      render: (_: string, item: FusionMelcoModelsUserImage) => (
        <ValueOrFallback
          value={item.view_name}
          fallback={t("global.fallback.none")}
        />
      ),
    },
    {
      title: t("blank_pictures_list.columns.color"),
      key: "color_name",
      dataIndex: "color_name",
      sorter: sorter("color_name"),
      render: (_: string, item: FusionMelcoModelsUserImage) => {
        const { color_hex: colorHex, color_name: colorName } = item;

        return (
          <LabeledColorSwatch
            argb={colorHex ?? undefined}
            label={
              <ValueOrFallback
                value={colorName}
                fallback={t("global.fallback.none")}
              />
            }
          />
        );
      },
    },
  ];
};

export const BlankPicturesList: React.FC = () => {
  const {
    isLoading,
    isFetching,
    data,
    isPreviousData,
    errorComponent,
    onTableConfigChange,
    pagination,
    searchQueryChange,
    refetch,
  } = useBlankPictures();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const { selectedRowKeys, setSelectedRowKeys, rowSelection, onRow } =
    useTableSelection((row: FusionMelcoModelsUserImage) => {
      navigate([DETAIL_VIEW_PATH, row.id].join("/"));
    });

  const columns = [
    ...blankPictureColumns(t),
    {
      title: t("blanks_list.columns.created_date"),
      key: "created_date",
      dataIndex: "created_date",
      sorter: true,
      render: (_: string, item: FusionMelcoModelsUserImage) => (
        <FormattedDate date={item.created_date} />
      ),
    },
    {
      title: t("blanks_list.columns.updated_date"),
      key: "updated_date",
      dataIndex: "updated_date",
      sorter: true,
      render: (_: string, item: FusionMelcoModelsUserImage) => (
        <FormattedDate date={item.updated_date} />
      ),
    },
    {
      title: t("blank_pictures_list.columns.publish_state"),
      key: "publish_state",
      dataIndex: "publish_state",
      sorter: true,
      render: (_: string, item: FusionMelcoModelsUserImage) => {
        return <PublishedState publishedState={item.publish_state ?? 1} />;
      },
    },
  ];

  return (
    <>
      <TableActionBarRow>
        <Col>
          <Search
            onSearchInputChange={(query: string) => searchQueryChange(query)}
          />
        </Col>
        <BulkActionContainerCol>
          <BulkActionCount selectedCount={selectedRowKeys.length} />
          <BulkActionDeleteBlankPictures
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            refetch={refetch}
            data={data}
          />
        </BulkActionContainerCol>
      </TableActionBarRow>
      <Table
        showSorterTooltip={false}
        sticky
        loading={isPreviousData ? isFetching : isLoading}
        dataSource={data}
        columns={columns}
        onChange={onTableConfigChange}
        pagination={pagination}
        rowKey="id"
        rowSelection={rowSelection}
        onRow={onRow}
        rowClassName={() => "clickable"}
        noDataMessage={t("blank_pictures_list.empty")}
        errorComponent={errorComponent}
      />
    </>
  );
};
