import { useTranslation } from "react-i18next";
import {
  BulkActionCount,
  MelcoModelsSupplierProduct,
  MelcoModelsThread,
  Search,
  useTableSelection,
} from "melco-shared-logic";
import {
  BulkActionContainerCol,
  Button,
  Col,
  PlusIcon,
  Table,
  TableActionBarRow,
} from "melco-ui";
import { useThreads } from "../../../hooks/libraries/colors/useThreads";
import { threadsListColumns } from "../colors/ThreadsList";
import { useAddColors } from "../../../hooks/libraries/colorCollections/useAddColors";
import { BulkActionAddColors } from "./BulkActionAddColors";

type AddColorsListProps = {
  threadSupplierProduct: MelcoModelsSupplierProduct;
};

export const AddColorsList: React.FC<AddColorsListProps> = ({
  threadSupplierProduct,
}) => {
  const { id } = threadSupplierProduct;

  const { addColors } = useAddColors();

  const {
    isLoading,
    isFetching,
    data,
    isPreviousData,
    errorComponent,
    onTableConfigChange,
    pagination,
    searchQueryChange,
  } = useThreads(id);

  const { t } = useTranslation();

  const columns = [
    ...threadsListColumns(t),

    {
      title: "",
      key: "action",
      dataIndex: "action",
      align: "right" as const,
      render: (_: string, thread: MelcoModelsThread) => (
        <Button
          type="primary"
          icon={<PlusIcon />}
          size="small"
          onClick={() => addColors([thread])}
        >
          {t("global.button.add")}
        </Button>
      ),
    },
  ];

  const { selectedRowKeys, setSelectedRowKeys, rowSelection, onRow } =
    useTableSelection<MelcoModelsThread>(() => {});

  return (
    <>
      <TableActionBarRow>
        <Col>
          <Search
            onSearchInputChange={(query: string) => searchQueryChange(query)}
          />
        </Col>
        <BulkActionContainerCol>
          <BulkActionCount selectedCount={selectedRowKeys.length} />
          <BulkActionAddColors
            allThreads={data ?? []}
            select={addColors}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
          />
        </BulkActionContainerCol>
      </TableActionBarRow>
      <Table
        showSorterTooltip={false}
        sticky
        loading={isPreviousData ? isFetching : isLoading}
        dataSource={data}
        columns={columns}
        onChange={onTableConfigChange}
        pagination={pagination}
        rowKey="id"
        rowSelection={rowSelection}
        onRow={onRow}
        noDataMessage={t("threads_list.empty")}
        errorComponent={errorComponent}
      />
    </>
  );
};
