import { Color, ColorUtil } from "@melco/renderer";
import {
  FusionMelcoModelsColorListItem,
  FusionMelcoModelsDesignElement,
  FusionMelcoModelsDesignLetteringElement,
} from "melco-shared-logic";
import { ColorHelper } from "melco-ui";
import compact from "lodash-es/compact";
import uniq from "lodash-es/uniq";
import { RendererElement } from "../hooks/renderer/useRenderer";

type ColorCollectionLookup = (
  colorCollectionId: string | undefined,
  colorId: string | undefined
) => FusionMelcoModelsColorListItem | undefined;

export const { argbToRGB, isValidARGB } = ColorHelper;

export const colorToRendererColor = (
  color?: FusionMelcoModelsColorListItem
) => {
  if (
    !color ||
    !color.color?.argb ||
    !isValidARGB(color.color.argb ?? undefined)
  ) {
    return;
  }

  const rgb = argbToRGB(color.color?.argb ?? undefined);

  return rgbColorToRendererColor(rgb);
};

export const rgbColorToRendererColor = (rgb: string) => {
  const rgbAsHexNumber = parseInt(rgb.replace(/^#/, ""), 16);

  return ColorUtil.fromHexVal(rgbAsHexNumber, 1.0);
};

export const truncateText = (
  lettering?: FusionMelcoModelsDesignLetteringElement
) => {
  if (!lettering) {
    return "";
  }

  const defaultLettering = lettering.default_text ?? "";

  const {
    text,
    text_max_length: textMaxLength,
    default_alphabet_id: defaultAlphabetId,
  } = lettering;

  // no font has been selected, render the default text
  if (!defaultAlphabetId || defaultAlphabetId === "") {
    return defaultLettering;
  }

  if (!text) {
    return "";
  }

  if (textMaxLength && textMaxLength > 0) {
    return text.substring(0, textMaxLength);
  }

  return text;
};

const getRfmUrlForAlphabet = (
  alphabetId: string | undefined,
  elementList: FusionMelcoModelsDesignElement[]
) => {
  if (!alphabetId) {
    return undefined;
  }

  for (const element of elementList) {
    for (const alphabet of element.lettering?.alphabet_list ?? []) {
      const { id, rfm_url: rfmUrl } = alphabet;
      if (id === alphabetId) {
        if (rfmUrl) {
          return rfmUrl;
        } else {
          console.error("Could not find RFM url for alphabet", alphabetId);
        }
      }
    }
  }
};

export const rendererElementsFromElementList = (
  elementList: FusionMelcoModelsDesignElement[],
  colorCollectionLookup?: ColorCollectionLookup
) => {
  const rendererElements: RendererElement[] = elementList.map((element) => {
    const {
      editable,
      active_color_group_id: activeColorGroupId,
      color_collection_id: colorCollectionId,
      color_collection: colorCollection,
      default_color_id: defaultColorId,
    } = element;

    let color: Color | undefined;

    if (editable) {
      if (colorCollectionLookup) {
        // look up color collections by fetching them from the API (e.g. on design detail page)
        color = colorToRendererColor(
          colorCollectionLookup(
            colorCollectionId ?? undefined,
            defaultColorId ?? undefined
          )
        );
      } else {
        // use color collections that are stored alongside the design (e.g. in configurator and on product page)
        const colorFromCollection = (colorCollection?.color_list ?? []).find(
          (c) => defaultColorId && defaultColorId === c.id
        );

        color = colorFromCollection
          ? colorToRendererColor(colorFromCollection)
          : rgbColorToRendererColor(element.color?.rgb ?? "");
      }
    } else {
      color = rgbColorToRendererColor(element.color?.rgb ?? "");
    }

    const lettering = element.lettering
      ? {
          text: truncateText(element.lettering),
          rfmUrl: getRfmUrlForAlphabet(
            element.lettering.default_alphabet_id ?? undefined,
            elementList
          ),
          rendererIndex: element.lettering.renderer_index!,
          isEditable: editable ?? false,
        }
      : undefined;

    return {
      color,
      activeColorGroupId: activeColorGroupId
        ? parseInt(activeColorGroupId)
        : undefined,
      lettering,
    };
  });

  return rendererElements;
};

export const deDuplicateAlphabetRFMUrls = (
  rendererElements: RendererElement[]
) =>
  compact(uniq(rendererElements.map((e) => e.lettering?.rfmUrl))).map(
    (url) => ({
      url,
      resourceType: "alphabet" as const,
    })
  );
