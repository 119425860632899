/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoModelsDevice,
    MelcoModelsDeviceFromJSON,
    MelcoModelsDeviceFromJSONTyped,
    MelcoModelsDeviceToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoModelsSingleUseCodeAuth
 */
export interface MelcoModelsSingleUseCodeAuth {
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsSingleUseCodeAuth
     */
    user_name: string;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsSingleUseCodeAuth
     */
    code: string;
    /**
     * 
     * @type {MelcoModelsDevice}
     * @memberof MelcoModelsSingleUseCodeAuth
     */
    device_info: MelcoModelsDevice;
}

export function MelcoModelsSingleUseCodeAuthFromJSON(json: any): MelcoModelsSingleUseCodeAuth {
    return MelcoModelsSingleUseCodeAuthFromJSONTyped(json, false);
}

export function MelcoModelsSingleUseCodeAuthFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsSingleUseCodeAuth {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user_name': json['user_name'],
        'code': json['code'],
        'device_info': MelcoModelsDeviceFromJSON(json['device_info']),
    };
}

export function MelcoModelsSingleUseCodeAuthToJSON(value?: MelcoModelsSingleUseCodeAuth | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_name': value.user_name,
        'code': value.code,
        'device_info': MelcoModelsDeviceToJSON(value.device_info),
    };
}


