import { useCallback, useEffect } from "react";
import { useField } from "formik";
import {
  compact,
  find,
  isEmpty,
  isEqual,
  reject,
  uniqBy,
  differenceBy,
} from "lodash";
import { FusionMelcoModelsUserImage } from "melco-shared-logic";
import { moveArray } from "../../../helper/moveArray";

export const deriveColorsFromBlankPictures = (
  blankPictures: FusionMelcoModelsUserImage[],
  previousColorIds: string[]
) => {
  const allColors = blankPictures.map((image) => ({
    id: image.id!,
    color_name: image.color_name,
  }));

  const previouslySortedColors = compact(
    previousColorIds.map((id) => find(blankPictures, (bp) => bp.id === id))
  );

  const newlyAddedColors = differenceBy(
    allColors,
    previouslySortedColors,
    "id"
  );

  const colors = [...previouslySortedColors, ...newlyAddedColors];

  const uniqueColors = reject(uniqBy(colors, "color_name"), (c) =>
    isEmpty(c.color_name)
  );

  return uniqueColors.map((c) => c.id) as string[];
};

export type ColorNameListItem = {
  key: string;
  colorName: string;
  colorHex: string;
};

export const idsToColorNames = (
  blankPictureIds: string[],
  blankPictures: FusionMelcoModelsUserImage[]
) =>
  uniqBy(
    blankPictureIds.flatMap((bpId) => {
      const blankPicture = find(blankPictures, (bp) => bp.id === bpId);
      if (!blankPicture) {
        return [];
      }

      const { color_name, color_hex } = blankPicture;

      return [
        {
          // we always need to pass a unique string to be used as a draggable id
          key: isEmpty(color_name) ? "EMPTY-STRING" : color_name!,
          colorName: color_name!,
          colorHex: color_hex,
        } as ColorNameListItem,
      ];
    }),
    "colorName"
  );

export const useColors = () => {
  const [{ value: blankPictures }] =
    useField<FusionMelcoModelsUserImage[]>("images");

  const [
    { value: orderedBlankPictureIds },
    ,
    { setValue: setOrderedBlankPictureIds },
  ] = useField<string[]>("colors");

  useEffect(() => {
    const newColors = deriveColorsFromBlankPictures(
      blankPictures,
      orderedBlankPictureIds
    );

    if (!isEqual(newColors, orderedBlankPictureIds)) {
      setOrderedBlankPictureIds(newColors);
    }

    // ignoring changes to colors, as we only perform a one way sync from blank pictures to colors
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blankPictures, setOrderedBlankPictureIds]);

  const reorderColors = useCallback(
    (fromIndex: number, toIndex: number) => {
      setOrderedBlankPictureIds(
        moveArray(orderedBlankPictureIds, fromIndex, toIndex)
      );
    },
    [orderedBlankPictureIds, setOrderedBlankPictureIds]
  );

  const colors = idsToColorNames(orderedBlankPictureIds, blankPictures);

  return [colors, reorderColors] as const;
};
