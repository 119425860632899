/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MelcoFusionModelsBlankReferences,
    MelcoFusionModelsBlankReferencesFromJSON,
    MelcoFusionModelsBlankReferencesToJSON,
    MelcoFusionModelsCreateBlank,
    MelcoFusionModelsCreateBlankFromJSON,
    MelcoFusionModelsCreateBlankToJSON,
    MelcoFusionModelsUpdateBlank,
    MelcoFusionModelsUpdateBlankFromJSON,
    MelcoFusionModelsUpdateBlankToJSON,
    MelcoFusionModelsUserBlank,
    MelcoFusionModelsUserBlankFromJSON,
    MelcoFusionModelsUserBlankToJSON,
    MelcoModelsAddAlphabetCollection,
    MelcoModelsAddAlphabetCollectionFromJSON,
    MelcoModelsAddAlphabetCollectionToJSON,
    MelcoModelsAddColorCollection,
    MelcoModelsAddColorCollectionFromJSON,
    MelcoModelsAddColorCollectionToJSON,
    MelcoModelsAlphabetCollection,
    MelcoModelsAlphabetCollectionFromJSON,
    MelcoModelsAlphabetCollectionToJSON,
    MelcoModelsAlphabetCollectionReferences,
    MelcoModelsAlphabetCollectionReferencesFromJSON,
    MelcoModelsAlphabetCollectionReferencesToJSON,
    MelcoModelsAlphabetReferences,
    MelcoModelsAlphabetReferencesFromJSON,
    MelcoModelsAlphabetReferencesToJSON,
    MelcoModelsBoolResponse,
    MelcoModelsBoolResponseFromJSON,
    MelcoModelsBoolResponseToJSON,
    MelcoModelsColorCollection,
    MelcoModelsColorCollectionFromJSON,
    MelcoModelsColorCollectionToJSON,
    MelcoModelsColorCollectionReferences,
    MelcoModelsColorCollectionReferencesFromJSON,
    MelcoModelsColorCollectionReferencesToJSON,
    MelcoModelsCreateDesignCollection,
    MelcoModelsCreateDesignCollectionFromJSON,
    MelcoModelsCreateDesignCollectionToJSON,
    MelcoModelsDesign,
    MelcoModelsDesignFromJSON,
    MelcoModelsDesignToJSON,
    MelcoModelsDesignCollection,
    MelcoModelsDesignCollectionFromJSON,
    MelcoModelsDesignCollectionToJSON,
    MelcoModelsDesignReferences,
    MelcoModelsDesignReferencesFromJSON,
    MelcoModelsDesignReferencesToJSON,
    MelcoModelsError,
    MelcoModelsErrorFromJSON,
    MelcoModelsErrorToJSON,
    MelcoModelsGenericList1MelcoFusionModelsUserBlankListItem,
    MelcoModelsGenericList1MelcoFusionModelsUserBlankListItemFromJSON,
    MelcoModelsGenericList1MelcoFusionModelsUserBlankListItemToJSON,
    MelcoModelsGenericList1MelcoModelsAlphabetCollection,
    MelcoModelsGenericList1MelcoModelsAlphabetCollectionFromJSON,
    MelcoModelsGenericList1MelcoModelsAlphabetCollectionToJSON,
    MelcoModelsGenericList1MelcoModelsColorCollection,
    MelcoModelsGenericList1MelcoModelsColorCollectionFromJSON,
    MelcoModelsGenericList1MelcoModelsColorCollectionToJSON,
    MelcoModelsGenericList1MelcoModelsDesignCollection,
    MelcoModelsGenericList1MelcoModelsDesignCollectionFromJSON,
    MelcoModelsGenericList1MelcoModelsDesignCollectionToJSON,
    MelcoModelsGenericList1MelcoModelsDesignListItem,
    MelcoModelsGenericList1MelcoModelsDesignListItemFromJSON,
    MelcoModelsGenericList1MelcoModelsDesignListItemToJSON,
    MelcoModelsGenericList1MelcoModelsUserAlphabet,
    MelcoModelsGenericList1MelcoModelsUserAlphabetFromJSON,
    MelcoModelsGenericList1MelcoModelsUserAlphabetToJSON,
    MelcoModelsGenericList1MelcoModelsUserImage,
    MelcoModelsGenericList1MelcoModelsUserImageFromJSON,
    MelcoModelsGenericList1MelcoModelsUserImageToJSON,
    MelcoModelsImageReferences,
    MelcoModelsImageReferencesFromJSON,
    MelcoModelsImageReferencesToJSON,
    MelcoModelsNotification,
    MelcoModelsNotificationFromJSON,
    MelcoModelsNotificationToJSON,
    MelcoModelsUpdateAlphabetCollection,
    MelcoModelsUpdateAlphabetCollectionFromJSON,
    MelcoModelsUpdateAlphabetCollectionToJSON,
    MelcoModelsUpdateColorCollection,
    MelcoModelsUpdateColorCollectionFromJSON,
    MelcoModelsUpdateColorCollectionToJSON,
    MelcoModelsUpdateDesign,
    MelcoModelsUpdateDesignFromJSON,
    MelcoModelsUpdateDesignToJSON,
    MelcoModelsUpdateDesignCollection,
    MelcoModelsUpdateDesignCollectionFromJSON,
    MelcoModelsUpdateDesignCollectionToJSON,
    MelcoModelsUserAlphabet,
    MelcoModelsUserAlphabetFromJSON,
    MelcoModelsUserAlphabetToJSON,
    MelcoModelsUserImage,
    MelcoModelsUserImageFromJSON,
    MelcoModelsUserImageToJSON,
} from '../models';

export interface UserContentsDraftPublishApiAlphabetCollectionsReferencesRequest {
    id: string;
}

export interface UserContentsDraftPublishApiColorCollectionsReferencesRequest {
    id: string;
}

export interface UserContentsDraftPublishApiDesignCollectionsReferencesRequest {
    id: string;
}

export interface UserContentsDraftPublishApiUserAlphabetCollectionPublishRequest {
    id: string;
}

export interface UserContentsDraftPublishApiUserAlphabetCollectionUpdateDraftRequest {
    id: string;
    melcoModelsUpdateAlphabetCollection?: MelcoModelsUpdateAlphabetCollection;
}

export interface UserContentsDraftPublishApiUserAlphabetCollectionsCreateDraftRequest {
    melcoModelsAddAlphabetCollection?: MelcoModelsAddAlphabetCollection;
}

export interface UserContentsDraftPublishApiUserAlphabetCollectionsDeleteRequest {
    id: string;
    force?: boolean;
}

export interface UserContentsDraftPublishApiUserAlphabetCollectionsDuplicateDraftRequest {
    id: string;
}

export interface UserContentsDraftPublishApiUserAlphabetCollectionsGetDefRequest {
    id: string;
    publishState?: string;
}

export interface UserContentsDraftPublishApiUserAlphabetCollectionsGetListRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    searchText?: string;
    publishState?: string;
}

export interface UserContentsDraftPublishApiUserAlphabetCollectionsRevertRequest {
    id: string;
}

export interface UserContentsDraftPublishApiUserAlphabetDeleteRequest {
    id: string;
    force?: boolean;
}

export interface UserContentsDraftPublishApiUserAlphabetDownloadRequest {
    id: string;
    publishState?: string;
}

export interface UserContentsDraftPublishApiUserAlphabetDuplicateDraftRequest {
    id: string;
}

export interface UserContentsDraftPublishApiUserAlphabetGetRequest {
    id: string;
    publishState?: string;
}

export interface UserContentsDraftPublishApiUserAlphabetPreviewRequest {
    id: string;
    width?: number;
    publishState?: string;
}

export interface UserContentsDraftPublishApiUserAlphabetPublishRequest {
    id: string;
}

export interface UserContentsDraftPublishApiUserAlphabetReferencesRequest {
    id: string;
}

export interface UserContentsDraftPublishApiUserAlphabetRevertRequest {
    id: string;
}

export interface UserContentsDraftPublishApiUserAlphabetSearchRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    searchText?: string;
    service?: string;
    publishState?: string;
}

export interface UserContentsDraftPublishApiUserAlphabetUpdateDraftRequest {
    id: string;
    name?: string;
    recommendedText?: string;
    file?: Blob;
    binaryFileName?: string;
    binaryFileFileData?: string;
    binaryFileSignature?: string;
}

export interface UserContentsDraftPublishApiUserAlphabetsUploadDraftRequest {
    service?: string;
    file?: Blob;
}

export interface UserContentsDraftPublishApiUserBlankCreateDraftRequest {
    melcoFusionModelsCreateBlank?: MelcoFusionModelsCreateBlank;
}

export interface UserContentsDraftPublishApiUserBlankDeleteRequest {
    id: string;
    force?: boolean;
}

export interface UserContentsDraftPublishApiUserBlankDuplicateDraftRequest {
    id: string;
}

export interface UserContentsDraftPublishApiUserBlankGetDefRequest {
    id: string;
    publishState?: string;
}

export interface UserContentsDraftPublishApiUserBlankPublishRequest {
    id: string;
}

export interface UserContentsDraftPublishApiUserBlankReferencesRequest {
    id: string;
}

export interface UserContentsDraftPublishApiUserBlankRevertRequest {
    id: string;
}

export interface UserContentsDraftPublishApiUserBlankSearchRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    searchText?: string;
    publishState?: string;
}

export interface UserContentsDraftPublishApiUserBlankUpdateDraftRequest {
    id: string;
    melcoFusionModelsUpdateBlank?: MelcoFusionModelsUpdateBlank;
}

export interface UserContentsDraftPublishApiUserBlanksPreviewRequest {
    id: string;
    height?: number;
    width?: number;
    publishState?: string;
}

export interface UserContentsDraftPublishApiUserColorCollectionPublishRequest {
    id: string;
}

export interface UserContentsDraftPublishApiUserColorCollectionUpdateDraftRequest {
    id: string;
    melcoModelsUpdateColorCollection?: MelcoModelsUpdateColorCollection;
}

export interface UserContentsDraftPublishApiUserColorCollectionsAddDraftRequest {
    melcoModelsAddColorCollection?: MelcoModelsAddColorCollection;
}

export interface UserContentsDraftPublishApiUserColorCollectionsDeleteRequest {
    id: string;
    force?: boolean;
}

export interface UserContentsDraftPublishApiUserColorCollectionsDownloadRequest {
    id: string;
    publishState?: string;
    productCode?: string;
}

export interface UserContentsDraftPublishApiUserColorCollectionsDuplicateDraftRequest {
    id: string;
}

export interface UserContentsDraftPublishApiUserColorCollectionsGetDefRequest {
    id: string;
    publishState?: string;
}

export interface UserContentsDraftPublishApiUserColorCollectionsGetListRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    searchText?: string;
    service?: string;
    publishState?: string;
}

export interface UserContentsDraftPublishApiUserColorCollectionsRevertRequest {
    id: string;
}

export interface UserContentsDraftPublishApiUserDesignCollectionPublishRequest {
    id: string;
}

export interface UserContentsDraftPublishApiUserDesignCollectionUpdateDraftRequest {
    id: string;
    melcoModelsUpdateDesignCollection?: MelcoModelsUpdateDesignCollection;
}

export interface UserContentsDraftPublishApiUserDesignCollectionsCreateDraftRequest {
    melcoModelsCreateDesignCollection?: MelcoModelsCreateDesignCollection;
}

export interface UserContentsDraftPublishApiUserDesignCollectionsDeleteRequest {
    id: string;
    force?: boolean;
}

export interface UserContentsDraftPublishApiUserDesignCollectionsDuplicateDraftRequest {
    id: string;
}

export interface UserContentsDraftPublishApiUserDesignCollectionsGetDefRequest {
    id: string;
    publishState?: string;
}

export interface UserContentsDraftPublishApiUserDesignCollectionsGetListRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    searchText?: string;
    publishState?: string;
}

export interface UserContentsDraftPublishApiUserDesignCollectionsRevertRequest {
    id: string;
}

export interface UserContentsDraftPublishApiUserDesignsDeleteRequest {
    id: string;
    force?: boolean;
}

export interface UserContentsDraftPublishApiUserDesignsDownloadRequest {
    id: string;
    publishState?: string;
}

export interface UserContentsDraftPublishApiUserDesignsDuplicateDraftRequest {
    id: string;
}

export interface UserContentsDraftPublishApiUserDesignsGetDefRequest {
    id: string;
    publishState?: string;
}

export interface UserContentsDraftPublishApiUserDesignsPreviewRequest {
    id: string;
    width?: number;
    publishState?: string;
}

export interface UserContentsDraftPublishApiUserDesignsPrintQueueUpdateFileDraftRequest {
    id: string;
    file?: Blob;
}

export interface UserContentsDraftPublishApiUserDesignsPrintQueueUploadDraftRequest {
    file?: Blob;
}

export interface UserContentsDraftPublishApiUserDesignsPublishRequest {
    id: string;
}

export interface UserContentsDraftPublishApiUserDesignsReferencesRequest {
    id: string;
}

export interface UserContentsDraftPublishApiUserDesignsRevertRequest {
    id: string;
}

export interface UserContentsDraftPublishApiUserDesignsSearchRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    searchText?: string;
    publishState?: string;
    service?: string;
}

export interface UserContentsDraftPublishApiUserDesignsUpdateDraftRequest {
    id: string;
    melcoModelsUpdateDesign?: MelcoModelsUpdateDesign;
}

export interface UserContentsDraftPublishApiUserDesignsUpdateDraftFileRequest {
    id: string;
    file?: Blob;
}

export interface UserContentsDraftPublishApiUserDesignsUploadDraftRequest {
    file?: Blob;
}

export interface UserContentsDraftPublishApiUserImageDeleteRequest {
    id: string;
    force?: boolean;
}

export interface UserContentsDraftPublishApiUserImageDownloadRequest {
    id: string;
    publishState?: string;
}

export interface UserContentsDraftPublishApiUserImageDuplicateDraftRequest {
    id: string;
}

export interface UserContentsDraftPublishApiUserImageGetDefRequest {
    id: string;
    publishState?: string;
}

export interface UserContentsDraftPublishApiUserImagePreviewRequest {
    id: string;
    width?: number;
    publishState?: string;
}

export interface UserContentsDraftPublishApiUserImagePublishRequest {
    id: string;
}

export interface UserContentsDraftPublishApiUserImageReferencesRequest {
    id: string;
}

export interface UserContentsDraftPublishApiUserImageRevertRequest {
    id: string;
}

export interface UserContentsDraftPublishApiUserImageSearchRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    searchText?: string;
    publishState?: string;
}

export interface UserContentsDraftPublishApiUserImageUpdateDraftRequest {
    id: string;
    name?: string;
    viewName?: string;
    colorName?: string;
    colorHex?: string;
    file?: Blob;
}

export interface UserContentsDraftPublishApiUserImageUploadDraftRequest {
    name?: string;
    viewName?: string;
    colorName?: string;
    colorHex?: string;
    file?: Blob;
}

/**
 * 
 */
export class UserContentsDraftPublishApi extends runtime.BaseAPI {

    /**
     * get alphabet collection references
     * get alphabet collection references
     */
    async alphabetCollectionsReferencesRaw(requestParameters: UserContentsDraftPublishApiAlphabetCollectionsReferencesRequest): Promise<runtime.ApiResponse<MelcoModelsAlphabetCollectionReferences>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling alphabetCollectionsReferences.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/alphabets/collections/{Id}/references`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsAlphabetCollectionReferencesFromJSON(jsonValue));
    }

    /**
     * get alphabet collection references
     * get alphabet collection references
     */
    async alphabetCollectionsReferences(requestParameters: UserContentsDraftPublishApiAlphabetCollectionsReferencesRequest): Promise<MelcoModelsAlphabetCollectionReferences> {
        const response = await this.alphabetCollectionsReferencesRaw(requestParameters);
        return await response.value();
    }

    /**
     * get color collection references
     * get color collection references
     */
    async colorCollectionsReferencesRaw(requestParameters: UserContentsDraftPublishApiColorCollectionsReferencesRequest): Promise<runtime.ApiResponse<MelcoModelsColorCollectionReferences>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling colorCollectionsReferences.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/colors/collections/{Id}/references`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsColorCollectionReferencesFromJSON(jsonValue));
    }

    /**
     * get color collection references
     * get color collection references
     */
    async colorCollectionsReferences(requestParameters: UserContentsDraftPublishApiColorCollectionsReferencesRequest): Promise<MelcoModelsColorCollectionReferences> {
        const response = await this.colorCollectionsReferencesRaw(requestParameters);
        return await response.value();
    }

    /**
     * get design collection references
     * get design collection references
     */
    async designCollectionsReferencesRaw(requestParameters: UserContentsDraftPublishApiDesignCollectionsReferencesRequest): Promise<runtime.ApiResponse<MelcoModelsDesignReferences>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling designCollectionsReferences.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/designs/collections/{Id}/references`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsDesignReferencesFromJSON(jsonValue));
    }

    /**
     * get design collection references
     * get design collection references
     */
    async designCollectionsReferences(requestParameters: UserContentsDraftPublishApiDesignCollectionsReferencesRequest): Promise<MelcoModelsDesignReferences> {
        const response = await this.designCollectionsReferencesRaw(requestParameters);
        return await response.value();
    }

    /**
     * publish alphabet collection
     * publish alphabet collection
     */
    async userAlphabetCollectionPublishRaw(requestParameters: UserContentsDraftPublishApiUserAlphabetCollectionPublishRequest): Promise<runtime.ApiResponse<MelcoModelsAlphabetCollection>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userAlphabetCollectionPublish.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/alphabets/collections/{Id}/publish`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsAlphabetCollectionFromJSON(jsonValue));
    }

    /**
     * publish alphabet collection
     * publish alphabet collection
     */
    async userAlphabetCollectionPublish(requestParameters: UserContentsDraftPublishApiUserAlphabetCollectionPublishRequest): Promise<MelcoModelsAlphabetCollection> {
        const response = await this.userAlphabetCollectionPublishRaw(requestParameters);
        return await response.value();
    }

    /**
     * updating existing alphabet collection draft
     */
    async userAlphabetCollectionUpdateDraftRaw(requestParameters: UserContentsDraftPublishApiUserAlphabetCollectionUpdateDraftRequest): Promise<runtime.ApiResponse<MelcoModelsAlphabetCollection>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userAlphabetCollectionUpdateDraft.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/alphabets/collections/{Id}/draft`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsUpdateAlphabetCollectionToJSON(requestParameters.melcoModelsUpdateAlphabetCollection),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsAlphabetCollectionFromJSON(jsonValue));
    }

    /**
     * updating existing alphabet collection draft
     */
    async userAlphabetCollectionUpdateDraft(requestParameters: UserContentsDraftPublishApiUserAlphabetCollectionUpdateDraftRequest): Promise<MelcoModelsAlphabetCollection> {
        const response = await this.userAlphabetCollectionUpdateDraftRaw(requestParameters);
        return await response.value();
    }

    /**
     * Add collection draft item
     */
    async userAlphabetCollectionsCreateDraftRaw(requestParameters: UserContentsDraftPublishApiUserAlphabetCollectionsCreateDraftRequest): Promise<runtime.ApiResponse<MelcoModelsAlphabetCollection>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/alphabets/collections/draft`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsAddAlphabetCollectionToJSON(requestParameters.melcoModelsAddAlphabetCollection),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsAlphabetCollectionFromJSON(jsonValue));
    }

    /**
     * Add collection draft item
     */
    async userAlphabetCollectionsCreateDraft(requestParameters: UserContentsDraftPublishApiUserAlphabetCollectionsCreateDraftRequest): Promise<MelcoModelsAlphabetCollection> {
        const response = await this.userAlphabetCollectionsCreateDraftRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete alphabet collection
     */
    async userAlphabetCollectionsDeleteRaw(requestParameters: UserContentsDraftPublishApiUserAlphabetCollectionsDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userAlphabetCollectionsDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.force !== undefined) {
            queryParameters['Force'] = requestParameters.force;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/alphabets/collections/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete alphabet collection
     */
    async userAlphabetCollectionsDelete(requestParameters: UserContentsDraftPublishApiUserAlphabetCollectionsDeleteRequest): Promise<void> {
        await this.userAlphabetCollectionsDeleteRaw(requestParameters);
    }

    /**
     * duplicate alphabet collection
     * duplicate alphabet collection draft
     */
    async userAlphabetCollectionsDuplicateDraftRaw(requestParameters: UserContentsDraftPublishApiUserAlphabetCollectionsDuplicateDraftRequest): Promise<runtime.ApiResponse<MelcoModelsAlphabetCollection>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userAlphabetCollectionsDuplicateDraft.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/alphabets/collections/{Id}/duplicate/draft`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsAlphabetCollectionFromJSON(jsonValue));
    }

    /**
     * duplicate alphabet collection
     * duplicate alphabet collection draft
     */
    async userAlphabetCollectionsDuplicateDraft(requestParameters: UserContentsDraftPublishApiUserAlphabetCollectionsDuplicateDraftRequest): Promise<MelcoModelsAlphabetCollection> {
        const response = await this.userAlphabetCollectionsDuplicateDraftRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get alphabet collection by Id
     */
    async userAlphabetCollectionsGetDefRaw(requestParameters: UserContentsDraftPublishApiUserAlphabetCollectionsGetDefRequest): Promise<runtime.ApiResponse<MelcoModelsAlphabetCollection>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userAlphabetCollectionsGetDef.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/alphabets/collections/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsAlphabetCollectionFromJSON(jsonValue));
    }

    /**
     * Get alphabet collection by Id
     */
    async userAlphabetCollectionsGetDef(requestParameters: UserContentsDraftPublishApiUserAlphabetCollectionsGetDefRequest): Promise<MelcoModelsAlphabetCollection> {
        const response = await this.userAlphabetCollectionsGetDefRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get alphabet collection list
     */
    async userAlphabetCollectionsGetListRaw(requestParameters: UserContentsDraftPublishApiUserAlphabetCollectionsGetListRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsAlphabetCollection>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/alphabets/collections`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsAlphabetCollectionFromJSON(jsonValue));
    }

    /**
     * Get alphabet collection list
     */
    async userAlphabetCollectionsGetList(requestParameters: UserContentsDraftPublishApiUserAlphabetCollectionsGetListRequest): Promise<MelcoModelsGenericList1MelcoModelsAlphabetCollection> {
        const response = await this.userAlphabetCollectionsGetListRaw(requestParameters);
        return await response.value();
    }

    /**
     * revert unpublished changes
     */
    async userAlphabetCollectionsRevertRaw(requestParameters: UserContentsDraftPublishApiUserAlphabetCollectionsRevertRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userAlphabetCollectionsRevert.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/alphabets/collections/{Id}/revert`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * revert unpublished changes
     */
    async userAlphabetCollectionsRevert(requestParameters: UserContentsDraftPublishApiUserAlphabetCollectionsRevertRequest): Promise<void> {
        await this.userAlphabetCollectionsRevertRaw(requestParameters);
    }

    /**
     * delete alphabet
     */
    async userAlphabetDeleteRaw(requestParameters: UserContentsDraftPublishApiUserAlphabetDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userAlphabetDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.force !== undefined) {
            queryParameters['Force'] = requestParameters.force;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/alphabets/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete alphabet
     */
    async userAlphabetDelete(requestParameters: UserContentsDraftPublishApiUserAlphabetDeleteRequest): Promise<void> {
        await this.userAlphabetDeleteRaw(requestParameters);
    }

    /**
     * download alphabet
     * download alphabet
     */
    async userAlphabetDownloadRaw(requestParameters: UserContentsDraftPublishApiUserAlphabetDownloadRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userAlphabetDownload.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/alphabets/{Id}/download`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * download alphabet
     * download alphabet
     */
    async userAlphabetDownload(requestParameters: UserContentsDraftPublishApiUserAlphabetDownloadRequest): Promise<Blob> {
        const response = await this.userAlphabetDownloadRaw(requestParameters);
        return await response.value();
    }

    /**
     * duplicate alphabet
     * duplicate alphabet
     */
    async userAlphabetDuplicateDraftRaw(requestParameters: UserContentsDraftPublishApiUserAlphabetDuplicateDraftRequest): Promise<runtime.ApiResponse<MelcoFusionModelsUserBlank>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userAlphabetDuplicateDraft.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/alphabets/{Id}/duplicate/draft`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoFusionModelsUserBlankFromJSON(jsonValue));
    }

    /**
     * duplicate alphabet
     * duplicate alphabet
     */
    async userAlphabetDuplicateDraft(requestParameters: UserContentsDraftPublishApiUserAlphabetDuplicateDraftRequest): Promise<MelcoFusionModelsUserBlank> {
        const response = await this.userAlphabetDuplicateDraftRaw(requestParameters);
        return await response.value();
    }

    /**
     * get user alphabet
     * get user alphabet
     */
    async userAlphabetGetRaw(requestParameters: UserContentsDraftPublishApiUserAlphabetGetRequest): Promise<runtime.ApiResponse<MelcoModelsUserAlphabet>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userAlphabetGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/alphabets/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsUserAlphabetFromJSON(jsonValue));
    }

    /**
     * get user alphabet
     * get user alphabet
     */
    async userAlphabetGet(requestParameters: UserContentsDraftPublishApiUserAlphabetGetRequest): Promise<MelcoModelsUserAlphabet> {
        const response = await this.userAlphabetGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * get alphabet preview
     * get alphabet preview
     */
    async userAlphabetPreviewRaw(requestParameters: UserContentsDraftPublishApiUserAlphabetPreviewRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userAlphabetPreview.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.width !== undefined) {
            queryParameters['width'] = requestParameters.width;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/alphabets/{Id}/preview`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * get alphabet preview
     * get alphabet preview
     */
    async userAlphabetPreview(requestParameters: UserContentsDraftPublishApiUserAlphabetPreviewRequest): Promise<void> {
        await this.userAlphabetPreviewRaw(requestParameters);
    }

    /**
     * publish alphabet
     * publish alphabet
     */
    async userAlphabetPublishRaw(requestParameters: UserContentsDraftPublishApiUserAlphabetPublishRequest): Promise<runtime.ApiResponse<MelcoModelsBoolResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userAlphabetPublish.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/alphabets/{Id}/publish`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     * publish alphabet
     * publish alphabet
     */
    async userAlphabetPublish(requestParameters: UserContentsDraftPublishApiUserAlphabetPublishRequest): Promise<MelcoModelsBoolResponse> {
        const response = await this.userAlphabetPublishRaw(requestParameters);
        return await response.value();
    }

    /**
     * get alphabet references or depedencies
     * get alphabet references
     */
    async userAlphabetReferencesRaw(requestParameters: UserContentsDraftPublishApiUserAlphabetReferencesRequest): Promise<runtime.ApiResponse<MelcoModelsAlphabetReferences>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userAlphabetReferences.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/alphabets/{Id}/references`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsAlphabetReferencesFromJSON(jsonValue));
    }

    /**
     * get alphabet references or depedencies
     * get alphabet references
     */
    async userAlphabetReferences(requestParameters: UserContentsDraftPublishApiUserAlphabetReferencesRequest): Promise<MelcoModelsAlphabetReferences> {
        const response = await this.userAlphabetReferencesRaw(requestParameters);
        return await response.value();
    }

    /**
     * revert unpublished changes
     */
    async userAlphabetRevertRaw(requestParameters: UserContentsDraftPublishApiUserAlphabetRevertRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userAlphabetRevert.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/alphabets/{Id}/revert`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * revert unpublished changes
     */
    async userAlphabetRevert(requestParameters: UserContentsDraftPublishApiUserAlphabetRevertRequest): Promise<void> {
        await this.userAlphabetRevertRaw(requestParameters);
    }

    /**
     * search user alphabets
     * search alphabets
     */
    async userAlphabetSearchRaw(requestParameters: UserContentsDraftPublishApiUserAlphabetSearchRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsUserAlphabet>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.service !== undefined) {
            queryParameters['service'] = requestParameters.service;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/alphabets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsUserAlphabetFromJSON(jsonValue));
    }

    /**
     * search user alphabets
     * search alphabets
     */
    async userAlphabetSearch(requestParameters: UserContentsDraftPublishApiUserAlphabetSearchRequest): Promise<MelcoModelsGenericList1MelcoModelsUserAlphabet> {
        const response = await this.userAlphabetSearchRaw(requestParameters);
        return await response.value();
    }

    /**
     * update existing alphabet
     * update existing alphabet
     */
    async userAlphabetUpdateDraftRaw(requestParameters: UserContentsDraftPublishApiUserAlphabetUpdateDraftRequest): Promise<runtime.ApiResponse<MelcoModelsUserAlphabet>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userAlphabetUpdateDraft.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.name !== undefined) {
            formParams.append('Name', requestParameters.name as any);
        }

        if (requestParameters.recommendedText !== undefined) {
            formParams.append('RecommendedText', requestParameters.recommendedText as any);
        }

        if (requestParameters.file !== undefined) {
            formParams.append('File', requestParameters.file as any);
        }

        if (requestParameters.binaryFileName !== undefined) {
            formParams.append('BinaryFile.Name', requestParameters.binaryFileName as any);
        }

        if (requestParameters.binaryFileFileData !== undefined) {
            formParams.append('BinaryFile.FileData', requestParameters.binaryFileFileData as any);
        }

        if (requestParameters.binaryFileSignature !== undefined) {
            formParams.append('BinaryFile.Signature', requestParameters.binaryFileSignature as any);
        }

        const response = await this.request({
            path: `/alphabets/{Id}/draft`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsUserAlphabetFromJSON(jsonValue));
    }

    /**
     * update existing alphabet
     * update existing alphabet
     */
    async userAlphabetUpdateDraft(requestParameters: UserContentsDraftPublishApiUserAlphabetUpdateDraftRequest): Promise<MelcoModelsUserAlphabet> {
        const response = await this.userAlphabetUpdateDraftRaw(requestParameters);
        return await response.value();
    }

    /**
     * upload alphabet
     * upload alphabet file
     */
    async userAlphabetsUploadDraftRaw(requestParameters: UserContentsDraftPublishApiUserAlphabetsUploadDraftRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsUserAlphabet>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.service !== undefined) {
            queryParameters['service'] = requestParameters.service;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/alphabets/upload/draft`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsUserAlphabetFromJSON(jsonValue));
    }

    /**
     * upload alphabet
     * upload alphabet file
     */
    async userAlphabetsUploadDraft(requestParameters: UserContentsDraftPublishApiUserAlphabetsUploadDraftRequest): Promise<MelcoModelsGenericList1MelcoModelsUserAlphabet> {
        const response = await this.userAlphabetsUploadDraftRaw(requestParameters);
        return await response.value();
    }

    /**
     * creates blank
     * create blank
     */
    async userBlankCreateDraftRaw(requestParameters: UserContentsDraftPublishApiUserBlankCreateDraftRequest): Promise<runtime.ApiResponse<MelcoFusionModelsUserBlank>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/blanks/draft`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoFusionModelsCreateBlankToJSON(requestParameters.melcoFusionModelsCreateBlank),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoFusionModelsUserBlankFromJSON(jsonValue));
    }

    /**
     * creates blank
     * create blank
     */
    async userBlankCreateDraft(requestParameters: UserContentsDraftPublishApiUserBlankCreateDraftRequest): Promise<MelcoFusionModelsUserBlank> {
        const response = await this.userBlankCreateDraftRaw(requestParameters);
        return await response.value();
    }

    /**
     * delete blank
     */
    async userBlankDeleteRaw(requestParameters: UserContentsDraftPublishApiUserBlankDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userBlankDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.force !== undefined) {
            queryParameters['Force'] = requestParameters.force;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/blanks/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete blank
     */
    async userBlankDelete(requestParameters: UserContentsDraftPublishApiUserBlankDeleteRequest): Promise<void> {
        await this.userBlankDeleteRaw(requestParameters);
    }

    /**
     * duplicate blank
     * duplicate blank
     */
    async userBlankDuplicateDraftRaw(requestParameters: UserContentsDraftPublishApiUserBlankDuplicateDraftRequest): Promise<runtime.ApiResponse<MelcoFusionModelsUserBlank>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userBlankDuplicateDraft.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/blanks/{Id}/duplicate/draft`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoFusionModelsUserBlankFromJSON(jsonValue));
    }

    /**
     * duplicate blank
     * duplicate blank
     */
    async userBlankDuplicateDraft(requestParameters: UserContentsDraftPublishApiUserBlankDuplicateDraftRequest): Promise<MelcoFusionModelsUserBlank> {
        const response = await this.userBlankDuplicateDraftRaw(requestParameters);
        return await response.value();
    }

    /**
     * get blank
     * get user blank
     */
    async userBlankGetDefRaw(requestParameters: UserContentsDraftPublishApiUserBlankGetDefRequest): Promise<runtime.ApiResponse<MelcoFusionModelsUserBlank>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userBlankGetDef.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/blanks/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoFusionModelsUserBlankFromJSON(jsonValue));
    }

    /**
     * get blank
     * get user blank
     */
    async userBlankGetDef(requestParameters: UserContentsDraftPublishApiUserBlankGetDefRequest): Promise<MelcoFusionModelsUserBlank> {
        const response = await this.userBlankGetDefRaw(requestParameters);
        return await response.value();
    }

    /**
     * publish blank
     * publish blank
     */
    async userBlankPublishRaw(requestParameters: UserContentsDraftPublishApiUserBlankPublishRequest): Promise<runtime.ApiResponse<MelcoFusionModelsUserBlank>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userBlankPublish.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/blanks/{Id}/publish`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoFusionModelsUserBlankFromJSON(jsonValue));
    }

    /**
     * publish blank
     * publish blank
     */
    async userBlankPublish(requestParameters: UserContentsDraftPublishApiUserBlankPublishRequest): Promise<MelcoFusionModelsUserBlank> {
        const response = await this.userBlankPublishRaw(requestParameters);
        return await response.value();
    }

    /**
     * get blank references or depedencies
     * get blank references
     */
    async userBlankReferencesRaw(requestParameters: UserContentsDraftPublishApiUserBlankReferencesRequest): Promise<runtime.ApiResponse<MelcoFusionModelsBlankReferences>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userBlankReferences.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/blanks/{Id}/references`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoFusionModelsBlankReferencesFromJSON(jsonValue));
    }

    /**
     * get blank references or depedencies
     * get blank references
     */
    async userBlankReferences(requestParameters: UserContentsDraftPublishApiUserBlankReferencesRequest): Promise<MelcoFusionModelsBlankReferences> {
        const response = await this.userBlankReferencesRaw(requestParameters);
        return await response.value();
    }

    /**
     * revert unpublished changes
     */
    async userBlankRevertRaw(requestParameters: UserContentsDraftPublishApiUserBlankRevertRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userBlankRevert.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/blanks/{Id}/revert`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * revert unpublished changes
     */
    async userBlankRevert(requestParameters: UserContentsDraftPublishApiUserBlankRevertRequest): Promise<void> {
        await this.userBlankRevertRaw(requestParameters);
    }

    /**
     * search user blanks
     */
    async userBlankSearchRaw(requestParameters: UserContentsDraftPublishApiUserBlankSearchRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoFusionModelsUserBlankListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/blanks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoFusionModelsUserBlankListItemFromJSON(jsonValue));
    }

    /**
     * search user blanks
     */
    async userBlankSearch(requestParameters: UserContentsDraftPublishApiUserBlankSearchRequest): Promise<MelcoModelsGenericList1MelcoFusionModelsUserBlankListItem> {
        const response = await this.userBlankSearchRaw(requestParameters);
        return await response.value();
    }

    /**
     * update existing blank
     * update existing blank
     */
    async userBlankUpdateDraftRaw(requestParameters: UserContentsDraftPublishApiUserBlankUpdateDraftRequest): Promise<runtime.ApiResponse<MelcoFusionModelsUserBlank>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userBlankUpdateDraft.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/blanks/{Id}/draft`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoFusionModelsUpdateBlankToJSON(requestParameters.melcoFusionModelsUpdateBlank),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoFusionModelsUserBlankFromJSON(jsonValue));
    }

    /**
     * update existing blank
     * update existing blank
     */
    async userBlankUpdateDraft(requestParameters: UserContentsDraftPublishApiUserBlankUpdateDraftRequest): Promise<MelcoFusionModelsUserBlank> {
        const response = await this.userBlankUpdateDraftRaw(requestParameters);
        return await response.value();
    }

    /**
     * get image
     * get blank canvas preview image
     */
    async userBlanksPreviewRaw(requestParameters: UserContentsDraftPublishApiUserBlanksPreviewRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userBlanksPreview.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.height !== undefined) {
            queryParameters['height'] = requestParameters.height;
        }

        if (requestParameters.width !== undefined) {
            queryParameters['width'] = requestParameters.width;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/blanks/{Id}/canvas/preview`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * get image
     * get blank canvas preview image
     */
    async userBlanksPreview(requestParameters: UserContentsDraftPublishApiUserBlanksPreviewRequest): Promise<void> {
        await this.userBlanksPreviewRaw(requestParameters);
    }

    /**
     * publish color collection
     * publish color collection
     */
    async userColorCollectionPublishRaw(requestParameters: UserContentsDraftPublishApiUserColorCollectionPublishRequest): Promise<runtime.ApiResponse<MelcoModelsColorCollection>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userColorCollectionPublish.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/colors/collections/{Id}/publish`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsColorCollectionFromJSON(jsonValue));
    }

    /**
     * publish color collection
     * publish color collection
     */
    async userColorCollectionPublish(requestParameters: UserContentsDraftPublishApiUserColorCollectionPublishRequest): Promise<MelcoModelsColorCollection> {
        const response = await this.userColorCollectionPublishRaw(requestParameters);
        return await response.value();
    }

    /**
     * updating existing color collection draft
     */
    async userColorCollectionUpdateDraftRaw(requestParameters: UserContentsDraftPublishApiUserColorCollectionUpdateDraftRequest): Promise<runtime.ApiResponse<MelcoModelsColorCollection>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userColorCollectionUpdateDraft.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/colors/collections/{Id}/draft`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsUpdateColorCollectionToJSON(requestParameters.melcoModelsUpdateColorCollection),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsColorCollectionFromJSON(jsonValue));
    }

    /**
     * updating existing color collection draft
     */
    async userColorCollectionUpdateDraft(requestParameters: UserContentsDraftPublishApiUserColorCollectionUpdateDraftRequest): Promise<MelcoModelsColorCollection> {
        const response = await this.userColorCollectionUpdateDraftRaw(requestParameters);
        return await response.value();
    }

    /**
     * Add collection draft item
     */
    async userColorCollectionsAddDraftRaw(requestParameters: UserContentsDraftPublishApiUserColorCollectionsAddDraftRequest): Promise<runtime.ApiResponse<MelcoModelsColorCollection>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/colors/collections/draft`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsAddColorCollectionToJSON(requestParameters.melcoModelsAddColorCollection),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsColorCollectionFromJSON(jsonValue));
    }

    /**
     * Add collection draft item
     */
    async userColorCollectionsAddDraft(requestParameters: UserContentsDraftPublishApiUserColorCollectionsAddDraftRequest): Promise<MelcoModelsColorCollection> {
        const response = await this.userColorCollectionsAddDraftRaw(requestParameters);
        return await response.value();
    }

    /**
     * delete color collection
     */
    async userColorCollectionsDeleteRaw(requestParameters: UserContentsDraftPublishApiUserColorCollectionsDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userColorCollectionsDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.force !== undefined) {
            queryParameters['Force'] = requestParameters.force;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/colors/collections/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete color collection
     */
    async userColorCollectionsDelete(requestParameters: UserContentsDraftPublishApiUserColorCollectionsDeleteRequest): Promise<void> {
        await this.userColorCollectionsDeleteRaw(requestParameters);
    }

    /**
     * download color collection
     * download color collection
     */
    async userColorCollectionsDownloadRaw(requestParameters: UserContentsDraftPublishApiUserColorCollectionsDownloadRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userColorCollectionsDownload.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        if (requestParameters.productCode !== undefined) {
            queryParameters['product_code'] = requestParameters.productCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/colors/collections/{Id}/download`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * download color collection
     * download color collection
     */
    async userColorCollectionsDownload(requestParameters: UserContentsDraftPublishApiUserColorCollectionsDownloadRequest): Promise<Blob> {
        const response = await this.userColorCollectionsDownloadRaw(requestParameters);
        return await response.value();
    }

    /**
     * duplicate color collection
     * duplicate color collection draft
     */
    async userColorCollectionsDuplicateDraftRaw(requestParameters: UserContentsDraftPublishApiUserColorCollectionsDuplicateDraftRequest): Promise<runtime.ApiResponse<MelcoModelsColorCollection>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userColorCollectionsDuplicateDraft.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/colors/collections/{Id}/duplicate/draft`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsColorCollectionFromJSON(jsonValue));
    }

    /**
     * duplicate color collection
     * duplicate color collection draft
     */
    async userColorCollectionsDuplicateDraft(requestParameters: UserContentsDraftPublishApiUserColorCollectionsDuplicateDraftRequest): Promise<MelcoModelsColorCollection> {
        const response = await this.userColorCollectionsDuplicateDraftRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get color collection by Id
     */
    async userColorCollectionsGetDefRaw(requestParameters: UserContentsDraftPublishApiUserColorCollectionsGetDefRequest): Promise<runtime.ApiResponse<MelcoModelsColorCollection>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userColorCollectionsGetDef.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/colors/collections/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsColorCollectionFromJSON(jsonValue));
    }

    /**
     * Get color collection by Id
     */
    async userColorCollectionsGetDef(requestParameters: UserContentsDraftPublishApiUserColorCollectionsGetDefRequest): Promise<MelcoModelsColorCollection> {
        const response = await this.userColorCollectionsGetDefRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get color collection list
     */
    async userColorCollectionsGetListRaw(requestParameters: UserContentsDraftPublishApiUserColorCollectionsGetListRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsColorCollection>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.service !== undefined) {
            queryParameters['service'] = requestParameters.service;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/colors/collections`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsColorCollectionFromJSON(jsonValue));
    }

    /**
     * Get color collection list
     */
    async userColorCollectionsGetList(requestParameters: UserContentsDraftPublishApiUserColorCollectionsGetListRequest): Promise<MelcoModelsGenericList1MelcoModelsColorCollection> {
        const response = await this.userColorCollectionsGetListRaw(requestParameters);
        return await response.value();
    }

    /**
     * revert unpublished changes
     */
    async userColorCollectionsRevertRaw(requestParameters: UserContentsDraftPublishApiUserColorCollectionsRevertRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userColorCollectionsRevert.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/colors/collections/{Id}/revert`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * revert unpublished changes
     */
    async userColorCollectionsRevert(requestParameters: UserContentsDraftPublishApiUserColorCollectionsRevertRequest): Promise<void> {
        await this.userColorCollectionsRevertRaw(requestParameters);
    }

    /**
     * publish design collection
     * publish design collection
     */
    async userDesignCollectionPublishRaw(requestParameters: UserContentsDraftPublishApiUserDesignCollectionPublishRequest): Promise<runtime.ApiResponse<MelcoModelsDesignCollection>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userDesignCollectionPublish.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/designs/collections/{Id}/publish`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsDesignCollectionFromJSON(jsonValue));
    }

    /**
     * publish design collection
     * publish design collection
     */
    async userDesignCollectionPublish(requestParameters: UserContentsDraftPublishApiUserDesignCollectionPublishRequest): Promise<MelcoModelsDesignCollection> {
        const response = await this.userDesignCollectionPublishRaw(requestParameters);
        return await response.value();
    }

    /**
     * updating existing design collection draft
     */
    async userDesignCollectionUpdateDraftRaw(requestParameters: UserContentsDraftPublishApiUserDesignCollectionUpdateDraftRequest): Promise<runtime.ApiResponse<MelcoModelsDesignCollection>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userDesignCollectionUpdateDraft.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/designs/collections/{Id}/draft`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsUpdateDesignCollectionToJSON(requestParameters.melcoModelsUpdateDesignCollection),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsDesignCollectionFromJSON(jsonValue));
    }

    /**
     * updating existing design collection draft
     */
    async userDesignCollectionUpdateDraft(requestParameters: UserContentsDraftPublishApiUserDesignCollectionUpdateDraftRequest): Promise<MelcoModelsDesignCollection> {
        const response = await this.userDesignCollectionUpdateDraftRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create design collection draft item
     */
    async userDesignCollectionsCreateDraftRaw(requestParameters: UserContentsDraftPublishApiUserDesignCollectionsCreateDraftRequest): Promise<runtime.ApiResponse<MelcoModelsDesignCollection>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/designs/collections/draft`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsCreateDesignCollectionToJSON(requestParameters.melcoModelsCreateDesignCollection),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsDesignCollectionFromJSON(jsonValue));
    }

    /**
     * Create design collection draft item
     */
    async userDesignCollectionsCreateDraft(requestParameters: UserContentsDraftPublishApiUserDesignCollectionsCreateDraftRequest): Promise<MelcoModelsDesignCollection> {
        const response = await this.userDesignCollectionsCreateDraftRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete design collection
     */
    async userDesignCollectionsDeleteRaw(requestParameters: UserContentsDraftPublishApiUserDesignCollectionsDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userDesignCollectionsDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.force !== undefined) {
            queryParameters['Force'] = requestParameters.force;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/designs/collections/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete design collection
     */
    async userDesignCollectionsDelete(requestParameters: UserContentsDraftPublishApiUserDesignCollectionsDeleteRequest): Promise<void> {
        await this.userDesignCollectionsDeleteRaw(requestParameters);
    }

    /**
     * duplicate design collection
     * duplicate design collection draft
     */
    async userDesignCollectionsDuplicateDraftRaw(requestParameters: UserContentsDraftPublishApiUserDesignCollectionsDuplicateDraftRequest): Promise<runtime.ApiResponse<MelcoModelsDesignCollection>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userDesignCollectionsDuplicateDraft.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/designs/collections/{Id}/duplicate/draft`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsDesignCollectionFromJSON(jsonValue));
    }

    /**
     * duplicate design collection
     * duplicate design collection draft
     */
    async userDesignCollectionsDuplicateDraft(requestParameters: UserContentsDraftPublishApiUserDesignCollectionsDuplicateDraftRequest): Promise<MelcoModelsDesignCollection> {
        const response = await this.userDesignCollectionsDuplicateDraftRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get design collection by id
     */
    async userDesignCollectionsGetDefRaw(requestParameters: UserContentsDraftPublishApiUserDesignCollectionsGetDefRequest): Promise<runtime.ApiResponse<MelcoModelsDesignCollection>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userDesignCollectionsGetDef.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/designs/collections/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsDesignCollectionFromJSON(jsonValue));
    }

    /**
     * Get design collection by id
     */
    async userDesignCollectionsGetDef(requestParameters: UserContentsDraftPublishApiUserDesignCollectionsGetDefRequest): Promise<MelcoModelsDesignCollection> {
        const response = await this.userDesignCollectionsGetDefRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get design collection list
     */
    async userDesignCollectionsGetListRaw(requestParameters: UserContentsDraftPublishApiUserDesignCollectionsGetListRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsDesignCollection>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/designs/collections`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsDesignCollectionFromJSON(jsonValue));
    }

    /**
     * Get design collection list
     */
    async userDesignCollectionsGetList(requestParameters: UserContentsDraftPublishApiUserDesignCollectionsGetListRequest): Promise<MelcoModelsGenericList1MelcoModelsDesignCollection> {
        const response = await this.userDesignCollectionsGetListRaw(requestParameters);
        return await response.value();
    }

    /**
     * revert unpublished changes on design collection
     */
    async userDesignCollectionsRevertRaw(requestParameters: UserContentsDraftPublishApiUserDesignCollectionsRevertRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userDesignCollectionsRevert.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/designs/collections/{Id}/revert`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * revert unpublished changes on design collection
     */
    async userDesignCollectionsRevert(requestParameters: UserContentsDraftPublishApiUserDesignCollectionsRevertRequest): Promise<void> {
        await this.userDesignCollectionsRevertRaw(requestParameters);
    }

    /**
     * delete design
     */
    async userDesignsDeleteRaw(requestParameters: UserContentsDraftPublishApiUserDesignsDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userDesignsDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.force !== undefined) {
            queryParameters['Force'] = requestParameters.force;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/designs/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete design
     */
    async userDesignsDelete(requestParameters: UserContentsDraftPublishApiUserDesignsDeleteRequest): Promise<void> {
        await this.userDesignsDeleteRaw(requestParameters);
    }

    /**
     * download design
     * download design
     */
    async userDesignsDownloadRaw(requestParameters: UserContentsDraftPublishApiUserDesignsDownloadRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userDesignsDownload.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/designs/{Id}/download`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * download design
     * download design
     */
    async userDesignsDownload(requestParameters: UserContentsDraftPublishApiUserDesignsDownloadRequest): Promise<Blob> {
        const response = await this.userDesignsDownloadRaw(requestParameters);
        return await response.value();
    }

    /**
     * duplicate design
     * duplicate design
     */
    async userDesignsDuplicateDraftRaw(requestParameters: UserContentsDraftPublishApiUserDesignsDuplicateDraftRequest): Promise<runtime.ApiResponse<MelcoModelsDesign>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userDesignsDuplicateDraft.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/designs/{Id}/duplicate/draft`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsDesignFromJSON(jsonValue));
    }

    /**
     * duplicate design
     * duplicate design
     */
    async userDesignsDuplicateDraft(requestParameters: UserContentsDraftPublishApiUserDesignsDuplicateDraftRequest): Promise<MelcoModelsDesign> {
        const response = await this.userDesignsDuplicateDraftRaw(requestParameters);
        return await response.value();
    }

    /**
     * get user design
     * get user design
     */
    async userDesignsGetDefRaw(requestParameters: UserContentsDraftPublishApiUserDesignsGetDefRequest): Promise<runtime.ApiResponse<MelcoModelsDesign>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userDesignsGetDef.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/designs/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsDesignFromJSON(jsonValue));
    }

    /**
     * get user design
     * get user design
     */
    async userDesignsGetDef(requestParameters: UserContentsDraftPublishApiUserDesignsGetDefRequest): Promise<MelcoModelsDesign> {
        const response = await this.userDesignsGetDefRaw(requestParameters);
        return await response.value();
    }

    /**
     * get design image
     * get design image
     */
    async userDesignsPreviewRaw(requestParameters: UserContentsDraftPublishApiUserDesignsPreviewRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userDesignsPreview.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.width !== undefined) {
            queryParameters['width'] = requestParameters.width;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/designs/{Id}/preview`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * get design image
     * get design image
     */
    async userDesignsPreview(requestParameters: UserContentsDraftPublishApiUserDesignsPreviewRequest): Promise<void> {
        await this.userDesignsPreviewRaw(requestParameters);
    }

    /**
     * update existing design file
     * update file for print queue design draft
     */
    async userDesignsPrintQueueUpdateFileDraftRaw(requestParameters: UserContentsDraftPublishApiUserDesignsPrintQueueUpdateFileDraftRequest): Promise<runtime.ApiResponse<MelcoModelsNotification>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userDesignsPrintQueueUpdateFileDraft.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/designs/printqueue/{Id}/update/draft`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsNotificationFromJSON(jsonValue));
    }

    /**
     * update existing design file
     * update file for print queue design draft
     */
    async userDesignsPrintQueueUpdateFileDraft(requestParameters: UserContentsDraftPublishApiUserDesignsPrintQueueUpdateFileDraftRequest): Promise<MelcoModelsNotification> {
        const response = await this.userDesignsPrintQueueUpdateFileDraftRaw(requestParameters);
        return await response.value();
    }

    /**
     * upload print design
     * upload print design to queue draft
     */
    async userDesignsPrintQueueUploadDraftRaw(requestParameters: UserContentsDraftPublishApiUserDesignsPrintQueueUploadDraftRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/designs/printqueue/upload/draft`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * upload print design
     * upload print design to queue draft
     */
    async userDesignsPrintQueueUploadDraft(requestParameters: UserContentsDraftPublishApiUserDesignsPrintQueueUploadDraftRequest): Promise<void> {
        await this.userDesignsPrintQueueUploadDraftRaw(requestParameters);
    }

    /**
     * publish design
     * publish design
     */
    async userDesignsPublishRaw(requestParameters: UserContentsDraftPublishApiUserDesignsPublishRequest): Promise<runtime.ApiResponse<MelcoModelsDesign>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userDesignsPublish.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/designs/{Id}/publish`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsDesignFromJSON(jsonValue));
    }

    /**
     * publish design
     * publish design
     */
    async userDesignsPublish(requestParameters: UserContentsDraftPublishApiUserDesignsPublishRequest): Promise<MelcoModelsDesign> {
        const response = await this.userDesignsPublishRaw(requestParameters);
        return await response.value();
    }

    /**
     * get design references or depedencies
     * get design references
     */
    async userDesignsReferencesRaw(requestParameters: UserContentsDraftPublishApiUserDesignsReferencesRequest): Promise<runtime.ApiResponse<MelcoModelsDesignReferences>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userDesignsReferences.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/designs/{Id}/references`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsDesignReferencesFromJSON(jsonValue));
    }

    /**
     * get design references or depedencies
     * get design references
     */
    async userDesignsReferences(requestParameters: UserContentsDraftPublishApiUserDesignsReferencesRequest): Promise<MelcoModelsDesignReferences> {
        const response = await this.userDesignsReferencesRaw(requestParameters);
        return await response.value();
    }

    /**
     * revert unpublished changes
     */
    async userDesignsRevertRaw(requestParameters: UserContentsDraftPublishApiUserDesignsRevertRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userDesignsRevert.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/designs/{Id}/revert`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * revert unpublished changes
     */
    async userDesignsRevert(requestParameters: UserContentsDraftPublishApiUserDesignsRevertRequest): Promise<void> {
        await this.userDesignsRevertRaw(requestParameters);
    }

    /**
     * search user designs
     */
    async userDesignsSearchRaw(requestParameters: UserContentsDraftPublishApiUserDesignsSearchRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsDesignListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        if (requestParameters.service !== undefined) {
            queryParameters['service'] = requestParameters.service;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/designs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsDesignListItemFromJSON(jsonValue));
    }

    /**
     * search user designs
     */
    async userDesignsSearch(requestParameters: UserContentsDraftPublishApiUserDesignsSearchRequest): Promise<MelcoModelsGenericList1MelcoModelsDesignListItem> {
        const response = await this.userDesignsSearchRaw(requestParameters);
        return await response.value();
    }

    /**
     * update existing design metadata
     * update existing design metadata
     */
    async userDesignsUpdateDraftRaw(requestParameters: UserContentsDraftPublishApiUserDesignsUpdateDraftRequest): Promise<runtime.ApiResponse<MelcoModelsDesign>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userDesignsUpdateDraft.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/designs/{Id}/draft`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsUpdateDesignToJSON(requestParameters.melcoModelsUpdateDesign),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsDesignFromJSON(jsonValue));
    }

    /**
     * update existing design metadata
     * update existing design metadata
     */
    async userDesignsUpdateDraft(requestParameters: UserContentsDraftPublishApiUserDesignsUpdateDraftRequest): Promise<MelcoModelsDesign> {
        const response = await this.userDesignsUpdateDraftRaw(requestParameters);
        return await response.value();
    }

    /**
     * update existing design file
     * update existing design file
     */
    async userDesignsUpdateDraftFileRaw(requestParameters: UserContentsDraftPublishApiUserDesignsUpdateDraftFileRequest): Promise<runtime.ApiResponse<MelcoModelsDesign>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userDesignsUpdateDraftFile.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/designs/{Id}/update/draft`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsDesignFromJSON(jsonValue));
    }

    /**
     * update existing design file
     * update existing design file
     */
    async userDesignsUpdateDraftFile(requestParameters: UserContentsDraftPublishApiUserDesignsUpdateDraftFileRequest): Promise<MelcoModelsDesign> {
        const response = await this.userDesignsUpdateDraftFileRaw(requestParameters);
        return await response.value();
    }

    /**
     * upload design
     * upload design file
     */
    async userDesignsUploadDraftRaw(requestParameters: UserContentsDraftPublishApiUserDesignsUploadDraftRequest): Promise<runtime.ApiResponse<MelcoModelsDesign>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/designs/upload/draft`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsDesignFromJSON(jsonValue));
    }

    /**
     * upload design
     * upload design file
     */
    async userDesignsUploadDraft(requestParameters: UserContentsDraftPublishApiUserDesignsUploadDraftRequest): Promise<MelcoModelsDesign> {
        const response = await this.userDesignsUploadDraftRaw(requestParameters);
        return await response.value();
    }

    /**
     * delete image
     */
    async userImageDeleteRaw(requestParameters: UserContentsDraftPublishApiUserImageDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userImageDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.force !== undefined) {
            queryParameters['Force'] = requestParameters.force;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/images/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete image
     */
    async userImageDelete(requestParameters: UserContentsDraftPublishApiUserImageDeleteRequest): Promise<void> {
        await this.userImageDeleteRaw(requestParameters);
    }

    /**
     * export/download image
     * download image
     */
    async userImageDownloadRaw(requestParameters: UserContentsDraftPublishApiUserImageDownloadRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userImageDownload.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/images/{Id}/download`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * export/download image
     * download image
     */
    async userImageDownload(requestParameters: UserContentsDraftPublishApiUserImageDownloadRequest): Promise<Blob> {
        const response = await this.userImageDownloadRaw(requestParameters);
        return await response.value();
    }

    /**
     * upload image
     * duplicate image
     */
    async userImageDuplicateDraftRaw(requestParameters: UserContentsDraftPublishApiUserImageDuplicateDraftRequest): Promise<runtime.ApiResponse<MelcoModelsUserImage>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userImageDuplicateDraft.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/images/{Id}/duplicate/draft`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsUserImageFromJSON(jsonValue));
    }

    /**
     * upload image
     * duplicate image
     */
    async userImageDuplicateDraft(requestParameters: UserContentsDraftPublishApiUserImageDuplicateDraftRequest): Promise<MelcoModelsUserImage> {
        const response = await this.userImageDuplicateDraftRaw(requestParameters);
        return await response.value();
    }

    /**
     * get user image
     * get user image
     */
    async userImageGetDefRaw(requestParameters: UserContentsDraftPublishApiUserImageGetDefRequest): Promise<runtime.ApiResponse<MelcoModelsUserImage>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userImageGetDef.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/images/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsUserImageFromJSON(jsonValue));
    }

    /**
     * get user image
     * get user image
     */
    async userImageGetDef(requestParameters: UserContentsDraftPublishApiUserImageGetDefRequest): Promise<MelcoModelsUserImage> {
        const response = await this.userImageGetDefRaw(requestParameters);
        return await response.value();
    }

    /**
     * get image
     * get image
     */
    async userImagePreviewRaw(requestParameters: UserContentsDraftPublishApiUserImagePreviewRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userImagePreview.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.width !== undefined) {
            queryParameters['width'] = requestParameters.width;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/images/{Id}/preview`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * get image
     * get image
     */
    async userImagePreview(requestParameters: UserContentsDraftPublishApiUserImagePreviewRequest): Promise<void> {
        await this.userImagePreviewRaw(requestParameters);
    }

    /**
     * publish image
     * publish image
     */
    async userImagePublishRaw(requestParameters: UserContentsDraftPublishApiUserImagePublishRequest): Promise<runtime.ApiResponse<MelcoModelsUserImage>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userImagePublish.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/images/{Id}/publish`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsUserImageFromJSON(jsonValue));
    }

    /**
     * publish image
     * publish image
     */
    async userImagePublish(requestParameters: UserContentsDraftPublishApiUserImagePublishRequest): Promise<MelcoModelsUserImage> {
        const response = await this.userImagePublishRaw(requestParameters);
        return await response.value();
    }

    /**
     * get image references or depedencies
     * get image references
     */
    async userImageReferencesRaw(requestParameters: UserContentsDraftPublishApiUserImageReferencesRequest): Promise<runtime.ApiResponse<MelcoModelsImageReferences>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userImageReferences.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/images/{Id}/references`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsImageReferencesFromJSON(jsonValue));
    }

    /**
     * get image references or depedencies
     * get image references
     */
    async userImageReferences(requestParameters: UserContentsDraftPublishApiUserImageReferencesRequest): Promise<MelcoModelsImageReferences> {
        const response = await this.userImageReferencesRaw(requestParameters);
        return await response.value();
    }

    /**
     * revert unpublished changes
     */
    async userImageRevertRaw(requestParameters: UserContentsDraftPublishApiUserImageRevertRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userImageRevert.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/images/{Id}/revert`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * revert unpublished changes
     */
    async userImageRevert(requestParameters: UserContentsDraftPublishApiUserImageRevertRequest): Promise<void> {
        await this.userImageRevertRaw(requestParameters);
    }

    /**
     * search user images
     */
    async userImageSearchRaw(requestParameters: UserContentsDraftPublishApiUserImageSearchRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsUserImage>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.publishState !== undefined) {
            queryParameters['publish_state'] = requestParameters.publishState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/images`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsUserImageFromJSON(jsonValue));
    }

    /**
     * search user images
     */
    async userImageSearch(requestParameters: UserContentsDraftPublishApiUserImageSearchRequest): Promise<MelcoModelsGenericList1MelcoModelsUserImage> {
        const response = await this.userImageSearchRaw(requestParameters);
        return await response.value();
    }

    /**
     * update existing image
     * update existing image
     */
    async userImageUpdateDraftRaw(requestParameters: UserContentsDraftPublishApiUserImageUpdateDraftRequest): Promise<runtime.ApiResponse<MelcoModelsUserImage>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userImageUpdateDraft.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.name !== undefined) {
            formParams.append('name', requestParameters.name as any);
        }

        if (requestParameters.viewName !== undefined) {
            formParams.append('view_name', requestParameters.viewName as any);
        }

        if (requestParameters.colorName !== undefined) {
            formParams.append('color_name', requestParameters.colorName as any);
        }

        if (requestParameters.colorHex !== undefined) {
            formParams.append('color_hex', requestParameters.colorHex as any);
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/images/{Id}/draft`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsUserImageFromJSON(jsonValue));
    }

    /**
     * update existing image
     * update existing image
     */
    async userImageUpdateDraft(requestParameters: UserContentsDraftPublishApiUserImageUpdateDraftRequest): Promise<MelcoModelsUserImage> {
        const response = await this.userImageUpdateDraftRaw(requestParameters);
        return await response.value();
    }

    /**
     * upload image
     * upload image (jpg,jpeg and png)
     */
    async userImageUploadDraftRaw(requestParameters: UserContentsDraftPublishApiUserImageUploadDraftRequest): Promise<runtime.ApiResponse<MelcoModelsUserImage>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.name !== undefined) {
            formParams.append('name', requestParameters.name as any);
        }

        if (requestParameters.viewName !== undefined) {
            formParams.append('view_name', requestParameters.viewName as any);
        }

        if (requestParameters.colorName !== undefined) {
            formParams.append('color_name', requestParameters.colorName as any);
        }

        if (requestParameters.colorHex !== undefined) {
            formParams.append('color_hex', requestParameters.colorHex as any);
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/images/upload/draft`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsUserImageFromJSON(jsonValue));
    }

    /**
     * upload image
     * upload image (jpg,jpeg and png)
     */
    async userImageUploadDraft(requestParameters: UserContentsDraftPublishApiUserImageUploadDraftRequest): Promise<MelcoModelsUserImage> {
        const response = await this.userImageUploadDraftRaw(requestParameters);
        return await response.value();
    }

}
