import { useField } from "formik";
import {
  BulkActionCount,
  DisplayFieldErrors,
  FusionMelcoModelsUserImage,
  ViewContext,
  useTableSelection,
} from "melco-shared-logic";
import isEmpty from "lodash-es/isEmpty";
import {
  Button,
  DesignTokens,
  EditIcon,
  FullWidthSpace,
  PlusIcon,
  Space,
  Table,
  Title,
} from "melco-ui";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { blankPictureColumns } from "./blankPictures/BlankPicturesList";
import { BulkActionRemoveBlankPictures } from "./BulkActionRemoveBlankPictures";

const { colors, spacings } = DesignTokens;

const EmptyBlankPicturesWrapper = styled.div`
  border: 1px dashed ${colors.gray.middle};
  padding: ${spacings.middle}px;
  min-height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TableWrapper = styled.div`
  margin-left: -${spacings.xlarge}px;
  margin-right: -${spacings.xlarge}px;
`;

const AddButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

type BlankBlankPicturesListProps = {
  setDrawerOpen: (open: boolean) => void;
};

export const BlankBlankPicturesList: React.FC<BlankBlankPicturesListProps> = ({
  setDrawerOpen,
}) => {
  const { t } = useTranslation();

  const { selectedRowKeys, setSelectedRowKeys, rowSelection } =
    useTableSelection<FusionMelcoModelsUserImage>(() => {});

  const [{ value: blankPictures }] =
    useField<FusionMelcoModelsUserImage[]>("images");

  const title = (
    <Title level={5}>
      {t("libraries.blanks.blanks.detail.form.label.images")}
    </Title>
  );

  const addButton = (
    <Button
      type="primary"
      icon={<PlusIcon />}
      onClick={() => setDrawerOpen(true)}
    >
      {t("blanks.actions.add_blank_pictures.button")}
    </Button>
  );

  if (isEmpty(blankPictures)) {
    return (
      <div>
        {title}
        <DisplayFieldErrors name="images" />
        <DisplayFieldErrors name="views" />

        <EmptyBlankPicturesWrapper>{addButton}</EmptyBlankPicturesWrapper>
      </div>
    );
  }

  const columns = [
    ...blankPictureColumns(t, ViewContext.Detail),
    {
      align: "right" as const,
      render: (_: string, item: FusionMelcoModelsUserImage) => (
        <Button
          icon={<EditIcon />}
          size="small"
          href={`/libraries/blank-pictures/${item.id!}`}
        >
          {t("blanks.actions.edit.button")}
        </Button>
      ),
    },
  ];

  return (
    <div>
      <TableHeader>
        {title}

        <Space size="middle">
          <BulkActionCount selectedCount={selectedRowKeys.length} />
          <BulkActionRemoveBlankPictures
            allBlankPictures={blankPictures}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
          />
        </Space>
      </TableHeader>

      <DisplayFieldErrors name="images" />
      <DisplayFieldErrors name="views" />

      <FullWidthSpace direction="vertical" size="large">
        <TableWrapper>
          <Table
            showSorterTooltip={false}
            dataSource={blankPictures}
            columns={columns}
            rowKey="id"
            pagination={false}
            rowSelection={rowSelection}
            data-cy="blank-pictures-table"
          />
        </TableWrapper>
        <AddButtonWrapper>{addButton}</AddButtonWrapper>
      </FullWidthSpace>
    </div>
  );
};
