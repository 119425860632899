/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoModelsProduct,
    MelcoModelsProductFromJSON,
    MelcoModelsProductFromJSONTyped,
    MelcoModelsProductToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoModelsAuthToken
 */
export interface MelcoModelsAuthToken {
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsAuthToken
     */
    user_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsAuthToken
     */
    token?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsAuthToken
     */
    user_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsAuthToken
     */
    warning?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsAuthToken
     */
    first_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsAuthToken
     */
    last_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsAuthToken
     */
    impersonation_for_id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsAuthToken
     */
    parent_user_id?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MelcoModelsAuthToken
     */
    force_password_change?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsAuthToken
     */
    account_name?: string | null;
    /**
     * 
     * @type {MelcoModelsProduct}
     * @memberof MelcoModelsAuthToken
     */
    product?: MelcoModelsProduct;
}

export function MelcoModelsAuthTokenFromJSON(json: any): MelcoModelsAuthToken {
    return MelcoModelsAuthTokenFromJSONTyped(json, false);
}

export function MelcoModelsAuthTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsAuthToken {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user_name': !exists(json, 'user_name') ? undefined : json['user_name'],
        'token': !exists(json, 'token') ? undefined : json['token'],
        'user_id': !exists(json, 'user_id') ? undefined : json['user_id'],
        'warning': !exists(json, 'warning') ? undefined : json['warning'],
        'first_name': !exists(json, 'first_name') ? undefined : json['first_name'],
        'last_name': !exists(json, 'last_name') ? undefined : json['last_name'],
        'impersonation_for_id': !exists(json, 'impersonation_for_id') ? undefined : json['impersonation_for_id'],
        'parent_user_id': !exists(json, 'parent_user_id') ? undefined : json['parent_user_id'],
        'force_password_change': !exists(json, 'force_password_change') ? undefined : json['force_password_change'],
        'account_name': !exists(json, 'account_name') ? undefined : json['account_name'],
        'product': !exists(json, 'product') ? undefined : MelcoModelsProductFromJSON(json['product']),
    };
}

export function MelcoModelsAuthTokenToJSON(value?: MelcoModelsAuthToken | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_name': value.user_name,
        'token': value.token,
        'user_id': value.user_id,
        'warning': value.warning,
        'first_name': value.first_name,
        'last_name': value.last_name,
        'impersonation_for_id': value.impersonation_for_id,
        'parent_user_id': value.parent_user_id,
        'force_password_change': value.force_password_change,
        'account_name': value.account_name,
        'product': MelcoModelsProductToJSON(value.product),
    };
}


