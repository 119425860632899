/* tslint:disable */
/* eslint-disable */
/**
 * Melco Reporting APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportingMelcoMachineStatisticsModelsCreateViewerLink
 */
export interface ReportingMelcoMachineStatisticsModelsCreateViewerLink {
    /**
     * 
     * @type {number}
     * @memberof ReportingMelcoMachineStatisticsModelsCreateViewerLink
     */
    days?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportingMelcoMachineStatisticsModelsCreateViewerLink
     */
    password?: string | null;
}

export function ReportingMelcoMachineStatisticsModelsCreateViewerLinkFromJSON(json: any): ReportingMelcoMachineStatisticsModelsCreateViewerLink {
    return ReportingMelcoMachineStatisticsModelsCreateViewerLinkFromJSONTyped(json, false);
}

export function ReportingMelcoMachineStatisticsModelsCreateViewerLinkFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportingMelcoMachineStatisticsModelsCreateViewerLink {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'days': !exists(json, 'days') ? undefined : json['days'],
        'password': !exists(json, 'password') ? undefined : json['password'],
    };
}

export function ReportingMelcoMachineStatisticsModelsCreateViewerLinkToJSON(value?: ReportingMelcoMachineStatisticsModelsCreateViewerLink | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'days': value.days,
        'password': value.password,
    };
}


