/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoModelsAppInfo,
    MelcoModelsAppInfoFromJSON,
    MelcoModelsAppInfoFromJSONTyped,
    MelcoModelsAppInfoToJSON,
    MelcoModelsDevice,
    MelcoModelsDeviceFromJSON,
    MelcoModelsDeviceFromJSONTyped,
    MelcoModelsDeviceToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoModelsMelcoAuth
 */
export interface MelcoModelsMelcoAuth {
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsMelcoAuth
     */
    user_name: string;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsMelcoAuth
     */
    password: string;
    /**
     * 
     * @type {MelcoModelsDevice}
     * @memberof MelcoModelsMelcoAuth
     */
    device_info: MelcoModelsDevice;
    /**
     * 
     * @type {MelcoModelsAppInfo}
     * @memberof MelcoModelsMelcoAuth
     */
    app_info?: MelcoModelsAppInfo;
}

export function MelcoModelsMelcoAuthFromJSON(json: any): MelcoModelsMelcoAuth {
    return MelcoModelsMelcoAuthFromJSONTyped(json, false);
}

export function MelcoModelsMelcoAuthFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsMelcoAuth {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user_name': json['user_name'],
        'password': json['password'],
        'device_info': MelcoModelsDeviceFromJSON(json['device_info']),
        'app_info': !exists(json, 'app_info') ? undefined : MelcoModelsAppInfoFromJSON(json['app_info']),
    };
}

export function MelcoModelsMelcoAuthToJSON(value?: MelcoModelsMelcoAuth | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_name': value.user_name,
        'password': value.password,
        'device_info': MelcoModelsDeviceToJSON(value.device_info),
        'app_info': MelcoModelsAppInfoToJSON(value.app_info),
    };
}


