import { FusionMelcoModelsDesign } from "../../../../.yalc/melco-shared-logic/dist";
import { useDesignSize } from "../../../hooks/libraries/designs/useDesignSize";

type DesignSizeProps = {
  design: FusionMelcoModelsDesign;
};

export const DesignSize: React.FC<DesignSizeProps> = ({ design }) => {
  const [isLoading, size] = useDesignSize(design);

  if (isLoading) {
    return null;
  }

  return <>{size}</>;
};
