/* tslint:disable */
/* eslint-disable */
/**
 * Melco Reporting APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ReportingMelcoModelsError,
    ReportingMelcoModelsErrorFromJSON,
    ReportingMelcoModelsErrorToJSON,
    ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsStatisticListItem,
    ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsStatisticListItemFromJSON,
    ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsStatisticListItemToJSON,
} from '../models';

export interface ReportsApiReportsDownloadRequest {
    startDate?: Date;
    endDate?: Date;
    groupList?: string;
    designList?: string;
    isGrouped?: boolean;
}

export interface ReportsApiReportsSearchRequest {
    startDate?: Date;
    endDate?: Date;
    groupList?: string;
    designList?: string;
    isGrouped?: boolean;
}

/**
 * 
 */
export class ReportsApi extends runtime.BaseAPI {

    /**
     */
    async reportsDownloadRaw(requestParameters: ReportsApiReportsDownloadRequest): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['start_date'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['end_date'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.groupList !== undefined) {
            queryParameters['group_list'] = requestParameters.groupList;
        }

        if (requestParameters.designList !== undefined) {
            queryParameters['design_list'] = requestParameters.designList;
        }

        if (requestParameters.isGrouped !== undefined) {
            queryParameters['is_grouped'] = requestParameters.isGrouped;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/machines/reporting/download`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async reportsDownload(requestParameters: ReportsApiReportsDownloadRequest): Promise<Blob> {
        const response = await this.reportsDownloadRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async reportsSearchRaw(requestParameters: ReportsApiReportsSearchRequest): Promise<runtime.ApiResponse<ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsStatisticListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['start_date'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['end_date'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.groupList !== undefined) {
            queryParameters['group_list'] = requestParameters.groupList;
        }

        if (requestParameters.designList !== undefined) {
            queryParameters['design_list'] = requestParameters.designList;
        }

        if (requestParameters.isGrouped !== undefined) {
            queryParameters['is_grouped'] = requestParameters.isGrouped;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/machines/reporting/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsStatisticListItemFromJSON(jsonValue));
    }

    /**
     */
    async reportsSearch(requestParameters: ReportsApiReportsSearchRequest): Promise<ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsStatisticListItem> {
        const response = await this.reportsSearchRaw(requestParameters);
        return await response.value();
    }

}
