/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoModelsAppInfo,
    MelcoModelsAppInfoFromJSON,
    MelcoModelsAppInfoFromJSONTyped,
    MelcoModelsAppInfoToJSON,
    MelcoModelsDeviceInfo,
    MelcoModelsDeviceInfoFromJSON,
    MelcoModelsDeviceInfoFromJSONTyped,
    MelcoModelsDeviceInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoModelsMachineCloudAuth
 */
export interface MelcoModelsMachineCloudAuth {
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsMachineCloudAuth
     */
    token?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsMachineCloudAuth
     */
    product_code?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsMachineCloudAuth
     */
    version_number?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsMachineCloudAuth
     */
    user_name: string;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsMachineCloudAuth
     */
    password: string;
    /**
     * 
     * @type {MelcoModelsDeviceInfo}
     * @memberof MelcoModelsMachineCloudAuth
     */
    device_info: MelcoModelsDeviceInfo;
    /**
     * 
     * @type {MelcoModelsAppInfo}
     * @memberof MelcoModelsMachineCloudAuth
     */
    app_info?: MelcoModelsAppInfo;
}

export function MelcoModelsMachineCloudAuthFromJSON(json: any): MelcoModelsMachineCloudAuth {
    return MelcoModelsMachineCloudAuthFromJSONTyped(json, false);
}

export function MelcoModelsMachineCloudAuthFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsMachineCloudAuth {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': !exists(json, 'token') ? undefined : json['token'],
        'product_code': !exists(json, 'product_code') ? undefined : json['product_code'],
        'version_number': !exists(json, 'version_number') ? undefined : json['version_number'],
        'user_name': json['user_name'],
        'password': json['password'],
        'device_info': MelcoModelsDeviceInfoFromJSON(json['device_info']),
        'app_info': !exists(json, 'app_info') ? undefined : MelcoModelsAppInfoFromJSON(json['app_info']),
    };
}

export function MelcoModelsMachineCloudAuthToJSON(value?: MelcoModelsMachineCloudAuth | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
        'product_code': value.product_code,
        'version_number': value.version_number,
        'user_name': value.user_name,
        'password': value.password,
        'device_info': MelcoModelsDeviceInfoToJSON(value.device_info),
        'app_info': MelcoModelsAppInfoToJSON(value.app_info),
    };
}


