import { Formik, FormikHelpers } from "formik";
import {
  FusionMelcoModelsColorCollection,
  FusionMelcoModelsColorListItem,
  UseApiQueryKey,
  useAuthenticatedAPIConfig,
  useFormSubmit,
  UserContentsDraftPublishApi,
  UserContentsDraftPublishApiUserColorCollectionUpdateDraftRequest,
} from "melco-shared-logic";
import { QueryObserverResult, useQueryClient } from "react-query";
import { SubmitAction } from "../../header/SavePublishHeader";
import { DisplayColorCollectionDetailForm } from "./DisplayColorCollectionDetailForm";
import { withoutTemporaryIds } from "../../../hooks/libraries/colorCollections/useAddColors";
import { Drawer as DrawerType } from "../../../hooks/drawer/useGenericDrawer";
import { DirtyFormPrompt } from "../../form/DirtyFormPrompt";
import { queryKeyForColorCollection } from "../../../hooks/libraries/colorCollections/useColorCollection";
import { ALL_PUBLISHED_STATES } from "../../../helper/publishedState";

type ColorCollectionDetailFormProps = {
  colorCollection: FusionMelcoModelsColorCollection;
  refetch: () => Promise<
    QueryObserverResult<FusionMelcoModelsColorCollection, unknown>
  >;
  referencesDrawer: DrawerType;
  queryKey: UseApiQueryKey;
};

type ColorCollectionDetailFormValueType = {
  id: string;
  name: string;
  color_list: FusionMelcoModelsColorListItem[];
  submitAction: SubmitAction;
};

export const colorCollectionToInitialValues = (
  colorCollection: FusionMelcoModelsColorCollection
) => {
  const { id, name, color_list } = colorCollection;

  const initialValues: ColorCollectionDetailFormValueType = {
    id: id!,
    name: name ?? "",
    color_list: color_list ?? [],
    submitAction: "save",
  };

  return initialValues;
};

export const ColorCollectionDetailForm: React.FC<
  ColorCollectionDetailFormProps
> = ({ colorCollection, refetch, referencesDrawer, queryKey }) => {
  const queryClient = useQueryClient();
  const authenticatedApiConfig = useAuthenticatedAPIConfig();
  const { id } = colorCollection;

  const onSubmit = async (
    values: ColorCollectionDetailFormValueType,
    formikHelpers: FormikHelpers<ColorCollectionDetailFormValueType>
  ) => {
    const { submitAction } = values;

    if (submitAction === "publish") {
      await onPublish(values, formikHelpers, { isManuallyResettingForm: true });
    } else {
      await onSave(values, formikHelpers, { isManuallyResettingForm: true });
    }
  };

  const handleSaveAndPublish = async (
    values: ColorCollectionDetailFormValueType,
    formikHelpers: FormikHelpers<ColorCollectionDetailFormValueType>,
    options: {
      publish: boolean;
    }
  ) => {
    const { publish } = options;
    const { name, color_list } = values;

    const api = new UserContentsDraftPublishApi(authenticatedApiConfig());

    const entityAttributes: UserContentsDraftPublishApiUserColorCollectionUpdateDraftRequest =
      {
        id: id!,
        melcoModelsUpdateColorCollection: {
          name,
          color_list: withoutTemporaryIds(color_list),
        },
      };

    let newEntity = await api.userColorCollectionUpdateDraft(entityAttributes);

    if (publish) {
      newEntity = await api.userColorCollectionPublish({
        id: id!,
      });
    }

    // update ui with new data
    formikHelpers.resetForm({
      values: colorCollectionToInitialValues(newEntity),
    });
    queryClient.setQueryData(queryKey, newEntity);

    return {
      successMessages: [
        {
          message: "default",
        },
      ],
    };
  };

  const formSubmitWithPublish = (publish: boolean) => {
    return [
      async (
        values: ColorCollectionDetailFormValueType,
        formikHelpers: FormikHelpers<ColorCollectionDetailFormValueType>
      ) => {
        return handleSaveAndPublish(values, formikHelpers, {
          publish,
        });
      },
      {
        translationPrefix: [
          "libraries.color_collections.detail",
          publish ? "publish" : "save",
        ].join("."),
        onError: () => {
          // refetch entity from server
          queryClient.invalidateQueries(
            queryKeyForColorCollection(
              colorCollection.id!,
              ALL_PUBLISHED_STATES
            )
          );
        },
      },
    ] as const;
  };

  const onSave = useFormSubmit(...formSubmitWithPublish(false));
  const onPublish = useFormSubmit(...formSubmitWithPublish(true));

  const initialValues = colorCollectionToInitialValues(colorCollection);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      <DirtyFormPrompt>
        <DisplayColorCollectionDetailForm
          colorCollection={colorCollection}
          refetch={refetch}
          referencesDrawer={referencesDrawer}
        />
      </DirtyFormPrompt>
    </Formik>
  );
};
