/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoModelsReferences,
    MelcoModelsReferencesFromJSON,
    MelcoModelsReferencesFromJSONTyped,
    MelcoModelsReferencesToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoModelsDesignReferences
 */
export interface MelcoModelsDesignReferences {
    /**
     * 
     * @type {Array<MelcoModelsReferences>}
     * @memberof MelcoModelsDesignReferences
     */
    custom_product_list?: Array<MelcoModelsReferences> | null;
    /**
     * 
     * @type {Array<MelcoModelsReferences>}
     * @memberof MelcoModelsDesignReferences
     */
    design_collection_list?: Array<MelcoModelsReferences> | null;
    /**
     * 
     * @type {Array<MelcoModelsReferences>}
     * @memberof MelcoModelsDesignReferences
     */
    mapping_list?: Array<MelcoModelsReferences> | null;
}

export function MelcoModelsDesignReferencesFromJSON(json: any): MelcoModelsDesignReferences {
    return MelcoModelsDesignReferencesFromJSONTyped(json, false);
}

export function MelcoModelsDesignReferencesFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsDesignReferences {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'custom_product_list': !exists(json, 'custom_product_list') ? undefined : (json['custom_product_list'] === null ? null : (json['custom_product_list'] as Array<any>).map(MelcoModelsReferencesFromJSON)),
        'design_collection_list': !exists(json, 'design_collection_list') ? undefined : (json['design_collection_list'] === null ? null : (json['design_collection_list'] as Array<any>).map(MelcoModelsReferencesFromJSON)),
        'mapping_list': !exists(json, 'mapping_list') ? undefined : (json['mapping_list'] === null ? null : (json['mapping_list'] as Array<any>).map(MelcoModelsReferencesFromJSON)),
    };
}

export function MelcoModelsDesignReferencesToJSON(value?: MelcoModelsDesignReferences | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'custom_product_list': value.custom_product_list === undefined ? undefined : (value.custom_product_list === null ? null : (value.custom_product_list as Array<any>).map(MelcoModelsReferencesToJSON)),
        'design_collection_list': value.design_collection_list === undefined ? undefined : (value.design_collection_list === null ? null : (value.design_collection_list as Array<any>).map(MelcoModelsReferencesToJSON)),
        'mapping_list': value.mapping_list === undefined ? undefined : (value.mapping_list === null ? null : (value.mapping_list as Array<any>).map(MelcoModelsReferencesToJSON)),
    };
}


