/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoModelsDesignElement,
    MelcoModelsDesignElementFromJSON,
    MelcoModelsDesignElementFromJSONTyped,
    MelcoModelsDesignElementToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoModelsUpdateDesign
 */
export interface MelcoModelsUpdateDesign {
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUpdateDesign
     */
    name?: string | null;
    /**
     * 
     * @type {Array<MelcoModelsDesignElement>}
     * @memberof MelcoModelsUpdateDesign
     */
    element_list?: Array<MelcoModelsDesignElement> | null;
}

export function MelcoModelsUpdateDesignFromJSON(json: any): MelcoModelsUpdateDesign {
    return MelcoModelsUpdateDesignFromJSONTyped(json, false);
}

export function MelcoModelsUpdateDesignFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsUpdateDesign {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'element_list': !exists(json, 'element_list') ? undefined : (json['element_list'] === null ? null : (json['element_list'] as Array<any>).map(MelcoModelsDesignElementFromJSON)),
    };
}

export function MelcoModelsUpdateDesignToJSON(value?: MelcoModelsUpdateDesign | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'element_list': value.element_list === undefined ? undefined : (value.element_list === null ? null : (value.element_list as Array<any>).map(MelcoModelsDesignElementToJSON)),
    };
}


