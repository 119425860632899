import { DesignTokens } from "melco-ui";
import { FusionMelcoFusionModelsCustomProductListItem } from "melco-shared-logic";
import { useProductThumbnailURL } from "../../../hooks/customization/products/useProductThumbnailURL";
import { ListThumbnail } from "../../shared/ListThumbnail";

const { spacings } = DesignTokens;

type ProductsListThumbnailProps = {
  product: FusionMelcoFusionModelsCustomProductListItem;
};

export const ProductsListThumbnail: React.FC<ProductsListThumbnailProps> = ({
  product,
}) => {
  const thumbnailSrc = useProductThumbnailURL(
    product,
    spacings.xlarge,
    spacings.xlarge
  );

  return <ListThumbnail thumbnailSrc={thumbnailSrc ?? undefined} />;
};
