/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MelcoFusionModelsFusionDefaultAppSetting,
    MelcoFusionModelsFusionDefaultAppSettingFromJSON,
    MelcoFusionModelsFusionDefaultAppSettingToJSON,
    MelcoInternalModelsControlCenterAppSetting,
    MelcoInternalModelsControlCenterAppSettingFromJSON,
    MelcoInternalModelsControlCenterAppSettingToJSON,
    MelcoModelsBoolResponse,
    MelcoModelsBoolResponseFromJSON,
    MelcoModelsBoolResponseToJSON,
    MelcoModelsCancelSerialNumber,
    MelcoModelsCancelSerialNumberFromJSON,
    MelcoModelsCancelSerialNumberToJSON,
    MelcoModelsError,
    MelcoModelsErrorFromJSON,
    MelcoModelsErrorToJSON,
    MelcoModelsGenerateSerialNumber,
    MelcoModelsGenerateSerialNumberFromJSON,
    MelcoModelsGenerateSerialNumberToJSON,
    MelcoModelsGenericList1MelcoModelsDocContent,
    MelcoModelsGenericList1MelcoModelsDocContentFromJSON,
    MelcoModelsGenericList1MelcoModelsDocContentToJSON,
    MelcoModelsGenericList1MelcoModelsProductListItem,
    MelcoModelsGenericList1MelcoModelsProductListItemFromJSON,
    MelcoModelsGenericList1MelcoModelsProductListItemToJSON,
    MelcoModelsGenericList1MelcoModelsProductPermissions,
    MelcoModelsGenericList1MelcoModelsProductPermissionsFromJSON,
    MelcoModelsGenericList1MelcoModelsProductPermissionsToJSON,
    MelcoModelsGenericList1MelcoModelsProductScopeListItem,
    MelcoModelsGenericList1MelcoModelsProductScopeListItemFromJSON,
    MelcoModelsGenericList1MelcoModelsProductScopeListItemToJSON,
    MelcoModelsGenericList1MelcoModelsProductVersionListItem,
    MelcoModelsGenericList1MelcoModelsProductVersionListItemFromJSON,
    MelcoModelsGenericList1MelcoModelsProductVersionListItemToJSON,
    MelcoModelsGenericList1MelcoModelsRole,
    MelcoModelsGenericList1MelcoModelsRoleFromJSON,
    MelcoModelsGenericList1MelcoModelsRoleToJSON,
    MelcoModelsGenericList1MelcoModelsSerialNumberListItem,
    MelcoModelsGenericList1MelcoModelsSerialNumberListItemFromJSON,
    MelcoModelsGenericList1MelcoModelsSerialNumberListItemToJSON,
    MelcoModelsGenericList1MelcoModelsSubscriptionListItem,
    MelcoModelsGenericList1MelcoModelsSubscriptionListItemFromJSON,
    MelcoModelsGenericList1MelcoModelsSubscriptionListItemToJSON,
    MelcoModelsProduct,
    MelcoModelsProductFromJSON,
    MelcoModelsProductToJSON,
    MelcoModelsUpdateProduct,
    MelcoModelsUpdateProductFromJSON,
    MelcoModelsUpdateProductToJSON,
} from '../models';

export interface SystemManagementApiGetRolesRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    searchText?: string;
}

export interface SystemManagementApiProductLicensesCancelRequest {
    serialNumber: string;
    melcoModelsCancelSerialNumber?: MelcoModelsCancelSerialNumber;
}

export interface SystemManagementApiProductLicensesExportRequest {
    searchText?: string;
    serialNumber?: string;
    productCodes?: string;
    subscriptionCode?: string;
    referralCode?: string;
    batch?: string;
    cancelled?: string;
    offset?: number;
    limit?: number;
    sortBy?: string;
    versionId?: string;
}

export interface SystemManagementApiProductLicensesGenerateRequest {
    melcoModelsGenerateSerialNumber?: MelcoModelsGenerateSerialNumber;
}

export interface SystemManagementApiProductLicensesSearchRequest {
    searchText?: string;
    serialNumber?: string;
    productCodes?: string;
    subscriptionCode?: string;
    referralCode?: string;
    batch?: string;
    cancelled?: string;
    offset?: number;
    limit?: number;
    sortBy?: string;
    versionId?: string;
    typeList?: string;
}

export interface SystemManagementApiProductPermissionsRequest {
    productCodeList?: string;
}

export interface SystemManagementApiProductsDocumentRequest {
    productCode: string;
    id: string;
    lang?: string;
}

export interface SystemManagementApiProductsEditProductRequest {
    productCode: string;
    melcoModelsUpdateProduct?: MelcoModelsUpdateProduct;
}

export interface SystemManagementApiProductsGetListRequest {
    searchText?: string;
    offset?: number;
    limit?: number;
    sortBy?: string;
}

export interface SystemManagementApiProductsGetModuleListRequest {
    productCodeList?: string;
    versionNumberList?: string;
}

export interface SystemManagementApiProductsGetProductRequest {
    productCode: string;
}

export interface SystemManagementApiProductsGetVersionListRequest {
    productCode: string;
    searchText?: string;
    offset?: number;
    limit?: number;
    sortBy?: string;
}

export interface SystemManagementApiProductsPdfDocumentRequest {
    productCode: string;
    id: string;
    download?: boolean;
}

export interface SystemManagementApiProductsScopesRequest {
    productCodeList?: string;
}

/**
 * 
 */
export class SystemManagementApi extends runtime.BaseAPI {

    /**
     * get roles
     */
    async getRolesRaw(requestParameters: SystemManagementApiGetRolesRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsRole>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/roles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsRoleFromJSON(jsonValue));
    }

    /**
     * get roles
     */
    async getRoles(requestParameters: SystemManagementApiGetRolesRequest): Promise<MelcoModelsGenericList1MelcoModelsRole> {
        const response = await this.getRolesRaw(requestParameters);
        return await response.value();
    }

    /**
     * cancel serial number
     * cancel serial number
     */
    async productLicensesCancelRaw(requestParameters: SystemManagementApiProductLicensesCancelRequest): Promise<runtime.ApiResponse<MelcoModelsBoolResponse>> {
        if (requestParameters.serialNumber === null || requestParameters.serialNumber === undefined) {
            throw new runtime.RequiredError('serialNumber','Required parameter requestParameters.serialNumber was null or undefined when calling productLicensesCancel.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/products/licenses/{serial_number}/cancel`.replace(`{${"serial_number"}}`, encodeURIComponent(String(requestParameters.serialNumber))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsCancelSerialNumberToJSON(requestParameters.melcoModelsCancelSerialNumber),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     * cancel serial number
     * cancel serial number
     */
    async productLicensesCancel(requestParameters: SystemManagementApiProductLicensesCancelRequest): Promise<MelcoModelsBoolResponse> {
        const response = await this.productLicensesCancelRaw(requestParameters);
        return await response.value();
    }

    /**
     * export licenses list
     * export licenses list
     */
    async productLicensesExportRaw(requestParameters: SystemManagementApiProductLicensesExportRequest): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.serialNumber !== undefined) {
            queryParameters['serial_number'] = requestParameters.serialNumber;
        }

        if (requestParameters.productCodes !== undefined) {
            queryParameters['product_codes'] = requestParameters.productCodes;
        }

        if (requestParameters.subscriptionCode !== undefined) {
            queryParameters['subscription_code'] = requestParameters.subscriptionCode;
        }

        if (requestParameters.referralCode !== undefined) {
            queryParameters['referral_code'] = requestParameters.referralCode;
        }

        if (requestParameters.batch !== undefined) {
            queryParameters['batch'] = requestParameters.batch;
        }

        if (requestParameters.cancelled !== undefined) {
            queryParameters['cancelled'] = requestParameters.cancelled;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.versionId !== undefined) {
            queryParameters['version_id'] = requestParameters.versionId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/products/licenses/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * export licenses list
     * export licenses list
     */
    async productLicensesExport(requestParameters: SystemManagementApiProductLicensesExportRequest): Promise<Blob> {
        const response = await this.productLicensesExportRaw(requestParameters);
        return await response.value();
    }

    /**
     * generate serial numbers
     * generate serial numbers
     */
    async productLicensesGenerateRaw(requestParameters: SystemManagementApiProductLicensesGenerateRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsSerialNumberListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/products/licenses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsGenerateSerialNumberToJSON(requestParameters.melcoModelsGenerateSerialNumber),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsSerialNumberListItemFromJSON(jsonValue));
    }

    /**
     * generate serial numbers
     * generate serial numbers
     */
    async productLicensesGenerate(requestParameters: SystemManagementApiProductLicensesGenerateRequest): Promise<MelcoModelsGenericList1MelcoModelsSerialNumberListItem> {
        const response = await this.productLicensesGenerateRaw(requestParameters);
        return await response.value();
    }

    /**
     * get licenses list
     */
    async productLicensesSearchRaw(requestParameters: SystemManagementApiProductLicensesSearchRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsSerialNumberListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.serialNumber !== undefined) {
            queryParameters['serial_number'] = requestParameters.serialNumber;
        }

        if (requestParameters.productCodes !== undefined) {
            queryParameters['product_codes'] = requestParameters.productCodes;
        }

        if (requestParameters.subscriptionCode !== undefined) {
            queryParameters['subscription_code'] = requestParameters.subscriptionCode;
        }

        if (requestParameters.referralCode !== undefined) {
            queryParameters['referral_code'] = requestParameters.referralCode;
        }

        if (requestParameters.batch !== undefined) {
            queryParameters['batch'] = requestParameters.batch;
        }

        if (requestParameters.cancelled !== undefined) {
            queryParameters['cancelled'] = requestParameters.cancelled;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.versionId !== undefined) {
            queryParameters['version_id'] = requestParameters.versionId;
        }

        if (requestParameters.typeList !== undefined) {
            queryParameters['type_list'] = requestParameters.typeList;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/products/licenses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsSerialNumberListItemFromJSON(jsonValue));
    }

    /**
     * get licenses list
     */
    async productLicensesSearch(requestParameters: SystemManagementApiProductLicensesSearchRequest): Promise<MelcoModelsGenericList1MelcoModelsSerialNumberListItem> {
        const response = await this.productLicensesSearchRaw(requestParameters);
        return await response.value();
    }

    /**
     * get permissions for products
     */
    async productPermissionsRaw(requestParameters: SystemManagementApiProductPermissionsRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsProductPermissions>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.productCodeList !== undefined) {
            queryParameters['product_code_list'] = requestParameters.productCodeList;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/products/permissions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsProductPermissionsFromJSON(jsonValue));
    }

    /**
     * get permissions for products
     */
    async productPermissions(requestParameters: SystemManagementApiProductPermissionsRequest): Promise<MelcoModelsGenericList1MelcoModelsProductPermissions> {
        const response = await this.productPermissionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * get api service default app settings
     * get api service default app settings
     */
    async productSettingsGetApiServiceAppSettingRaw(): Promise<runtime.ApiResponse<MelcoFusionModelsFusionDefaultAppSetting>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/products/api_service/settings/app`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoFusionModelsFusionDefaultAppSettingFromJSON(jsonValue));
    }

    /**
     * get api service default app settings
     * get api service default app settings
     */
    async productSettingsGetApiServiceAppSetting(): Promise<MelcoFusionModelsFusionDefaultAppSetting> {
        const response = await this.productSettingsGetApiServiceAppSettingRaw();
        return await response.value();
    }

    /**
     * get control center app settings
     */
    async productSettingsGetControlCenterAppSettingRaw(): Promise<runtime.ApiResponse<MelcoInternalModelsControlCenterAppSetting>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/products/control_center/settings/app`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoInternalModelsControlCenterAppSettingFromJSON(jsonValue));
    }

    /**
     * get control center app settings
     */
    async productSettingsGetControlCenterAppSetting(): Promise<MelcoInternalModelsControlCenterAppSetting> {
        const response = await this.productSettingsGetControlCenterAppSettingRaw();
        return await response.value();
    }

    /**
     * get fusion default app settings
     * get fusion default app settings
     */
    async productSettingsGetFusionAppSettingRaw(): Promise<runtime.ApiResponse<MelcoFusionModelsFusionDefaultAppSetting>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/products/fusion/settings/app`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoFusionModelsFusionDefaultAppSettingFromJSON(jsonValue));
    }

    /**
     * get fusion default app settings
     * get fusion default app settings
     */
    async productSettingsGetFusionAppSetting(): Promise<MelcoFusionModelsFusionDefaultAppSetting> {
        const response = await this.productSettingsGetFusionAppSettingRaw();
        return await response.value();
    }

    /**
     * get document (supported types: serviceterms)
     * get document
     */
    async productsDocumentRaw(requestParameters: SystemManagementApiProductsDocumentRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsDocContent>> {
        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling productsDocument.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productsDocument.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.lang !== undefined) {
            queryParameters['Lang'] = requestParameters.lang;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/products/{product_code}/documents/{Id}`.replace(`{${"product_code"}}`, encodeURIComponent(String(requestParameters.productCode))).replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsDocContentFromJSON(jsonValue));
    }

    /**
     * get document (supported types: serviceterms)
     * get document
     */
    async productsDocument(requestParameters: SystemManagementApiProductsDocumentRequest): Promise<MelcoModelsGenericList1MelcoModelsDocContent> {
        const response = await this.productsDocumentRaw(requestParameters);
        return await response.value();
    }

    /**
     * edit product
     * edit product
     */
    async productsEditProductRaw(requestParameters: SystemManagementApiProductsEditProductRequest): Promise<runtime.ApiResponse<MelcoModelsProduct>> {
        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling productsEditProduct.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/products/{product_code}`.replace(`{${"product_code"}}`, encodeURIComponent(String(requestParameters.productCode))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsUpdateProductToJSON(requestParameters.melcoModelsUpdateProduct),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsProductFromJSON(jsonValue));
    }

    /**
     * edit product
     * edit product
     */
    async productsEditProduct(requestParameters: SystemManagementApiProductsEditProductRequest): Promise<MelcoModelsProduct> {
        const response = await this.productsEditProductRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get list of products
     */
    async productsGetListRaw(requestParameters: SystemManagementApiProductsGetListRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsProductListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/products`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsProductListItemFromJSON(jsonValue));
    }

    /**
     * Get list of products
     */
    async productsGetList(requestParameters: SystemManagementApiProductsGetListRequest): Promise<MelcoModelsGenericList1MelcoModelsProductListItem> {
        const response = await this.productsGetListRaw(requestParameters);
        return await response.value();
    }

    /**
     * get list of product subscriptions.
     * get product subscriptions list
     */
    async productsGetModuleListRaw(requestParameters: SystemManagementApiProductsGetModuleListRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsSubscriptionListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.productCodeList !== undefined) {
            queryParameters['product_code_list'] = requestParameters.productCodeList;
        }

        if (requestParameters.versionNumberList !== undefined) {
            queryParameters['version_number_list'] = requestParameters.versionNumberList;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/products/subscriptions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsSubscriptionListItemFromJSON(jsonValue));
    }

    /**
     * get list of product subscriptions.
     * get product subscriptions list
     */
    async productsGetModuleList(requestParameters: SystemManagementApiProductsGetModuleListRequest): Promise<MelcoModelsGenericList1MelcoModelsSubscriptionListItem> {
        const response = await this.productsGetModuleListRaw(requestParameters);
        return await response.value();
    }

    /**
     * get product
     * get product
     */
    async productsGetProductRaw(requestParameters: SystemManagementApiProductsGetProductRequest): Promise<runtime.ApiResponse<MelcoModelsProduct>> {
        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling productsGetProduct.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/products/{product_code}`.replace(`{${"product_code"}}`, encodeURIComponent(String(requestParameters.productCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsProductFromJSON(jsonValue));
    }

    /**
     * get product
     * get product
     */
    async productsGetProduct(requestParameters: SystemManagementApiProductsGetProductRequest): Promise<MelcoModelsProduct> {
        const response = await this.productsGetProductRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get list of product version
     */
    async productsGetVersionListRaw(requestParameters: SystemManagementApiProductsGetVersionListRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsProductVersionListItem>> {
        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling productsGetVersionList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/products/{product_code}/versions`.replace(`{${"product_code"}}`, encodeURIComponent(String(requestParameters.productCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsProductVersionListItemFromJSON(jsonValue));
    }

    /**
     * Get list of product version
     */
    async productsGetVersionList(requestParameters: SystemManagementApiProductsGetVersionListRequest): Promise<MelcoModelsGenericList1MelcoModelsProductVersionListItem> {
        const response = await this.productsGetVersionListRaw(requestParameters);
        return await response.value();
    }

    /**
     * get pdf document
     * get pdf document
     */
    async productsPdfDocumentRaw(requestParameters: SystemManagementApiProductsPdfDocumentRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling productsPdfDocument.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling productsPdfDocument.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.download !== undefined) {
            queryParameters['Download'] = requestParameters.download;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/products/{product_code}/documents/pdf/{Id}`.replace(`{${"product_code"}}`, encodeURIComponent(String(requestParameters.productCode))).replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * get pdf document
     * get pdf document
     */
    async productsPdfDocument(requestParameters: SystemManagementApiProductsPdfDocumentRequest): Promise<void> {
        await this.productsPdfDocumentRaw(requestParameters);
    }

    /**
     * Get list of product scopes
     */
    async productsScopesRaw(requestParameters: SystemManagementApiProductsScopesRequest): Promise<runtime.ApiResponse<MelcoModelsGenericList1MelcoModelsProductScopeListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.productCodeList !== undefined) {
            queryParameters['product_code_list'] = requestParameters.productCodeList;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/products/apikeys/scopes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsGenericList1MelcoModelsProductScopeListItemFromJSON(jsonValue));
    }

    /**
     * Get list of product scopes
     */
    async productsScopes(requestParameters: SystemManagementApiProductsScopesRequest): Promise<MelcoModelsGenericList1MelcoModelsProductScopeListItem> {
        const response = await this.productsScopesRaw(requestParameters);
        return await response.value();
    }

    /**
     * start beta subscription
     */
    async subscriptionStartBetaRaw(): Promise<runtime.ApiResponse<MelcoModelsBoolResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/products/subscriptions/reporting/start/beta`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsBoolResponseFromJSON(jsonValue));
    }

    /**
     * start beta subscription
     */
    async subscriptionStartBeta(): Promise<MelcoModelsBoolResponse> {
        const response = await this.subscriptionStartBetaRaw();
        return await response.value();
    }

}
