/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MelcoModelsCreateSupplier
 */
export interface MelcoModelsCreateSupplier {
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsCreateSupplier
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsCreateSupplier
     */
    supplier_type?: string | null;
}

export function MelcoModelsCreateSupplierFromJSON(json: any): MelcoModelsCreateSupplier {
    return MelcoModelsCreateSupplierFromJSONTyped(json, false);
}

export function MelcoModelsCreateSupplierFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsCreateSupplier {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'supplier_type': !exists(json, 'supplier_type') ? undefined : json['supplier_type'],
    };
}

export function MelcoModelsCreateSupplierToJSON(value?: MelcoModelsCreateSupplier | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'supplier_type': value.supplier_type,
    };
}


