import {
  useAuthenticatedAPIConfig,
  useFormSubmit,
  UserAccountApi,
} from "melco-shared-logic";

export type ConfiguratorSettingsFormValueType = {
  version?: number;
};

export const useConfiguratorSettingsFormSubmit = () => {
  const authenticatedApiConfig = useAuthenticatedAPIConfig();

  const onSubmit = useFormSubmit<ConfiguratorSettingsFormValueType>(
    async (values: ConfiguratorSettingsFormValueType, { resetForm }) => {
      const { version } = values;

      const api = new UserAccountApi(authenticatedApiConfig());
      await api.accountProductSettingsEditFusionConfiguratorSetting({
        melcoFusionModelsFusionConfiguratorSetting: {
          version,
        },
      });

      resetForm({ values });

      return { successMessages: [{ message: "default" }] };
    },
    { translationPrefix: "settings.configurator" }
  );

  return onSubmit;
};
