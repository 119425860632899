import {
  FusionMelcoModelsUserImage,
  useAPIQuery,
  UserContentsDraftPublishApi,
  useTable,
} from "melco-shared-logic";
import isEmpty from "lodash-es/isEmpty";
import {
  ALL_PUBLISHED_STATES,
  PublishState,
} from "../../../../helper/publishedState";
import { useTableErrorComponent } from "../../../table/useTableErrorComponent";

export const useBlankPictures = (publishedState?: PublishState) => {
  const {
    onTableConfigChange,
    pagination,
    setTotal,
    filters,
    sorter,
    currentPage,
    pageSize,
    sortBy,
    searchQuery,
    searchQueryChange,
  } = useTable<FusionMelcoModelsUserImage>();

  const publishState = publishedState ?? ALL_PUBLISHED_STATES;

  const query = useAPIQuery(
    UserContentsDraftPublishApi,
    [
      "blankPictures",
      currentPage,
      pageSize,
      filters,
      sortBy(),
      searchQuery,
      publishState,
    ],
    async (api) => {
      const response = await api.userImageSearch({
        offset: (currentPage - 1) * pageSize,
        limit: pageSize,
        sortBy: sortBy(),
        publishState,
        searchText: !isEmpty(searchQuery) ? searchQuery : undefined,
      });

      setTotal(response.total);

      return response.list;
    },
    {
      queryOptions: {
        keepPreviousData: true,
      },
    }
  );

  const errorComponent = useTableErrorComponent(query.error, query.refetch);

  return {
    onTableConfigChange,
    pagination,
    setTotal,
    filters,
    sorter,
    searchQuery,
    searchQueryChange,
    errorComponent,
    ...query,
  };
};
