/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MelcoModelsApiKeyAuth,
    MelcoModelsApiKeyAuthFromJSON,
    MelcoModelsApiKeyAuthToJSON,
    MelcoModelsAuthToken,
    MelcoModelsAuthTokenFromJSON,
    MelcoModelsAuthTokenToJSON,
    MelcoModelsError,
    MelcoModelsErrorFromJSON,
    MelcoModelsErrorToJSON,
    MelcoModelsJwtAuth,
    MelcoModelsJwtAuthFromJSON,
    MelcoModelsJwtAuthToJSON,
    MelcoModelsMachineCloudAuth,
    MelcoModelsMachineCloudAuthFromJSON,
    MelcoModelsMachineCloudAuthToJSON,
    MelcoModelsMachineCloudLogin,
    MelcoModelsMachineCloudLoginFromJSON,
    MelcoModelsMachineCloudLoginToJSON,
    MelcoModelsMelcoAuth,
    MelcoModelsMelcoAuthFromJSON,
    MelcoModelsMelcoAuthToJSON,
    MelcoModelsMicroServiceAuth,
    MelcoModelsMicroServiceAuthFromJSON,
    MelcoModelsMicroServiceAuthToJSON,
    MelcoModelsSingleUseCodeAuth,
    MelcoModelsSingleUseCodeAuthFromJSON,
    MelcoModelsSingleUseCodeAuthToJSON,
    MelcoModelsSupportToken,
    MelcoModelsSupportTokenFromJSON,
    MelcoModelsSupportTokenToJSON,
    MelcoModelsVirtualAuth,
    MelcoModelsVirtualAuthFromJSON,
    MelcoModelsVirtualAuthToJSON,
    MelcoModelsVirtualLogin,
    MelcoModelsVirtualLoginFromJSON,
    MelcoModelsVirtualLoginToJSON,
} from '../models';

export interface AuthenticationApiAuthenticationRequest {
    token?: string;
}

export interface AuthenticationApiAuthenticationAuthenticateJwtRequest {
    melcoModelsJwtAuth?: MelcoModelsJwtAuth;
}

export interface AuthenticationApiAuthenticationAuthenticateJwtTokenRequest {
    melcoModelsJwtAuth?: MelcoModelsJwtAuth;
}

export interface AuthenticationApiAuthenticationAuthenticateMelcoRequest {
    melcoModelsMelcoAuth?: MelcoModelsMelcoAuth;
}

export interface AuthenticationApiAuthenticationAuthenticateMicroServiceRequest {
    melcoModelsMicroServiceAuth?: MelcoModelsMicroServiceAuth;
}

export interface AuthenticationApiAuthenticationLoginByApiKeyRequest {
    melcoModelsApiKeyAuth?: MelcoModelsApiKeyAuth;
}

export interface AuthenticationApiAuthenticationLoginBySingleUseCodeRequest {
    melcoModelsSingleUseCodeAuth?: MelcoModelsSingleUseCodeAuth;
}

export interface AuthenticationApiAuthenticationMachineCloudLoginRequest {
    melcoModelsMachineCloudAuth?: MelcoModelsMachineCloudAuth;
}

export interface AuthenticationApiAuthenticationVirtualLoginRequest {
    melcoModelsVirtualAuth?: MelcoModelsVirtualAuth;
}

export interface AuthenticationApiSupportValidateTokenRequest {
    melcoModelsSupportToken?: MelcoModelsSupportToken;
}

/**
 * 
 */
export class AuthenticationApi extends runtime.BaseAPI {

    /**
     * validate and return auth token
     * verify token
     */
    async authenticationRaw(requestParameters: AuthenticationApiAuthenticationRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.token !== undefined) {
            queryParameters['token'] = requestParameters.token;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/verify_token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * validate and return auth token
     * verify token
     */
    async authentication(requestParameters: AuthenticationApiAuthenticationRequest): Promise<string> {
        const response = await this.authenticationRaw(requestParameters);
        return await response.value();
    }

    /**
     * validate and return auth token
     * login using bearer authentication system
     */
    async authenticationAuthenticateJwtRaw(requestParameters: AuthenticationApiAuthenticationAuthenticateJwtRequest): Promise<runtime.ApiResponse<MelcoModelsAuthToken>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/auth/jwt`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsJwtAuthToJSON(requestParameters.melcoModelsJwtAuth),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsAuthTokenFromJSON(jsonValue));
    }

    /**
     * validate and return auth token
     * login using bearer authentication system
     */
    async authenticationAuthenticateJwt(requestParameters: AuthenticationApiAuthenticationAuthenticateJwtRequest): Promise<MelcoModelsAuthToken> {
        const response = await this.authenticationAuthenticateJwtRaw(requestParameters);
        return await response.value();
    }

    /**
     * validate and return auth token
     * login using bearer authentication system
     */
    async authenticationAuthenticateJwtTokenRaw(requestParameters: AuthenticationApiAuthenticationAuthenticateJwtTokenRequest): Promise<runtime.ApiResponse<MelcoModelsAuthToken>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/auth/token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsJwtAuthToJSON(requestParameters.melcoModelsJwtAuth),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsAuthTokenFromJSON(jsonValue));
    }

    /**
     * validate and return auth token
     * login using bearer authentication system
     */
    async authenticationAuthenticateJwtToken(requestParameters: AuthenticationApiAuthenticationAuthenticateJwtTokenRequest): Promise<MelcoModelsAuthToken> {
        const response = await this.authenticationAuthenticateJwtTokenRaw(requestParameters);
        return await response.value();
    }

    /**
     * validate and return auth token
     * login using melco authentication system
     */
    async authenticationAuthenticateMelcoRaw(requestParameters: AuthenticationApiAuthenticationAuthenticateMelcoRequest): Promise<runtime.ApiResponse<MelcoModelsAuthToken>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/auth/melco`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsMelcoAuthToJSON(requestParameters.melcoModelsMelcoAuth),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsAuthTokenFromJSON(jsonValue));
    }

    /**
     * validate and return auth token
     * login using melco authentication system
     */
    async authenticationAuthenticateMelco(requestParameters: AuthenticationApiAuthenticationAuthenticateMelcoRequest): Promise<MelcoModelsAuthToken> {
        const response = await this.authenticationAuthenticateMelcoRaw(requestParameters);
        return await response.value();
    }

    /**
     * AuthenticateMicroService
     */
    async authenticationAuthenticateMicroServiceRaw(requestParameters: AuthenticationApiAuthenticationAuthenticateMicroServiceRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/auth/microservice`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsMicroServiceAuthToJSON(requestParameters.melcoModelsMicroServiceAuth),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * AuthenticateMicroService
     */
    async authenticationAuthenticateMicroService(requestParameters: AuthenticationApiAuthenticationAuthenticateMicroServiceRequest): Promise<string> {
        const response = await this.authenticationAuthenticateMicroServiceRaw(requestParameters);
        return await response.value();
    }

    /**
     * validate and return auth token
     * login using api key
     */
    async authenticationLoginByApiKeyRaw(requestParameters: AuthenticationApiAuthenticationLoginByApiKeyRequest): Promise<runtime.ApiResponse<MelcoModelsAuthToken>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/auth/apikey`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsApiKeyAuthToJSON(requestParameters.melcoModelsApiKeyAuth),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsAuthTokenFromJSON(jsonValue));
    }

    /**
     * validate and return auth token
     * login using api key
     */
    async authenticationLoginByApiKey(requestParameters: AuthenticationApiAuthenticationLoginByApiKeyRequest): Promise<MelcoModelsAuthToken> {
        const response = await this.authenticationLoginByApiKeyRaw(requestParameters);
        return await response.value();
    }

    /**
     * validate and return auth token
     * login using single use code
     */
    async authenticationLoginBySingleUseCodeRaw(requestParameters: AuthenticationApiAuthenticationLoginBySingleUseCodeRequest): Promise<runtime.ApiResponse<MelcoModelsAuthToken>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/auth/singleusecode`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsSingleUseCodeAuthToJSON(requestParameters.melcoModelsSingleUseCodeAuth),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsAuthTokenFromJSON(jsonValue));
    }

    /**
     * validate and return auth token
     * login using single use code
     */
    async authenticationLoginBySingleUseCode(requestParameters: AuthenticationApiAuthenticationLoginBySingleUseCodeRequest): Promise<MelcoModelsAuthToken> {
        const response = await this.authenticationLoginBySingleUseCodeRaw(requestParameters);
        return await response.value();
    }

    /**
     * log off from melco authentication
     */
    async authenticationLogoffByApiKeyRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/apikey/logoff`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * log off from melco authentication
     */
    async authenticationLogoffByApiKey(): Promise<void> {
        await this.authenticationLogoffByApiKeyRaw();
    }

    /**
     * log off from melco authentication
     */
    async authenticationLogoffMelcoRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/melco/logoff`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * log off from melco authentication
     */
    async authenticationLogoffMelco(): Promise<void> {
        await this.authenticationLogoffMelcoRaw();
    }

    /**
     * validate user and return product features
     * login for machine cloud
     */
    async authenticationMachineCloudLoginRaw(requestParameters: AuthenticationApiAuthenticationMachineCloudLoginRequest): Promise<runtime.ApiResponse<MelcoModelsMachineCloudLogin>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/auth/melco/machinecloud`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsMachineCloudAuthToJSON(requestParameters.melcoModelsMachineCloudAuth),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsMachineCloudLoginFromJSON(jsonValue));
    }

    /**
     * validate user and return product features
     * login for machine cloud
     */
    async authenticationMachineCloudLogin(requestParameters: AuthenticationApiAuthenticationMachineCloudLoginRequest): Promise<MelcoModelsMachineCloudLogin> {
        const response = await this.authenticationMachineCloudLoginRaw(requestParameters);
        return await response.value();
    }

    /**
     * validate and return auth token
     * refresh melco authentication cookie (requires configuration from Melco)
     */
    async authenticationMelcoRefreshRaw(): Promise<runtime.ApiResponse<MelcoModelsAuthToken>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/melco/refresh`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsAuthTokenFromJSON(jsonValue));
    }

    /**
     * validate and return auth token
     * refresh melco authentication cookie (requires configuration from Melco)
     */
    async authenticationMelcoRefresh(): Promise<MelcoModelsAuthToken> {
        const response = await this.authenticationMelcoRefreshRaw();
        return await response.value();
    }

    /**
     * validate user and return product features
     * virtual login
     */
    async authenticationVirtualLoginRaw(requestParameters: AuthenticationApiAuthenticationVirtualLoginRequest): Promise<runtime.ApiResponse<MelcoModelsVirtualLogin>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/auth/melco/virtual`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsVirtualAuthToJSON(requestParameters.melcoModelsVirtualAuth),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsVirtualLoginFromJSON(jsonValue));
    }

    /**
     * validate user and return product features
     * virtual login
     */
    async authenticationVirtualLogin(requestParameters: AuthenticationApiAuthenticationVirtualLoginRequest): Promise<MelcoModelsVirtualLogin> {
        const response = await this.authenticationVirtualLoginRaw(requestParameters);
        return await response.value();
    }

    /**
     * Validate token and return auth token
     */
    async supportValidateTokenRaw(requestParameters: AuthenticationApiSupportValidateTokenRequest): Promise<runtime.ApiResponse<MelcoModelsAuthToken>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/auth/support/token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MelcoModelsSupportTokenToJSON(requestParameters.melcoModelsSupportToken),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MelcoModelsAuthTokenFromJSON(jsonValue));
    }

    /**
     * Validate token and return auth token
     */
    async supportValidateToken(requestParameters: AuthenticationApiSupportValidateTokenRequest): Promise<MelcoModelsAuthToken> {
        const response = await this.supportValidateTokenRaw(requestParameters);
        return await response.value();
    }

}
