/* tslint:disable */
/* eslint-disable */
/**
 * Melco Reporting APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ReportingMelcoModelsError,
    ReportingMelcoModelsErrorFromJSON,
    ReportingMelcoModelsErrorToJSON,
    ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsMachineListItem,
    ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsMachineListItemFromJSON,
    ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsMachineListItemToJSON,
} from '../models';

export interface MachinesApiStatisticsMachinesSearchRequest {
    offset?: number;
    limit?: number;
    sortBy?: string;
    searchText?: string;
}

/**
 * 
 */
export class MachinesApi extends runtime.BaseAPI {

    /**
     */
    async statisticsMachinesSearchRaw(requestParameters: MachinesApiStatisticsMachinesSearchRequest): Promise<runtime.ApiResponse<ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsMachineListItem>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        if (requestParameters.searchText !== undefined) {
            queryParameters['search_text'] = requestParameters.searchText;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/machines`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsMachineListItemFromJSON(jsonValue));
    }

    /**
     */
    async statisticsMachinesSearch(requestParameters: MachinesApiStatisticsMachinesSearchRequest): Promise<ReportingMelcoModelsGenericList1MelcoMachineStatisticsModelsMachineListItem> {
        const response = await this.statisticsMachinesSearchRaw(requestParameters);
        return await response.value();
    }

}
