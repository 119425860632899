/* tslint:disable */
/* eslint-disable */
/**
 * Melco Efs APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EfsMelcoEfsModelsOutputFormat
 */
export interface EfsMelcoEfsModelsOutputFormat {
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoEfsModelsOutputFormat
     */
    service_type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoEfsModelsOutputFormat
     */
    extension?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EfsMelcoEfsModelsOutputFormat
     */
    desc?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EfsMelcoEfsModelsOutputFormat
     */
    active?: boolean;
}

export function EfsMelcoEfsModelsOutputFormatFromJSON(json: any): EfsMelcoEfsModelsOutputFormat {
    return EfsMelcoEfsModelsOutputFormatFromJSONTyped(json, false);
}

export function EfsMelcoEfsModelsOutputFormatFromJSONTyped(json: any, ignoreDiscriminator: boolean): EfsMelcoEfsModelsOutputFormat {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'service_type': !exists(json, 'service_type') ? undefined : json['service_type'],
        'extension': !exists(json, 'extension') ? undefined : json['extension'],
        'desc': !exists(json, 'desc') ? undefined : json['desc'],
        'active': !exists(json, 'active') ? undefined : json['active'],
    };
}

export function EfsMelcoEfsModelsOutputFormatToJSON(value?: EfsMelcoEfsModelsOutputFormat | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'service_type': value.service_type,
        'extension': value.extension,
        'desc': value.desc,
        'active': value.active,
    };
}


