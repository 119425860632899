/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoModelsUserProductAccess,
    MelcoModelsUserProductAccessFromJSON,
    MelcoModelsUserProductAccessFromJSONTyped,
    MelcoModelsUserProductAccessToJSON,
    MelcoModelsUserRole,
    MelcoModelsUserRoleFromJSON,
    MelcoModelsUserRoleFromJSONTyped,
    MelcoModelsUserRoleToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoModelsUserProductPermissions
 */
export interface MelcoModelsUserProductPermissions {
    /**
     * 
     * @type {MelcoModelsUserRole}
     * @memberof MelcoModelsUserProductPermissions
     */
    role?: MelcoModelsUserRole;
    /**
     * 
     * @type {Array<MelcoModelsUserProductAccess>}
     * @memberof MelcoModelsUserProductPermissions
     */
    user_product_access?: Array<MelcoModelsUserProductAccess> | null;
}

export function MelcoModelsUserProductPermissionsFromJSON(json: any): MelcoModelsUserProductPermissions {
    return MelcoModelsUserProductPermissionsFromJSONTyped(json, false);
}

export function MelcoModelsUserProductPermissionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsUserProductPermissions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'role': !exists(json, 'role') ? undefined : MelcoModelsUserRoleFromJSON(json['role']),
        'user_product_access': !exists(json, 'user_product_access') ? undefined : (json['user_product_access'] === null ? null : (json['user_product_access'] as Array<any>).map(MelcoModelsUserProductAccessFromJSON)),
    };
}

export function MelcoModelsUserProductPermissionsToJSON(value?: MelcoModelsUserProductPermissions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'role': MelcoModelsUserRoleToJSON(value.role),
        'user_product_access': value.user_product_access === undefined ? undefined : (value.user_product_access === null ? null : (value.user_product_access as Array<any>).map(MelcoModelsUserProductAccessToJSON)),
    };
}


