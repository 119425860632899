/* tslint:disable */
/* eslint-disable */
/**
 * Melco Efs APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EfsMelcoModelsCustomWarning,
    EfsMelcoModelsCustomWarningFromJSON,
    EfsMelcoModelsCustomWarningFromJSONTyped,
    EfsMelcoModelsCustomWarningToJSON,
    EfsMelcoModelsProductUserListItem,
    EfsMelcoModelsProductUserListItemFromJSON,
    EfsMelcoModelsProductUserListItemFromJSONTyped,
    EfsMelcoModelsProductUserListItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface EfsMelcoModelsGenericList1MelcoModelsProductUserListItem
 */
export interface EfsMelcoModelsGenericList1MelcoModelsProductUserListItem {
    /**
     * 
     * @type {Array<EfsMelcoModelsProductUserListItem>}
     * @memberof EfsMelcoModelsGenericList1MelcoModelsProductUserListItem
     */
    list: Array<EfsMelcoModelsProductUserListItem>;
    /**
     * 
     * @type {Array<EfsMelcoModelsCustomWarning>}
     * @memberof EfsMelcoModelsGenericList1MelcoModelsProductUserListItem
     */
    warning_list?: Array<EfsMelcoModelsCustomWarning> | null;
    /**
     * 
     * @type {number}
     * @memberof EfsMelcoModelsGenericList1MelcoModelsProductUserListItem
     */
    total: number;
}

export function EfsMelcoModelsGenericList1MelcoModelsProductUserListItemFromJSON(json: any): EfsMelcoModelsGenericList1MelcoModelsProductUserListItem {
    return EfsMelcoModelsGenericList1MelcoModelsProductUserListItemFromJSONTyped(json, false);
}

export function EfsMelcoModelsGenericList1MelcoModelsProductUserListItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): EfsMelcoModelsGenericList1MelcoModelsProductUserListItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'list': ((json['list'] as Array<any>).map(EfsMelcoModelsProductUserListItemFromJSON)),
        'warning_list': !exists(json, 'warning_list') ? undefined : (json['warning_list'] === null ? null : (json['warning_list'] as Array<any>).map(EfsMelcoModelsCustomWarningFromJSON)),
        'total': json['total'],
    };
}

export function EfsMelcoModelsGenericList1MelcoModelsProductUserListItemToJSON(value?: EfsMelcoModelsGenericList1MelcoModelsProductUserListItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'list': ((value.list as Array<any>).map(EfsMelcoModelsProductUserListItemToJSON)),
        'warning_list': value.warning_list === undefined ? undefined : (value.warning_list === null ? null : (value.warning_list as Array<any>).map(EfsMelcoModelsCustomWarningToJSON)),
        'total': value.total,
    };
}


