import { FusionMelcoModelsDesign } from "melco-shared-logic";
import { StitchCanvas } from "@melco/renderer-react-component";
import styled from "styled-components";
import { useDesignRenderer } from "../../hooks/renderer/useDesignRenderer";
import { ZoomToDesignButton } from "../libraries/designs/ZoomToDesignButton";
import { ElementConfiguration } from "../../hooks/renderer/useRenderer";

const CanvasWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 800px;
`;

type RenderedDesignProps = {
  design: FusionMelcoModelsDesign;
  elementConfiguration: ElementConfiguration;
  showOrigin?: boolean;
};

export const RenderedDesign: React.FC<RenderedDesignProps> = ({
  design,
  elementConfiguration,
  showOrigin,
}) => {
  const {
    canvasId,
    initCallback,
    rendererState,
    isLoading,
    canvas,
    rendererDispatch,
    elementUtil,
  } = useDesignRenderer(design, elementConfiguration, showOrigin);

  const { scene, viewPort, renderOptions, animationParams } = rendererState;

  return (
    <CanvasWrapper>
      {isLoading && "Loading..."}

      <StitchCanvas
        canvasId={canvasId}
        initCallback={initCallback}
        scene={scene}
        viewPort={viewPort}
        renderOptions={renderOptions}
        animationParams={animationParams}
      />

      <ZoomToDesignButton
        canvas={canvas}
        rendererDispatch={rendererDispatch}
        elementUtil={elementUtil}
      />
    </CanvasWrapper>
  );
};
