import React from "react";
import { Col, Table, TableActionBarRow, ValueOrFallback } from "melco-ui";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useThreadSupplierProducts } from "../../../hooks/libraries/colors/useThreadSupplierProducts";
import { MelcoModelsSupplierProduct, Search } from "melco-shared-logic";

type ThreadSupplierProductsListProps = {
  onRowClick?: (row: MelcoModelsSupplierProduct) => void;
};

export const ThreadSupplierProductsList: React.FC<
  ThreadSupplierProductsListProps
> = ({ onRowClick }) => {
  const {
    isLoading,
    isFetching,
    data,
    isPreviousData,
    errorComponent,
    onTableConfigChange,
    pagination,
    searchQueryChange,
  } = useThreadSupplierProducts();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const columns = [
    {
      title: t("thread_supplier_products_list.columns.supplier_name"),
      key: "supplier_name",
      dataIndex: "supplier_name",
      sorter: true,
    },
    {
      title: t("thread_supplier_products_list.columns.name"),
      key: "name",
      dataIndex: "name",
      sorter: true,
      render: (_: string, item: MelcoModelsSupplierProduct) => (
        <ValueOrFallback
          value={item.name}
          fallback={t("global.fallback.empty")}
        />
      ),
    },
  ];

  return (
    <>
      <TableActionBarRow>
        <Col>
          <Search
            onSearchInputChange={(query: string) => searchQueryChange(query)}
          />
        </Col>
      </TableActionBarRow>
      <Table
        showSorterTooltip={false}
        sticky
        loading={isPreviousData ? isFetching : isLoading}
        dataSource={data}
        columns={columns}
        onChange={onTableConfigChange}
        pagination={pagination}
        rowKey="id"
        onRow={(row: MelcoModelsSupplierProduct) => {
          return {
            onClick: () => {
              if (onRowClick) {
                onRowClick(row);
              } else {
                navigate(`/libraries/colors/embroidery/${row.id}`);
              }
            },
          };
        }}
        rowClassName={() => "clickable"}
        noDataMessage={t("thread_supplier_products_list.empty")}
        errorComponent={errorComponent}
      />
    </>
  );
};
