/* tslint:disable */
/* eslint-disable */
/**
 * Melco Rest Services
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MelcoModelsCustomWarning,
    MelcoModelsCustomWarningFromJSON,
    MelcoModelsCustomWarningFromJSONTyped,
    MelcoModelsCustomWarningToJSON,
    MelcoModelsPreview,
    MelcoModelsPreviewFromJSON,
    MelcoModelsPreviewFromJSONTyped,
    MelcoModelsPreviewToJSON,
} from './';

/**
 * 
 * @export
 * @interface MelcoModelsUserImage
 */
export interface MelcoModelsUserImage {
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserImage
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserImage
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoModelsUserImage
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoModelsUserImage
     */
    height?: number;
    /**
     * 
     * @type {number}
     * @memberof MelcoModelsUserImage
     */
    dpi?: number;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserImage
     */
    signature?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserImage
     */
    file_name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoModelsUserImage
     */
    file_size?: number;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserImage
     */
    url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserImage
     */
    view_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserImage
     */
    color_name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserImage
     */
    color_hex?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MelcoModelsUserImage
     */
    publish_state?: number;
    /**
     * 
     * @type {Date}
     * @memberof MelcoModelsUserImage
     */
    created_date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MelcoModelsUserImage
     */
    publish_date?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof MelcoModelsUserImage
     */
    updated_date?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MelcoModelsUserImage
     */
    original_file_name?: string | null;
    /**
     * 
     * @type {Array<MelcoModelsPreview>}
     * @memberof MelcoModelsUserImage
     */
    previews?: Array<MelcoModelsPreview> | null;
    /**
     * 
     * @type {Array<MelcoModelsCustomWarning>}
     * @memberof MelcoModelsUserImage
     */
    warning_list?: Array<MelcoModelsCustomWarning> | null;
}

export function MelcoModelsUserImageFromJSON(json: any): MelcoModelsUserImage {
    return MelcoModelsUserImageFromJSONTyped(json, false);
}

export function MelcoModelsUserImageFromJSONTyped(json: any, ignoreDiscriminator: boolean): MelcoModelsUserImage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'width': !exists(json, 'width') ? undefined : json['width'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'dpi': !exists(json, 'dpi') ? undefined : json['dpi'],
        'signature': !exists(json, 'signature') ? undefined : json['signature'],
        'file_name': !exists(json, 'file_name') ? undefined : json['file_name'],
        'file_size': !exists(json, 'file_size') ? undefined : json['file_size'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'view_name': !exists(json, 'view_name') ? undefined : json['view_name'],
        'color_name': !exists(json, 'color_name') ? undefined : json['color_name'],
        'color_hex': !exists(json, 'color_hex') ? undefined : json['color_hex'],
        'publish_state': !exists(json, 'publish_state') ? undefined : json['publish_state'],
        'created_date': !exists(json, 'created_date') ? undefined : (new Date(json['created_date'])),
        'publish_date': !exists(json, 'publish_date') ? undefined : (json['publish_date'] === null ? null : new Date(json['publish_date'])),
        'updated_date': !exists(json, 'updated_date') ? undefined : (json['updated_date'] === null ? null : new Date(json['updated_date'])),
        'original_file_name': !exists(json, 'original_file_name') ? undefined : json['original_file_name'],
        'previews': !exists(json, 'previews') ? undefined : (json['previews'] === null ? null : (json['previews'] as Array<any>).map(MelcoModelsPreviewFromJSON)),
        'warning_list': !exists(json, 'warning_list') ? undefined : (json['warning_list'] === null ? null : (json['warning_list'] as Array<any>).map(MelcoModelsCustomWarningFromJSON)),
    };
}

export function MelcoModelsUserImageToJSON(value?: MelcoModelsUserImage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'width': value.width,
        'height': value.height,
        'dpi': value.dpi,
        'signature': value.signature,
        'file_name': value.file_name,
        'file_size': value.file_size,
        'url': value.url,
        'view_name': value.view_name,
        'color_name': value.color_name,
        'color_hex': value.color_hex,
        'publish_state': value.publish_state,
        'created_date': value.created_date === undefined ? undefined : (value.created_date.toISOString()),
        'publish_date': value.publish_date === undefined ? undefined : (value.publish_date === null ? null : value.publish_date.toISOString()),
        'updated_date': value.updated_date === undefined ? undefined : (value.updated_date === null ? null : value.updated_date.toISOString()),
        'original_file_name': value.original_file_name,
        'previews': value.previews === undefined ? undefined : (value.previews === null ? null : (value.previews as Array<any>).map(MelcoModelsPreviewToJSON)),
        'warning_list': value.warning_list === undefined ? undefined : (value.warning_list === null ? null : (value.warning_list as Array<any>).map(MelcoModelsCustomWarningToJSON)),
    };
}


